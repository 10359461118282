import { AvatarColors } from '@casecare/types';

export const AvatarColorOptions: Partial<Record<AvatarColors, string[]>> = {
  SKIN: ['#FEE1B9', '#FDC786', ' #FBC5A4', '#A87256', '#6F331D'],
  HAIR: ['#FFF5B7', '#A7856A', '#4E433F', '#c63927', '#000000'],
  EYEBROWS: ['#FFF5B7', '#A7856A', '#4E433F', '#c63927', '#000000'],
  BEARD: ['#FFF5B7', '#A7856A', '#4E433F', '#c63927', '#000000'],
  EYE: ['#5A6925', '#144B7A', '#8B572A', '#84837E'],
  MOUTH: ['#fe2400', '#fa8172', '#ee2939', '#7c0a01', '#ce5c5c'],
};
