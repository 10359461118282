import { AccordionDetails, Box, Button, Grid, Typography } from '@mui/material';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import { theme } from '../../../utils';
import { DndContext, useDroppable } from '@dnd-kit/core';
import EcoMapAvatarIcon from '../eco-map-avatar-icon/eco-map-avatar-icon';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import EcoMapApi from '../../../api/eco-map/eco-map';
import LeaderLine from 'leader-line-new';
import {
  EcoMapBackgroundWrapper,
  EcoMapSplitter,
  EcoMapUtilityButtons,
} from '../../../utils/styling/styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
} from '@fortawesome/free-solid-svg-icons';
import { ClientDataType } from '@casecare/types';
import EcoMapIconDraggable from '../eco-map-icon-map/eco-map-icon-draggable/eco-map-icon-draggable';
import EcoMapIconCircleSlice from '../eco-map-icon-map/eco-map-icon-circle-slice/eco-map-icon-circle-slice';
import html2canvas from 'html2canvas';

interface EcoMapPrintableMapProps {
  ecoMap: any;
  indexClient: string | undefined;
  expertMode: boolean;
  small?: boolean;
}
const EcoMapPrintableMap: FC<EcoMapPrintableMapProps> = (props) => {
  const [ecoMap, setEcoMap] = useState(props.ecoMap);
  const [positions, setPositions] = useState<any>(props.ecoMap.positions);
  const [connections, setConnections] = useState<any>(props.ecoMap.connections);
  const [segments, setSegments] = useState<any>(props.ecoMap.segments);
  const lines = useRef<LeaderLine[]>([]);

  const iconIdPrefix = 'eco-map-drag-icon-' + ecoMap.id + '-';

  const [disableDrag, setDisableDrag] = useState(false);
  const test = useRef(null);

  useEffect(() => {
    setEcoMap(props.ecoMap);
  }, [props.ecoMap]);

  useEffect(() => {
    lines.current = [];
    const lineArray = [];
    const removeDuplicated = [];
    if (connections) {
      for (const connection of connections) {
        const otherSide = connections.find(
          (conn: any) =>
            conn.clientFromId == connection.clientToId &&
            conn.clientToId == connection.clientFromId,
        );
        if (otherSide == undefined)
          lineArray.push({
            clientFromId: connection.clientFromId,
            clientToId: connection.clientToId,
            color: connection.color,
          });
        else {
          if (
            removeDuplicated.find((dup: any) => dup == connection.id) ==
            undefined
          ) {
            lineArray.push({
              clientFromId: connection.clientFromId,
              clientToId: otherSide.clientFromId,
              colorFrom: connection.color,
              colorTo: otherSide.color,
            });
            removeDuplicated.push(otherSide.id);
          }
        }
      }

      for (const connection of lineArray) {
        const start = document.getElementById(
          iconIdPrefix + connection.clientFromId,
        );
        const end = document.getElementById(
          iconIdPrefix + connection.clientToId,
        );
        if (start !== null && end !== null && start !== end)
          if (connection.colorFrom && connection.colorTo)
            lines.current.push(
              new LeaderLine(start, end, {
                startPlug: 'arrow3',
                endPlug: 'arrow3',
                path: 'straight',
                startPlugColor: connection.colorTo,
                endPlugColor: connection.colorFrom,
                size: 4,
                endPlugSize: 1.5,
                startPlugSize: 1.5,
                gradient: true,
              }),
            );
          else
            lines.current.push(
              new LeaderLine(start, end, {
                startPlug: 'behind',
                endPlug: 'arrow3',
                path: 'straight',
                color: connection.color,
                size: 4,
                endPlugSize: 1.5,
              }),
            );
      }
    }
  }, [lines.current, connections]);

  return (
    ecoMap &&
    positions &&
    connections && (
      <Box
        ref={test}
        sx={{
          minHeight: props.small ? '660px' : '1000px',
          maxHeight: props.small ? '660px' : '1000px',
          minWidth: props.small ? '660px' : '1000px',
          maxWidth: props.small ? '660px' : '1000px',
          width: '100%',
          position: 'relative',
          height: '100%',
          backgroundColor: theme.palette.grey[200],
          overflow: 'hidden',
        }}
      >
        <Box sx={EcoMapBackgroundWrapper}>
          <Box className="circle-wrapper">
            <Box sx={{ backgroundColor: ecoMap.outerCircleColor }}></Box>
            <Box sx={{ backgroundColor: ecoMap.innerCircleColor }}></Box>
            <Box sx={{ backgroundColor: ecoMap.middleCircleColor }}></Box>
          </Box>
          <Box className="text-wrapper">
            {ecoMap.segments.map((segment: any, index: any) => (
              <Typography
                key={segment.id}
                className={`text-${segment.seq}`}
                sx={{ fontSize: props.small ? '0.7rem' : '' }}
              >
                {segment.label}
              </Typography>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          {positions.length > 0 && (
            <>
              {positions.map((position: any) => (
                <EcoMapIconDraggable
                  id={iconIdPrefix + position.client.id}
                  key={position.id}
                  position={position}
                  setDisableDrag={setDisableDrag}
                  disableDrag={disableDrag}
                  disabled={true}
                  isIndex={props.indexClient == position.client.id}
                  expertMode={props.expertMode}
                  label={position.client[ClientDataType.FIRST_NAME]}
                  small={props.small}
                ></EcoMapIconDraggable>
              ))}
              <Box sx={EcoMapSplitter}>
                {segments.map((segment: any, index: any) => {
                  if (index >= 4) return <></>;
                  return (
                    <EcoMapIconCircleSlice
                      key={segment.id}
                      id={segment.id}
                      index={index + 1}
                      isSplitted={
                        segments.findIndex(
                          (seg: any) => seg.seq === segment.seq + 4,
                        ) !== -1
                          ? true
                          : false
                      }
                    ></EcoMapIconCircleSlice>
                  );
                })}
              </Box>
            </>
          )}
        </Box>
      </Box>
    )
  );
};

export default EcoMapPrintableMap;
