import { FC, useEffect, useState } from 'react';

import { ReactComponent as Zero } from '../../../assets/avatar/mood/0.svg';
import { ReactComponent as One } from '../../../assets/avatar/mood/1.svg';
import { ReactComponent as Two } from '../../../assets/avatar/mood/2.svg';
import { ReactComponent as Three } from '../../../assets/avatar/mood/3.svg';
import { ReactComponent as Four } from '../../../assets/avatar/mood/4.svg';
import { ReactComponent as Five } from '../../../assets/avatar/mood/5.svg';
import { ReactComponent as Six } from '../../../assets/avatar/mood/6.svg';
import { ReactComponent as Seven } from '../../../assets/avatar/mood/7.svg';
import { ReactComponent as Eight } from '../../../assets/avatar/mood/8.svg';
import { ReactComponent as Nine } from '../../../assets/avatar/mood/9.svg';
import { ReactComponent as Ten } from '../../../assets/avatar/mood/10.svg';
import { faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Zoom, Badge, IconButton, SvgIcon } from '@mui/material';

interface AvatarMoodButtonProps {
  mood: number;
  onCLick?: () => void;
  onNote?: () => void;
  big?: boolean;
  hide?: boolean;
  halfOpacity?: boolean;
  quaterOpacity?: boolean;
  showNote: boolean;
  greyNote: boolean;
  disabled?: boolean;
}

const moods = [
  {
    svg: <Zero />,
    value: 0,
  },
  {
    svg: <One />,
    value: 1,
  },
  {
    svg: <Two />,
    value: 2,
  },
  {
    svg: <Three />,
    value: 3,
  },
  {
    svg: <Four />,
    value: 4,
  },
  {
    svg: <Five />,
    value: 5,
  },
  {
    svg: <Six />,
    value: 6,
  },
  {
    svg: <Seven />,
    value: 7,
  },
  {
    svg: <Eight />,
    value: 8,
  },
  {
    svg: <Nine />,
    value: 9,
  },
  {
    svg: <Ten />,
    value: 10,
  },
];

const AvatarMoodButton: FC<AvatarMoodButtonProps> = (props) => {
  const [svg, setSvg] = useState(
    moods.find((m) => m.value === props.mood)?.svg,
  );

  useEffect(() => {
    setSvg(moods.find((m) => m.value === props.mood)?.svg);
  }, [props.mood]);

  return (
    <Zoom in={!props.hide}>
      <Badge
        sx={{
          color: '#1876d2',
          '& .MuiBadge-badge': Object.assign(
            {
              transform: 'translate(5px, -10px)',
            },
            props.greyNote && {
              filter: 'grayscale(1)',
              opacity: 0.5,
            },
          ),
        }}
        badgeContent={
          props.showNote && (
            <FontAwesomeIcon
              onClick={props.onNote}
              size="lg"
              style={{
                cursor: 'pointer',
              }}
              icon={faNoteSticky}
            />
          )
        }
      >
        <IconButton
          disableRipple
          sx={Object.assign(
            { padding: 0 },
            props.halfOpacity && {
              opacity: 0.5,
            },
            props.halfOpacity && {
              opacity: 0.2,
            },
            props.hide && {
              display: 'none',
            },
          )}
          onClick={props.onCLick ? props.onCLick : props.onNote}
          size="large"
          disabled={props.disabled}
        >
          <SvgIcon sx={{ fontSize: props.big ? 60 : 50 }} fontSize="large">
            {svg}
          </SvgIcon>
        </IconButton>
      </Badge>
    </Zoom>
  );
};

export default AvatarMoodButton;
