import { faPlus, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, IconButton, Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { context } from 'msw';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { EditProductDialog } from '../../../dialogs';
import { useQuery } from 'react-query';
import { AdminBillingApi } from '../../../../api';

export const ProductTable: FC = () => {
  const context = useContext(AppContext);

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);

  const [productId, setProductId] = useState<string | undefined>();

  useQuery(
    ['sevDeskProducts'],
    () => AdminBillingApi.getSevDeskProducts(context.authToken),
    {
      enabled: context.authToken !== undefined && context.sevDeskActive,
      onSuccess: (res) => {
        setRows(res.data);
      },
    },
  );

  const tableColumns: GridColDef[] = [
    {
      headerName: context.i18n.name,
      field: 'name',
      flex: 1,
    },
    {
      headerName: context.i18n.nettoRate,
      field: 'price',
      flex: 0.5,
    },
    {
      headerName: context.i18n.grossRate,
      field: 'grossPrice',
      flex: 1,
      renderCell: (params) => {
        const tax = (+params.row.price * params.row.taxRate) / 100;

        return <>{`${+params.row.price + tax}€ (${tax}€)`}</>;
      },
    },
    {
      field: 'actions',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Tooltip title={context.i18n.addCustomer}>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
            color="primary"
            disableRipple
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params: any) => (
        <Box>
          <Tooltip title={context.i18n.editCustomer}>
            <IconButton
              onClick={() => {
                setProductId(params.id);
                setOpen(true);
              }}
              color="primary"
              disableRipple
            >
              <FontAwesomeIcon icon={faPen} />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={context.i18n.deleteCustomer}>
            <IconButton
              onClick={() => {
                // onDelete(params.id);
              }}
              color="error"
              disableRipple
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip> */}
        </Box>
      ),
    },
  ];

  return (
    <>
      <DataGridPro
        sx={{
          fontSize: 17,
          m: 5,
          height: '90%',
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        disableColumnSelector={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        pageSizeOptions={[15]}
        autoHeight
      />
      <EditProductDialog
        id={productId}
        open={open}
        onClose={() => {
          setOpen(false);
          setProductId(undefined);
        }}
      />
    </>
  );
};
