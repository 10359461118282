import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Link } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../utils';

interface SubTabNavProps {
  setSelected?: (id: string) => void;
  selected?: string;
  subTabs: any[];
  onBack?: () => void;
  onClick?: () => void;
}

const SubTabNav: FC<SubTabNavProps> = (props) => {
  return (
    <Box
      sx={{
        mr: 3,
        ml: 3,
        p: props.subTabs.length > 0 ? 2 : 1,
        bgcolor: 'primary.main',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ ml: 2, display: 'flex' }}>
        {props.onBack && (
          <IconButton
            sx={{ color: 'white', padding: '0px' }}
            onClick={() => props.onBack && props.onBack()}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </IconButton>
        )}

        {props.subTabs.map((subTab, index) => (
          <Box
            key={subTab.id}
            sx={{ display: subTab.hidden ? 'none' : 'flex' }}
          >
            <Link
              sx={{
                color:
                  props.selected === subTab.id ? 'secondary.main' : 'white',
                mr: 2,
                ml: 2,
              }}
              component="button"
              variant="subtitle2"
              onClick={() => {
                if (subTab.url) {
                  window.open(subTab.url, '_blank');
                } else {
                  props.setSelected && props.setSelected(subTab.id);
                }
              }}
            >
              {subTab.name
                ? subTab.name
                : subTab.label
                ? subTab.label
                : subTab.title
                ? subTab.title
                : '{}.name/label/title'}
            </Link>
            {props.subTabs.length > index + 1 ? (
              <Divider
                orientation="vertical"
                sx={{ color: 'white', bgcolor: 'white' }}
                flexItem
              />
            ) : null}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SubTabNav;
