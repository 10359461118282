import { ClientDataType, FormFieldType } from '@casecare/types';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { ClientApi } from '../../../../api';
import { queryClient } from '../../../../utils';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { FormElement, FormElementHeader } from '../..';
import ClientDataField from '../client-data-field/client-data-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import AddClientHelpFormDialog from '../../../dialogs/add-client-help-form-dialog/add-client-help-form.dialog';
import { ClientHelpFormDataType } from '@casecare/types/lib/client';
import { useNavigate } from 'react-router-dom';

interface ClientDataHelpFromProps {
  saved: boolean;
  clientData: Record<ClientDataType, any>;
  care?: boolean;
}

const ClientDataHelpFrom: FC<ClientDataHelpFromProps> = (props) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [clientHelpForms, setClientHelpForms] = useState<any>([]);
  const [clientMissingHelpForms, setClientMissingHelpForms] = useState<any>([]);

  const [clientData, setClientData] = useState<Partial<Record<string, any>>>(
    props.clientData,
  );
  const [fieldChanges, setFieldChanges] = useState<
    Partial<Record<string, boolean>>
  >({});
  const [fieldValues, setFieldValues] = useState<Partial<Record<string, any>>>(
    {},
  );
  const [saved, setSaved] = useState(false);
  const [openAddHelpForm, setOpenAddHelpForm] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchor);

  const groupId = 'Help-Form-';

  const [helpFormOrderer, setHelpFormOrderer] = useState<
    { id: string; name: string }[]
  >([]);

  const [selectedHelpFormOrderer, setSelectedHelpFormOrderer] = useState<{
    [key: string]: { key: string; label: string };
  }>({});

  const clientHelpFormOrderer = useQuery({
    queryKey: ['helpFormOrderer'],
    queryFn: () => {
      return ClientApi.getHelpFormOrderer(context.authToken);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!clientHelpFormOrderer.isLoading) {
      if (clientHelpFormOrderer.error) {
        console.error(clientHelpFormOrderer.error);
      } else if (
        clientHelpFormOrderer.data &&
        clientHelpFormOrderer.data.data
      ) {
        setHelpFormOrderer(clientHelpFormOrderer.data.data);
      }
    }
  }, [
    clientHelpFormOrderer.data,
    clientHelpFormOrderer.error,
    clientHelpFormOrderer.isLoading,
  ]);

  const clientHelpFormsQuery = useQuery({
    queryKey: ['helpForms'],
    queryFn: () => {
      return ClientApi.getClientHelpForms(context.authToken, context.clientId);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!clientHelpFormsQuery.isLoading) {
      if (clientHelpFormsQuery.error) {
        console.error(clientHelpFormsQuery.error);
      } else if (
        clientHelpFormsQuery.data &&
        clientHelpFormsQuery.data.data &&
        clientHelpFormsQuery.data.data.data
      ) {
        setClientHelpForms(
          (clientHelpFormsQuery.data.data.data as any[]).sort((a, b) => {
            if (a.helpForm.name < b.helpForm.name) {
              return -1;
            }
            if (a.helpForm.name > b.helpForm.name) {
              return 1;
            }
            return 0;
          }),
        );
        setClientMissingHelpForms(
          clientHelpFormsQuery.data.data.missingHelpForms,
        );
      }
    }
  }, [
    clientHelpFormsQuery.data,
    clientHelpFormsQuery.error,
    clientHelpFormsQuery.isLoading,
  ]);

  useEffect(() => {
    const hfOrderer: any = {};
    clientHelpForms.map((chf: any) => {
      if (chf.helpFormOrderer) {
        hfOrderer[chf.id] = {
          key: chf.helpFormOrderer.id,
          label: chf.helpFormOrderer.name,
        };
      }
    });
    setSelectedHelpFormOrderer(hfOrderer);
  }, [clientHelpForms]);

  useEffect(() => {
    setClientData(props.clientData);
  }, [props.clientData]);

  useEffect(() => {
    setSaved(props.saved);
  }, [props.saved]);

  useEffect(() => {
    if (
      saved &&
      Object.entries(fieldValues).length === Object.entries(fieldChanges).length
    ) {
      saveFields(fieldValues);
    }
  }, [saved, fieldValues]);

  const saveFields = async (fields: Partial<Record<string, any>>) => {
    let error = '';
    const helpFormData: Record<string, any> = {};
    try {
      for (const clientHelpForm of clientHelpForms) {
        const groupData = context.getGroupData(groupId + clientHelpForm.id);
        helpFormData[clientHelpForm.id] = [];
        for (const data of groupData) {
          if (data.error !== null) error += data.error + '\n';
          if (data.type === 'ORDERER_INSTITUTION_SELECT') {
            data.value = data.value.key;
          }
          helpFormData[clientHelpForm.id] = {
            ...helpFormData[clientHelpForm.id],
            [data.id]: {
              value: data.value,
              type: data.type,
            },
          };
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }

    if (error === '') {
      const res = await ClientApi.saveClientHelpFormsData(
        context.authToken,
        context.clientId,
        { update: helpFormData },
      );

      if (res.message) {
        snackbarUtils.success(res.message);
        context.saveChanges(true);
        clientHelpFormsQuery.refetch();
      }
    } else snackbarUtils.error(error);

    if (fields.length > 0) {
      const res = await ClientApi.updateClientData(
        context.authToken,
        context.clientId,
        {
          data: fields,
        },
      );

      if (res.message) snackbarUtils.success(res.message);
    }

    setFieldValues({});
    setFieldChanges({});
    queryClient.invalidateQueries('clientData');
    queryClient.invalidateQueries('clients');
    queryClient.invalidateQueries('helpForms');
  };

  const onSaveField = async (type: ClientDataType, value: any) => {
    if (fieldChanges[type]) {
      const data: any = fieldValues;
      data[type] = value;
      if (!saved) {
        try {
          onChange(type, false);
          setFieldValues({});
          if (type == ClientDataType.PREVIOUSLY_USED_HELP) {
            const res = await ClientApi.updateClientData(
              context.authToken,
              context.clientId,
              {
                data,
              },
            );

            if (res.message) {
              snackbarUtils.success(res.message);
            }

            if (clientData) {
              clientData[type] = value;
              setClientData(clientData);
            }
          }
        } catch (e) {
          snackbarUtils.error(context.i18n.error);
        }
      }
      setFieldValues(data);
    }
  };

  const saveSingleField = async (object: any, clientHelpFormId: string) => {
    let res;
    if (Array.isArray(object)) {
      for (const field of object) {
        if (field.value) {
          if (field.type === 'ORDERER_INSTITUTION_SELECT') {
            field.value = field.value.key;
          }
          res = await ClientApi.saveClientHelpFormData(
            context.authToken,
            context.clientId,
            {
              id: field.id,
              value: field.value,
              clientHelpFormId,
              type: field.type,
            },
          );
        }
      }
    } else if (object.value) {
      if (object.type === 'ORDERER_INSTITUTION_SELECT') {
        object.value = object.value.key;
      }
      res = await ClientApi.saveClientHelpFormData(
        context.authToken,
        context.clientId,
        {
          id: object.id,
          value: object.value,
          clientHelpFormId,
          type: object.type,
        },
      );
    }

    if (res.message) {
      context.getAndRemoveGroupDataByIdAndGroupId(
        groupId + clientHelpFormId,
        object.id,
      );
      snackbarUtils.success(res.message);
      clientHelpFormsQuery.refetch();
    }
  };

  const onChange = async (type: string | undefined, changes: boolean) => {
    if (clientData) {
      let unsavedChanges = changes;
      const tmpFieldChanges: any = fieldChanges;
      if (type) {
        tmpFieldChanges[type] = changes;
      }

      unsavedChanges = true;
      setFieldChanges(tmpFieldChanges);
      context.setUnsavedChanges(unsavedChanges);
    }
  };

  const addClientHelpForm = async (id: string) => {
    try {
      const res = await ClientApi.addClientHelpForm(
        context.authToken,
        context.clientId,
        {
          id: id,
        },
      );

      if (res.message) {
        snackbarUtils.success(res.message);
        clientHelpFormsQuery.refetch();
      }
    } catch (e) {
      snackbarUtils.error(context.i18n.error);
    }
  };

  const getClientHelpFormDataValue = (
    helpFormData: any,
    clientHelpFormDataType: ClientHelpFormDataType,
  ) => {
    return helpFormData[clientHelpFormDataType]?.value;
  };

  const getClientHelpFormDataId = (
    helpFormData: any,
    clientHelpFormDataType: ClientHelpFormDataType,
  ) => {
    return helpFormData[clientHelpFormDataType]
      ? helpFormData[clientHelpFormDataType].id
      : clientHelpFormDataType;
  };

  return clientHelpFormsQuery.isLoading ? (
    <></>
  ) : (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        borderRadius: 0,
      }}
    >
      <Grid container>
        <Grid item xs={12} m={1}>
          <Box display="flex" justifyContent="flex-end">
            {context.isAdmin && (
              <Button
                startIcon={<FontAwesomeIcon icon={faPen}></FontAwesomeIcon>}
                onClick={() => navigate('/admin/help-form')}
              >
                {context.i18n.addHelpFormButton}
              </Button>
            )}

            {clientMissingHelpForms.length > 0 && (
              <Button
                startIcon={<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>}
                onClick={() => setOpenAddHelpForm(true)}
              >
                {context.i18n.allocateClientHelpForm}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      {clientMissingHelpForms.length > 0 && (
        <Dialog
          open={openAddHelpForm}
          onClose={() => setOpenAddHelpForm(false)}
        >
          <AddClientHelpFormDialog
            onAdd={addClientHelpForm}
            onClose={() => setOpenAddHelpForm(false)}
            helpForms={clientMissingHelpForms}
          />
        </Dialog>
      )}
      {clientHelpForms.map((clientHelpForm: any) => (
        <Grid key={clientHelpForm.id} container>
          <Grid item xs={12} m={1}>
            <Typography
              variant="h5"
              color="primary"
              fontWeight="bold"
              textTransform="uppercase"
            >
              {clientHelpForm.helpForm.name}
            </Typography>
            <Divider />
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormElement
                  key={ClientHelpFormDataType.FIRST_INTERVIEW}
                  hideExport
                  type={FormFieldType.DATE}
                  value={getClientHelpFormDataValue(
                    clientHelpForm.helpFormData,
                    ClientHelpFormDataType.FIRST_INTERVIEW,
                  )}
                  id={getClientHelpFormDataId(
                    clientHelpForm.helpFormData,
                    ClientHelpFormDataType.FIRST_INTERVIEW,
                  )}
                  title={context.i18n.startOfHelp}
                  groupId={groupId + clientHelpForm.id}
                  customGlobalSave={true}
                  onSave={(object: any) =>
                    saveSingleField(object, clientHelpForm.id)
                  }
                  objectProps={{
                    type: ClientHelpFormDataType.FIRST_INTERVIEW,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormElement
                  key={ClientHelpFormDataType.INTERVIEW_UNTIL}
                  hideExport
                  type={FormFieldType.DATE}
                  value={getClientHelpFormDataValue(
                    clientHelpForm.helpFormData,
                    ClientHelpFormDataType.INTERVIEW_UNTIL,
                  )}
                  id={getClientHelpFormDataId(
                    clientHelpForm.helpFormData,
                    ClientHelpFormDataType.INTERVIEW_UNTIL,
                  )}
                  title={context.i18n.endOfHelp}
                  groupId={groupId + clientHelpForm.id}
                  customGlobalSave={true}
                  onSave={(object: any) =>
                    saveSingleField(object, clientHelpForm.id)
                  }
                  objectProps={{
                    type: ClientHelpFormDataType.INTERVIEW_UNTIL,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormElement
                  key={ClientHelpFormDataType.HELPER}
                  hideExport
                  type={FormFieldType.DROPDOWN}
                  multiple
                  title={context.i18n.authorizedUser}
                  value={
                    getClientHelpFormDataValue(
                      clientHelpForm.helpFormData,
                      ClientHelpFormDataType.HELPER,
                    ) || []
                  }
                  id={getClientHelpFormDataId(
                    clientHelpForm.helpFormData,
                    ClientHelpFormDataType.HELPER,
                  )}
                  fieldOptions={clientHelpForm.helpForm.users.map(
                    (user: any) => {
                      return {
                        key: user.id,
                        label: user.firstName + ' ' + user.lastName,
                      };
                    },
                  )}
                  groupId={groupId + clientHelpForm.id}
                  customGlobalSave={true}
                  onSave={(object: any) =>
                    saveSingleField(object, clientHelpForm.id)
                  }
                  objectProps={{
                    type: ClientHelpFormDataType.HELPER,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormElement
                  key={ClientHelpFormDataType.MONTHLY_HOURS}
                  hideExport
                  type={FormFieldType.TEXT}
                  value={getClientHelpFormDataValue(
                    clientHelpForm.helpFormData,
                    ClientHelpFormDataType.MONTHLY_HOURS,
                  )}
                  id={ClientHelpFormDataType.MONTHLY_HOURS}
                  title={context.i18n.monthlyHours}
                  groupId={groupId + clientHelpForm.id}
                  customGlobalSave={true}
                  onSave={(object: any) =>
                    saveSingleField(object, clientHelpForm.id)
                  }
                  objectProps={{
                    type: ClientHelpFormDataType.MONTHLY_HOURS,
                  }}
                />
                <Box sx={{ m: 2 }}>
                  {clientHelpForm.helpFormData[
                    ClientHelpFormDataType.MONTHLY_HOURS + '_HISTORY'
                  ] &&
                    clientHelpForm.helpFormData[
                      ClientHelpFormDataType.MONTHLY_HOURS + '_HISTORY'
                    ].map((hoursHistory: any) => (
                      <Typography key={hoursHistory.id}>
                        {hoursHistory.value}{' '}
                        <Typography color={'grey.500'} variant="caption">
                          {hoursHistory.date}
                        </Typography>
                      </Typography>
                    ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormElement
                  key={ClientHelpFormDataType.ORDERER_INSTITUTION_SELECT}
                  hideExport
                  type={FormFieldType.AUTOCOMPLETE}
                  fieldOptions={helpFormOrderer.map((o) => ({
                    key: o.id,
                    label: o.name,
                  }))}
                  value={selectedHelpFormOrderer[clientHelpForm.id] || null}
                  id={getClientHelpFormDataId(
                    clientHelpForm.helpFormData,
                    ClientHelpFormDataType.ORDERER_INSTITUTION_SELECT,
                  )}
                  title={context.i18n.applicantOfHelpForm}
                  groupId={groupId + clientHelpForm.id}
                  customGlobalSave={true}
                  onChange={(v) =>
                    setSelectedHelpFormOrderer({
                      [clientHelpForm.id]: v,
                      ...selectedHelpFormOrderer,
                    })
                  }
                  onSave={(object: any) =>
                    saveSingleField(object, clientHelpForm.id)
                  }
                  objectProps={{
                    type: ClientHelpFormDataType.ORDERER_INSTITUTION_SELECT,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormElement
                  key={ClientHelpFormDataType.ORDERER}
                  hideExport
                  type={FormFieldType.TEXT}
                  title={context.i18n.socialworker}
                  value={getClientHelpFormDataValue(
                    clientHelpForm.helpFormData,
                    ClientHelpFormDataType.ORDERER,
                  )}
                  id={ClientHelpFormDataType.ORDERER}
                  groupId={groupId + clientHelpForm.id}
                  customGlobalSave={true}
                  onSave={(object: any) =>
                    saveSingleField(object, clientHelpForm.id)
                  }
                  objectProps={{
                    type: ClientHelpFormDataType.ORDERER,
                  }}
                />
                <Box sx={{ m: 2 }}>
                  {clientHelpForm.helpFormData[
                    ClientHelpFormDataType.ORDERER + '_HISTORY'
                  ] &&
                    clientHelpForm.helpFormData[
                      ClientHelpFormDataType.ORDERER + '_HISTORY'
                    ].map((ordererHistory: any) => (
                      <Typography key={ordererHistory.id}>
                        {ordererHistory.value}{' '}
                        <Typography color={'grey.500'} variant="caption">
                          {ordererHistory.date}
                        </Typography>
                      </Typography>
                    ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormElement
                  key={ClientHelpFormDataType.ORDERER_DATA}
                  hideExport
                  type={FormFieldType.FORM_GROUP}
                  onSave={(object: any) =>
                    saveSingleField(object, clientHelpForm.id)
                  }
                  value={[
                    {
                      value: getClientHelpFormDataValue(
                        clientHelpForm.helpFormData,
                        ClientHelpFormDataType.ORDERER_DATA_PHONE,
                      ),
                      id: getClientHelpFormDataId(
                        clientHelpForm.helpFormData,
                        ClientHelpFormDataType.ORDERER_DATA_PHONE,
                      ),
                      type: FormFieldType.TEXT,
                      label: context.i18n.phone,
                      seq: 0,
                      groupId: groupId + clientHelpForm.id,
                      objectProps: {
                        type: ClientHelpFormDataType.ORDERER_DATA_PHONE,
                      },
                    },
                    {
                      value: getClientHelpFormDataValue(
                        clientHelpForm.helpFormData,
                        ClientHelpFormDataType.ORDERER_DATA_EMAIL,
                      ),
                      id: getClientHelpFormDataId(
                        clientHelpForm.helpFormData,
                        ClientHelpFormDataType.ORDERER_DATA_EMAIL,
                      ),
                      type: FormFieldType.TEXT,
                      label: context.i18n.email,
                      seq: 1,
                      groupId: groupId + clientHelpForm.id,
                      objectProps: {
                        type: ClientHelpFormDataType.ORDERER_DATA_EMAIL,
                      },
                    },
                    // !selectedHelpFormOrderer[clientHelpForm.id] && {
                    //   value: getClientHelpFormDataValue(
                    //     clientHelpForm.helpFormData,
                    //     ClientHelpFormDataType.ORDERER_DATA_STREET,
                    //   ),
                    //   id: getClientHelpFormDataId(
                    //     clientHelpForm.helpFormData,
                    //     ClientHelpFormDataType.ORDERER_DATA_STREET,
                    //   ),
                    //   type: FormFieldType.TEXT,
                    //   label: context.i18n.street,
                    //   seq: 2,
                    //   groupId: groupId + clientHelpForm.id,
                    //   objectProps: {
                    //     type: ClientHelpFormDataType.ORDERER_DATA_STREET,
                    //   },
                    // },
                    // !selectedHelpFormOrderer[clientHelpForm.id] && {
                    //   value: getClientHelpFormDataValue(
                    //     clientHelpForm.helpFormData,
                    //     ClientHelpFormDataType.ORDERER_DATA_POSTCODE,
                    //   ),
                    //   id: getClientHelpFormDataId(
                    //     clientHelpForm.helpFormData,
                    //     ClientHelpFormDataType.ORDERER_DATA_POSTCODE,
                    //   ),
                    //   type: FormFieldType.TEXT,
                    //   label: context.i18n.postcode,
                    //   seq: 3,
                    //   groupId: groupId + clientHelpForm.id,
                    //   objectProps: {
                    //     type: ClientHelpFormDataType.ORDERER_DATA_POSTCODE,
                    //   },
                    // },
                    // !selectedHelpFormOrderer[clientHelpForm.id] && {
                    //   value: getClientHelpFormDataValue(
                    //     clientHelpForm.helpFormData,
                    //     ClientHelpFormDataType.ORDERER_DATA_LOCATION,
                    //   ),
                    //   id: getClientHelpFormDataId(
                    //     clientHelpForm.helpFormData,
                    //     ClientHelpFormDataType.ORDERER_DATA_LOCATION,
                    //   ),
                    //   type: FormFieldType.TEXT,
                    //   label: context.i18n.location,
                    //   seq: 4,
                    //   groupId: groupId + clientHelpForm.id,
                    //   objectProps: {
                    //     type: ClientHelpFormDataType.ORDERER_DATA_LOCATION,
                    //   },
                    // },
                  ]}
                  title={context.i18n.contactDataApplicant}
                  id={ClientHelpFormDataType.ORDERER_DATA}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid container>
        <Grid item xs={12} md={6}>
          <ClientDataField
            name={context.i18n.previouslyUsedHelp}
            type={ClientDataType.PREVIOUSLY_USED_HELP}
            fieldType={FormFieldType.CHECKBOX}
            options={[
              {
                label: context.i18n.psychotherapy,
                key: 'psychotherapy',
              },
              {
                label: context.i18n.ergotherapy,
                key: 'ergotherapy',
              },
              {
                label: context.i18n.speechTherapy,
                key: 'speechTherapy',
              },
              {
                label: context.i18n.psychologicalInvestigations,
                key: 'psychologicalInvestigations',
              },
              {
                label: context.i18n.psychiatricInvestigations,
                key: 'psychiatricInvestigations',
              },
              {
                label: context.i18n.inpatientStays,
                key: 'inpatientStays',
              },
              {
                label: context.i18n.educationalAdvice,
                key: 'educationalAdvice',
              },
              {
                label: context.i18n.supportChildWelfare,
                key: 'supportChildWelfare',
              },
              {
                label: context.i18n.miscellaneous,
                key: 'miscellaneous',
              },
            ]}
            onSaveFields={saveFields}
            clientData={clientData}
            onChange={onChange}
            onSaveField={onSaveField}
            saved={saved}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClientDataHelpFrom;
