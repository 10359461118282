import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
} from '@mui/material';
import React, { FC, useState, useEffect, useContext } from 'react';
import { getValueString } from '../../../../../utils';
import { FormListElement } from './form-list';
import { AppContext } from '../../../../../hooks/context';

interface FormSlideProps {
  element: FormListElement;
  onOpen: () => void;
  //is needed to update checkboxes
  update?: boolean;
  preview?: boolean;
  readOnly?: boolean;
}

const FormListSlide: FC<FormSlideProps> = (props) => {
  const [children, setChildren] = useState(props.element.children);
  const context = useContext(AppContext);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    formatChildren();
  }, [props.element.children, props.update]);

  const formatChildren = () => {
    const formatedChildren = props.element.children.map((child) => {
      if (child.value) {
        return { ...child, value: getValueString(child) };
      }
      return child;
    });

    setChildren(formatedChildren);
  };

  return (
    <Paper
      sx={{
        m: 2,
        maxHeight: 225,
        overflowY: children.length > 3 ? 'scroll' : 'hidden',
      }}
    >
      <List>
        {children.map((data, index) => (
          <ListItem key={index}>
            <ListItemText
              primaryTypographyProps={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    fontWeight="bold"
                    color="primary"
                  >
                    {data.label ? data.label : data.value}
                  </Typography>
                </React.Fragment>
              }
              secondary={data.value ? data.value : null}
            />
            {index === 0 && !readOnly ? (
              <IconButton
                edge="end"
                color="primary"
                onClick={() =>
                  props.preview || readOnly ? null : props.onOpen()
                }
              >
                <FontAwesomeIcon icon={faPen} />
              </IconButton>
            ) : null}
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default FormListSlide;
