import {
  ClientDataType,
  FormFieldTextType,
  FormFieldType,
  OtherCharacteristics,
  Sex,
  Sexuality,
  Sickness,
} from '@casecare/types';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { FamilyApi, ClientApi, ICD10Api } from '../../../../api';
import { AppContext } from '../../../../hooks/context';
import {
  ClientDataFieldType,
  FormDateType,
  ICD10Code,
} from '../../../../types';
import { TextSize } from '../../../../types/form-text-props';
import { queryClient } from '../../../../utils';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import {
  AddSupervisorDialog,
  ICD10DetailsDialog,
  ICD10Dialog,
  RemoveSupervisorDialog,
} from '../../../dialogs';
import FormDate from '../../forms/form-elements/form-date/form-date';
import FormDropdown from '../../forms/form-elements/form-dropdown/form-dropdown';
import FormElementHeader from '../../forms/form-elements/form-element-header/form-element-header';
import FormRadio from '../../forms/form-elements/form-radio/form-radio';
import FormText from '../../forms/form-elements/form-text/form-text';
import UserSelect from '../../user-select/user-select';
import ClientDataField from '../client-data-field/client-data-field';

interface ClientDataProps {
  saved: boolean;
  clientData: Record<ClientDataType, any>;
  isReferencePerson: boolean;
  // clientFamilies?: any[];
}

const ClientData: FC<ClientDataProps> = (props) => {
  const context = useContext(AppContext);

  const [clientData, setClientData] = useState<
    Partial<Record<ClientDataType, any>>
  >(props.clientData);
  const [fieldChanges, setFieldChanges] = useState<
    Partial<Record<ClientDataType | 'family', boolean>>
  >({});
  const [fieldValues, setFieldValues] = useState<
    Partial<Record<ClientDataType | 'family', any>>
  >({});
  const [isLoading, setIsLoading] = useState(true);
  const [saved, setSaved] = useState(false);
  const [supervisorIds, setSupervisorIds] = useState(
    props.clientData.SUPERVISOR,
  );
  // const [clientFamilies, setClientFamilies] = useState<any>(
  //   props.clientFamilies ? props.clientFamilies : [],
  // );
  // const [clientFamilyIds, setClientFamilyIds] = useState(
  //   props.clientFamilies ? props.clientFamilies.map((family) => family.id) : [],
  // );
  const [families, setFamilies] = useState([]);
  const [addedSupervisorId, setAddedSupervisorId] = useState<
    string | undefined
  >();
  const [removedSupervisorId, setRemovedSupervisorId] = useState<
    string | undefined
  >();
  const [openICD10Codes, setOpenICD10Codes] = useState(false);
  const [icd10Codes, setICD10Codes] = useState<ICD10Code[]>([]);
  const [detailsCode, setDetailsCode] = useState<ICD10Code>();
  const [isDead, setIsDead] = useState(clientData.IS_DEAD);

  const clientFields: Partial<Record<ClientDataType, ClientDataFieldType>> = {
    [ClientDataType.CLIENT_NUMBER]: {
      name: context.i18n.clientNumber,
      type: ClientDataType.CLIENT_NUMBER,
    },
    [ClientDataType.FIRST_NAME]: {
      name: context.i18n.firstName,
      type: ClientDataType.FIRST_NAME,
      required: true,
    },
    [ClientDataType.LAST_NAME]: {
      name: context.i18n.lastName,
      type: ClientDataType.LAST_NAME,
      required: true,
    },
    [ClientDataType.ACADEMIC_DEGREE_PRE]: {
      name: context.i18n.academicDegreePresented,
      type: ClientDataType.ACADEMIC_DEGREE_PRE,
    },
    [ClientDataType.ACADEMIC_DEGREE_AFT]: {
      name: context.i18n.academicDegreeAdjusted,
      type: ClientDataType.ACADEMIC_DEGREE_AFT,
    },
    [ClientDataType.BIRTH_PLACE]: {
      name: context.i18n.birthPlace,
      type: ClientDataType.BIRTH_PLACE,
    },
    [ClientDataType.BIRTHDAY]: {
      name: context.i18n.birthday,
      type: ClientDataType.BIRTHDAY,
      required: true,
      fieldType: FormFieldType.DATE,
    },
    // [ClientDataType.CLIENT_UNDERAGE]: {
    //   name: context.i18n.clientUnderAge,
    //   type: ClientDataType.CLIENT_UNDERAGE,
    //   fieldType: FormFieldType.RADIO,
    //   options: [
    //     {
    //       label: context.i18n.yes,
    //       key: 'yes',
    //     },
    //     {
    //       label: context.i18n.no,
    //       key: 'no',
    //     },
    //   ],
    // },
    [ClientDataType.HAS_LEGAL_REPRESENTATIVE]: {
      name: context.i18n.hasClientLegalRepresentative,
      type: ClientDataType.HAS_LEGAL_REPRESENTATIVE,
      fieldType: FormFieldType.RADIO,
      options: [
        {
          label: context.i18n.yes,
          key: 'yes',
        },
        {
          label: context.i18n.no,
          key: 'no',
        },
      ],
    },
    [ClientDataType.SEX]: {
      name: context.i18n.sex,
      type: ClientDataType.SEX,
      required: true,
      fieldType: FormFieldType.DROPDOWN,
      options: Object.entries(Sex).map((sex) => {
        return { key: sex[0], label: context.i18n[sex[0]] };
      }),
    },
    [ClientDataType.SEXUALITY]: {
      name: context.i18n.sexuality,
      type: ClientDataType.SEXUALITY,
      required: false,
      fieldType: FormFieldType.DROPDOWN,
      options: Object.entries(Sexuality).map((s) => {
        return { key: s[0], label: context.i18n[s[0]] };
      }),
    },
    [ClientDataType.KNOWN_DIAGNOSIS]: {
      name: context.i18n.knownDiagnosis,
      type: ClientDataType.KNOWN_DIAGNOSIS,
      onClick: () => setOpenICD10Codes(true),
      icd10Codes: icd10Codes,
      onRemove: (code) => onRemoveCode(code),
      onDetails: (code) => setDetailsCode(code),
    },
    // [ClientDataType.SICKNESS]: {
    //   name: context.i18n.sicknesses,
    //   type: ClientDataType.SICKNESS,
    //   fieldType: FormFieldType.DROPDOWN,
    //   options: Object.values(Sickness).map((s) => ({
    //     key: s,
    //     label: context.i18n[s],
    //   })),
    //   multiple: true,
    // },
    [ClientDataType.OTHER_CHARACTERISTICS]: {
      name: context.i18n.otherCharacteristics,
      type: ClientDataType.OTHER_CHARACTERISTICS,
    },
    [ClientDataType.RELIGION]: {
      name: context.i18n.religion,
      type: ClientDataType.RELIGION,
    },
    [ClientDataType.MARITAL_STATUS]: {
      name: context.i18n.martialStatus,
      type: ClientDataType.MARITAL_STATUS,
      fieldType: FormFieldType.RADIO,
      options: [
        {
          label: context.i18n.single,
          key: 'single',
        },
        {
          label: context.i18n.married,
          key: 'married',
        },
        {
          label: context.i18n.registeredLifePartnership,
          key: 'registeredLifePartnership',
        },
        {
          label: context.i18n.widowed,
          key: 'widowed',
        },
        {
          label: context.i18n.divorced,
          key: 'divorced',
        },
        {
          label: context.i18n.miscellaneous,
          key: 'miscellaneous',
        },
      ],
    },
    // [ClientDataType.IS_DEAD]: {
    //   name: context.i18n.alreadyDead,
    //   type: ClientDataType.IS_DEAD,
    //   selected: isDead,
    //   fieldType: FormFieldType.CHECKBOX,
    //   onClick: () => setIsDead(!isDead),
    //   onCancle: () => setIsDead(props.clientData.IS_DEAD),
    //   options: [
    //     {
    //       label: context.i18n.alreadyDead,
    //       key: 'isDead',
    //     },
    //   ],
    // },
    [ClientDataType.DEATH_DAY]: {
      name: context.i18n.deathDay,
      type: ClientDataType.DEATH_DAY,
      fieldType: FormFieldType.DATE,
      hidden: !isDead,
    },
    [ClientDataType.MOTHER_TOUNGE]: {
      name: context.i18n.motherTounge,
      type: ClientDataType.MOTHER_TOUNGE,
    },
    [ClientDataType.CITIZENSHIP]: {
      name: context.i18n.citizenship,
      type: ClientDataType.CITIZENSHIP,
    },
    [ClientDataType.STREET]: {
      name: context.i18n.street,
      type: ClientDataType.STREET,
    },
    [ClientDataType.STREET_NUMBER]: {
      name: context.i18n.streetNumber,
      type: ClientDataType.STREET_NUMBER,
    },
    [ClientDataType.ZIPCODE]: {
      name: context.i18n.zipcode,
      type: ClientDataType.ZIPCODE,
    },
    [ClientDataType.CITY]: {
      name: context.i18n.city,
      type: ClientDataType.CITY,
    },
    [ClientDataType.INSURED_AT]: {
      name: context.i18n.insuredAt,
      type: ClientDataType.INSURED_AT,
    },
    [ClientDataType.SOCIAL_SECURITY]: {
      name: context.i18n.socialSecurity,
      type: ClientDataType.SOCIAL_SECURITY,
    },
    [ClientDataType.PHONE_NUMBER]: {
      name: context.i18n.phone,
      dataType: FormFieldTextType.PHONE,
      type: ClientDataType.PHONE_NUMBER,
    },
    [ClientDataType.EMAIL]: {
      name: context.i18n.email,
      dataType: FormFieldTextType.EMAIL,
      type: ClientDataType.EMAIL,
    },
    [ClientDataType.NOTES]: {
      name: context.i18n.notes,
      type: ClientDataType.NOTES,
    },
  };

  // const familyQuery = useQuery(
  //   ['families', props.clientFamilies === undefined],
  //   () => FamilyApi.getFamilies(context.authToken),
  //   {
  //     onSuccess: (res) => {
  //       setFamilies(res.data);
  //     },
  //     enabled: context.authToken !== undefined,
  //   },
  // );

  const icd10Query = useQuery(
    ['icd10Codes'],
    () => ICD10Api.getICD10ClientCodes(context.authToken, context.clientId),
    {
      onSuccess: (res) => {
        setICD10Codes(res.data);
      },
      enabled:
        context.authToken !== undefined && context.clientId !== undefined,
    },
  );

  const onRemoveCode = async (code: string) => {
    const res = await ICD10Api.removeICD10Code(
      context.authToken,
      context.clientId,
      code,
    );

    if (res.statusCode === 201) {
      if (res.message) {
        // Triggers reload to allow clientName to be shown
        context.setClientList(undefined);
        snackbarUtils.success(res.message);
      }
      queryClient.invalidateQueries('icd10Codes');
    } else {
      snackbarUtils.error(context.i18n.addCodeError);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 100);
    setClientData(props.clientData);

    setSupervisorIds(props.clientData.SUPERVISOR.slice());
    setIsDead(props.clientData.IS_DEAD);
  }, [props.clientData]);

  useEffect(() => {
    setSaved(props.saved);
  }, [props.saved]);

  useEffect(() => {
    if (
      saved &&
      Object.entries(fieldValues).length === Object.entries(fieldChanges).length
    ) {
      saveAll();
    }
  }, [saved, fieldValues]);

  const saveAll = async () => {
    delete fieldValues.family;

    const res = await ClientApi.updateClientData(
      context.authToken,
      context.clientId,
      {
        data: fieldValues,
        // families: clientFamilyIds,
      },
    );

    if (res.message) snackbarUtils.success(res.message);

    setFieldValues({});
    setFieldChanges({});
    queryClient.invalidateQueries('clientData');
    queryClient.invalidateQueries('clients');
  };

  const onSaveField = async (type: ClientDataType, value: string) => {
    if (fieldChanges[type]) {
      const data: any = fieldValues;
      if (type === ClientDataType.IS_DEAD) {
        data[type] = isDead;
      } else {
        data[type] = value;
      }
      if (!saved) {
        try {
          onChange(type, false);
          setFieldValues({});
          const res = await ClientApi.updateClientData(
            context.authToken,
            context.clientId,
            {
              data,
            },
          );

          if (res.message) snackbarUtils.success(res.message);
          clientData[type as keyof typeof ClientDataType] = value;
          setClientData(clientData);
        } catch (e) {
          snackbarUtils.error(context.i18n.error);
        }
      }
      setFieldValues(data);
    }
  };

  // const saveFamilies = async () => {
  //   const res = await ClientApi.updateClientData(
  //     context.authToken,
  //     context.clientId,
  //     {
  //       families: clientFamilyIds,
  //     },
  //   );

  //   if (res.message) snackbarUtils.success(res.message);

  //   queryClient.invalidateQueries('families');
  // };

  const onChange = async (type: ClientDataType | string, changes: boolean) => {
    if (clientData) {
      let unsavedChanges = changes;
      const tmpFieldChanges: any = fieldChanges;

      if (type) {
        tmpFieldChanges[type] = changes;
      }

      for (const key in fieldChanges) {
        if (fieldChanges[key as keyof typeof ClientDataType]) {
          unsavedChanges = true;
        }
      }
      setFieldChanges(tmpFieldChanges);
      context.setUnsavedChanges(unsavedChanges);
    }
  };

  const onChangeSupervisor = async (ids: string[]) => {
    if (ids.length > supervisorIds.length) {
      setAddedSupervisorId(
        ids.filter((id: string) => !supervisorIds.includes(id))[0],
      );
    } else if (ids.length < supervisorIds.length) {
      setRemovedSupervisorId(
        supervisorIds.filter((id: string) => !ids.includes(id))[0],
      );
    }
  };

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        borderRadius: 0,
      }}
    >
      {isLoading ||
      // familyQuery.isLoading ||
      icd10Query.isLoading ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <Grid container>
          {!props.isReferencePerson && (
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <FormElementHeader
                  required={true}
                  hideExport={true}
                  title={context.i18n.supervisor}
                />
                <UserSelect
                  userIds={supervisorIds}
                  error={false}
                  multiple
                  setId={onChangeSupervisor}
                />
              </Box>
            </Grid>
          )}
          {/* {context.familyActive && (
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <FormElementHeader
                  required={false}
                  hideExport={true}
                  title={context.i18n.family}
                  save={fieldChanges['family']}
                  onSave={() => {
                    saveFamilies();
                    onChange('family', false);
                  }}
                  onCancel={() => {
                    onChange('family', false);
                    setClientFamilies(props.clientFamilies);
                    setClientFamilyIds(
                      props.clientFamilies
                        ? props.clientFamilies.map((family) => family.id)
                        : [],
                    );
                  }}
                />
                <Autocomplete
                  options={families}
                  multiple
                  onChange={(evetn, option: any) => {
                    onChange(
                      'family',
                      clientFamilyIds.join('') ===
                        props.clientFamilies
                          ?.map((family: any) => family.id)
                          .join(''),
                    );
                    setFieldValues({ ...fieldValues, family: true });
                    setClientFamilies(option);
                    setClientFamilyIds(option.map((family: any) => family.id));
                  }}
                  noOptionsText={context.i18n.noFamilies}
                  getOptionLabel={(option: any) => option.name || ''}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={context.i18n.family} />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={clientFamilies}
                />
              </Box>
            </Grid>
          )} */}
          {Object.entries(clientFields).map(([type, field]) => (
            <Grid
              display={field.hidden ? 'none' : 'block'}
              key={type}
              item
              xs={12}
              md={6}
            >
              <ClientDataField
                {...field}
                clientData={clientData}
                onChange={onChange}
                onSaveField={onSaveField}
                saved={saved}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <AddSupervisorDialog
        open={addedSupervisorId !== undefined}
        onClose={() => setAddedSupervisorId(undefined)}
        onCancel={() => {
          setSupervisorIds(props.clientData.SUPERVISOR.slice());
          setAddedSupervisorId(undefined);
        }}
        addedSupervisorId={addedSupervisorId}
      />
      <RemoveSupervisorDialog
        open={removedSupervisorId !== undefined}
        onClose={() => setRemovedSupervisorId(undefined)}
        onCancel={() => {
          setSupervisorIds(props.clientData.SUPERVISOR.slice());
          setRemovedSupervisorId(undefined);
        }}
        removedSupervisorId={removedSupervisorId}
      />
      <ICD10Dialog
        open={openICD10Codes}
        onClose={() => setOpenICD10Codes(false)}
      />
      {detailsCode && (
        <ICD10DetailsDialog
          open={detailsCode !== undefined}
          onClose={() => setDetailsCode(undefined)}
          code={detailsCode}
        />
      )}
    </Paper>
  );
};

export default ClientData;
