import { faCaretDown, faX, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Tabs,
  Tab,
  Grid,
  Button,
  Paper,
  Box,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { queryClient, theme } from '../../../utils';
import { AccordionStyle } from '../../../utils/styling/styling';
import { AppContext } from '../../../hooks/context';
import { ClientApi, GenoGrammApi } from '../../../api';
import { ClientDataType } from '@casecare/types';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { GenoGrammClientData, GenoGrammRelation } from '..';
import { GenoGrammClient } from '../../../types';

interface GenoGrammClientsProps {
  clients: GenoGrammClient[];
  clientList: any[];
  indexId: string | undefined;
  onSaveClient: (client: any) => void;
  getChanges: (changes: boolean) => void;
}

const GenoGrammClients: FC<GenoGrammClientsProps> = (props) => {
  const context = useContext(AppContext);

  const [clients, setClients] = useState(props.clients);
  const [changes, setChanges] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setChanges({});
    setClients(props.clients);
  }, [props.clients]);

  useEffect(() => {
    let tmpChanges = false;

    for (const key in changes) {
      tmpChanges = changes[key];
    }

    props.getChanges(tmpChanges);
  }, [changes]);

  const onRemoveClient = async (id: string) => {
    try {
      const res = await ClientApi.updateClientArchiveState(
        context.authToken,
        id,
        true,
      );

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      queryClient.invalidateQueries(['geno-gramm-data']);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {clients.map((c) => (
        <Accordion
          onClick={() => {
            return;
          }}
          square
          disableGutters
          elevation={0}
          key={c.id}
          slotProps={{ transition: { unmountOnExit: true } }}
          sx={{
            ...AccordionStyle(undefined, theme.palette.primary.main),
          }}
        >
          <AccordionSummary
            expandIcon={
              <FontAwesomeIcon
                size="2x"
                color={theme.palette.primary.main}
                icon={faCaretDown}
              />
            }
          >
            <Typography sx={{ ml: 2 }}>
              {c[ClientDataType.FIRST_NAME]} {c[ClientDataType.LAST_NAME]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: 'unset !important' }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                {c.id !== props.indexId && (
                  <GenoGrammRelation
                    client={c}
                    indexId={props.indexId}
                    clientList={props.clientList.filter(
                      (listCLient) => listCLient.id !== c.id,
                    )}
                    onChange={(rChanges) => {
                      setChanges({ ...changes });
                    }}
                  />
                )}
                <GenoGrammClientData
                  isIndex={c.id === props.indexId}
                  client={c}
                  onChange={(dChanges) => {
                    changes[c.id] = dChanges;
                    setChanges({ ...changes });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{ p: 2, width: '100%' }}
                  color="inherit"
                  onClick={() => onRemoveClient(c.id)}
                  endIcon={
                    <FontAwesomeIcon
                      color={theme.palette.error.main}
                      icon={faX}
                    />
                  }
                >
                  {context.i18n.removePerson}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{ p: 2, width: '100%' }}
                  color="inherit"
                  size="large"
                  endIcon={
                    <FontAwesomeIcon
                      color={theme.palette.success.main}
                      icon={faCheck}
                    />
                  }
                  onClick={() => props.onSaveClient(c)}
                >
                  {context.i18n.save}
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default GenoGrammClients;
