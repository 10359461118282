import {
  faPen,
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AdminApi } from '../../../api';
import { AdminDrawer } from '../../../components/admin';
import EditUserDialog, {
  User,
} from '../../../components/admin/user/edit-user-dialog';
import InviteUserDialog from '../../../components/admin/user/invite-user-dialog';
import Navbar from '../../../components/core/navbar/navbar';
import { AppContext } from '../../../hooks/context';
import { loadTitle, setDocumentTitle } from '../../../utils';
import { drawerWidthSpaced } from '../../../utils/constants';
import { MenuWrapper } from '../../../components/core';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import CreateHelpFormDialog from '../../../components/admin/help-form/create-help-form-dialog';
import EditHelpFormDialog from '../../../components/admin/help-form/edit-help-form-dialog';
import DeleteHelpFormDialog from '../../../components/admin/help-form/delete-help-form-dialog';

interface DataGridElement {
  id: string;
  username: string;
  isAdmin: boolean;
}

const HelpForm = () => {
  const context = useContext(AppContext);
  const [createHelpFormDialogOpen, setCreateHelpFormDialogOpen] =
    useState(false);
  const [editHelpFormDialogOpen, setEditHelpFormDialogOpen] = useState(false);
  const [deleteHelpFormDialogOpen, setDeleteHelpFormDialogOpen] =
    useState(false);
  const [rows, setRows] = useState<DataGridElement[]>([]);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [selectedHelpForm, setSelectedHelpForm] = useState<any>({});

  useEffect(() => {
    setDocumentTitle(context.i18n.helpForm);
    return () => loadTitle();
  }, []);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['getAdminHelpForms'],
    queryFn: () => AdminApi.getHelpForms(context.authToken),
    enabled: context.authToken !== undefined && context.isAdmin,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (data && data.data && data.data.helpForms) {
      setRows(data.data.helpForms);
      setTotalRowCount(data.data.helpForms.length);
    }
  }, [data, error]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: context.i18n.name,
      editable: false,
      flex: 1,
    },
    {
      field: 'users',
      headerName: context.i18n.user,
      editable: false,
      valueGetter: (value: any) => {
        return value.map((u: any) => `${u.firstName} ${u.lastName}`).join(', ');
      },
      flex: 1,
    },
    {
      field: context.i18n.actions,
      headerName: '',
      flex: 1,
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <Tooltip title={context.i18n.createHelpForm}>
          <IconButton
            disableRipple
            color="primary"
            id="invite-user-button"
            data-id="invite-user-button"
            onClick={() => setCreateHelpFormDialogOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Box>
          <Tooltip title={context.i18n.editHelpForm}>
            <IconButton
              disableRipple
              sx={{ height: 39 }}
              color="primary"
              id="edit-help-form-button"
              data-id="edit-help-form-button"
              onClick={() => {
                setSelectedHelpForm(params.row);
                setEditHelpFormDialogOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.deleteHelpForm}>
            <IconButton
              disableRipple
              sx={{ height: 39 }}
              color="error"
              id="delete-help-form-button"
              data-id="delete-help-form-button"
              onClick={() => {
                setSelectedHelpForm(params.row);
                setDeleteHelpFormDialogOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <MenuWrapper
      title={context.i18n.helpForm}
      selected="admin-help-form"
      addClient
      search
      admin
    >
      <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
        <DataGridPro
          sx={{
            m: 5,
          }}
          columns={columns}
          rows={rows}
          loading={isLoading}
          getRowId={(row) => row.id}
          autoHeight
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
        />
      </Box>
      <CreateHelpFormDialog
        open={createHelpFormDialogOpen}
        onClose={() => {
          setCreateHelpFormDialogOpen(false);
          refetch();
        }}
      />
      <EditHelpFormDialog
        open={editHelpFormDialogOpen}
        onClose={() => {
          setEditHelpFormDialogOpen(false);
          setSelectedHelpForm({});
          refetch();
        }}
        selected={selectedHelpForm}
      />

      <DeleteHelpFormDialog
        open={deleteHelpFormDialogOpen}
        onClose={() => {
          setDeleteHelpFormDialogOpen(false);
          setSelectedHelpForm({});
          refetch();
        }}
        helpFormId={selectedHelpForm.id}
      />
    </MenuWrapper>
  );
};

export default HelpForm;
