import { FC, useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { AppContext } from '../hooks/context';

const NonMobileRoute: FC = () => {
  const context = useContext(AppContext);

  return !context.isMobile ? <Outlet /> : <Navigate to="/mobile/clients" />;
};

export default NonMobileRoute;
