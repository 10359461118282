import { FC, useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../hooks/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const RecoveryCodeDialog: FC = () => {
  const context = useContext(AppContext);
  const onClose = () => {
    context.setRecoveryCodeDialogOpen(false);
    context.setRecoveryCode('');
    setShowCloseButton(false);
  };

  const [showCloseButton, setShowCloseButton] = useState(false);

  useEffect(() => {
    if (context.recoveryCodeDialogOpen) {
      setTimeout(() => setShowCloseButton(true), 5000);
    }
  }, [context.recoveryCodeDialogOpen]);

  return (
    <Dialog open={context.recoveryCodeDialogOpen}>
      <DialogTitle sx={{ color: 'red', fontSize: '2.8rem' }}>
        {context.i18n.recoveryCodeDialogTitle}
        {showCloseButton && (
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ color: 'red' }}>
        <Typography component="p">
          {context.i18n.recoveryCodeDialogContent}
        </Typography>
        <br />
        <Typography variant="h3" component="h3" textAlign="center">
          {context.recoveryCode}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default RecoveryCodeDialog;
