// Import the SVG path data from the file
import { FC, ReactElement } from 'react';
import { SvgIcon } from '@mui/material';
import {
  CaseCareSvgIconProps,
  CaseCareSvgIconType,
} from '../../types/case-care-svg-icon';

interface _CaseCareSvgIconProps extends CaseCareSvgIconProps {
  default: ReactElement;
  border?: ReactElement;
  outlined?: ReactElement;
  filled?: ReactElement;
}

const CaseCareSvgIcon: FC<_CaseCareSvgIconProps> = (props) => {
  const iconTypes = {
    [CaseCareSvgIconType.DEFAULT]: props.default,
    [CaseCareSvgIconType.BORDER]: props.border,
    [CaseCareSvgIconType.OUTLINED]: props.outlined,
    [CaseCareSvgIconType.FILLED]: props.filled,
  };

  return (
    <SvgIcon {...props}>
      {props.iconType && iconTypes[props.iconType] ? (
        <>{iconTypes[props.iconType]}</>
      ) : (
        <>{iconTypes[CaseCareSvgIconType.DEFAULT]}</>
      )}
    </SvgIcon>
  );
};
export default CaseCareSvgIcon;
