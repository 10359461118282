import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement, UserSelect } from '../../client';
import { FormFieldType } from '@casecare/types';
import { EcoMapAddDialogType } from '../../../types/eco-map-add-dialog-type';
import EcoMapApi from '../../../api/eco-map/eco-map';
import { AdminApi } from '../../../api';
import { FormFieldStyle } from '../../../types/form-element-props';

interface CreateHelpFormDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateHelpFormDialog: FC<CreateHelpFormDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(props.open);
  const [users, setUsers] = useState([]);
  const groupId = 'Help-Form-Add-Dialog';
  const helpFormFields = [
    {
      id: 'name',
      name: context.i18n.name,
      required: true,
      type: FormFieldType.TEXT,
      value: '',
    },
  ];

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onSave = async () => {
    try {
      let error = '';
      const groupData = context.getGroupData(groupId);
      const sendData: Record<string, any> = {};
      for (const data of groupData) {
        if (data.error !== null) error += data.error + '\n';
        sendData[data.id] = data.value;
      }
      if (error === '' && users.length >= 0) {
        sendData['users'] = users;
        const res = await AdminApi.createHelpForm(context.authToken, sendData);

        if (res.message) {
          snackbarUtils.success(res.message);
          context.removeGroupData(groupId);
          onClose();
        }
      } else snackbarUtils.error(error);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onClose = async () => {
    for (const helpFormField of helpFormFields) {
      context.removeChangedCategoryData(helpFormField.id);
    }
    setUsers([]);
    props.onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{context.i18n.createHelpForm}</DialogTitle>
      <DialogContent>
        {helpFormFields.map((field) => (
          <FormElement
            dialog={true}
            hideExport
            type={field.type}
            value={field.value}
            title={field.name}
            required={field.required}
            id={field.id}
            key={field.id}
            groupId={groupId}
            style={FormFieldStyle.OUTLINED}
          />
        ))}
        <Box sx={{ m: 2 }}>
          <UserSelect
            setId={setUsers}
            multiple
            noAdmins
            required
            error={false}
            title={context.i18n.user}
          ></UserSelect>
        </Box>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton onClick={onSave} disableRipple color="primary">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateHelpFormDialog;
