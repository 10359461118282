import { FC, useContext, useEffect, useState } from 'react';
import { MenuWrapper } from '../../components/core';
import { AppContext } from '../../hooks/context';
import { ProtocolBillingTable } from '../../components/billing';
import { useParams } from 'react-router-dom';
import { setDocumentTitle, loadTitle } from '../../utils';
import { rejects } from 'assert';
import { BillingType } from '../../types/billing-type';

interface BillingProps {
  type: BillingType;
}

export const Billing: FC<BillingProps> = (props) => {
  const context = useContext(AppContext);

  const [title, setTitle] = useState('');
  const [selected, setSelected] = useState('');

  useEffect(() => {
    const title =
      context.i18n[
        'siteTitleBilling' +
          props.type.charAt(0).toUpperCase() +
          props.type.slice(1).toLowerCase()
      ];
    setTitle(title);
    setDocumentTitle(title);
    setSelected('billing-' + props.type.toString().toLowerCase());
    return () => loadTitle();
  }, [props.type]);

  return (
    <MenuWrapper title={title} selected={selected} search billing addClient>
      <ProtocolBillingTable
        rejected={props.type == BillingType.REJECTED}
        released={props.type == BillingType.RELEASED}
      />
    </MenuWrapper>
  );
};
