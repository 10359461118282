import { AvatarPropType } from '@casecare/types';
import { Tabs, Tab } from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';

import eyes from '../../../assets/avatar/props/eyes.svg';
import eyeBrows from '../../../assets/avatar/props/eye-brows.svg';
import glasses from '../../../assets/avatar/props/glasses.svg';
import face from '../../../assets/avatar/props/face.svg';
import hair from '../../../assets/avatar/props/hair.svg';
import mouth from '../../../assets/avatar/props/mouth.svg';
import ears from '../../../assets/avatar/props/ears.svg';
import nose from '../../../assets/avatar/props/nose.svg';
import body from '../../../assets/avatar/props/body.svg';
import beard from '../../../assets/avatar/props/beard.svg';
import {
  TabScrollButtonStyle,
  AvatarTabButtonStyle,
} from '../../../utils/styling/styling';

interface AvatarPropTabsProps {
  onChangePropType: (type: AvatarPropType) => void;
  selected?: AvatarPropType;
}

const AvatarPropTabs: FC<AvatarPropTabsProps> = (props) => {
  const context = useContext(AppContext);

  const tabs = [
    {
      type: AvatarPropType.HAIR,
      label: context.i18n.hairStyle,
      icon: hair,
    },
    {
      type: AvatarPropType.GLASSES,
      label: context.i18n.glasses,
      icon: glasses,
    },
    {
      type: AvatarPropType.EYEBROWS,
      label: context.i18n.eyeBrows,
      icon: eyeBrows,
    },
    {
      type: AvatarPropType.BEARD,
      label: context.i18n.beard,
      icon: beard,
    },
    {
      type: AvatarPropType.EYES,
      label: context.i18n.eyes,
      icon: eyes,
    },
    {
      type: AvatarPropType.NOSE,
      label: context.i18n.nose,
      icon: nose,
    },
    {
      type: AvatarPropType.EARS,
      label: context.i18n.ears,
      icon: ears,
    },
    {
      type: AvatarPropType.MOUTH,
      label: context.i18n.mouth,
      icon: mouth,
    },
    {
      type: AvatarPropType.FACE,
      label: context.i18n.face,
      icon: face,
    },
    {
      type: AvatarPropType.BODY,
      label: context.i18n.body,
      icon: body,
    },
  ];

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      sx={{
        ...TabScrollButtonStyle,
        height: '100%',
        zIndex: 10,
        position: 'relative',
      }}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
      value={props.selected ? props.selected : false}
      scrollButtons
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.type}
          sx={AvatarTabButtonStyle}
          onClick={() => props.onChangePropType(tab.type)}
          label={tab.label}
          icon={<img width="50vh" src={tab.icon} />}
          value={tab.type}
        />
      ))}
    </Tabs>
  );
};

export default AvatarPropTabs;
