import { Badge, Box, Grow, IconButton, Typography } from '@mui/material';
import { FC, useContext, useEffect, useRef, useState } from 'react';

import { AddAvatarDialog, AvatarNoteDialog } from '../../../components/dialogs';
import { AvatarColors, AvatarPropType, NoteType } from '@casecare/types';
import { AvatarApi } from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AppContext } from '../../../hooks/context';
import { loadTitle, queryClient, setDocumentTitle } from '../../../utils';
import {
  AvatarEditorPopper,
  AvatarMoodPopper,
  AvatarOptionTabs,
  AvatarPropTabs,
  AvatarSymbolsPopper,
  AvatarTabs,
} from '../../../components/avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import ghost from '../../../assets/avatar/ghost.svg';
import AvatarDeadIcon from '../../../components/avatar/avatar-dead-icon/avatar-dead-icon';

const AvatarEditor: FC = () => {
  const context = useContext(AppContext);
  const { indexId, clientId } = useParams();
  const navigate = useNavigate();

  const [editPopperOpen, setEditPopperOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [propType, setPropType] = useState<AvatarPropType | undefined>(
    undefined,
  );
  const [elements, setElements] =
    useState<Partial<Record<AvatarPropType, string>>>();
  const [colors, setColors] = useState<Record<AvatarColors, string>>();
  const [previewUrl, setPreviewUrl] = useState();
  const [clientName, setClientName] = useState('');
  const [avatarList, setAvatarList] = useState([]);
  const [familyIds, setFamilyIds] = useState([]);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [openMoodPopper, setOpenMoodPopper] = useState(false);
  const [mood, setMood] = useState(4);
  const [isDead, setIsDead] = useState(false);
  const [openSymbolsPopper, setOpenSymbolsPopper] = useState(false);
  const [symbolIds, setSymbolIds] = useState<string[]>([]);
  const [noteType, setNoteType] = useState(NoteType.AVATAR);
  const [noteSymbolId, setNoteSymbolId] = useState<string | undefined>();
  const [hasMoodNote, setHasMoodNote] = useState(false);

  const moodRef = useRef(null);
  const propTabRef = useRef(null);
  const symbolsRef = useRef(null);

  useEffect(() => {
    setDocumentTitle(context.i18n.avatar);
    return () => loadTitle();
  }, []);

  const { isLoading } = useQuery(
    ['avatar', clientId],
    () => AvatarApi.getAvatar(context.authToken, clientId, indexId),
    {
      onSuccess: (res) => {
        if (res.data.noAvatar)
          navigate(`/avatar/details/${indexId}/${clientId}`);
        setElements(res.data.elements);
        setColors(res.data.colors);
        setPreviewUrl(res.data.previewUrl);
        setClientName(res.data.name);
        setFamilyIds(res.data.familyIds);
        setIsDead(res.data.isDead);
        setMood(res.data.mood);
        setSymbolIds(res.data.symbolIds);
        setHasMoodNote(res.data.hasMoodNote);
      },
      enabled:
        context.authToken !== undefined &&
        clientId !== undefined &&
        indexId !== undefined,
    },
  );

  useQuery(
    ['avatarList', clientId],
    () => AvatarApi.listAvatars(context.authToken, clientId, indexId),
    {
      onSuccess: (res) => {
        setAvatarList(res.data);
      },
      enabled:
        context.authToken !== undefined &&
        clientId !== undefined &&
        indexId !== undefined,
    },
  );

  const onChangePropType = (type: AvatarPropType) => {
    setEditPopperOpen(true);
    setPropType(type);
  };

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="space-around"
    >
      <Box
        position="absolute"
        width="100%"
        height="100%"
        zIndex={9}
        display={editPopperOpen ? 'inherit' : 'none'}
        sx={{ backdropFilter: 'blur(5px)' }}
      />
      <Box ref={propTabRef}>
        <AvatarPropTabs
          selected={propType}
          onChangePropType={onChangePropType}
        />
      </Box>
      {elements !== undefined &&
        clientId !== undefined &&
        indexId !== undefined &&
        colors !== undefined && (
          <AvatarEditorPopper
            indexClientId={indexId}
            clientId={clientId}
            elements={elements}
            colors={colors}
            propType={propType}
            open={editPopperOpen}
            anchorEl={propTabRef.current}
            onClose={() => {
              setEditPopperOpen(false);
              setPropType(undefined);
              queryClient.invalidateQueries('avatar');
            }}
          />
        )}
      <Box width="100%" height="100%" display="flex" alignItems="end">
        <Box
          position="absolute"
          left={0}
          top={0}
          width="90%"
          display="flex"
          justifyContent="center"
          alignContent="center"
          m={1}
        >
          <Typography variant="h4">{clientName}</Typography>
          <IconButton
            disableRipple
            sx={{ bgcolor: '#1876d2', m: 1 }}
            size="small"
            onClick={() => navigate(`/avatar/details/${indexId}/${clientId}`)}
          >
            <FontAwesomeIcon color="white" icon={faPen} />
          </IconButton>
        </Box>
        <Box m={10} height="70vh" display="flex" zIndex={-1}>
          <AvatarDeadIcon
            invisible={isDead ? false : true}
            sx={{
              '& .MuiBadge-badge': {
                transform: 'translate(-25vh, 5vh)',
              },
            }}
          >
            <Grow in={!isLoading}>
              <img height="100%" src={previewUrl} />
            </Grow>
          </AvatarDeadIcon>
        </Box>
      </Box>
      <AvatarOptionTabs
        onNewConnection={() => setAddDialogOpen(true)}
        onNotes={() => {
          setNoteType(NoteType.AVATAR);
          setOpenNotesDialog(true);
        }}
        onClose={() => window.close()}
        onMood={() => {
          setOpenMoodPopper(!openMoodPopper);
        }}
        onSymbols={() => setOpenSymbolsPopper(!openSymbolsPopper)}
        moodRef={moodRef}
        symbolsRef={symbolsRef}
      />
      {symbolsRef.current !== null && (
        <AvatarSymbolsPopper
          onNote={(id) => {
            setNoteSymbolId(id);
            setNoteType(NoteType.AVATAR_SYMBOL);
            setOpenNotesDialog(true);
          }}
          clientId={clientId}
          indexId={indexId}
          onClose={() => setOpenSymbolsPopper(false)}
          anchorEl={symbolsRef.current}
          open={openSymbolsPopper}
          ids={symbolIds}
        />
      )}
      {moodRef.current !== null && (
        <AvatarMoodPopper
          indexId={indexId}
          clientId={clientId}
          onClose={() => setOpenMoodPopper(false)}
          open={openMoodPopper}
          anchorEl={moodRef.current}
          mood={mood}
          onNote={() => {
            setNoteType(NoteType.AVATAR_MOOD);
            setOpenNotesDialog(true);
          }}
          hasNote={hasMoodNote}
        />
      )}
      <Grow in={avatarList.length > 0}>
        <Box m={1} position="absolute" right={100} bottom={0}>
          <AvatarTabs
            avatars={avatarList}
            onChange={(id) => navigate(`/avatar/editor/${indexId}/${id}`)}
          />
        </Box>
      </Grow>
      <AddAvatarDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        indexId={indexId}
        clientId={clientId}
      />
      <AvatarNoteDialog
        symbolId={noteSymbolId}
        clientId={clientId}
        open={openNotesDialog}
        onClose={() => {
          setOpenNotesDialog(false);
          queryClient.invalidateQueries('avatarSymbols');
        }}
        type={noteType}
        indexClientId={indexId}
      />
    </Box>
  );
};

export default AvatarEditor;
