import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { AdminBillingApi } from '../../../../api';
import { theme } from '../../../../utils';

export const TokenCard: FC = () => {
  const context = useContext(AppContext);

  const [token, setToken] = useState('');

  const [showTokenEdit, setShowTokenEdit] = useState(!context.sevDeskActive);

  const onSave = async () => {
    try {
      const res = await AdminBillingApi.saveToken(context.authToken, token);

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      context.setSevDeskActive(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setShowTokenEdit(!context.sevDeskActive);
  }, [context.sevDeskActive]);

  return (
    <Box m={2} width="100%">
      <Typography color={theme.palette.primary.main} align="left" variant="h5">
        {context.i18n.sevDeskToken}
      </Typography>
      <Box
        m={2}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        {showTokenEdit ? (
          <>
            <TextField
              value={token}
              onChange={(e) => setToken(e.currentTarget.value)}
              label={context.i18n.sevDeskToken}
              sx={{
                width: '22rem',
              }}
            />
            <Box flexDirection="column" display="flex">
              <Button sx={{ ml: 2 }} onClick={onSave}>
                {context.i18n.saveToken}
              </Button>
              {context.sevDeskActive && (
                <Button sx={{ ml: 2 }} onClick={() => setShowTokenEdit(false)}>
                  {context.i18n.cancelBtnTooltip}
                </Button>
              )}
            </Box>
          </>
        ) : (
          <Button sx={{ ml: 2 }} onClick={() => setShowTokenEdit(true)}>
            {context.i18n.editToken}
          </Button>
        )}
      </Box>
    </Box>
  );
};
