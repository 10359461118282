import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { Autocomplete, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { AdminApi, UserApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface UserGroupSelectProps {
  setId: (id: any) => void;
  error: boolean;
  userGroupId?: string;
  userGroupIds?: string[];
  title?: string;
  multiple?: boolean;
  disabled?: boolean;
  noAdmins?: boolean;
  noShowUsers?: string[];
  required?: boolean;
  helperText?: string;
}

export const UserGroupSelect: FC<UserGroupSelectProps> = (props) => {
  const context = useContext(AppContext);

  const [userGroups, setUserGroups] = useState([]);
  const [selected, setSelected] = useState(
    props.multiple ? props.userGroupIds : '',
  );
  const [error, setError] = useState(props.error);

  useEffect(() => {
    if (props.userGroupId) {
      const supervisor: any = userGroups.find(
        (s: any) => s.id === props.userGroupId,
      );
      if (supervisor) {
        setSelected(supervisor);
      }
    }
  }, [props.userGroupId, userGroups]);

  useEffect(() => {
    if (props.userGroupIds) {
      const supervisors = userGroups.filter((user: any) =>
        props.userGroupIds?.includes(user.id),
      );
      setSelected(supervisors);
    }
  }, [props.userGroupIds, userGroups]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const { isLoading } = useQuery(
    ['userGroups', props.noAdmins],
    () =>
      context.isAdmin
        ? AdminApi.getUserGroups(context.authToken)
        : UserApi.getUsersGroups(context.authToken),
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setUserGroups(res.data.groups);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  return isLoading ? (
    <></>
  ) : (
    <Autocomplete
      disabled={props.disabled}
      disableClearable={selected !== ''}
      onChange={(evetn, option: any) => {
        setSelected(option);
        props.multiple
          ? props.setId(option.map((o: any) => o.id))
          : props.setId(option.id);
      }}
      multiple={props.multiple}
      value={selected}
      noOptionsText={context.i18n.noUsersGroups}
      getOptionLabel={(option: any) => option.name || ''}
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          label={props.title}
          required={props.required}
          helperText={props.helperText}
        />
      )}
      options={userGroups}
    />
  );
};
