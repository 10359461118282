import {
  faFileArrowUp,
  faFileExport,
  faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  FormControl,
  TextField,
  DialogActions,
  Tooltip,
  IconButton,
  DialogContentText,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import moment from 'moment';

interface ReleaseProtocolHoursDialogProps {
  open: boolean;
  onClose: () => void;
  onRelease: () => void;
}

export const ReleaseProtocolHoursDialog: FC<ReleaseProtocolHoursDialogProps> = (
  props,
) => {
  const context = useContext(AppContext);

  const [date, setDate] = useState(moment());

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.releaseHours}</DialogTitle>
      <DialogContent sx={{ display: 'flex' }}>
        <DialogContentText>{context.i18n.releaseHoursText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRelease}>{context.i18n.submit}</Button>
        <Button onClick={props.onClose}>{context.i18n.cancel}</Button>
      </DialogActions>
    </Dialog>
  );
};
