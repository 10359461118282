import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import moment from 'moment';

interface DeleteProtocolDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteProtocolDialog: FC<DeleteProtocolDialogProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.deleteProtocol}</DialogTitle>
      <DialogContent sx={{ display: 'flex' }}>
        <DialogContentText>{context.i18n.deleteProtocolText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onDelete}>{context.i18n.delete}</Button>
        <Button onClick={props.onClose}>{context.i18n.cancel}</Button>
      </DialogActions>
    </Dialog>
  );
};
