import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AvatarApi } from '../../../api';
import { useQuery } from 'react-query';
import { AppContext } from '../../../hooks/context';
import { theme } from '../../../utils';
import { useNavigate } from 'react-router-dom';

interface AddAvatarDialogProps {
  open: boolean;
  onClose: () => void;
  indexId: string | undefined;
  clientId: string | undefined;
}

const buttonStyle = {
  backgroundColor: theme.palette.primary.main,
  width: 110,
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
};

const AddAvatarDialog: FC<AddAvatarDialogProps> = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(props.open);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [familyChosen, setFamilyChosen] = useState(false);

  useEffect(() => {
    if (!props.open) {
      setFamilyChosen(false);
    }

    setOpen(props.open);
  }, [props.open]);

  useQuery(
    ['avatarFamilyMembers', props.clientId],
    () =>
      AvatarApi.getAvailableFamilyMembers(
        context.authToken,
        props.clientId,
        props.indexId,
      ),
    {
      onSuccess: (res) => {
        setFamilyMembers(res.data);
      },
      enabled:
        context.authToken !== undefined &&
        props.clientId !== undefined &&
        props.indexId !== undefined &&
        familyChosen,
    },
  );

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.addAvatar}</DialogTitle>
      <DialogContent>
        {familyChosen ? (
          <FormControl color="secondary" sx={{ mt: 2, width: '22rem' }}>
            <InputLabel sx={{ alignSelf: 'center' }} id="family-slect-label">
              {context.i18n.persons}
            </InputLabel>
            <Select labelId="family-select-label" label={context.i18n.persons}>
              {familyMembers.length === 0 && (
                <MenuItem disabled>{context.i18n.noFamilyMembers}</MenuItem>
              )}
              {familyMembers.map((fm: any, index) => (
                <MenuItem
                  key={index}
                  onClick={() =>
                    navigate(`/avatar/details/${props.indexId}/${fm.id}`)
                  }
                >
                  {fm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Stack direction="row" spacing={2}>
            <Stack
              direction="column"
              alignItems="center"
              sx={buttonStyle}
              padding={2}
              onClick={() => navigate(`/avatar/details/${props.indexId}`)}
            >
              <Typography color="white" textTransform="none">
                {context.i18n.addNewPerson}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              alignItems="center"
              padding={2}
              sx={buttonStyle}
              onClick={() => setFamilyChosen(true)}
            >
              <Typography color="white" textTransform="none">
                {context.i18n.addExistingPerson}
              </Typography>
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddAvatarDialog;
