import { Autocomplete, TextField } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AdminBillingApi } from '../../../api';
import { AppContext } from '../../../hooks/context';

interface BillingContactsAutoCompleteProps {
  error?: boolean;
  orgOnly?: boolean;
  onSelect: (id: string) => void;
  title: string;
  disabled?: boolean;
  required?: boolean;
  availableOrderers?: any[];
}

export const BillingContactsAutoComplete: FC<
  BillingContactsAutoCompleteProps
> = (props) => {
  const context = useContext(AppContext);

  const [contacts, setContacts] = useState<any[]>([]);

  useQuery(
    ['autoCompleteContacts'],
    () => AdminBillingApi.getSevDeskContacts(context.authToken),
    {
      enabled:
        context.authToken !== undefined &&
        context.sevDeskActive &&
        !props.availableOrderers,
      onSuccess: (res) => {
        setContacts(res.data);
      },
    },
  );

  useEffect(() => {
    if (props.availableOrderers) {
      setContacts(props.availableOrderers);
    }
  }, [props.availableOrderers]);

  return (
    <Autocomplete
      disabled={props.disabled}
      onChange={(event, option) => props.onSelect(option ? option : undefined)}
      options={contacts}
      isOptionEqualToValue={(o, v) => o.id === v}
      getOptionLabel={(o: any) => o.name}
      renderInput={(params) => (
        <TextField
          required={props.required}
          error={props.error}
          {...params}
          label={props.title}
        />
      )}
    />
  );
};
