import { Box } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import { AdminDrawer } from '../../../components/admin';
import { ClientTable } from '../../../components/client';
import Navbar from '../../../components/core/navbar/navbar';
import { AppContext } from '../../../hooks/context';
import { loadTitle, setDocumentTitle } from '../../../utils';
import { drawerWidthSpaced } from '../../../utils/constants';
import { MenuWrapper } from '../../../components/core';

const ClientArchive: FC = () => {
  const context = useContext(AppContext);

  useEffect(() => {
    setDocumentTitle(context.i18n.clientArchive);
    return () => loadTitle();
  }, []);

  return (
    <MenuWrapper
      admin
      search
      addClient
      title={context.i18n.clientArchive}
      selected="admin-archive"
    >
      <ClientTable archive />
    </MenuWrapper>
  );
};

export default ClientArchive;
