import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle, theme } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faX } from '@fortawesome/free-solid-svg-icons';
import EcoMapMenu from '../../../components/eco-map/eco-map-menu/eco-map-menu';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import EcoMapClientMenu from '../../../components/eco-map/eco-map-client-menu/eco-map-client-menu';
import { ClientDataType } from '@casecare/types';
import GenderIcon from '../../../components/core/gender-icon/gender-icon';
import EcoMapApi from '../../../api/eco-map/eco-map';
import EcoMapIconMap from '../../../components/eco-map/eco-map-icon-map/eco-map-icon-map';

const EcoMapDetails: FC = () => {
  const context = useContext(AppContext);
  const { clientId, indexId } = useParams();
  const [connectedClients, setConnectedClients] = useState([]);
  const [client, setClient] = useState<any>(undefined);
  const [ecoMap, setEcoMap] = useState<any>(undefined);
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [expertMode, setExpertMode] = useState(false);
  const [isEcoMapPrintLoading, setIsEcoMapPrintLoading] = useState(false);
  const [family, setFamily] = useState<any[]>([]);

  const setAccordionSelected = (newSelected: string | undefined) => {
    if (newSelected === selected) newSelected = undefined;
    setSelected(newSelected);
  };

  useEffect(() => {
    setDocumentTitle(context.i18n.ecoMap);
    return () => loadTitle();
  }, []);

  const ecoMapQuery = useQuery({
    queryKey: ['ecoMap', clientId, indexId],
    queryFn: () => {
      return EcoMapApi.getEcoMap(context.authToken, clientId, indexId);
    },
    enabled:
      context.authToken !== undefined &&
      clientId !== undefined &&
      indexId !== undefined,
  });

  useEffect(() => {
    if (!ecoMapQuery.isLoading) {
      if (ecoMapQuery.error) {
        console.error(ecoMapQuery.error);
      } else if (ecoMapQuery.data && ecoMapQuery.data.data) {
        setEcoMap(ecoMapQuery.data.data);
      }
    }
  }, [ecoMapQuery.data, ecoMapQuery.error, ecoMapQuery.isLoading]);

  const clientsQuery = useQuery({
    queryKey: ['ecoMapClients', clientId, indexId],
    queryFn: () => {
      return EcoMapApi.getEcoMapClients(context.authToken, clientId, indexId);
    },
    enabled:
      context.authToken !== undefined &&
      clientId !== undefined &&
      indexId !== undefined,
  });

  useEffect(() => {
    if (!clientsQuery.isLoading) {
      if (clientsQuery.error) {
        console.error(clientsQuery.error);
      } else if (clientsQuery.data && clientsQuery.data.data) {
        setConnectedClients(clientsQuery.data.data.connectedClients);
        setClient(clientsQuery.data.data.clientData);
        setFamily(clientsQuery.data.data.family);
      }
    }
  }, [clientsQuery.data, clientsQuery.error, clientsQuery.isLoading]);

  const printEcoMap = () => {
    const iframe = document.createElement('iframe');
    iframe.src = '/eco-map/print/' + clientId + '/' + indexId;
    iframe.style.width = '4000px';
    iframe.style.height = '0px';
    iframe.style.visibility = 'hidden';
    iframe.style.overflow = 'hidden';
    iframe.id = 'eco-map-print-iframe';
    document.body.appendChild(iframe);
  };

  const handleMessage = (event: any) => {
    if (event.data.action === 'eco-map-print-loaded') {
      setIsEcoMapPrintLoading(false);
      const iframe = document.getElementById(
        'eco-map-print-iframe',
      ) as HTMLIFrameElement;
      setTimeout(() => {
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.onafterprint = () => {
            iframe.remove();
          };
          iframe.contentWindow.print();
        }
      }, 500);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Grid container>
      {ecoMapQuery.isLoading ||
      clientsQuery.isLoading ||
      client === undefined ||
      ecoMap === undefined ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <>
          <Grid
            height={'100vh'}
            maxHeight={'100vh'}
            overflow={'auto'}
            item
            xs={5}
            paddingRight={5}
            sx={{
              backgroundColor: 'white',
              position: 'relative',
              zIndex: 100,
            }}
          >
            <Typography m={3} variant="h1">
              {context.i18n.ecoMapFor}
              {': '}
              {client && client[ClientDataType.FIRST_NAME]}{' '}
              {client && client[ClientDataType.LAST_NAME]},{' '}
              <GenderIcon
                sex={client && client[ClientDataType.SEX]}
                size="xl"
              ></GenderIcon>
            </Typography>
            <EcoMapMenu
              family={family}
              ecoMap={ecoMap}
              client={client}
              reloadData={() => {
                ecoMapQuery.refetch();
                clientsQuery.refetch();
              }}
              selected={selected}
              setSelected={setAccordionSelected}
            ></EcoMapMenu>
            <Box sx={{ mt: 5 }}>
              <EcoMapClientMenu
                family={family}
                setConnectedClients={setConnectedClients}
                connectedClients={connectedClients}
                selected={selected}
                reloadData={(all: boolean) => {
                  ecoMapQuery.refetch();
                  if (all) clientsQuery.refetch();
                }}
                setSelected={setAccordionSelected}
                ecoMapId={ecoMap.id}
                clientId={client.id}
              />
            </Box>
            <Grid container sx={{ mt: 1 }} spacing={3}>
              <Grid item xs={4}>
                <FormControlLabel
                  sx={{
                    textTransform: 'uppercase',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'grey.400',
                    width: '100%',
                    pl: 2,
                    pt: 2,
                    pb: 2,
                  }}
                  control={
                    <Switch
                      checked={expertMode}
                      onChange={() => setExpertMode(!expertMode)}
                    />
                  }
                  label={context.i18n.expertMode}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  disabled={isEcoMapPrintLoading}
                  onClick={() => {
                    setIsEcoMapPrintLoading(true);
                    printEcoMap();
                  }}
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                  variant="contained"
                  color="inherit"
                  endIcon={
                    isEcoMapPrintLoading ? (
                      <CircularProgress />
                    ) : (
                      <FontAwesomeIcon
                        color={theme.palette.success.main}
                        icon={faFileExport}
                      />
                    )
                  }
                >
                  {!isEcoMapPrintLoading && context.i18n.export}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                  onClick={() => window.close()}
                  variant="contained"
                  color="inherit"
                  endIcon={
                    <FontAwesomeIcon
                      icon={faX}
                      color={theme.palette.error.main}
                    />
                  }
                >
                  {context.i18n.close}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            height={'100vh'}
            maxHeight={'100vh'}
            item
            xs={7}
            sx={{ position: 'relative' }}
          >
            <EcoMapIconMap
              ecoMap={ecoMap}
              expertMode={expertMode}
              indexClient={client}
            ></EcoMapIconMap>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default EcoMapDetails;
