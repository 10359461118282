import { FC, useContext, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../../utils';
import TodoList from '../../../components/planning/todo-list/todo-list';
import { MenuWrapper } from '../../../components/core';

const PlanningDashboard: FC = () => {
  const context = useContext(AppContext);

  useEffect(() => {
    setDocumentTitle(context.i18n.planningDashboard);
    return () => loadTitle();
  }, []);

  return (
    <MenuWrapper
      title={context.i18n.planningDashboard}
      selected="planing-todo"
      search
      addClient
    >
      <TodoList title={context.i18n.todoListTitle} done={false} />
      <TodoList title={context.i18n.doneListTitle} done={true} />
    </MenuWrapper>
  );
};

export default PlanningDashboard;
