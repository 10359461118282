import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AdminApi } from '../../../api';
import { AdminDrawer } from '../../../components/admin';
import EditUserDialog, {
  User,
} from '../../../components/admin/user/edit-user-dialog';
import InviteUserDialog from '../../../components/admin/user/invite-user-dialog';
import Navbar from '../../../components/core/navbar/navbar';
import { AppContext } from '../../../hooks/context';
import { loadTitle, setDocumentTitle } from '../../../utils';
import { drawerWidthSpaced } from '../../../utils/constants';
import { MenuWrapper } from '../../../components/core';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

interface DataGridElement {
  id: string;
  username: string;
  isAdmin: boolean;
}

const UserList = () => {
  const context = useContext(AppContext);
  const [inviteUserOpen, setInviteUserOpen] = useState(false);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [rows, setRows] = useState<DataGridElement[]>([]);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [selectedUser, setSelectedUser] = useState<User>();

  useEffect(() => {
    setDocumentTitle('Benutzerverwaltung');
    return () => loadTitle();
  }, []);

  const { isLoading, error, data } = useQuery({
    queryKey: ['getUsers'],
    queryFn: () => AdminApi.getUsers(context.authToken),
    enabled: context.authToken !== undefined && context.isAdmin,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (data && data.data && data.data.users) {
      setRows(data.data.users);
      setTotalRowCount(data.data.users.length);
    }
  }, [data, error]);

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: context.i18n.email,
      editable: false,
      flex: 1,
    },
    {
      field: 'name',
      headerName: context.i18n.name,
      editable: false,
      flex: 1,
    },
    {
      field: 'role',
      headerName: context.i18n.role,
      editable: false,
      flex: 1,
      valueFormatter: (value) => context.i18n[value] || '',
    },
    {
      field: 'status',
      headerName: context.i18n.userStatus,
      editable: false,
      flex: 1,
      valueFormatter: (value) => context.i18n[value] || '',
    },
    {
      field: context.i18n.actions,
      headerName: '',
      flex: 1,
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <Tooltip title={context.i18n.inviteUser}>
          <IconButton
            disableRipple
            color="primary"
            id="invite-user-button"
            data-id="invite-user-button"
            onClick={() => setInviteUserOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Box>
          <Tooltip title="Benutzer bearbeiten">
            <IconButton
              disableRipple
              color="primary"
              sx={{ height: 39 }}
              onClick={() => {
                setSelectedUser(params.row);
                setEditUserOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <MenuWrapper
      title={context.i18n.userManagement}
      selected="admin-users"
      addClient
      search
      admin
    >
      <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
        <DataGridPro
          sx={{
            m: 5,
          }}
          columns={columns}
          rows={rows}
          loading={isLoading}
          getRowId={(row) => row.id}
          rowCount={totalRowCount}
          autoHeight
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
        />
      </Box>
      <InviteUserDialog
        open={inviteUserOpen}
        onClose={() => {
          setInviteUserOpen(false);
        }}
      />
      <EditUserDialog
        open={editUserOpen}
        onClose={() => {
          setEditUserOpen(false);
          setSelectedUser(undefined);
        }}
        existingUser={selectedUser}
      />
    </MenuWrapper>
  );
};

export default UserList;
