import { FormFieldType, FormFieldValueType } from '@casecare/types';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Tooltip,
  IconButton,
  TableFooter,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../../hooks/context';
import { FormDialogProps } from '../../../../types';

const FormSelectionDialog: FC<FormDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [options, setOptions] = useState(
    props.element?.fieldOptions
      ? props.element.fieldOptions.sort((a, b) => a.key - b.key)
      : [],
  );
  const [optionName, setOptionName] = useState('');
  const [optionNameError, setOptionNameError] = useState(false);
  const [optionHelperText, setOptionHelperText] = useState('');
  const [selectionType, setSelectionType] = useState(
    props.element?.type ? props.element.type : '',
  );
  const [selcetionTypeError, setSelectionTypeError] = useState(false);

  useEffect(() => {
    if (!props.saving) {
      return;
    }
    let errors = false;

    if (!options || options.length === 0) {
      errors = true;
      setOptionNameError(true);
      setOptionHelperText(context.i18n.minOneOption);
    }

    if (selectionType === '') {
      errors = true;
      setSelectionTypeError(true);
    }

    // hack to make soure selction type is not a string
    // beacause of select
    const safeSelectionType =
      typeof selectionType !== 'string' ? selectionType : 5;

    const valueType =
      selectionType === FormFieldType.CHECKBOX
        ? FormFieldValueType.NUMBER_LIST
        : FormFieldValueType.NUMBER;

    props.onSave(
      {
        ...props.element,
        type: safeSelectionType,
        fieldOptions: options,
        valueType,
      },
      errors,
    );
  }, [props.saving]);

  const addOption = () => {
    if (optionName === '') {
      setOptionNameError(true);
      return;
    }

    setOptions((current) => {
      return [
        ...current,
        // key must be at least 1 for dropdown beacuase 0 auto selects nothing
        { label: optionName, key: options.length + 1 },
      ];
    });
    setOptionName('');
  };

  const deleteOption = (key: number) => {
    setOptions((current) =>
      current.filter((option) => {
        return option.key !== key;
      }),
    );
  };

  const updateOption = (label: string, key: number) => {
    const nextOptions = options.map((option) => {
      if (option.key !== key) {
        return option;
      }
      return {
        label,
        key,
      };
    });

    setOptions(
      nextOptions.filter((option) => {
        return option.label !== '';
      }),
    );
  };

  return (
    <Stack direction="column" sx={{ width: '22rem' }} spacing={2}>
      <FormControl color="secondary" error={selcetionTypeError}>
        <InputLabel
          sx={{ alignSelf: 'center' }}
          id="selection-type-select-label"
        >
          {context.i18n.selectionType}
        </InputLabel>
        <Select
          labelId="selection-type-select-label"
          id="selection-type-select"
          label={context.i18n.selectionType}
          value={selectionType}
          onSelect={() => setSelectionTypeError(false)}
        >
          <MenuItem
            onClick={() => {
              setSelectionTypeError(false);
              setSelectionType(FormFieldType.CHECKBOX);
            }}
            value={FormFieldType.CHECKBOX}
          >
            {context.i18n.formElementTypeCheckbox}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectionTypeError(false);
              setSelectionType(FormFieldType.DROPDOWN);
            }}
            value={FormFieldType.DROPDOWN}
          >
            {context.i18n.formElementTypeDropdown}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectionTypeError(false);
              setSelectionType(FormFieldType.RADIO);
            }}
            value={FormFieldType.RADIO}
          >
            {context.i18n.formElementTypeRadio}
          </MenuItem>
        </Select>
      </FormControl>
      <TableContainer sx={{ maxHeight: 276 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell color="primary">{context.i18n.name}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {options.map((row) => (
              <TableRow key={row.key}>
                <TableCell>
                  <TextField
                    color="secondary"
                    size="small"
                    type={'text'}
                    value={row.label}
                    onChange={(e) =>
                      updateOption(e.currentTarget.value, row.key)
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={context.i18n.deleteOption}>
                    <IconButton
                      disableRipple
                      id={'delete-selection-row-' + row.key}
                      color="error"
                      onClick={() => deleteOption(row.key)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter
            sx={{
              position: 'sticky',
              left: 0,
              bottom: 0,
              backgroundColor: 'white',
            }}
          >
            <TableRow>
              <TableCell>
                <TextField
                  color="secondary"
                  size="small"
                  type={'text'}
                  id="add-select-row-input"
                  error={optionNameError}
                  helperText={optionHelperText}
                  value={optionName}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') addOption();
                  }}
                  onChange={(e) => {
                    setOptionHelperText('');
                    setOptionNameError(false);
                    setOptionName(e.currentTarget.value);
                  }}
                />
              </TableCell>
              <TableCell>
                <Tooltip title={context.i18n.addOption}>
                  <IconButton
                    id="add-select-row-btn"
                    disableRipple
                    color="primary"
                    onClick={() => addOption()}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default FormSelectionDialog;
