import { Tab, TabProps } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface TabLinkProps extends TabProps {
  href: string;
  target?: string;
}
const TabLink: FC<TabLinkProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Tab
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        if (props.target) {
          window.open(props.href, props.target);
        } else {
          navigate(props.href);
        }
      }}
      {...props}
    />
  );
};

export default TabLink;
