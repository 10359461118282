import { CircularProgress, Grid } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import EcoMapApi from '../../../api/eco-map/eco-map';
import EcoMapPrintableMap from '../../../components/eco-map/eco-map-printable-map/eco-map-printable-map';
import EcoMapCalculation from '../../../components/eco-map/eco-map-calculation/eco-map-calculation';

const EcoMapPrint: FC = () => {
  const context = useContext(AppContext);
  const { clientId, indexId } = useParams();
  const [ecoMap, setEcoMap] = useState<any>(undefined);

  useEffect(() => {
    setDocumentTitle(context.i18n.ecoMapCalculation);
    return () => loadTitle();
  }, []);

  const ecoMapQuery = useQuery({
    queryKey: ['ecoMap', clientId, indexId],
    queryFn: () => {
      return EcoMapApi.getEcoMap(context.authToken, clientId, indexId);
    },
    enabled:
      context.authToken !== undefined &&
      clientId !== undefined &&
      indexId !== undefined,
  });

  useEffect(() => {
    if (!ecoMapQuery.isLoading) {
      if (ecoMapQuery.error) {
        console.error(ecoMapQuery.error);
      } else if (ecoMapQuery.data && ecoMapQuery.data.data) {
        setEcoMap(ecoMapQuery.data.data);
      }
    }
  }, [ecoMapQuery.data, ecoMapQuery.error, ecoMapQuery.isLoading]);

  return (
    <Grid container>
      {ecoMapQuery.isLoading || ecoMap === undefined ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <>
          <Grid xs={8} item>
            <EcoMapPrintableMap
              ecoMap={ecoMap}
              expertMode={true}
              indexClient={clientId}
            ></EcoMapPrintableMap>
          </Grid>
          <Grid xs={8} item>
            <EcoMapCalculation client={clientId} ecoMap={ecoMap} />
          </Grid>
          {parent.postMessage({ action: 'eco-map-print-loaded' })}
        </>
      )}
    </Grid>
  );
};

export default EcoMapPrint;
