import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { useQueries, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import EcoMapApi from '../../../api/eco-map/eco-map';
import EcoMapPrintableMap from '../../../components/eco-map/eco-map-printable-map/eco-map-printable-map';
import EcoMapCalculation from '../../../components/eco-map/eco-map-calculation/eco-map-calculation';
import { FormElement } from '../../../components/client';
import { FormFieldType } from '@casecare/types';

const EcoMapCompare: FC = () => {
  const context = useContext(AppContext);
  const { clientId } = useParams();
  const [ecoMaps, setEcoMaps] = useState<any[]>([]);
  const [ecoMapsData, setEcoMapsData] = useState<any[]>([]);
  const [selectedEcoMaps, setSelectedEcoMaps] = useState<any[]>([]);

  useEffect(() => {
    setDocumentTitle(context.i18n.ecoMapCompare);
    return () => loadTitle();
  }, []);

  const ecoMapsQuery = useQuery({
    queryKey: ['ecoMapsQuery', clientId],
    queryFn: () => {
      const data: any = {
        pageSize: 0,
      };
      return EcoMapApi.listEcoMap(context.authToken, clientId, data);
    },
    enabled: context.authToken !== undefined && clientId !== undefined,
  });

  useEffect(() => {
    if (!ecoMapsQuery.isLoading) {
      if (ecoMapsQuery.error) {
        console.error(ecoMapsQuery.error);
      } else if (ecoMapsQuery.data && ecoMapsQuery.data.data) {
        setEcoMaps(ecoMapsQuery.data.data.ecoMaps);
      }
    }
  }, [ecoMapsQuery.data, ecoMapsQuery.error, ecoMapsQuery.isLoading]);

  const ecoMapsDataQuery = useQueries(
    selectedEcoMaps.map((map: any) => {
      return {
        queryKey: ['ecoMapsDataQuery', clientId, map],
        queryFn: () => {
          return EcoMapApi.getEcoMap(context.authToken, clientId, map);
        },
        enabled:
          context.authToken !== undefined &&
          clientId !== undefined &&
          map !== undefined,
      };
    }),
  );

  const ecoMapsDataQueryFinished = ecoMapsDataQuery.every(
    (query) => query.isSuccess,
  );

  useEffect(() => {
    const currentEcoMaps: any[] = [];
    ecoMapsDataQuery.map((ecoMapQuery: any) => {
      if (ecoMapQuery.error) {
        console.error(ecoMapQuery.error);
      } else if (ecoMapQuery.data && ecoMapQuery.data.data) {
        currentEcoMaps.push(ecoMapQuery.data.data);
      }
    });
    setEcoMapsData(Object.assign(currentEcoMaps));
  }, [ecoMapsDataQueryFinished]);

  return (
    <Box>
      {ecoMapsQuery.isLoading ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <FormElement
                dialog={true}
                hideExport
                type={FormFieldType.DROPDOWN}
                multiple
                fieldOptions={ecoMaps.map((ecoMap) => {
                  return { key: ecoMap.id, id: ecoMap.id, label: ecoMap.name };
                })}
                value={selectedEcoMaps}
                id={'eco-map-compare-select'}
                onChange={(value: any) => {
                  if (value) {
                    document
                      .querySelectorAll('.leader-line')
                      .forEach((el) => el.remove());
                    setSelectedEcoMaps(Object.assign(value));
                  }
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
            }}
          >
            {ecoMapsData.length <= 0 ? (
              <Typography sx={{ width: '100%' }} align="center">
                {context.i18n.noResults}
              </Typography>
            ) : (
              ecoMapsData.map((ecoMap: any) => (
                <Box key={ecoMap.id} sx={{ padding: 5, pt: 0 }}>
                  <Typography>{ecoMap.name}</Typography>
                  <EcoMapPrintableMap
                    ecoMap={ecoMap}
                    expertMode={true}
                    small={true}
                    indexClient={clientId}
                  ></EcoMapPrintableMap>
                  <EcoMapCalculation client={clientId} ecoMap={ecoMap} />
                </Box>
              ))
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default EcoMapCompare;
