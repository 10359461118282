import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProtocolApi } from '../../../api';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface EditProtokollBulletPointsDialogProps {
  open: boolean;
  onClose: () => void;
  clientId?: string;
  familyId?: string;
  fullScreen?: boolean;
}

export const EditProtokollBulletPointsDialog: FC<
  EditProtokollBulletPointsDialogProps
> = (props) => {
  const context = useContext(AppContext);

  const [protocolBulletPoints, setProtocolBulletPoints] = useState(
    `${context.i18n.workRelationProtocol}\n\t-\n${context.i18n.themeProtocol}\n\t-\n${context.i18n.developmentsProtocol}\n\t-\n${context.i18n.nextStepsProtocol}\n\t-\n${context.i18n.agreementsProtocol}\n\t-\n${context.i18n.remarksProtocol}\n\t-\n`,
  );

  useQuery(
    ['protocolBulletPoints', props.open],
    () =>
      props.clientId
        ? ProtocolApi.getProtocolBulletPoints(context.authToken, props.clientId)
        : ProtocolApi.getFamilyProtocolBulletPoints(
            context.authToken,
            props.familyId,
          ),
    {
      enabled: context.authToken !== undefined && props.open,
      onSuccess: (res) => {
        console.log(res.data);
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const onSave = async () => {
    try {
      const body = Object.assign(
        {
          protocolBulletPoints,
        },
        props.clientId && {
          clientId: props.clientId,
        },
        props.familyId && {
          familyId: props.familyId,
        },
      );

      const res = await ProtocolApi.editProtocolBulletPoints(
        context.authToken,
        body,
      );

      if (res.message) {
        snackbarUtils.success(res.message);
        props.onClose();
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>{context.i18n.protocolBulletPoints}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <TextField
            variant="outlined"
            multiline
            rows={props.fullScreen ? 28 : 18}
            onChange={(e) => setProtocolBulletPoints(e.target.value)}
            value={protocolBulletPoints}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={onSave} disableRipple color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
