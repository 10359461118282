import { RiskAssessmentAnswer, RiskAssessmentQuestion } from '../../types';
import { RiskAssessmentSurveyResult } from '../../types/risk-assesment';
import { RiskAssessmentQuestionType } from '../../types/risk-assessment-question';
import { i18n } from '../i18n/i18n';

function getJson(url: string) {
  return fetch(url).then(function (response) {
    return response.json();
  });
}

function getResultTranslation(result: string, riskSubTypes: string[]) {
  switch (result) {
    case RiskAssessmentSurveyResult[RiskAssessmentSurveyResult.HIGH_RISK]:
      return i18n.riskAssessmentHighRisk + ' ' + riskSubTypes.join(', ');
    case RiskAssessmentSurveyResult[RiskAssessmentSurveyResult.MEDIUM_RISK]:
      return i18n.riskAssessmentMediumRisk + ' ' + riskSubTypes.join(', ');
    case RiskAssessmentSurveyResult[RiskAssessmentSurveyResult.NO_RISK]:
      return i18n.riskAssessmentNoRisk;
    case RiskAssessmentSurveyResult[RiskAssessmentSurveyResult.UNFINISHED]:
      return i18n.riskAssessmentNotFinished;
    case RiskAssessmentSurveyResult[
      RiskAssessmentSurveyResult.HIGH_RISK_UNFINISHED
    ]:
      return i18n.formatString(
        i18n.riskAssessmentHighRiskUnfinished,
        '(' + riskSubTypes.join(', ') + ')',
      );
    case RiskAssessmentSurveyResult[
      RiskAssessmentSurveyResult.MEDIUM_RISK_UNFINISHED
    ]:
      return i18n.formatString(
        i18n.riskAssessmentMediumRiskUnfinished,
        '(' + riskSubTypes.join(', ') + ')',
      );
  }
}

function evaluateChildRiskAssessment(
  riskAssessmentQuestions: RiskAssessmentQuestion[],
  answers: RiskAssessmentAnswer[],
): RiskAssessmentSurveyResult {
  let infoAnswers = 0;
  let noInfoAnswers = 0;
  let noAnswers = 0;
  for (const question of riskAssessmentQuestions) {
    const answer = answers.find((answer) => answer.question === question.id);
    if (answer) {
      if (
        answer.value === 1 &&
        question.type === RiskAssessmentQuestionType.EXTREME
      ) {
        return RiskAssessmentSurveyResult.HIGH_RISK;
      } else if (answer.value === 1) {
        infoAnswers++;
        if (infoAnswers === 2) {
          return RiskAssessmentSurveyResult.MEDIUM_RISK;
        }
      } else if (answer.value === 0) {
        noInfoAnswers++;
        if (noInfoAnswers >= 10) {
          return RiskAssessmentSurveyResult.REPEAT;
        }
      } else if (answer.value === 2) {
        noAnswers++;
      }
    }
  }
  if (noAnswers + noInfoAnswers !== riskAssessmentQuestions.length) {
    return RiskAssessmentSurveyResult.UNFINISHED;
  } else {
    return RiskAssessmentSurveyResult.NO_RISK;
  }
}

export { getJson, getResultTranslation, evaluateChildRiskAssessment };
