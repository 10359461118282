import { faFloppyDisk, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  IconButton,
} from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';

interface DiscardDialogProps {
  onClose: () => void;
  onDiscard: () => void;
  onSave: () => void;
}

const DiscardDialog: FC<DiscardDialogProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Box>
      <DialogTitle>{context.i18n.discardChangesTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{context.i18n.discardChangesText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton
            id="discard-dialog-save-btn"
            onClick={props.onSave.bind(this)}
            color="primary"
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.discardChangesBtnTooltip}>
          <IconButton onClick={props.onDiscard.bind(this)} color="error">
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose.bind(this)} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Box>
  );
};

export default DiscardDialog;
