import {
  faArrowsUpToLine,
  faArrowUp,
  faArrowDown,
  faArrowsDownToLine,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Tooltip, IconButton } from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';

interface MovementButtonsProps {
  moveElement: (up: boolean, id: string, allTheWay?: boolean) => void;
  id: any;
  first: boolean;
  last: boolean;
}

const MovementButtons: FC<MovementButtonsProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Stack
      direction="row"
      spacing={0}
      width="100%"
      justifyContent={props.first ? 'end' : props.last ? 'start' : 'center'}
    >
      {!props.first && (
        <>
          <Tooltip title={context.i18n.moveAllTheWayUp}>
            <IconButton
              color="primary"
              disableRipple
              onClick={() => props.moveElement(true, props.id, true)}
            >
              <FontAwesomeIcon size="2xs" icon={faArrowsUpToLine} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.moveUp}>
            <IconButton
              color="primary"
              disableRipple
              onClick={() => props.moveElement(true, props.id, false)}
            >
              <FontAwesomeIcon size="2xs" icon={faArrowUp} />
            </IconButton>
          </Tooltip>
        </>
      )}
      {!props.last && (
        <>
          <Tooltip title={context.i18n.moveDown}>
            <IconButton
              color="primary"
              disableRipple
              onClick={() => props.moveElement(false, props.id, false)}
            >
              <FontAwesomeIcon size="2xs" icon={faArrowDown} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.moveAllTheWayDown}>
            <IconButton
              color="primary"
              disableRipple
              onClick={() => props.moveElement(false, props.id, true)}
            >
              <FontAwesomeIcon size="2xs" icon={faArrowsDownToLine} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default MovementButtons;
