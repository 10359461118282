import { FC, useContext, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { MenuWrapper } from '../../../components/core';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { TeamTodoList } from '../../../components/planning';

export const TeamDashboard: FC = () => {
  const context = useContext(AppContext);

  useEffect(() => {
    setDocumentTitle(context.i18n.planningDashboardTeam);
    return () => loadTitle();
  }, []);

  return (
    <MenuWrapper
      title={context.i18n.planningDashboardTeam}
      selected="planing-todo-team"
      search
      addClient
    >
      <TeamTodoList title={context.i18n.openTodos} done={false} />
    </MenuWrapper>
  );
};
