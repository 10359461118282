import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { faEye, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Tooltip,
  AlertColor,
  DialogContent,
  DialogContentText,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { RiskAssessmentSurveyResult } from '../../../../types/risk-assesment';

interface RiskAssessmentResultProps {
  result: RiskAssessmentSurveyResult;
  name: string;
}

const RiksAssessmentResult: FC<RiskAssessmentResultProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [alertMessage, setAlertMessage] = useState();
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogMessage, setDialogMessage] = useState();
  const [dialogPoints, setDialogPoints] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [disableDialog, setDisableDialog] = useState(false);

  useEffect(() => {
    switch (props.result) {
      case RiskAssessmentSurveyResult.HIGH_RISK:
        setDisableDialog(false);
        setShowAlert(true);
        setSeverity('error');
        setAlertMessage(context.i18n.childAtRisk);
        setDialogTitle(context.i18n.childAtRisk);
        setDialogMessage(
          context.i18n.formatString(
            context.i18n.childAtRiskMessage,
            props.name,
          ),
        );
        setDialogPoints(context.i18n.childAtRiskPoints.split(';'));
        break;
      case RiskAssessmentSurveyResult.MEDIUM_RISK:
        setDisableDialog(false);
        setShowAlert(true);
        setSeverity('warning');
        setAlertMessage(context.i18n.hintsChildAtRisk);
        setDialogTitle(context.i18n.hintsChildAtRisk);
        setDialogMessage(
          context.i18n.formatString(
            context.i18n.hintsChildAtRiskMessage,
            props.name,
          ),
        );
        setDialogPoints(context.i18n.hintsChildAtRiskPoints.split(';'));
        break;
      case RiskAssessmentSurveyResult.REPEAT:
        setDisableDialog(false);
        setShowAlert(true);
        setSeverity('info');
        setAlertMessage(context.i18n.repeatRiskAssessment);
        setDialogTitle(context.i18n.repeatRiskAssessment);
        setDialogMessage(context.i18n.repeatRiskAssessmentMessage);
        setDialogPoints([]);
        break;
      case RiskAssessmentSurveyResult.NO_RISK:
        setShowAlert(true);
        setSeverity('success');
        setAlertMessage(context.i18n.noRiskResultMessage);
        setDisableDialog(true);
        break;
      default:
        setShowAlert(false);
    }
  }, [props.result]);

  return (
    <>
      <Alert
        sx={{ display: showAlert ? 'flex' : 'none', m: 2 }}
        severity={severity}
        onClick={() => !disableDialog && setOpen(true)}
        action={
          <Tooltip
            sx={{ display: disableDialog ? 'none' : 'inherit' }}
            title="Genaue Informationen anzeigen"
          >
            <IconButton disableRipple color="inherit" size="small">
              <FontAwesomeIcon icon={faEye} />
            </IconButton>
          </Tooltip>
        }
      >
        {alertMessage}
      </Alert>
      <Dialog sx={{ zIndex: 9999 }} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
          <List>
            {dialogPoints.map((point) => (
              <ListItem key={point}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faDotCircle} />
                </ListItemIcon>
                <ListItemText>{point}</ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Tooltip title={context.i18n.close}>
            <IconButton onClick={() => setOpen(false)} color="primary">
              <FontAwesomeIcon icon={faX} />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RiksAssessmentResult;
