import { Box, Button, CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LinksApi, UserApi } from '../../../api';
import Navbar from '../../../components/core/navbar/navbar';
import LoginInput from '../../../components/public/login-input/login-input';
import { AppContext } from '../../../hooks/context';
import { LinkTypes } from '../../../types';
import { setDocumentTitle, loadTitle } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

type LinkParams = {
  type: string;
  id: string;
};

const Links: FC = () => {
  const { type, id } = useParams<LinkParams>();

  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('R');

  useEffect(() => {
    checkId();
    let t = title;
    switch (type as LinkTypes) {
      case LinkTypes.INVITE:
        t = context.i18n.acceptInvite;
        break;
      case LinkTypes.PASSWORDRESET:
        t = context.i18n.passwordReset;
        break;
    }
    setTitle(t);
    setDocumentTitle(t);
    return () => loadTitle();
  }, [type, id]);

  const invalidLink = (message?: string) => {
    snackbarUtils.error(message || context.i18n.linkInvalid);
    navigate('/login');
  };

  const handleSuccess = (
    message: string,
    data?: { email: string; recoveryCode?: string },
  ) => {
    snackbarUtils.success(message);
    if (data && data.recoveryCode) {
      context.setRecoveryCode(data.recoveryCode);
      context.setRecoveryCodeDialogOpen(true);
    }
    navigate('/login', Object.assign({}, data && { state: data.email }));
  };

  const checkId = async () => {
    if (type) {
      if (!(Object.values(LinkTypes) as string[]).includes(type)) {
        invalidLink();
        return;
      }
      try {
        await LinksApi.checkValid({ type, id });
        if (type === LinkTypes.EMAILCONFIRMATION) await confirmEmail();
      } catch (e) {
        console.error(e);
        const errorData = (e as AxiosError)?.response?.data;
        invalidLink(errorData.detail ? errorData.detail[0] : undefined);
      }
    }
  };

  const handleLink = async () => {
    if (type && id) {
      switch (type as LinkTypes) {
        case LinkTypes.INVITE:
        case LinkTypes.PASSWORDRESET:
          try {
            setLoading(true);
            const res = await UserApi.handleUserLink(
              context.authToken,
              id,
              type,
              password,
              type === LinkTypes.PASSWORDRESET ? recoveryCode : undefined,
            );
            handleSuccess(
              res.message ? res.message : undefined,
              res.data ? res.data : undefined,
            );
            setLoading(false);
          } catch (e) {
            const errorData = (e as AxiosError)?.response?.data;
            errorData?.detail?.forEach((message: string) => {
              snackbarUtils.error(message);
            });
            setLoading(false);
          }
          break;
        default:
          invalidLink();
      }
    }
  };

  const confirmEmail = async () => {
    if (id) {
      const res = await LinksApi.confirmEmail(id);
      handleSuccess(
        res.message ? res.message : undefined,
        res.data ? res.data : undefined,
      );
      return;
    }
    invalidLink();
  };

  const updateRecoveryCode = (value: string) => {
    if (/^R\d+$/.test(value) || value === 'R') {
      setRecoveryCode(value);
    }
    if (value === '') {
      setRecoveryCode('R');
    }
  };

  return type === LinkTypes.EMAILCONFIRMATION ? null : (
    <Box>
      <Navbar hideMenuDrawer title={title} />
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <LoginInput
          label={context.i18n.inputPassword}
          onChange={setPassword.bind(this)}
          password
          showPasswordCheck
        />
        <LoginInput
          error={passwordConfirm && passwordConfirm !== password ? true : false}
          helperText={
            passwordConfirm && passwordConfirm !== password
              ? context.i18n.passwordConfirmHelper
              : ''
          }
          label={context.i18n.inputPasswordConfirm}
          onChange={setPasswordConfirm.bind(this)}
          password
        />
        {type === LinkTypes.PASSWORDRESET && (
          <LoginInput
            label={context.i18n.recoveryCode}
            value={recoveryCode}
            onChange={updateRecoveryCode.bind(this)}
          />
        )}
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{ mt: 2 }}
        >
          <Button
            color="primary"
            variant="contained"
            id="loginBtn"
            onClick={handleLink.bind(this)}
            className="formBtn"
            disabled={
              password === '' ||
              passwordConfirm === '' ||
              password !== passwordConfirm ||
              (type === LinkTypes.PASSWORDRESET &&
                (recoveryCode === '' || recoveryCode === 'R'))
            }
          >
            {loading ? <CircularProgress sx={{ color: 'white' }} /> : title}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Links;
