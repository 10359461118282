import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
} from '@mui/material';
import { ErrorInfo, FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { FormGroupElement } from '../../../types';
import { FormElement } from '../../client';

interface FormGroupDialogProps {
  id: string;
  onSave: () => void;
  onClose: () => void;
  elements: FormGroupElement[];
  title?: string;
  saved?: boolean;
  preivew?: boolean;
}

const FormGroupDialog: FC<FormGroupDialogProps> = (props) => {
  const context = useContext(AppContext);
  const sortedElements = props.elements.sort((a, b) => a.seq - b.seq);

  return (
    <Box>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        {sortedElements.map((data) => (
          <FormElement
            dialog={true}
            key={data.id}
            hideExport
            type={data.type}
            value={data.value}
            title={data.label ? data.label : ''}
            id={data.id}
            groupId={props.id}
            saved={props.saved}
            required={data.required}
            fieldOptions={data.fieldOptions}
            objectProps={data.objectProps}
          />
        ))}
      </DialogContent>
      <DialogActions>
        {!props.preivew && (
          <Tooltip title={context.i18n.saveBtnTooltip}>
            <IconButton onClick={props.onSave.bind(this)} color="primary">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose.bind(this)} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Box>
  );
};

export default FormGroupDialog;
