import {
  ClientDataType,
  FormFieldType,
  FormFieldTextType,
} from '@casecare/types';
import { FC, useEffect, useState } from 'react';
import { ClientDataFieldType, FormDateType } from '../../../../types';
import { TextSize } from '../../../../types/form-text-props';
import {
  FormCheckBox,
  FormDate,
  FormDropdown,
  FormElementHeader,
  FormGroup,
  FormListMultiData,
  FormRadio,
  FormText,
} from '../..';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../../../utils';

interface ClientDataFieldProps extends ClientDataFieldType {
  onSaveField: (type: ClientDataType, value: any) => void;
  onSaveFields?: (fields: Partial<Record<ClientDataType, any>>) => void;
  onChange: (type: ClientDataType, changes: boolean) => void;
  clientData: Partial<Record<ClientDataType, any>>;
  saved: boolean;
}

const ClientDataField: FC<ClientDataFieldProps> = (props) => {
  if (props.fieldType === FormFieldType.FORM_GROUP) {
    return (
      <FormGroup
        onSave={(v) =>
          props.onSaveFields &&
          props.onSaveFields(
            v.reduce((rec: any, v: any) => {
              rec[v.id] = v.value;
              return rec;
            }, {} as Record<ClientDataType, any>),
          )
        }
        values={props.groupValues || []}
        id={props.type}
        title={props.name}
        hideExport={true}
      />
    );
  } else if (props.fieldType === FormFieldType.LIST_MULTI_DATA) {
    return (
      <FormListMultiData
        onSave={(v) => {
          props.onSaveFields &&
            props.onSaveFields(
              v.reduce((rec: any, v: any) => {
                rec[v.id] = { value: v.value, dataset: v.dataset };
                return rec;
              }, {} as Record<ClientDataType, any>),
            );
        }}
        value={props.groupValues || []}
        id={props.type}
        title={props.name}
        hideExport={true}
      />
    );
  } else if (
    props.fieldType === FormFieldType.RADIO &&
    Object.values(ClientDataType).includes(props.type)
  ) {
    return (
      <FormRadio
        required={props.required}
        id={props.type}
        title={props.name}
        options={props.options || []}
        onChange={(value) =>
          props.onChange(
            ClientDataType[props.type],
            props.clientData[ClientDataType[props.type]] !== value,
          )
        }
        onCancel={() => props.onChange(ClientDataType[props.type], false)}
        onSave={(value) => props.onSaveField(ClientDataType[props.type], value)}
        value={props.clientData[ClientDataType[props.type]]}
        hideExport={true}
        saved={props.saved}
      />
    );
  } else if (props.fieldType === FormFieldType.DATE) {
    return (
      <FormDate
        hideExport
        id={props.type}
        title={props.name}
        required={props.required}
        value={props.clientData[props.type]}
        onChange={(value) =>
          props.onChange(props.type, props.clientData[props.type] !== value)
        }
        onCancel={() => props.onChange(props.type, false)}
        onSave={(value) => props.onSaveField(props.type, value)}
        saved={props.saved}
        format={FormDateType.date}
      />
    );
  } else if (props.fieldType === FormFieldType.CHECKBOX) {
    const [checkBoxValue, setCheckBoxValue] = useState<any[]>([]);

    useEffect(() => {
      if (props.type === ClientDataType.IS_DEAD) {
        setCheckBoxValue(props.selected ? ['isDead'] : []);
      } else if (props.type === ClientDataType.CLIENT_BILLING) {
        setCheckBoxValue(props.selected ? ['clientBilling'] : []);
      } else {
        setCheckBoxValue(props.clientData[ClientDataType[props.type]]);
      }
    }, [props.clientData]);

    return (
      <FormCheckBox
        required={props.required}
        hideExport
        id={props.type}
        title={props.name}
        value={checkBoxValue}
        onChange={(value: any) => {
          props.onClick && props.onClick();
          props.onChange(
            ClientDataType[props.type],
            props.clientData[props.type] === undefined ||
              value.every(
                (val: any, index: number) =>
                  val === props.clientData[props.type][index],
              ) ||
              props.clientData[ClientDataType[props.type]].length !==
                value.length,
          );
        }}
        onCancel={() => {
          props.onCancle && props.onCancle();
          props.onChange(ClientDataType[props.type], false);
        }}
        onSave={(value: any) =>
          props.onSaveField(ClientDataType[props.type], value)
        }
        saved={props.saved}
        options={props.options || []}
      />
    );
  } else if (props.fieldType === FormFieldType.DROPDOWN) {
    return (
      <FormDropdown
        hideExport
        id={props.type}
        title={props.name}
        value={props.clientData[ClientDataType[props.type]]}
        required={props.required}
        multiple={props.multiple}
        onChange={(value) => {
          props.onChange(
            ClientDataType[props.type],
            props.clientData[ClientDataType[props.type]] !== value,
          );
        }}
        options={props.options || []}
        onCancel={() => props.onChange(ClientDataType[props.type], false)}
        onSave={(value) => props.onSaveField(ClientDataType[props.type], value)}
        saved={props.saved}
      />
    );
  } else if (props.type === ClientDataType.KNOWN_DIAGNOSIS) {
    return (
      <Box sx={{ m: 2 }}>
        <FormElementHeader
          add
          onAdd={props.onClick}
          title={props.name}
          required={props.required}
        />
        <List
          sx={{
            maxHeight: 200,
            overflowY:
              props.icd10Codes && props.icd10Codes.length > 3
                ? 'scroll'
                : 'hidden',
          }}
        >
          {props.icd10Codes &&
            props.icd10Codes.map((code) => (
              <ListItem key={code.code}>
                <ListItemButton
                  onClick={() => props.onDetails && props.onDetails(code)}
                >
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          fontWeight="bold"
                          color="primary"
                        >
                          {code.code}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={code.display}
                  />
                </ListItemButton>
                <ListItemIcon>
                  <IconButton
                    disableRipple
                    onClick={() => props.onRemove && props.onRemove(code.code)}
                    color="error"
                  >
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            ))}
        </List>
      </Box>
    );
  } else {
    return (
      <FormText
        dataType={props.dataType ? props.dataType : FormFieldTextType.TEXT}
        required={props.required}
        hideExport
        id={props.type}
        size={TextSize.NORMAL}
        title={props.name}
        value={props.clientData[ClientDataType[props.type]]}
        onChange={(value) =>
          props.onChange(
            ClientDataType[props.type],
            props.clientData[ClientDataType[props.type]] !== value,
          )
        }
        onClick={props.onClick}
        onCancel={() => props.onChange(ClientDataType[props.type], false)}
        onSave={(value) => props.onSaveField(ClientDataType[props.type], value)}
        saved={props.saved}
      />
    );
  }

  return <></>;
};

export default ClientDataField;
