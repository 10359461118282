import { Box, TextField, Button, CircularProgress } from '@mui/material';
import { FC, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LinksApi } from '../../../api';
import Navbar from '../../../components/core/navbar/navbar';
import { AppContext } from '../../../hooks/context';
import { LinkTypes } from '../../../types';
import { setDocumentTitle, loadTitle } from '../../../utils';
import {
  LoginBackgroundImage,
  LoginBackgroundWave,
} from '../../../utils/background/background';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

const ResetPassword: FC = () => {
  const location = useLocation();
  const [email, setEmail] = useState(
    typeof location.state === 'string' ? location.state : '',
  );
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);

  const sendEmail = (): void => {
    setLoading(true);
    LinksApi.send({
      email: email,
      type: LinkTypes.PASSWORDRESET,
    })
      .then((res) => {
        snackbarUtils.success(res.message);
        setEmail('');
        setLoading(false);
      })
      .catch((error) => {
        const errorData = error.response.data;
        errorData.detail?.forEach((message: string) => {
          snackbarUtils.error(message);
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setDocumentTitle(context.i18n.passwordResetTitle);
    return () => loadTitle();
  }, []);

  return (
    <Box sx={LoginBackgroundWave}>
      <Navbar hideMenuDrawer title={context.i18n.passwordResetTitle} />
      <Box sx={LoginBackgroundImage}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            position: 'absolute',
            top: '30%',
            right: '50%',
            transform: 'translate(50%, -50%)',
          }}
        >
          <TextField
            color="secondary"
            label={context.i18n.email}
            size="medium"
            sx={{ mt: 2, width: '22rem' }}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={sendEmail.bind(this)}
              className="formBtn"
            >
              {loading ? (
                <CircularProgress sx={{ color: 'white' }} />
              ) : (
                context.i18n.passwordReset
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
