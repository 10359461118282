import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { isLocalhost } from '../../../utils/constants';
import { useLocation } from 'react-router-dom';
import { ManageApi } from '../../../api';
import { useQuery } from 'react-query';

const BetaBanner: React.FC = () => {
  const { user, isMobile } = useContext(AppContext);

  const [backendVersion, setBackendVersion] = useState('...');
  const baseUrl = isLocalhost ? 'localhost' : 'CC_ENV_BASE_URL';

  const [bugReportUrl, setBugReportUrl] = useState('');
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (
      !bugReportUrl ||
      (user && !bugReportUrl.includes('email=')) ||
      (backendVersion !== '...' && bugReportUrl.includes('...')) ||
      location.pathname !== currentPath
    ) {
      let url = 'https://form.jotform.com/231433614234043?';
      if (user) {
        url += `name=${user.firstName}+${user.lastName}&`;
        url += `email=${user.email}&`;
      }

      url += `softwareversion=Frontend:+${process.env.REACT_APP_VERSION},+Backend:+${backendVersion}&`;
      url += `useragent=${encodeURIComponent(navigator.userAgent)}&`;
      if ((navigator as any).userAgentData) {
        url += `browserInfo=${encodeURIComponent(
          JSON.stringify((navigator as any).userAgentData),
        )}&`;
      }
      url += `path=${encodeURIComponent(
        `${window.location.origin}${location.pathname}`,
      )}`;
      setCurrentPath(location.pathname);
      setBugReportUrl(url);
    }
  }, [user, bugReportUrl, backendVersion, location]);

  useQuery(['getBackendVersion'], () => ManageApi.getVersion(), {
    onSuccess: (version) => setBackendVersion(version),
  });

  if (process.env.REACT_APP_BETA_TEST_BANNER && !isMobile) {
    return (
      <Box
        sx={{
          position: 'fixed',
          left: '0px',
          bottom: '0px',
          ml: 'auto',
          mr: 'auto',
          pl: '10px',
          pr: '10px',
          pb: '5px',
          pt: '5px',
          textAlign: 'center',
          zIndex: 1399,
          color: 'white',
          background: '#32436E',
          width: '100%',
          height: '40px',
        }}
      >
        <Typography fontSize="1rem">
          BETA TEST |&nbsp;
          <a
            href={bugReportUrl}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'white' }}
          >
            Fehler melden
          </a>
        </Typography>
        <Typography fontSize="0.6rem">
          Frontend: {process.env.REACT_APP_VERSION} | Backend: {backendVersion}
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
};

export default BetaBanner;
