import { FC } from 'react';
import { ReactComponent as Outlined } from '../../assets/icons/Octagon/Octagon Umrandung.svg';
import { ReactComponent as Filled } from '../../assets/icons/Octagon/Octagon gefüllt.svg';
import { ReactComponent as Border } from '../../assets/icons/Octagon/Octagon Rahmen.svg';
import CaseCareSvgIcon from './case-care-svg-icon';
import { CaseCareSvgIconProps } from '../../types/case-care-svg-icon';

const OctagonIcon: FC<CaseCareSvgIconProps> = (props) => {
  return (
    <CaseCareSvgIcon
      {...props}
      default={<Outlined />}
      outlined={<Outlined />}
      filled={<Filled />}
      border={<Border />}
    />
  );
};
export default OctagonIcon;
