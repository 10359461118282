import { ClientDataType } from '@casecare/types';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Typography,
  Box,
  Tabs,
  IconButton,
  Dialog,
  Drawer,
  ClickAwayListener,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { GenoGrammApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { useSavePrompt } from '../../../hooks/useBlocker';
import {
  setDocumentTitle,
  loadTitle,
  queryClient,
  theme,
} from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { TabScrollButtonEcoMapStyle } from '../../../utils/styling/styling';
import GenderIcon from '../../core/gender-icon/gender-icon';
import { DiscardDialog } from '../../dialogs';
import GenoGrammAddPerson from '../geno-gramm-add-person/geno-gramm-add-person';
import GenoGrammClients from '../geno-gramm-clients/geno-gramm-clients';
import GenoGrammLayerTabs from '../geno-gramm-layer-tabs/geno-gramm-layer-tabs';

interface GenoGrammDrawerPersonProps {
  open: boolean;
  onClose: () => void;
  client: any;
  layers: any;
  indexId: string | undefined;
}

const GenoGrammDrawerPerson: FC<GenoGrammDrawerPersonProps> = (props) => {
  const context = useContext(AppContext);

  const [layers, setLayers] = useState<Record<number, any[]>>(props.layers);
  const [client, setClient] = useState<any>(props.client);
  const [selectedLayer, setSelectedLayer] = useState<number | string>(3);
  const [futureLayer, setFutureLayer] = useState<number | string>(3);
  const [changes, setChanges] = useState(false);
  const [openSavePrompt, setOpenSavePrompt] = useState(false);

  useSavePrompt(
    () => onSave(),
    () => console.log('do nothing'),
    changes,
  );

  useEffect(() => {
    setClient(props.client);
  }, [props.client]);

  useEffect(() => {
    setLayers(props.layers);
  }, [props.layers]);

  useEffect(() => {
    if (changes && futureLayer !== selectedLayer) {
      setOpenSavePrompt(true);
    } else {
      setSelectedLayer(futureLayer);
    }
  }, [futureLayer]);

  const onSaveClient = async (client: any) => {
    try {
      const groupIdClient = 'Geno-Gramm-Client-Data-' + client.id,
        groupIdClientRelation = 'Geno-Gramm-Client-Relations-' + client.id;

      const groupErrors = context.getGroupDataError([
        groupIdClient,
        groupIdClientRelation,
      ]);

      const error = groupErrors.length > 0;

      if (!error) {
        // SAVE relations //
        let i = 0;

        for (const data of client.genoGrammRelations) {
          if (data.relationId !== props.indexId) {
            if (!data.delete) {
              await GenoGrammApi.editRelation(
                context.authToken,
                props.indexId,
                {
                  type: data.type,
                  clientFromId: data.clientId,
                  clientToId: data.relationId,
                },
              );
            } else if (!data.holdBack) {
              await GenoGrammApi.deleteRelation(
                context.authToken,
                props.indexId,
                {
                  clientFromId: data.clientId,
                  clientToId: data.relationId,
                },
              );
              client.genoGrammRelations.splice(i, 1);
            }
            i++;
          }
        }

        // SAVE client data //
        const groupData = context.getAndRemoveGroupData(groupIdClient);
        const sendData: Record<string, any> = {};
        for (const data of groupData) {
          if (data.value !== '') {
            sendData[data.id] = data.value;
          }
        }

        sendData.id = client.id;

        const res = await GenoGrammApi.saveClient(
          context.authToken,
          props.indexId,
          sendData,
        );
        if (res.message) {
          snackbarUtils.success(res.message);
          queryClient.invalidateQueries('geno-gramm-data');
        }
      } else snackbarUtils.error(context.i18n.redFieldsNeedToBeFilledOut);

      context.removeGroupData(groupIdClientRelation);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onImport = async () => {
    try {
      const res = await GenoGrammApi.importFamilyMembers(
        context.authToken,
        client.id,
      );

      if (res.message) {
        snackbarUtils.success(res.message);
        queryClient.invalidateQueries('geno-gramm-data');
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onSave = async () => {
    let error = false;

    if (typeof selectedLayer === 'number') {
      layers[selectedLayer].map((c) => {
        if (c.placeHolder) return;

        const groupIdClient = 'Geno-Gramm-Client-Data-' + c.id,
          groupIdClientRelation = 'Geno-Gramm-Client-Relations-' + c.id;

        const groupErrors = context.getGroupDataError([
          groupIdClient,
          groupIdClientRelation,
        ]);

        if (!error) {
          error = groupErrors.length > 0;
        }

        context.removeGroupData(groupIdClientRelation);
        context.removeGroupData(groupIdClient);
      });

      if (!error) {
        await Promise.all(
          layers[selectedLayer].map(async (c) => {
            if (c.placeHolder) return;
            await onSaveClient(c);
          }),
        );
        setSelectedLayer(futureLayer);
        setChanges(false);
      } else {
        snackbarUtils.error(context.i18n.cannotSaveBecauseOfErrors);
      }
    }

    setOpenSavePrompt(false);
  };

  return (
    <Drawer
      open={props.open}
      PaperProps={{ sx: { width: '50%' } }}
      onClose={(ev, reason) => props.onClose()}
    >
      <Grid height="100%" container spacing={4}>
        <Grid item xs={12}>
          <GenoGrammAddPerson indexClient={client} />
          <Box sx={{ mt: 5 }}>
            <GenoGrammLayerTabs
              onSelectLayer={setFutureLayer}
              layers={Object.keys(layers).map((l) => +l)}
              selectedLayer={selectedLayer}
            />
            {typeof selectedLayer === 'number' && layers[selectedLayer] && (
              <GenoGrammClients
                clients={layers[selectedLayer].filter(
                  (c) => !c.isPregnancy && !c.placeHolder,
                )}
                indexId={client.id}
                onSaveClient={onSaveClient}
                clientList={Object.values(layers)
                  .flatMap((l: any[]) => l)
                  .filter(
                    (c: any) =>
                      c.id !== client.id && !c.isPregnancy && !c.placeHolder,
                  )}
                getChanges={(changes) => {
                  setChanges(changes);
                }}
              />
            )}
          </Box>
        </Grid>
        <Dialog open={openSavePrompt} onClose={() => setOpenSavePrompt(false)}>
          <DiscardDialog
            onDiscard={() => {
              setSelectedLayer(futureLayer);
              setOpenSavePrompt(false);
            }}
            onSave={onSave}
            onClose={() => {
              setFutureLayer(selectedLayer);
              setOpenSavePrompt(false);
            }}
          />
        </Dialog>
      </Grid>
    </Drawer>
  );
};

export default GenoGrammDrawerPerson;
