import {
  ClientDataType,
  OtherCharacteristics,
  Sex,
  Sexuality,
  Sickness,
} from '@casecare/types';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { Tooltip, Paper, Box, tooltipClasses, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../utils';

interface GenoGrammDragableProps {
  client: any;
  isIndex: boolean;
}

export const GenoGrammDragable: FC<GenoGrammDragableProps> = (props) => {
  const { client, isIndex } = props;
  const { attributes, listeners, setNodeRef, transform, over } = useDraggable({
    id: client.positionId,
    data: client,
  });
  const style = transform && {
    transform: `translate3d(${transform.x}px, 0, 0)`,
  };

  return (
    <>
      <Tooltip
        open
        arrow
        PopperProps={{
          sx: {
            zIndex: 99,
            [`& .${tooltipClasses.tooltip}`]: {
              color: 'black',
              background: 'rgba(255, 255, 255, 0.5)',
            },
          },
        }}
        title={`${client[ClientDataType.FIRST_NAME]} ${
          client[ClientDataType.LAST_NAME]
            ? client[ClientDataType.LAST_NAME][0]
            : ''
        }.`}
      >
        <Paper
          id={client.id}
          //@ts-expect-error Position cannot be added to sx object otherwise
          sx={Object.assign(
            {
              height: 40,
              width: 40,

              position: 'absolute',
              left: `${client.x}px`,

              touchAction: 'none',
              // Animal
              // transform: 'rotate(45deg);',

              border: '1px solid black',
              ...style,
            },
            isIndex && {
              outline: '1px solid black',
              outlineLeft: '50px solid transparent',
              outlineRight: '50px solid transparent',
              outlineBottom: `100px solid`,
              outlineOffset: 2,
            },
            (client[ClientDataType.SEX] === Sex.FEMALE ||
              client[ClientDataType.SEX] === Sex.TRANS_F) && {
              borderRadius: '50%',
            },
          )}
          ref={setNodeRef}
          {...listeners}
          {...attributes}
        >
          {client[ClientDataType.SEX] === Sex.TRANS_M && (
            // Tansgender male to female
            <Box
              width={20}
              height={20}
              borderRadius="50%"
              position="fixed"
              border="1px solid black"
              color="black"
            />
          )}

          {client[ClientDataType.SEX] === Sex.TRANS_F && (
            // Tansgender female to male
            <Box
              width={20}
              height={20}
              position="fixed"
              border="1px solid black"
              color="black"
            />
          )}

          {client[ClientDataType.IS_DEAD] && (
            // is Dead
            <Box
              sx={{
                '&::before, &::after': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  right: '0',
                  height: '1px',
                  backgroundColor: 'black',
                },
                '&::before': {
                  transform: 'rotate(-45deg)',
                },
                '&::after': {
                  transform: 'rotate(45deg)',
                },
              }}
            />
          )}

          {/* gay */}
          {client[ClientDataType.SEXUALITY] &&
            client[ClientDataType.SEXUALITY] !== Sexuality.STRAIGHT && (
              <svg width="30" height="30">
                <polygon
                  points="5,5 25,5 15,25"
                  style={{
                    stroke: 'black',
                    fill: 'none',
                    strokeWidth: 2,
                    //enable for bisexual
                    strokeDasharray:
                      client[ClientDataType.SEXUALITY] === Sexuality.BISEXUAL
                        ? '5, 5'
                        : '',
                    strokeLinejoin: 'round',
                  }}
                />
              </svg>
            )}

          {client[ClientDataType.SICKNESS] && (
            <>
              {(client[ClientDataType.SICKNESS].includes(
                Sickness.SUSPECTED_HIV,
              ) ||
                client[ClientDataType.SICKNESS].includes(Sickness.HIV)) && (
                // Infected
                <Box
                  //@ts-expect-error gets error because of !arr.includes i dont know why
                  sx={Object.assign(
                    {
                      '&::before, &::after': {
                        content: '""',
                        position: 'absolute',
                        top: '50%',
                        left: '0',
                        right: '0',
                        height: '1px',
                        backgroundColor: 'black',
                      },
                      '&::after': {
                        transform: 'rotate(90deg)',
                      },
                    },
                    !client[ClientDataType.SICKNESS].includes(Sickness.HIV) && {
                      // Only Suspected Infestation
                      '&::before': {
                        display: 'none',
                      },
                    },
                  )}
                />
              )}

              {client[ClientDataType.SICKNESS].includes(
                Sickness.POSSIBLE_HIV,
              ) && (
                // possible infected
                <Typography m={1} variant="h1" position="absolute">
                  ?
                </Typography>
              )}

              {client[ClientDataType.SICKNESS].includes(Sickness.CARRIER) && (
                // Carrier
                <Box
                  position="absolute"
                  width={10}
                  height={10}
                  borderRadius="50%"
                  border="1px solid black"
                  bgcolor="black"
                />
              )}
            </>
          )}
        </Paper>
      </Tooltip>
      {client[ClientDataType.OTHER_CHARACTERISTICS] && (
        <Typography mt={3} justifySelf="end">
          {/* Fett leibig */}
          {client[ClientDataType.OTHER_CHARACTERISTICS].includes(
            OtherCharacteristics.OVERWEIGHT,
          ) && <>O</>}
          {/* Sprachprobleme */}
          {client[ClientDataType.OTHER_CHARACTERISTICS].includes(
            OtherCharacteristics.SPEECH_IMPEDIMENT,
          ) && <>L</>}
          {/* Raucher */}
          {client[ClientDataType.OTHER_CHARACTERISTICS].includes(
            OtherCharacteristics.SMOKER,
          ) && <>S</>}
        </Typography>
      )}
    </>
  );
};
