import { FrontendFormImportField, FormFieldType } from '@casecare/types';
import { faSave, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  DialogContent,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import FormElementDialogContent from './form-element-dialog-content';

interface FormElementDialogProps {
  onClose: () => void;
  onSave: (element: FrontendFormImportField, createElement: boolean) => void;
  onFormGroupSelect?: (name: string | undefined) => void;
  onListMultidataSelect?: (name: string | undefined) => void;
  onListSelect?: (name: string | undefined) => void;
  editElement?: FrontendFormImportField;
  editElementType?: FormFieldType;
  inFormGroup?: boolean;
}

const FormElementDialog: FC<FormElementDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [elementType, setElementType] = useState<FormFieldType | undefined>(
    props.editElementType,
  );
  const [name, setName] = useState(
    props.editElement ? props.editElement.label : '',
  );
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [createElement] = useState(props.editElement === undefined);
  const [typeSelected, setTypeSelected] = useState<boolean>(!createElement);
  const [errors, setErrors] = useState(false);
  const [saving, setSaving] = useState(false);
  const [required, setRequired] = useState(
    props.editElement ? props.editElement.required : false,
  );

  const selectElement = (type: FormFieldType) => {
    setTypeError(false);
    setElementType(type);
    setTypeSelected(true);
  };

  const onSave = () => {
    setSaving(true);
    let errors = false;
    if (name === undefined || name === '') {
      errors = true;
      setNameError(true);
    }

    if (!elementType && !typeSelected) {
      errors = true;
      setTypeError(true);
    }

    setErrors(errors);
  };

  const onSaveElement = (
    element: FrontendFormImportField,
    elementErrors: boolean,
  ) => {
    setSaving(false);
    element.label = name;
    if (!errors && !elementErrors) {
      props.onSave({ ...element, required }, createElement);
    }
  };

  return (
    <Box>
      <AppBar sx={{ position: 'sticky', background: 'white', mb: 0 }}>
        <Toolbar>
          <Typography
            sx={{ flex: 1 }}
            color="black"
            variant="h6"
            component="div"
          >
            {context.i18n.createElement}
          </Typography>
          <Tooltip title={context.i18n.saveBtnTooltip}>
            <IconButton disableRipple onClick={() => onSave()} color="primary">
              <FontAwesomeIcon icon={faSave} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.cancelBtnTooltip}>
            <IconButton
              disableRipple
              onClick={props.onClose.bind(this)}
              color="primary"
            >
              <FontAwesomeIcon icon={faX} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Stack direction="column" sx={{ width: '22rem', mt: 1 }} spacing={2}>
          <TextField
            error={nameError}
            autoFocus
            color="secondary"
            label={context.i18n.name}
            value={name}
            size="medium"
            type={'text'}
            onChange={(e) => {
              setName(e.currentTarget.value);
              setNameError(false);
            }}
          />
          <FormControl error={typeError} color="secondary">
            <InputLabel sx={{ alignSelf: 'center' }} id="type-select-label">
              {context.i18n.type}
            </InputLabel>
            <Select
              labelId="type-select-label"
              disabled={typeSelected}
              label={context.i18n.type}
              // when the value is 0 nothing is selected so this is needed to show the text type
              value={
                elementType !== undefined
                  ? elementType !== FormFieldType.TEXT
                    ? elementType
                    : 'text'
                  : ''
              }
            >
              <MenuItem
                value={'text'}
                onClick={() => selectElement(FormFieldType.TEXT)}
              >
                {context.i18n.formElementTypeText}
              </MenuItem>
              <MenuItem
                value={FormFieldType.DATE}
                onClick={() => selectElement(FormFieldType.DATE)}
              >
                {context.i18n.formElementTypeDate}
              </MenuItem>
              <MenuItem
                value={FormFieldType.DROPDOWN}
                onClick={() => selectElement(FormFieldType.DROPDOWN)}
              >
                {context.i18n.selection}
              </MenuItem>
              {!props.inFormGroup && (
                <MenuItem
                  value={FormFieldType.FORM_GROUP}
                  onClick={() =>
                    props.onFormGroupSelect
                      ? props.onFormGroupSelect(name)
                      : null
                  }
                >
                  {context.i18n.formElementTypeGroup}
                </MenuItem>
              )}
              {!props.inFormGroup && (
                <MenuItem
                  value={FormFieldType.LIST}
                  onClick={() =>
                    props.onListSelect ? props.onListSelect(name) : null
                  }
                >
                  {context.i18n.formElementTypeList}
                </MenuItem>
              )}
              {!props.inFormGroup && (
                <MenuItem
                  value={FormFieldType.LIST_MULTI_DATA}
                  onClick={() =>
                    props.onListMultidataSelect
                      ? props.onListMultidataSelect(name)
                      : null
                  }
                >
                  {context.i18n.formElementTypeListMultiData}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormGroup sx={{ mt: 2 }}>
            <FormControlLabel
              value={required}
              control={
                <Checkbox
                  checked={required}
                  onClick={() => setRequired(!required)}
                />
              }
              label={context.i18n.requiredField}
            />
          </FormGroup>
          <FormElementDialogContent
            type={elementType}
            onSave={(element, errors) => onSaveElement(element, errors)}
            saving={saving}
            element={props.editElement}
          />
        </Stack>
      </DialogContent>
    </Box>
  );
};

export default FormElementDialog;
