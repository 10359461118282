import { FrontendFormImportField } from '@casecare/types';
import { Box, CircularProgress, Dialog } from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState, useEffect } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AdminApi } from '../../../api';
import { AdminDrawer, FormTable } from '../../../components/admin';
import Navbar from '../../../components/core/navbar/navbar';
import {
  CreateCategoryDialog,
  CategoryDetailsDialog,
} from '../../../components/dialogs';
import { AppContext } from '../../../hooks/context';
import { ChildImportCategories, CategoryData } from '../../../types';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { BackgroundWave } from '../../../utils/background/background';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { MenuWrapper } from '../../../components/core';

const FormList: FC = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isImport, setIsImport] = useState(false);
  const [openCategoryCreateDialog, setOpenCreateCategoryDialog] =
    useState(false);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    setDocumentTitle(context.i18n.manageForm);
    return () => loadTitle();
  }, []);

  const { data, status } = useQuery(
    'adminCategoryData',
    () => AdminApi.getCategories(context.authToken),
    {
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
      enabled: context.authToken !== undefined && context.isAdmin,
    },
  );

  const editCategory = (id: any) => {
    setSelectedCategory(
      data ? data.data.find((category: any) => category.id === id) : undefined,
    );
    setCategoryLoading(false);
    setIsImport(false);
    setOpenCategoryDialog(true);
  };

  const openCategoryDetails = (isImport: boolean) => {
    setCategoryLoading(false);
    setOpenCreateCategoryDialog(false);
    setSelectedCategory(undefined);
    setOpenCategoryDialog(true);
    setIsImport(isImport);
  };

  const createCategory = (
    categoryName: string,
    isSubCategory: boolean,
    isDefault: boolean,
    parentId?: string,
  ) => {
    setCategoryLoading(true);
    setOpenCategoryDialog(false);
    if (!isSubCategory) {
      (async function () {
        try {
          const categoryRes: any = await AdminApi.createCategory(
            context.authToken,
            categoryName,
            isDefault,
          );
          const errors = categoryRes.data.errors;
          const categoryId = categoryRes.data.created[0];
          if (errors.length > 0) {
            errors.forEach((error: any) => {
              snackbarUtils.error(error.message);
            });
          } else {
            navigate('/admin/categories/' + categoryId);
          }
        } catch (e) {
          console.error(e);
          snackbarUtils.error(context.i18n.errorTryAgainLater);
        }
      })();
      return;
    }

    (async function () {
      try {
        const categoryRes: any = await AdminApi.createSubCategory(
          context.authToken,
          categoryName,
          parentId,
        );
        const errors = categoryRes.data.errors;
        if (errors.length > 0) {
          errors.forEach((error: any) => {
            snackbarUtils.error(error.message);
          });
        } else {
          navigate('/admin/categories/' + parentId);
        }
      } catch (e) {
        console.error(e);
        snackbarUtils.error(context.i18n.errorTryAgainLater);
      }
    })();
  };

  const importCategory = async (
    categoryName: string,
    isDefault: boolean,
    importFields?: FrontendFormImportField[],
    parentId?: string,
    firsRun?: boolean,
    childCategories?: ChildImportCategories[],
    seq?: number,
  ): Promise<void> => {
    setCategoryLoading(true);
    try {
      let categoryRes: any;
      if (parentId) {
        categoryRes = await AdminApi.createSubCategory(
          context.authToken,
          categoryName,
          parentId,
          seq,
        );
      } else {
        categoryRes = await AdminApi.createCategory(
          context.authToken,
          categoryName,
          isDefault,
        );
      }
      const categoryErrors = categoryRes.data.errors;
      const categoryId = categoryRes.data.created[0];
      if (categoryErrors.length > 0) {
        categoryErrors.forEach((error: any) => {
          snackbarUtils.error(error.message);
        });
      } else {
        if (importFields && importFields.length > 0) {
          const formRes = await AdminApi.updateCategoryFormData(
            categoryId,
            importFields,
            context.authToken,
          );
          const formErrors = formRes.data.errors;
          if (formErrors.length > 0) {
            formErrors.forEach((error: any) => {
              snackbarUtils.error(error.message);
            });
          } else if (firsRun) {
            navigate('/admin/categories/' + categoryId);
          }
        } else if (childCategories && childCategories.length > 0) {
          await Promise.all(
            childCategories.map(async (category) => {
              await importCategory(
                category.name,
                false,
                category.fields,
                categoryId,
                false,
                [],
                category.seq,
              );
              return;
            }),
          );
          navigate('/admin/categories/' + categoryId);
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onDeleteCategory = () => {
    setCategoryLoading(true);
    queryClient.invalidateQueries('adminCategoryData');
    setOpenCategoryDialog(false);
    setCategoryLoading(false);
  };

  const onEdit = (category: CategoryData) => {
    try {
      (async function () {
        const allCategories = category.childCategories
          ? [...category.childCategories, category]
          : [category];
        setCategoryLoading(true);
        const res = await AdminApi.updateCategories(
          allCategories,
          context.authToken,
        );
        const errors = res.data.errors;
        if (errors.length > 0) {
          errors.forEach((error: any) => {
            snackbarUtils.error(error.message);
          });
          setOpenCategoryDialog(false);
        } else {
          queryClient.invalidateQueries('adminCategoryData');
          snackbarUtils.success(context.i18n.categoryUpdatedSuccessfully);
          setOpenCategoryDialog(false);
        }
      })();
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <MenuWrapper
      showCategories
      title={context.i18n.editFormCategories}
      search
      addClient
      selected="admin-categories"
      admin
    >
      {status === 'loading' || status === 'error' ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <FormTable
          editCategoryDetails={(id) => editCategory(id)}
          createCategory={() => setOpenCreateCategoryDialog(true)}
          categories={data ? data.data : undefined}
        />
      )}
      <Dialog
        open={openCategoryCreateDialog}
        onClose={() => setOpenCreateCategoryDialog(false)}
      >
        <CreateCategoryDialog
          onCreate={() => openCategoryDetails(false)}
          onImport={() => openCategoryDetails(true)}
        />
      </Dialog>
      <Dialog
        open={openCategoryDialog}
        onClose={() => setOpenCategoryDialog(false)}
      >
        <CategoryDetailsDialog
          loading={categoryLoading}
          onCreate={createCategory}
          onImport={(importFields, categoryName, isDefault, childCategories) =>
            importCategory(
              categoryName,
              isDefault,
              importFields,
              undefined,
              true,
              childCategories,
            )
          }
          onEdit={(category) => onEdit(category)}
          onClose={() => setOpenCategoryDialog(false)}
          onDelete={() => onDeleteCategory()}
          categories={data ? data.data : []}
          category={selectedCategory}
          import={isImport}
        />
      </Dialog>
    </MenuWrapper>
  );
};

export default FormList;
