import {
  faPen,
  faFloppyDisk,
  faX,
  faPlus,
  faList,
  faGrip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, IconButton } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';

interface FormElementHeaderProps {
  title: string;
  hideExport?: boolean;
  edit?: boolean;
  add?: boolean;
  save?: boolean;
  required?: boolean;
  formList?: boolean;
  multiDataFormList?: boolean;
  onEdit?: () => void;
  onAdd?: () => void;
  onList?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  readOnly?: boolean;
}

const FormElementHeader: FC<FormElementHeaderProps> = (props) => {
  // const [marked, setMarked] = useState(true);
  const [slider, setSlider] = useState(true);

  const context = useContext(AppContext);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  const onList = () => {
    setSlider(!slider);
    props.onList?.();
  };

  return (
    <Box
      sx={
        props.edit || props.formList || props.add
          ? { borderBottom: 1, borderColor: '#C8CBD1' }
          : {}
      }
    >
      <Typography
        color="primary"
        fontWeight="bold"
        textTransform="uppercase"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        component="div"
      >
        {props.title} {props.required ? '*' : null}
        <Box>
          {!readOnly && (
            <>
              {props.edit && (
                <IconButton
                  color="primary"
                  onClick={() => (props.onEdit ? props.onEdit() : null)}
                >
                  <FontAwesomeIcon icon={faPen} />
                </IconButton>
              )}
              <IconButton
                color="primary"
                onClick={() => (props.onSave ? props.onSave() : null)}
                sx={{ visibility: props.save ? 'inherit' : 'hidden' }}
              >
                <FontAwesomeIcon icon={faFloppyDisk} />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => (props.onCancel ? props.onCancel() : null)}
                sx={{ visibility: props.save ? 'inherit' : 'hidden' }}
              >
                <FontAwesomeIcon icon={faX} />
              </IconButton>
              {props.add && (
                <IconButton
                  color="secondary"
                  onClick={() => (props.onAdd ? props.onAdd() : null)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </IconButton>
              )}
            </>
          )}

          {props.formList ? (
            <IconButton color="secondary" onClick={onList.bind(this)}>
              {slider ? (
                <FontAwesomeIcon icon={faList} />
              ) : (
                <FontAwesomeIcon icon={faGrip} />
              )}
            </IconButton>
          ) : null}
          {/* {props.hideExport ? null : (
            <IconButton
              onClick={() => setMarked(!marked)}
              sx={props.edit || props.formList ? { mr: 2 } : {}}
              color={marked ? 'success' : 'error'}
            >
              <FontAwesomeIcon icon={faFileArrowDown} />
            </IconButton>
          )} */}
        </Box>
      </Typography>
    </Box>
  );
};

export default FormElementHeader;
