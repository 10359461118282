import moment from 'moment';

export const parseDateTimeString = (
  dateString: string | undefined,
  overrideFormat?: string,
): string => {
  if (!dateString) return '';
  return moment(dateString).format(overrideFormat || 'DD.MM.YYYY | HH:mm');
};

export const parseDateString = (dateString: string | undefined): string => {
  if (!dateString) return '';
  return moment(dateString).format('DD.MM.YYYY');
};
