import { FC, useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { AppContext } from '../hooks/context';

const MobileRoute: FC = () => {
  const context = useContext(AppContext);

  return context.isMobile ? <Outlet /> : <Navigate to="/client/list" />;
};

export default MobileRoute;
