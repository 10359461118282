import {
  ClientDataType,
  ReferencePersonRole,
  RelationshipType,
  Sex,
} from '@casecare/types';
import {
  faFileImport,
  faFloppyDisk,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Tooltip,
  IconButton,
  Autocomplete,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  FormHelperText,
  Chip,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FC, useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ClientApi, FamilyApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { Family } from '../../../types';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { UserSelect } from '../../client';
import FamilySelectDialog from '../family-select-dialog/family-select-dialog';
import { queryClient } from '../../../utils';
import { ClientDialogFields } from './client-dialog-fields';
import { FamilyDialogFields } from './family-dialog-fields';

export interface CreateClientDialogProps {
  onClose: () => void;
  open: boolean;
  onFinish: (id: string, isFamily: boolean) => void;
  // families?: Family[];
  // selectedFamilies?: Family[];
  // initialFamilies?: Family[];
  isReferencePerson?: boolean;
  clientId?: string;
  indexClientId?: string;
  clientFamilyDialog?: boolean;
  clientFamily?: Family;
  disableFamilyToggle?: boolean;
}

const CreateClientDialog: FC<CreateClientDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [toggleValue, setToggleValue] = useState('client');
  const [saveFields, setSaveFields] = useState(false);

  useEffect(() => {
    if (saveFields) setSaveFields(false);
  }, [saveFields]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {props.isReferencePerson
          ? context.i18n.createReferencePerson
          : props.clientFamilyDialog
          ? context.i18n.createFamilyMemberDialogTitle
          : context.i18n.createClientDialogTitle}
      </DialogTitle>
      <DialogContent>
        {!props.disableFamilyToggle && (
          <Box width="100%" display="flex" justifyContent="center">
            <ToggleButtonGroup
              value={toggleValue}
              onChange={(e, v) => setToggleValue(v)}
              color="primary"
              exclusive
              aria-label="Platform"
            >
              <ToggleButton value="client">{context.i18n.client}</ToggleButton>
              <ToggleButton value="family">{context.i18n.family}</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
        {toggleValue === 'client' ? (
          <ClientDialogFields {...props} save={saveFields} />
        ) : (
          <FamilyDialogFields {...props} save={saveFields} />
        )}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={() => setSaveFields(true)} color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose.bind(this)} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
      {/* <FamilySelectDialog
        open={openFamilySelect}
        onClose={() => setOpenFamilySelect(false)}
        onSelectFamily={importFamilyData}
        families={selectedFamilies || []}
      /> */}
    </Dialog>
  );
};

export default CreateClientDialog;
