import { axiosInstance } from '../../utils';

export default class ICD10Api {
  static listICD10Codes(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('icd10/list', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addICD10Code(
    token: string | undefined,
    clientId: string | undefined,
    code: string,
  ): Promise<any> {
    return axiosInstance
      .post(
        'icd10/add',
        {
          clientId,
          code,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static removeICD10Code(
    token: string | undefined,
    clientId: string | undefined,
    code: string,
  ): Promise<any> {
    return axiosInstance
      .post(
        'icd10/remove',
        {
          clientId,
          code,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getICD10ClientCodes(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`icd10/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
