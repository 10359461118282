import {
  faBoxArchive,
  faBoxOpen,
  faExclamationCircle,
  faFileExport,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip, SvgIcon, IconButton, Link } from '@mui/material';
import {
  GridColDef,
  DataGridPro,
  GridSortModel,
  GridPaginationModel,
} from '@mui/x-data-grid-pro';
import { FC, useContext, useState, useEffect, ReactElement } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ClientApi, ExportApi, FamilyApi, UserApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { Client, ClientDataGridElement } from '../../../types';
import { parseDateTimeString, queryClient } from '../../../utils';
import { ClientExportType } from '@casecare/types';
import { ClientArchiveDialog } from '../../dialogs';
import ClientFilterToolBar from './client-filter-toolbar';
import { ClientHelpFormListColors } from '../../../utils/styling/styling';
import moment from 'moment';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import {
  ClientTableFilterToolbar,
  HelpFormOrdererOption,
  SocialWorkerOption,
} from './client-table-filter-toolbar';

const ClientTable: FC<{ archive: boolean; mobile?: boolean }> = (props) => {
  const context = useContext(AppContext);

  const navigate = useNavigate();
  const [rows, setRows] = useState<ClientDataGridElement[]>([]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'client', sort: 'asc' },
  ]);
  const [searchText, setSearchText] = useState('');

  const [rowCount, setRowCount] = useState(0);
  const [archivedModal, setArchivedModal] = useState<ReactElement>(<></>);
  const [helpFormColumns, setHelpFormColumns] = useState<any>([]);

  const [supervisorFilter, setSupervisorFilter] = useState(null);
  const [selectedSocialWorkers, setSelectedSocialWorkers] = useState<
    SocialWorkerOption[]
  >([]);
  const [selectedHelpFormOrderer, setSelectedHelpFormOrderer] = useState<
    HelpFormOrdererOption[]
  >([]);
  // Used for loading the hours of the specific month shown in table
  // const [protocolDate, setProtocolDate] = useState<moment.Moment>(moment());

  const openClientDetails = (id: any, isFamily: boolean) => {
    isFamily
      ? navigate('/families/' + id + '/familyData')
      : navigate('/clients/' + id + '/clientData');
  };

  const openMobileProtocols = (id: any, isFamily: boolean) => {
    isFamily
      ? navigate('/mobile/protocols/' + id + '/family')
      : navigate('/mobile/protocols/' + id);
  };

  const openClientExport = async (id: any) => {
    const res = await ExportApi.clientExport(
      context.authToken,
      id,
      ClientExportType.ALL,
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const a = document.createElement('a');
    a.href = url;
    const contentDisposition = res.headers['content-disposition'];
    let fileName = 'client.docx';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=(.+)/);
      if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
    }
    a.setAttribute('download', fileName);
    a.download = fileName;
    a.click();
  };

  const updateArchiveClient = async (
    id: any,
    bool: boolean,
    isFamily: boolean,
  ) => {
    const res = isFamily
      ? await FamilyApi.updateFamilyArchiveState(context.authToken, id, bool)
      : await ClientApi.updateClientArchiveState(context.authToken, id, bool);

    if (res.message) {
      snackbarUtils.success(res.message);
    }

    setArchivedModal(<></>);
    refetch();
  };

  const openArchiveModal = (id: any, bool: boolean, isFamily: boolean) => {
    setArchivedModal(
      <ClientArchiveDialog
        onArchive={() => updateArchiveClient(id, bool, isFamily)}
        open={true}
        onClose={() => setArchivedModal(<></>)}
        archive={bool}
        clientName={rows.find((r) => r.id === id)?.client.name || ''}
      />,
    );
  };

  const helpFormsQuery = useQuery({
    queryKey: ['getUserHelpForms'],
    queryFn: () => {
      return UserApi.getHelpForms(context.authToken);
    },
    enabled: context.authToken !== undefined && !props.mobile,
  });

  useEffect(() => {
    if (!helpFormsQuery.isLoading) {
      if (helpFormsQuery.error) {
        console.error(helpFormsQuery.error);
      } else if (helpFormsQuery.data && helpFormsQuery.data.data) {
        const helpFormColumns = [];
        for (const helpForm of helpFormsQuery.data.data) {
          helpFormColumns.push({
            field: helpForm.id,
            headerName: helpForm.name,
            hideable: true,
            sortable: false,
            flex: 1,
            renderCell: (params: any) => {
              if (
                params.value &&
                (params.value.hoursUsed ||
                  params.value.hoursMax ||
                  params.value.date)
              ) {
                const hoursUsed =
                  params.value && params.value.hoursUsed
                    ? params.value.hoursUsed
                    : 0;
                const hoursMax =
                  params.value && params.value.hoursMax
                    ? params.value.hoursMax
                    : 0;

                let percent = (hoursUsed / hoursMax) * 100;
                percent.toFixed(2);
                if (hoursMax == 0 || isNaN(percent)) percent = 100;

                let cssClass = 'help-form-perfect';
                switch (true) {
                  case percent <= 25:
                    cssClass = 'help-form-bad';
                    break;
                  case percent > 25 && percent <= 50:
                    cssClass = 'help-form-not-ok';
                    break;
                  case percent > 50 && percent <= 75:
                    cssClass = 'help-form-ok';
                    break;
                  case percent > 75 && percent < 90:
                    cssClass = 'help-form-good';
                    break;
                }
                const date = moment(params.value.date);
                return (
                  <Box display="flex" flexDirection="row">
                    <Box
                      sx={{
                        color: date.isBefore(moment(), 'month')
                          ? 'error.main'
                          : 'black',
                      }}
                      mr={0}
                    >
                      {date.isValid() ? date.format('MM.YY') : '-'}
                    </Box>
                    <span>&nbsp;|&nbsp;</span>
                    <Box
                      sx={ClientHelpFormListColors}
                      className={cssClass}
                      tabIndex={0}
                    >
                      {context.i18n.formatString(
                        context.i18n.clientListHelpFormText,
                        params.value?.hoursUsed || 0,
                        params.value?.hoursMax || 0,
                      )}
                    </Box>
                  </Box>
                );
              }
              return <></>;
            },
          });
        }
        setHelpFormColumns(helpFormColumns);
      }
    }
  }, [helpFormsQuery.data, helpFormsQuery.error, helpFormsQuery.isLoading]);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      'clients',
      paginationModel,
      sortModel,
      searchText,
      supervisorFilter,
      selectedSocialWorkers,
      selectedHelpFormOrderer,
      // protocolDate,
    ],
    queryFn: () => {
      const data: any = Object.assign(
        {
          pageNum: paginationModel.page,
          // pageSize: paginationModel.pageSize,
          sortModel,
          searchText,
        },
        supervisorFilter && { supervisor: supervisorFilter },
        selectedSocialWorkers && {
          clientHelpFormIds: selectedSocialWorkers
            .map((sw) => sw.clientHelpFormIds)
            .flat(1),
        },
        selectedHelpFormOrderer.length > 0 && {
          helpFormOrdererIds: selectedHelpFormOrderer.map((o) => o.id),
        },
        // protocolDate && { protocolDate: protocolDate.toISOString() },
      );
      return props.archive
        ? ClientApi.listArchivedClientFamilyCollection(context.authToken, data)
        : ClientApi.listClientFamilyCollection(context.authToken, data);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error(error);
      } else if (data && data.data) {
        setRows(
          data.data.collection.map((client: Client) => {
            return Object.assign({
              id: client.id,
              client: client,
              creationDate: parseDateTimeString(client.creationDate),
              updateDate: parseDateTimeString(client.updateDate),
              actions: true,
              isFamily: client.isFamily,
              ...client.helpForms,
            });
          }),
        );
        setRowCount(data.data.totalCount);
      }
    }
  }, [data, error, isLoading]);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'client',
      headerName: props.mobile ? context.i18n.clients : context.i18n.name,
      minWidth: 150,
      flex: 1.5,
      hideable: false,
      // sortable: false,
      renderHeader: (params) => (
        <Box tabIndex={0}>{params.colDef.headerName}</Box>
      ),
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          {params.value.requiredFieldsMissing ? (
            <Tooltip title={context.i18n.requiredFieldsNotFilled}>
              <SvgIcon color="error" sx={{ height: '1rem', mr: 1 }}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </SvgIcon>
            </Tooltip>
          ) : null}
          <Link
            onClick={() =>
              props.mobile
                ? openMobileProtocols(params.id, params.row.isFamily)
                : openClientDetails(params.id, params.row.isFamily)
            }
          >
            {params.value.name}
          </Link>
        </Box>
      ),
    },
    ...helpFormColumns,
    // {
    //   field: 'creationDate',
    //   headerName: context.i18n.creationDate,
    //   flex: 1,
    //   hideable: true,
    //   renderHeader: (params) => (
    //     <Box tabIndex={0}>{params.colDef.headerName}</Box>
    //   ),
    //   renderCell: (params) => <Box tabIndex={0}>{params.value}</Box>,
    // },
    // {
    //   field: 'editDate',
    //   headerName: context.i18n.editDate,
    //   flex: 1,
    //   hideable: true,
    //   renderCell: (params) => <Box tabIndex={0}>{params.value}</Box>,
    //   renderHeader: (params) => (
    //     <Box tabIndex={0}>{params.colDef.headerName}</Box>
    //   ),
    // },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <>
          {props.archive ? (
            <>
              <Tooltip
                title={context.i18n.formatString(
                  context.i18n.openClientExport,
                  rows.find((r) => r.id === params.id)?.client.name,
                )}
              >
                <IconButton
                  disableRipple
                  color="primary"
                  id={'export-button-' + params.id}
                  sx={{
                    height: 39,
                    display: params.row.isFamily ? 'none' : 'inherit',
                  }}
                  disabled={!context.basicActive}
                  onClick={() => openClientExport(params.id)}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={context.i18n.formatString(
                  context.i18n.unArchiveClient,
                  rows.find((r) => r.id === params.id)?.client.name,
                )}
              >
                <IconButton
                  disableRipple
                  color="primary"
                  id={'export-button-' + params.id}
                  sx={{ height: 39 }}
                  disabled={!context.basicActive}
                  onClick={() =>
                    openArchiveModal(params.id, false, params.row.isFamily)
                  }
                >
                  <FontAwesomeIcon icon={faBoxOpen} />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip
                title={context.i18n.formatString(
                  context.i18n.openClientExport,
                  rows.find((r) => r.id === params.id)?.client.name,
                )}
              >
                <IconButton
                  disableRipple
                  color="primary"
                  id={'export-button-' + params.id}
                  sx={{
                    height: 39,
                    display: params.row.isFamily ? 'none' : 'inherit',
                  }}
                  disabled={!context.basicActive}
                  onClick={() => openClientExport(params.id)}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={context.i18n.formatString(
                  context.i18n.archiveClient,
                  rows.find((r) => r.id === params.id)?.client.name,
                )}
              >
                <IconButton
                  disableRipple
                  color="primary"
                  id={'export-button-' + params.id}
                  sx={{ height: 39 }}
                  disabled={!context.basicActive}
                  onClick={() =>
                    openArchiveModal(params.id, true, params.row.isFamily)
                  }
                >
                  <FontAwesomeIcon icon={faBoxArchive} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <DataGridPro
        sx={{
          fontSize: 17,
          m: props.mobile ? 0 : 5,
        }}
        columnVisibilityModel={{
          family: context.familyActive && !props.mobile,
          creationDate: !props.mobile,
          actions: !props.mobile,
        }}
        slotProps={{
          toolbar: props.mobile
            ? {
                searchText,
                setSearchText: setSearchText,
              }
            : context.user && context.user?.role !== 'USER'
            ? {
                supervisorId: supervisorFilter,
                setSupervisorId: setSupervisorFilter,
                selectedSocialWorkers,
                setSelectedSocialWorkers,
                selectedHelpFormOrderer,
                setSelectedHelpFormOrderer,
                // protocolDate,
                // setProtocolDate,
              }
            : {},
        }}
        slots={
          props.mobile
            ? {
                toolbar(props: any) {
                  return ClientFilterToolBar(props);
                },
              }
            : context.user && context.user?.role !== 'USER'
            ? {
                toolbar(props: any) {
                  return ClientTableFilterToolbar(props);
                },
              }
            : {}
        }
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        loading={isLoading}
        columnBufferPx={4}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        pagination
        // Pagination settings
        // paginationMode="client"
        // onPaginationModelChange={setPaginationModel}
        // paginationModel={paginationModel}
        // rowCount={rowCount}
        // End Pagination settings
        // Sorting settings
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />
      {archivedModal && archivedModal}
    </>
  );
};

export default ClientTable;
