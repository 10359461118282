import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import LoginInput from '../../public/login-input/login-input';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserSelect } from '../../client';
import { AdminApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { queryClient } from '../../../utils';
import { useQuery } from 'react-query';

interface EditUserGroupsDialogProps {
  open: boolean;
  onClose: () => void;
  groupId?: string;
}

export const EditUserGroupsDialog: FC<EditUserGroupsDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [groupLeaders, setGroupLeaders] = useState([]);
  const [groupLeadersError, setGroupLeadersError] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);

  const { isLoading, error, data } = useQuery({
    queryKey: ['getUserGroup', props.groupId],
    queryFn: () => AdminApi.getUserGroup(context.authToken, props.groupId),
    enabled:
      context.authToken !== undefined &&
      context.isAdmin &&
      props.groupId !== undefined,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (data && data.data && data.data.group) {
      console.log(data.data.group.name);
      setName(data.data.group.name);
      setGroupLeaders(data.data.group.groupLeaders.map((gl: any) => gl.id));
      setGroupMembers(data.data.group.members.map((gl: any) => gl.id));
    }
  }, [data, error]);

  const onSave = async () => {
    setNameError(name === '');
    setGroupLeadersError(groupLeaders.length === 0);

    if (name === '' || groupLeaders.length === 0) return;

    try {
      const res = await AdminApi.editUserGroup(context.authToken, {
        userGroupId: props.groupId,
        name,
        groupLeaderIds: groupLeaders,
        memberIds: groupMembers,
      });
      if (res.message) snackbarUtils.success(res.message);
      props.onClose();
      queryClient.invalidateQueries(['getUserGroups']);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.editUserGroup}</DialogTitle>
      <DialogContent>
        <LoginInput
          required
          onChange={(value) => {
            setName(value);
            setNameError(false);
          }}
          label={context.i18n.name}
          value={name}
          error={nameError}
        />
        <Box mt={2}>
          <UserSelect
            userIds={groupLeaders}
            title={context.i18n.groupLeader}
            error={groupLeadersError}
            helperText={
              groupLeadersError ? context.i18n.atLeastOneGroupLeader : ''
            }
            multiple
            required={true}
            setId={(id) => {
              console.log(id);
              setGroupLeadersError(false);
              setGroupLeaders(id);
            }}
          />
        </Box>
        <Box mt={2}>
          <UserSelect
            userIds={groupMembers}
            title={context.i18n.groupMembers}
            error={false}
            helperText={''}
            multiple
            required={false}
            setId={(id) => {
              setGroupMembers(id);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={onSave} color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
