import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Dialog,
} from '@mui/material';
import React, { FC, useContext, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { FormApi } from '../../../../../api';
import { AppContext } from '../../../../../hooks/context';
import { getValueString } from '../../../../../utils';
import snackbarUtils from '../../../../../utils/snackbar/snackbar-utils';
import { FormGroupDialog, DiscardDialog } from '../../../../dialogs';
import { FormListProps, FormListElement } from './form-list';

const FormListScroll: FC<FormListProps> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [activeGroup, setActiveGroup] = useState<FormListElement>(
    props.values[0],
  );
  const [discard, setDiscard] = useState(false);
  const [saved, setSaved] = useState<boolean>();
  const [mappedValues, setMappedValues] = useState<FormListElement[]>([]);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    if (props.addedDataset) {
      setActiveGroup(props.values[props.values.length - 1]);
      setTimeout(() => {
        setOpen(true);
      }, 100);
    }
  }, [props.addedDataset]);

  useEffect(() => {
    const mappedValues = props.values.map((value) => {
      let title: any = '';
      let subTitle: any = '';

      value.children.forEach((data) => {
        if (data.isListTitle) {
          title = getValueString(data);
        } else if (data.isListSubtitle) {
          subTitle = getValueString(data);
        }
      });

      if (title === '') {
        title = getValueString(value.children.sort((a, b) => a.seq - b.seq)[0]);
      }

      return { ...value, title, subTitle };
    });

    setMappedValues(mappedValues);
  }, [props.values]);

  const onSave = async () => {
    const groupData = context.getAndRemoveGroupData(props.id).map((data) => {
      return {
        ...data,
        dataset: activeGroup.dataset ? +activeGroup.dataset : 0,
      };
    });

    if (groupData) {
      let errorMessage = '';
      groupData.map((data) => {
        if (data.error !== null) {
          errorMessage += data.error + '\n';
        }
      });

      if (errorMessage === '') {
        if (props.onSave) {
          props.onSave(groupData);
          setOpen(false);
          setDiscard(false);
        } else {
          try {
            const res = await FormApi.updateFormFields(
              context.clientId,
              context.authToken,
              groupData,
            );
            if (res.message) snackbarUtils.success(res.message);
            queryClient.invalidateQueries('formData');

            setSaved(true);
            setOpen(false);
            setDiscard(false);
            setTimeout(() => {
              setSaved(undefined);
            }, 10);
          } catch (e) {
            snackbarUtils.error(context.i18n.error);
          }
        }
      }
    }
  };

  const onDiscard = () => {
    context.removeGroupData(props.id);

    setOpen(false);
    setDiscard(false);
  };

  const onClose = () => {
    if (context.getAndRemoveGroupData(props.id).length > 0) {
      setDiscard(true);
    } else {
      setOpen(false);
    }
  };

  const onEdit = (element: FormListElement) => {
    setActiveGroup(element);
    setOpen(true);
  };

  return (
    <Box sx={{ m: 2, maxHeight: 220, overflowY: 'scroll' }}>
      <List id={props.id}>
        {mappedValues[0] && mappedValues[0].title ? (
          mappedValues.map((data, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      color="primary"
                      component={Box}
                    >
                      <Typography fontWeight="bold">
                        {data.title}
                        {data.subTitle !== '' && (
                          <Typography fontWeight="normal" fontSize={'0.8rem'}>
                            {data.subTitle}
                          </Typography>
                        )}
                      </Typography>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() =>
                          props.preview || readOnly ? null : onEdit(data)
                        }
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </IconButton>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))
        ) : (
          <Typography>Keine Daten vorhanden</Typography>
        )}
      </List>
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <FormGroupDialog
          id={props.id}
          elements={activeGroup.children}
          onSave={onSave}
          saved={saved}
          onClose={onClose}
          title={props.title}
        />
      </Dialog>
      <Dialog open={discard} onClose={() => setDiscard(false)}>
        <DiscardDialog
          onDiscard={onDiscard}
          onSave={onSave}
          onClose={() => setDiscard(false)}
        />
      </Dialog>
    </Box>
  );
};

export default FormListScroll;
