import {
  Box,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  FormControl,
} from '@mui/material';
import { FC, useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';
import { FormElementProps, FormOptions } from '../../../../../types';
import FormElementHeader from '../form-element-header/form-element-header';
import { FormFieldStyle } from '../../../../../types/form-element-props';

interface FormDropdownProps extends FormElementProps {
  options: FormOptions[];
  value?: number | string | string[];
  multiple?: boolean;
  hideHeader?: boolean;
  noNoneOption?: boolean;
}

const FormDropdown: FC<FormDropdownProps> = (props) => {
  const [value, setValue] = useState<any>(
    props.value !== undefined ? props.value : props.multiple ? [] : '',
  );
  const [save, setSave] = useState(false);
  const context = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  const checkErrors = (value: any) => {
    let currentError = null;
    if (props.required) {
      if (value === undefined || !value) {
        currentError = context.i18n.inputRequiredError;
      } else if (value.length && value.length === 0) {
        currentError = context.i18n.inputRequiredError;
      }
    }
    setError(currentError);
    return currentError;
  };

  useEffect(() => {
    checkErrors(value);
    props.dialog &&
      props.onChange &&
      props.onChange('', checkErrors(value), true);

    return () => {
      props.dialog && props.onChange && props.onChange('', null);
    };
  }, []);

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    props.saved ? setSave(!props.saved) : null;
    context.saveAll ? setSave(!context.saveAll) : null;
    props.saved && props.onSave && props.onSave(value);
  }, [props.saved, context.saveAll]);

  const onSave = () => {
    if (error !== null) return;
    props.onSave ? props.onSave(value) : null;
    setSave(false);
  };

  const onCancel = () => {
    setValue(
      props.value !== undefined ? props.value : props.multiple ? [] : '',
    );
    setSave(false);
    props.onCancel ? props.onCancel() : null;
  };

  const onChange = (key: number | string) => {
    setSave(true);
    setValue(key);
    props.onChange && props.onChange(key, checkErrors(key));
  };

  const onChangeMultiple = (value: any) => {
    setSave(true);
    setValue(value);
    props.onChange && props.onChange(value, checkErrors(value));
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const renderStyle = () => {
    switch (props.style) {
      case FormFieldStyle.NORMAL:
        return (
          <>
            <FormControl disabled={props.disabled} sx={{ width: '100%' }}>
              <InputLabel>{props.title}</InputLabel>
              <Select
                value={value}
                id={props.id}
                fullWidth
                error={error !== null}
                readOnly={readOnly}
                multiple={props.multiple}
                label={props.title}
                onChange={(event) => {
                  if (props.multiple) {
                    onChangeMultiple(event.target.value);
                  }
                }}
              >
                {!props.multiple && !props.noNoneOption && (
                  <MenuItem
                    value={''}
                    onClick={() => {
                      if (!props.multiple) {
                        onChange('');
                      }
                    }}
                  >
                    {context.i18n.formDropdownRemoveSelected}
                  </MenuItem>
                )}

                {props.options.map((value) => (
                  <MenuItem
                    key={value.key}
                    value={value.key}
                    onClick={() => {
                      if (!props.multiple) {
                        onChange(value.key);
                      }
                    }}
                  >
                    {value.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{error && error}</FormHelperText>
            </FormControl>
          </>
        );
      default:
        return (
          <>
            {props.title && (
              <FormElementHeader
                save={
                  props.dialog || props.preview || readOnly || props.hideHeader
                    ? false
                    : save
                }
                onSave={onSave}
                onCancel={onCancel}
                hideExport={props.hideExport}
                title={props.title}
                required={props.required}
              />
            )}
            <Select
              disabled={props.disabled}
              value={value}
              id={props.id}
              fullWidth
              error={error !== null}
              readOnly={readOnly}
              multiple={props.multiple}
              onChange={(event) => {
                if (props.multiple) {
                  onChangeMultiple(event.target.value);
                }
              }}
            >
              {props.options.map((value) => (
                <MenuItem
                  key={value.key}
                  value={value.key}
                  sx={{
                    backgroundColor: value.backgroundColor ?? '',
                    color: value.color ?? '',
                  }}
                  onClick={() => {
                    if (!props.multiple) {
                      onChange(value.key);
                    }
                  }}
                >
                  {value.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{error && error}</FormHelperText>
          </>
        );
    }
  };

  return <Box sx={{ m: 2 }}>{renderStyle()}</Box>;
};

export default FormDropdown;
