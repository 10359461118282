import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../../../utils';
import {
  faCheck,
  faCircleArrowDown,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import EcoMapApi from '../../../../api/eco-map/eco-map';
import { FormElement } from '../../../client';
import { FormFieldType } from '@casecare/types';
import { FormFieldStyle } from '../../../../types/form-element-props';
import { ChromePicker } from 'react-color';

interface EcoMapEditProps {
  ecoMap: any;
  onCancel: () => void;
  reloadData?: () => void;
}

const EcoMapEdit: FC<EcoMapEditProps> = (props) => {
  const context = useContext(AppContext);
  const [ecoMap, setEcoMap] = useState<any>(props.ecoMap);
  const groupId = 'Eco-Map-Edit-' + ecoMap.id;

  const currentSegmentMax = 4;

  const onSave = async () => {
    try {
      let error = '';
      const groupData = context.getAndRemoveGroupData(groupId);
      const sendData: Record<string, any> = {};
      for (const data of groupData) {
        if (data.error !== null) error += data.error + '\n';
        sendData[data.id] = data.value;
      }
      if (error === '') {
        const newEcoMap = Object.assign({ ...ecoMap });
        for (const segment of newEcoMap.segments) {
          if (sendData[segment.id] !== undefined)
            segment.label = sendData[segment.id];
        }
        sendData['segments'] = newEcoMap.segments;

        const res = await EcoMapApi.editEcoMap(
          context.authToken,
          ecoMap.id,
          sendData,
        );

        if (res.message) {
          snackbarUtils.success(res.message);
          props.reloadData && props.reloadData();
        }
      } else snackbarUtils.error(error);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const addSegments = () => {
    const newEcoMap = Object.assign({ ...ecoMap });
    const segments = [];
    let ecoMapCounter = 0;
    for (let length = 0; length < currentSegmentMax; length++) {
      const seq = length + 1;
      if (
        newEcoMap.segments[ecoMapCounter] !== undefined &&
        newEcoMap.segments[ecoMapCounter].seq === seq
      ) {
        segments.push(newEcoMap.segments[ecoMapCounter]);
        ecoMapCounter++;
      } else segments.push({ id: 'segment_' + seq, label: '', seq: seq });

      newEcoMap.segments[ecoMapCounter] === undefined && ecoMapCounter++;
    }
    newEcoMap.segments = segments;
    setEcoMap(newEcoMap);
  };

  useEffect(() => {
    setEcoMap(props.ecoMap);
    if (ecoMap.segments.length < currentSegmentMax) addSegments();
  }, [props.ecoMap]);

  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography
            sx={{ textTransform: 'uppercase', color: 'primary.main' }}
          >
            {context.i18n.circleColor}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box sx={{ m: -2 }}>
                <FormElement
                  type={FormFieldType.TEXT}
                  value={ecoMap.outerCircleColor}
                  id={'outerCircleColor'}
                  style={FormFieldStyle.COLOR_PICKER}
                  icon={<FontAwesomeIcon icon={faCircleArrowDown} />}
                  groupId={groupId}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ m: -2 }}>
                <FormElement
                  type={FormFieldType.TEXT}
                  value={ecoMap.middleCircleColor}
                  id={'middleCircleColor'}
                  style={FormFieldStyle.COLOR_PICKER}
                  icon={<FontAwesomeIcon icon={faCircleArrowDown} />}
                  groupId={groupId}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ m: -2 }}>
                <FormElement
                  type={FormFieldType.TEXT}
                  value={ecoMap.innerCircleColor}
                  id={'innerCircleColor'}
                  style={FormFieldStyle.COLOR_PICKER}
                  icon={<FontAwesomeIcon icon={faCircleArrowDown} />}
                  groupId={groupId}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{ textTransform: 'uppercase', color: 'primary.main' }}
          >
            {context.i18n.segments}
          </Typography>
          <Box sx={{ m: -2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {ecoMap.segments.map((segment: any) => (
                  <FormElement
                    key={segment.id}
                    style={FormFieldStyle.NORMAL}
                    type={FormFieldType.TEXT}
                    value={segment.label}
                    id={segment.id}
                    title={context.i18n.formatString(
                      context.i18n.singleSegment,
                      segment.seq,
                    )}
                    groupId={groupId}
                    required
                  />
                ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            sx={{ p: 2, width: '100%' }}
            color="inherit"
            size="large"
            endIcon={
              <FontAwesomeIcon color={theme.palette.error.main} icon={faX} />
            }
            onClick={props.onCancel}
          >
            {context.i18n.cancel}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            sx={{ p: 2, width: '100%' }}
            color="inherit"
            size="large"
            endIcon={
              <FontAwesomeIcon
                color={theme.palette.success.main}
                icon={faCheck}
              />
            }
            onClick={onSave}
          >
            {context.i18n.save}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EcoMapEdit;
