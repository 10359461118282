import { FC, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from '../hooks/context';

const AdminRoute: FC = () => {
  const context = useContext(AppContext);
  return context.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
