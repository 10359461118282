import { ButtonGroup, Popper } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';

import { AvatarApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import AvatarMoodButton from '../avatar-mood-button/avatar-mood-button';

interface AvatarMoodSliderProps {
  anchorEl: HTMLElement | null;
  mood: number;
  open: boolean;
  onClose: () => void;
  onNote: () => void;
  clientId: string | undefined;
  indexId: string | undefined;
  hasNote: boolean;
}

const AvatarMoodPopper: FC<AvatarMoodSliderProps> = (props) => {
  const context = useContext(AppContext);

  const [mood, setMood] = useState(props.mood);

  useEffect(() => {
    setMood(props.mood);
  }, [props.mood]);

  const onMoodChange = async (mood: number) => {
    setMood(mood);
    const res = await AvatarApi.updateAvatarMood(
      context.authToken,
      props.clientId,
      props.indexId,
      mood,
    );

    if (res.statusCode !== 201) {
      snackbarUtils.error(context.i18n.updateAvatarError);
    } else if (res.statusCode === 201) {
      snackbarUtils.success(res.message);
    }

    props.onClose();
  };

  return (
    <Popper
      placement="left"
      open
      anchorEl={props.anchorEl}
      sx={{
        background: 'rgb(255,255,255,0.7)',
        borderRadius: 2,
        padding: 1,
      }}
    >
      <ButtonGroup>
        {Array.from({ length: 11 }, (_, index) => index).map((m) => (
          <AvatarMoodButton
            key={m}
            mood={m}
            onCLick={() => onMoodChange(m)}
            onNote={props.onNote}
            showNote={mood === m && !props.open}
            hide={!props.open && Math.abs(mood - m) >= 2}
            halfOpacity={Math.abs(mood - m) === 1 && !props.open}
            quaterOpacity={Math.abs(mood - m) === 2 && !props.open}
            greyNote={!props.hasNote}
          />
        ))}
      </ButtonGroup>
    </Popper>
  );
};

export default AvatarMoodPopper;
