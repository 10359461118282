import {
  SnackbarAction,
  useSnackbar,
  VariantType,
  WithSnackbarProps,
} from 'notistack';
import React from 'react';

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: string, action?: SnackbarAction) {
    this.toast(msg, 'success', action);
  },
  warning(msg: string, action?: SnackbarAction) {
    this.toast(msg, 'warning', action);
  },
  info(msg: string, action?: SnackbarAction) {
    this.toast(msg, 'info', action);
  },
  error(msg: string, action?: SnackbarAction) {
    this.toast(msg, 'error', action);
  },
  toast(
    msg: string,
    variant: VariantType = 'default',
    action?: SnackbarAction,
  ) {
    if (!msg || msg.startsWith('translation.')) return;
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      action,
      style: { whiteSpace: 'pre-line' },
    });
  },
};
