import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext } from 'react';
import { useQuery } from 'react-query';
import { ClientApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AppContext } from '../../../hooks/context';
import { ICD10Table } from '../../client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { ICD10Code } from '../../../types';

interface ICD10DialogProps {
  open: boolean;
  onClose: () => void;
}

const ICD10Dialog: FC<ICD10DialogProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Dialog maxWidth="lg" fullWidth open={props.open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.icd10Codes}</DialogTitle>
      <DialogContent>
        <ICD10Table />
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.close}>
          <IconButton disableRipple onClick={props.onClose}>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ICD10Dialog;
