import { axiosInstance } from '../../utils';

export default class FamilyApi {
  static getFamilies(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('family', { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        return res.data;
      });
  }

  static getFamily(
    token: string | undefined,
    familyId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`family/${familyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateFamilyArchiveState(
    token: string | undefined,
    familyId: string | undefined,
    archived: boolean,
  ): Promise<any> {
    return axiosInstance
      .post(
        'family/archive/' + familyId,
        {
          archived,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getClientFamily(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`family/client/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addFamily(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('family', body, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        return res.data;
      });
  }

  static updateFamily(
    token: string | undefined,
    familyId: string | undefined,
    body: any,
  ) {
    return axiosInstance
      .post(`family/${familyId}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addClientToFamily(
    token: string | undefined,
    familyId: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .put(
        `family/${familyId}/${clientId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getAvailableClients(token: string | undefined): Promise<any> {
    return axiosInstance
      .get(`family/available`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getFamilyHelpForms(
    token: string | undefined,
    familyId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('family/help-form/' + familyId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getMinimalFamilyHelpForms(
    token: string | undefined,
    familyId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('family/help-form/minimal/' + familyId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addFamilyHelpForm(
    token: string | undefined,
    familyId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('family/help-form/' + familyId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveFamilyHelpFormsData(
    token: string | undefined,
    body: any,
    familyId: string,
  ): Promise<any> {
    return axiosInstance
      .post('family/help-form/data/' + familyId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveFamilyHelpFormData(
    token: string | undefined,
    body: any,
    familyId: string,
  ): Promise<any> {
    return axiosInstance
      .post('family/help-form/single-data/' + familyId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getFamilyHelpFormSupervisor(
    token: string | undefined,
    familyId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('family/help-form/supervisor/' + familyId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
