import { Autocomplete, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { UserApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface UserSelectProps {
  setId: (id: any) => void;
  error: boolean;
  userId?: string;
  userIds?: string[];
  title?: string;
  multiple?: boolean;
  disabled?: boolean;
  noAdmins?: boolean;
  noShowUsers?: string[];
  required?: boolean;
  helperText?: string;
  allowClearable?: boolean;
  showDisabledUsers?: boolean;
}

const UserSelect: FC<UserSelectProps> = (props) => {
  const context = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState<
    string[] | string | null | undefined
  >(props.multiple ? props.userIds : null);
  const [error, setError] = useState(props.error);

  useEffect(() => {
    if (props.userId) {
      const supervisor: any = users.find((s: any) => s.id === props.userId);
      if (supervisor) {
        setSelected(supervisor);
      }
    }
  }, [props.userId, users]);

  useEffect(() => {
    if (props.userIds) {
      const supervisors = users.filter((user: any) =>
        props.userIds?.includes(user.id),
      );
      setSelected(supervisors);
    }
  }, [props.userIds, users]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const { isLoading } = useQuery(
    ['users', props.noAdmins, props.showDisabledUsers],
    () =>
      UserApi.getUsers(
        context.authToken,
        Object.assign(
          {
            disabledUsers: props.showDisabledUsers,
          },
          props.noAdmins && { noAdmins: true },
        ),
      ),
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        if (props.noShowUsers) {
          setUsers(
            res.data.filter((u: any) => !props.noShowUsers?.includes(u.id)),
          );
        } else {
          setUsers(res.data);
        }
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  return isLoading ? (
    <></>
  ) : (
    <Autocomplete
      disabled={props.disabled}
      disableClearable={
        props.allowClearable ? false : selected !== '' && selected !== null
      }
      onChange={(e, option: any) => {
        setSelected(option);
        props.multiple
          ? props.setId(option.map((o: any) => o.id))
          : props.setId(option ? option.id : '');
      }}
      multiple={props.multiple}
      disableCloseOnSelect={props.multiple}
      value={selected}
      noOptionsText={context.i18n.noUsers}
      getOptionLabel={(option: any) => option.name || ''}
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          label={props.title}
          required={props.required}
          helperText={props.helperText}
          disabled={props.disabled}
        />
      )}
      options={users}
    />
  );
};

export default UserSelect;
