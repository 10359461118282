export interface RiskAssessment {
  name: string;
  id: string;
  type: RiskAssessmentType;
  result: RiskAssessmentSurveyResult;
  children: RiskAssessment[];
}

export interface RiskAssessmentSurvey {
  id: string;
  riskAssessments: {
    id: string;
    name: string;
  }[];
  result: string;
  startDate: string;
  atRiskSubTypes: string[];
  creator: string;
}

export enum RiskAssessmentType {
  CHILD_RISK_ASSESSMENT,
}

export enum RiskAssessmentSurveyResult {
  UNFINISHED = 'UNFINISHED',
  NO_RISK = 'NO_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
  HIGH_RISK = 'HIGH_RISK',
  MEDIUM_RISK_UNFINISHED = 'MEDIUM_RISK_UNFINISHED',
  HIGH_RISK_UNFINISHED = 'HIGH_RISK_UNFINISHED',
  REPEAT = 'REPEAT',
}
