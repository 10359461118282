import {
  faCaretDown,
  faCheck,
  faPersonCirclePlus,
  faPersonPregnant,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  colors,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails,
  Grid,
  Button,
} from '@mui/material';
import { context } from 'msw';
import { FC, useContext, useEffect, useState } from 'react';
import { queryClient, theme } from '../../../utils';
import { AccordionStyle } from '../../../utils/styling/styling';
import { AppContext } from '../../../hooks/context';
import { ClientApi, GenoGrammApi } from '../../../api';
import { FormElement } from '../../client';
import { ClientDataType, FormFieldType, PregnancyState } from '@casecare/types';
import { GenoGrammPregnancyData } from '../geno-gramm-pregnancy-data/geno-gramm-pregnancy-data';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface GenoGrammAddPregnancyProps {
  clientList: any[];
  indexId: string | undefined;
}

export const GenoGrammAddPregnancy: FC<GenoGrammAddPregnancyProps> = (
  props,
) => {
  const context = useContext(AppContext);

  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const colors = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  };

  const groupId = 'Geno-Gramm-Pregnancy-Data-CREATE';

  useEffect(() => {
    context.getAndRemoveGroupData(groupId);
  }, []);

  const onSavePragnancy = async () => {
    try {
      const groupErrors = context.getGroupDataError(groupId),
        error = groupErrors.length > 0;

      if (!error) {
        const groupData = context.getAndRemoveGroupData(groupId);

        const sendData: Record<string, any> = {};
        for (const data of groupData) {
          if (data.value && data.value !== '') {
            if (data.id === 'mother-select') {
              sendData.motherId = data.value;
            } else if (data.id === 'father-select') {
              sendData.fatherId = data.value;
            } else if (data.id === 'pregnancy-select') {
              sendData.state = data.value;
            }
          }
        }

        const res = await GenoGrammApi.savePregnancy(
          context.authToken,
          sendData,
          props.indexId,
        );
        setIsLoading(true);

        if (res.message) snackbarUtils.success(res.message);

        queryClient.invalidateQueries(['geno-gramm-data']);
      } else snackbarUtils.error(context.i18n.redFieldsNeedToBeFilledOut);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }

    setIsLoading(false);
  };

  return (
    <Accordion
      onClick={() => {
        return;
      }}
      square
      expanded={expanded}
      disableGutters
      elevation={5}
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        ...AccordionStyle(colors.backgroundColor, colors.color),
      }}
    >
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        expandIcon={
          <FontAwesomeIcon
            size="2x"
            color={theme.palette.primary.main}
            icon={faCaretDown}
          />
        }
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            color={colors.color}
            icon={faPersonPregnant}
            size="2x"
            style={{
              padding: '0.5rem',
              marginLeft: '1.5rem',
            }}
          ></FontAwesomeIcon>
          <Typography sx={{ ml: 2 }}>{context.i18n.addPregnancy}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ m: 2 }}>
        <Box mt={5}>
          {expanded && (
            <GenoGrammPregnancyData
              isLoading={isLoading}
              clientList={props.clientList}
              pregnancy={{}}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{ p: 2, width: '100%' }}
                color="inherit"
                size="large"
                endIcon={
                  <FontAwesomeIcon
                    color={theme.palette.success.main}
                    icon={faCheck}
                  />
                }
                onClick={onSavePragnancy}
              >
                {context.i18n.save}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
