import { FC, useContext, useEffect } from 'react';
import { ClientTable } from '../../../components/client';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { MenuWrapper } from '../../../components/core';
import { useNavigate, useParams } from 'react-router-dom';
import { StorageType } from '@casecare/types';
import { StorageTable } from '../../../components/storage';

const StorageList: FC = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const { storageItemFolderTypeId, storageItemFolderId } = useParams();

  useEffect(() => {
    setDocumentTitle(context.i18n.storage);
    return () => loadTitle();
  }, []);

  return (
    <MenuWrapper
      customBreadCrumb
      title={context.i18n.storage}
      storage
      selected={storageItemFolderTypeId}
    >
      {storageItemFolderTypeId && (
        <StorageTable
          storageItemId={storageItemFolderId ?? storageItemFolderTypeId}
          storageItemFolderId={storageItemFolderTypeId}
        ></StorageTable>
      )}
    </MenuWrapper>
  );
};

export default StorageList;
