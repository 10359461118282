import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement } from '../../client';
import { FormFieldType } from '@casecare/types';
import { EcoMapAddDialogType } from '../../../types/eco-map-add-dialog-type';
import EcoMapApi from '../../../api/eco-map/eco-map';
import { StorageApi } from '../../../api';
import { StorageItem } from '../../../types/storage-item';

interface EditStorageDialogProps {
  open: boolean;
  storageItem: StorageItem | null;
  onClose: () => void;
}

const EditStorageDialog: FC<EditStorageDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(props.open);
  const groupId = 'Edit-Storage-Dialog';
  const staticFields = [
    {
      id: 'name',
      name: context.i18n.name,
      required: true,
      type: FormFieldType.TEXT,
      value: props.storageItem?.name,
    },
  ];

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onSave = async () => {
    try {
      let error = '';
      const groupData = context.getAndRemoveGroupData(groupId);
      const sendData: Record<string, any> = {};
      for (const data of groupData) {
        if (data.error !== null) error += data.error + '\n';
        sendData[data.id] = data.value;
      }
      if (error === '') {
        const res = await StorageApi.edit(
          context.authToken,
          props.storageItem?.id ?? '',
          sendData,
        );

        if (res.message) {
          snackbarUtils.success(res.message);
          onClose();
        }
      } else snackbarUtils.error(error);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onClose = async () => {
    for (const staticField of staticFields) {
      context.removeChangedCategoryData(staticField.id);
    }
    props.onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{context.i18n.editStorageDialog}</DialogTitle>
      <DialogContent>
        {staticFields.map((field) => (
          <FormElement
            dialog={true}
            hideExport
            type={field.type}
            value={field.value}
            title={field.name}
            required={field.required}
            id={field.id}
            key={field.id}
            groupId={groupId}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton onClick={onSave} disableRipple color="primary">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default EditStorageDialog;
