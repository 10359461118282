import {
  faSmile,
  faStar,
  faNoteSticky,
  faPeopleGroup,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs } from '@mui/material';
import { FC, useContext } from 'react';
import {
  TabScrollButtonStyle,
  AvatarTabButtonStyle,
} from '../../../utils/styling/styling';
import { AppContext } from '../../../hooks/context';

interface AvatarOptionTabsProps {
  onNotes: () => void;
  onNewConnection: () => void;
  onClose: () => void;
  onMood: () => void;
  onSymbols: () => void;
  moodRef: React.RefObject<HTMLDivElement>;
  symbolsRef: React.RefObject<HTMLDivElement>;
  blur?: boolean;
}

const AvatarOptionTabs: FC<AvatarOptionTabsProps> = (props) => {
  const context = useContext(AppContext);

  const tabs = [
    {
      label: context.i18n.close,
      icon: faX,
      color: 'red',
      onClick: props.onClose,
    },
    {
      label: context.i18n.mood,
      icon: faSmile,
      onClick: props.onMood,
      ref: props.moodRef,
    },
    {
      label: context.i18n.symbols,
      icon: faStar,
      onClick: props.onSymbols,
      ref: props.symbolsRef,
    },
    {
      label: context.i18n.notes,
      icon: faNoteSticky,
      onClick: props.onNotes,
    },
    {
      label: context.i18n.newConnection,
      icon: faPeopleGroup,
      onClick: props.onNewConnection,
    },
  ];

  return (
    <Tabs
      orientation="vertical"
      centered
      sx={{
        zIndex: 0,
        ...TabScrollButtonStyle,
        filter: props.blur ? 'blur(1px)' : '',
      }}
      value={false}
    >
      {tabs.map((t, index) => (
        <Tab
          key={index}
          ref={t.ref}
          sx={{ ...AvatarTabButtonStyle }}
          label={t.label}
          onClick={t.onClick}
          icon={<FontAwesomeIcon color={t.color} size="2x" icon={t.icon} />}
        />
      ))}
    </Tabs>
  );
};

export default AvatarOptionTabs;
