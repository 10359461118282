import { FrontendFormImportField } from '@casecare/types';
import {
  faEye,
  faPlus,
  faTrash,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useMediaQuery,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  TableBody,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { FormEditDatagridElement } from '../../../types';
import { theme } from '../../../utils';
import { drawerWidthNumber, drawerWidthSpaced } from '../../../utils/constants';
import formTypeTranslation from '../../../utils/i18n/form-field-type-util';
import { MovementButtons } from '../../core';

interface FormEditTableProps {
  elements: FrontendFormImportField[];
  onAddElement: () => void;
  onEditElement: (id: any) => void;
  onDeleteElement: (id: any) => void;
  onMoveElement: (up: boolean, elementId: any, allTheWay?: boolean) => void;
  onOpenPreview?: () => void;
  updateElements?: (
    elements: FrontendFormImportField[],
    changedIds: string[],
  ) => void;
  showPreview?: boolean;
  formGroup?: boolean;
  multiData?: boolean;
}

const FormEditTable: FC<FormEditTableProps> = (props) => {
  const context = useContext(AppContext);
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const headerStyle = { fontWeight: 500, fontSize: 20, color: 'primary' };
  const bodyStyle = { fontSize: 17 };

  const [rows, setRows] = useState<FormEditDatagridElement[]>([]);
  const [formData, setFormData] = useState<FrontendFormImportField[]>(
    props.elements
      ? props.elements.sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1))
      : [],
  );

  useEffect(() => {
    setFormData(
      props.elements
        ? props.elements.sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1))
        : [],
    );
  }, [props.elements]);

  useEffect(() => {
    if (formData && formData.length > 0) {
      mapRows();
    }
  }, [formData]);

  const mapRows = () => {
    setRows(
      formData
        .flatMap((element) => {
          if (element.delete) {
            return [];
          } else {
            return {
              id: element.id,
              name: element.label,
              seq: element.seq,
              type: formTypeTranslation(element.type ? element.type : 0),
              isListTitle: element.isListTitle,
              isListSubtitle: element.isListSubtitle,
            };
          }
        })
        .sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1)),
    );
  };

  const moveElement = (up: boolean, elementId: any, allTheWay?: boolean) => {
    props.onMoveElement(up, elementId, allTheWay);
    setTimeout(() => {
      mapRows();
    }, 10);
  };

  const setTitle = (id: string, setTitle: boolean) => {
    const changedIds: string[] = [];
    setFormData((prevFormdata) => {
      const newFormData = prevFormdata.map((data) => {
        if (data.id === id) {
          changedIds.push(id);
          return { ...data, isListTitle: setTitle, isListSubtitle: false };
        }
        if (data.isListTitle) {
          changedIds.push(data.id ? data.id : '');
        }
        return { ...data, isListTitle: false };
      });

      props.updateElements
        ? props.updateElements(newFormData, changedIds)
        : null;

      return newFormData;
    });
  };

  const setSubTitle = (id: string, setSubTitle: boolean) => {
    setFormData((prevFormdata) => {
      const changedIds: string[] = [];
      const newFormData = prevFormdata.map((data) => {
        if (data.id === id) {
          changedIds.push(id);
          return { ...data, isListSubtitle: setSubTitle, isListTitle: false };
        }
        if (data.isListSubtitle) {
          changedIds.push(data.id ? data.id : '');
        }
        return { ...data, isListSubtitle: false };
      });

      props.updateElements
        ? props.updateElements(newFormData, changedIds)
        : null;

      return newFormData;
    });
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: !props.formGroup ? `calc(100% - 32px)` : '95%',
        ml: 2,
        height: '90%',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...headerStyle, minWidth: 150 }} color="primary">
              {context.i18n.name}
            </TableCell>
            <TableCell sx={{ ...headerStyle }} color="primary">
              {context.i18n.type}
            </TableCell>
            <TableCell sx={{ width: 70 }}></TableCell>
            {props.multiData && (
              <>
                <TableCell
                  sx={{ ...headerStyle }}
                  color="primary"
                  align="center"
                >
                  {context.i18n.title}
                </TableCell>
                <TableCell
                  sx={{ ...headerStyle }}
                  color="primary"
                  align="center"
                >
                  {context.i18n.subTitle}
                </TableCell>
              </>
            )}
            <TableCell sx={{ width: 40 }}>
              {!props.showPreview && (
                <Tooltip title={context.i18n.preview}>
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={() =>
                      props.onOpenPreview ? props.onOpenPreview() : null
                    }
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
            <TableCell sx={{ ...headerStyle }} color="primary" align="center">
              <Tooltip title={context.i18n.createElement}>
                <IconButton
                  disableRipple
                  color="primary"
                  onClick={() => props.onAddElement()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={bodyStyle}>{row.name}</TableCell>
              <TableCell sx={bodyStyle}>{row.type}</TableCell>
              <TableCell align="center">
                <MovementButtons
                  moveElement={moveElement}
                  id={row.id ? row.id : ''}
                  first={row.seq === 1}
                  last={row.seq === rows.length}
                />
              </TableCell>
              {props.multiData && (
                <>
                  <TableCell sx={bodyStyle} align="center">
                    <FormGroup>
                      <Checkbox
                        disableRipple
                        checked={row.isListTitle ? row.isListTitle : false}
                        onClick={() =>
                          setTitle(
                            row.id ? row.id : '',
                            row.isListTitle ? row.isListTitle : true,
                          )
                        }
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell sx={bodyStyle} align="center">
                    <FormGroup>
                      <Checkbox
                        disableRipple
                        checked={
                          row.isListSubtitle ? row.isListSubtitle : false
                        }
                        onClick={() =>
                          setSubTitle(
                            row.id ? row.id : '',
                            row.isListTitle ? row.isListTitle : true,
                          )
                        }
                      />
                    </FormGroup>
                  </TableCell>
                </>
              )}
              <TableCell align="center">
                <Tooltip title={context.i18n.editElement}>
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={() => props.onEditElement(row.id)}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                <Tooltip title={context.i18n.deleteElement}>
                  <IconButton
                    disableRipple
                    id={row.id + 'edit-btn'}
                    data-id={row.id + 'edit-btn'}
                    color="error"
                    onClick={() => props.onDeleteElement(row.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormEditTable;
