import {
  faFloppyDisk,
  faRefresh,
  faSquareArrowUpRight,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { useQuery } from 'react-query';
import { AdminApi, AdminBillingApi } from '../../../api';

interface LinkSevDeskContactDialogProps {
  open: boolean;
  onClose: () => void;
  orderer: any;
}

const LinkSevDeskContactDialog: FC<LinkSevDeskContactDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(props.open);

  const [sevDeskContact, setSevDeskContact] = useState<any>();
  const [sevDeskContacts, setSevDeskContacts] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.orderer.sevDeskContactId) {
      sevDeskContacts.some((c) => {
        if (c.id === props.orderer.sevDeskContactId) {
          setSevDeskContact(c);
          return true;
        }
      });
    } else {
      setSevDeskContact(null);
    }
  }, [props.orderer, sevDeskContacts]);

  const { refetch } = useQuery({
    queryKey: ['sevDeskContacts'],
    enabled: context.authToken !== undefined,
    queryFn: () => AdminBillingApi.getSevDeskContacts(context.authToken),
    onSuccess: (data) => setSevDeskContacts(data.data),
  });

  const onClose = async () => {
    refetch();
    props.onClose();
  };

  const onSave = async () => {
    try {
      const clear: string[] = [];
      if (!sevDeskContact) {
        if (props.orderer.sevDeskContactId) {
          clear.push('sevDeskContactId');
        } else {
          return;
        }
      }

      const body = Object.assign(
        {
          id: props.orderer.id,
          clear,
        },
        sevDeskContact && { sevDeskContactId: sevDeskContact.id },
      );

      if (Object.keys(body).length === 2 && body.clear.length === 0) return;
      setLoading(true);
      const res = await AdminApi.editHelpFormOrderer(context.authToken, body);

      if (res.message) {
        snackbarUtils.success(res.message);
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const onCreate = async () => {
    try {
      if (!props.orderer) return;
      const body = Object.assign({
        name: props.orderer.name,
        name2: props.orderer.additionalDetails,
        street: props.orderer.street,
        zipcode: props.orderer.zipcode,
        city: props.orderer.city,
        email: props.orderer.email,
        vatNumber: props.orderer.vatNumber,
      });
      setLoading(true);
      const res = await AdminBillingApi.editOrCreateSevDeskContact(
        context.authToken,
        body,
      );

      if (res.message) {
        await AdminApi.editHelpFormOrderer(context.authToken, {
          id: props.orderer.id,
          sevDeskContactId: res.data.sevDeskContactId,
        });

        snackbarUtils.success(res.message);
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const onSync = async () => {
    try {
      if (!props.orderer || !sevDeskContact) return;
      const body = Object.assign({
        id: sevDeskContact.id,
        name: props.orderer.name,
        name2: props.orderer.additionalDetails,
        street: props.orderer.street,
        zipcode: props.orderer.zipcode,
        city: props.orderer.city,
        email: props.orderer.email,
        vatNumber: props.orderer.vatNumber,
      });
      setLoading(true);
      const res = await AdminBillingApi.editOrCreateSevDeskContact(
        context.authToken,
        body,
      );

      if (res.message) {
        snackbarUtils.success(res.message);
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{context.i18n.sevDeskContactLink}</DialogTitle>
      <DialogContent>
        <Box>
          <Autocomplete
            onChange={(e, option: any) => {
              setSevDeskContact(option);
            }}
            value={sevDeskContact}
            getOptionLabel={(option: any) => option.name || ''}
            noOptionsText={context.i18n.existingContact}
            renderInput={(params) => (
              <TextField
                {...params}
                label={context.i18n.existingContact}
                required={true}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            isOptionEqualToValue={(o: any, v: any) => o.id === v.id}
            options={sevDeskContacts}
            sx={{ mt: 1 }}
          />
          {props.orderer.sevDeskContactId && (
            <Tooltip title={context.i18n.syncToSevDesk}>
              <IconButton
                disableRipple
                color="primary"
                id="invite-user-button"
                data-id="invite-user-button"
                disabled={loading}
                onClick={onSync}
              >
                <FontAwesomeIcon icon={faRefresh} />
              </IconButton>
            </Tooltip>
          )}
          {props.orderer.sevDeskContactId && sevDeskContact && (
            <Tooltip title={context.i18n.openInSevDesk}>
              <a
                href={`https://my.sevdesk.de/crm/detail/id/${props.orderer.sevDeskContactId}`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  disableRipple
                  color="primary"
                  id="invite-user-button"
                  data-id="invite-user-button"
                >
                  <FontAwesomeIcon icon={faSquareArrowUpRight} />
                </IconButton>
              </a>
            </Tooltip>
          )}
        </Box>
        {!sevDeskContact && (
          <>
            <Typography sx={{ textAlign: 'center', mt: 1, mb: 1 }} variant="h5">
              - {context.i18n.or} -
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button onClick={onCreate} variant="contained" disabled={loading}>
                {context.i18n.createContact}
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton
              onClick={onSave}
              disableRipple
              color="primary"
              disabled={
                loading ||
                (sevDeskContact &&
                  props.orderer.sevDeskContactId === sevDeskContact.id)
              }
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default LinkSevDeskContactDialog;
