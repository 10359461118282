import {
  AccordionDetails,
  Box,
  BoxProps,
  Grid,
  IconButton,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, LegacyRef, useContext, useEffect, useRef, useState } from 'react';
import EcoMapAvatarIcon from '../../eco-map-avatar-icon/eco-map-avatar-icon';
import { useDraggable } from '@dnd-kit/core';
import { ResizableBox } from 'react-resizable';
import { theme } from '../../../../utils';
import { Sex } from '@casecare/types';
import GenderIcon from '../../../core/gender-icon/gender-icon';
import EcoMapApi from '../../../../api/eco-map/eco-map';
import { AppContext } from '../../../../hooks/context';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import AvatarDeadIcon from '../../../avatar/avatar-dead-icon/avatar-dead-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquare,
  faStar,
  faCircle,
  faAngry,
} from '@fortawesome/free-regular-svg-icons';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import RectangleIcon from '../../../icons/rectangle-icon';
import CircleIcon from '../../../icons/circle-icon';
import StarIcon from '../../../icons/star-icon';
import OctagonIcon from '../../../icons/octagon-icon';
import TriangleIcon from '../../../icons/triangle-icon';
interface EcoMapIconDraggableProps extends BoxProps {
  position: any;
  id: string;
  setDisableDrag: (t: boolean) => void;
  disableDrag: boolean;
  disabled?: boolean;
  isIndex?: boolean;
  expertMode?: boolean;
  label?: string;
  small?: boolean;
}

const EcoMapIconDraggable: FC<EcoMapIconDraggableProps> = (props) => {
  const resizableBoxSpacing = '-15px';
  const resizableBoxMin = props.small ? 25 * 0.66 : 25;
  const resizableBoxMax = props.small
    ? resizableBoxMin * 2 * 0.66
    : resizableBoxMin * 2;

  const [currentSize, setCurrentSize] = useState(
    props.isIndex
      ? props.small
        ? '23.1px'
        : '35px'
      : resizableBoxMin * props.position.diameter + 'px',
  );

  const context = useContext(AppContext);
  const [showResizableBox, setShowResizableBox] = useState(false);
  const [showLabel, setShowLabel] = useState(false);

  const renderEcoMapExpert = () => {
    const style = props.position.client.isDead
      ? {
          fontSize: currentSize,
          color: 'grey',
        }
      : {
          fontSize: currentSize,
        };

    if (props.position.client.isRealPerson)
      switch (props.position.client.sex) {
        case Sex.MALE:
          return <RectangleIcon style={style} id={props.id} />;
        case Sex.FEMALE:
          return <CircleIcon style={style} id={props.id} />;
        case Sex.DIVERS:
          return <TriangleIcon style={style} id={props.id} />;
        default:
          return <OctagonIcon style={style} id={props.id} />;
      }
    else return <StarIcon style={style} id={props.id} />;
  };

  const { attributes, listeners, setNodeRef, transform, node } = useDraggable({
    id: props.position.id,
    data: props.position,
    disabled: props.isIndex || props.disabled,
  });
  const style =
    transform && !props.disableDrag
      ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        }
      : undefined;

  const fixedCenter = props.isIndex
    ? {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '& svg *': {
          fill: '#000000',
        },
      }
    : {};

  const saveSize = async (data: any) => {
    props.setDisableDrag(false);
    const diameter = data.size.width / resizableBoxMin;
    try {
      await EcoMapApi.editPosition(context.authToken, props.position.id, {
        diameter: diameter,
      });
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Box
      onMouseEnter={(event: any) => {
        setShowLabel(true);
      }}
      onMouseLeave={(event: any) => {
        setShowLabel(false);
      }}
      onFocus={(event: any) => {
        setShowResizableBox(true);
      }}
      onBlur={(event: any) => {
        setShowResizableBox(false);
      }}
      onTouchStart={(event: any) => {
        if (node.current) node.current.focus();
      }}
      sx={{
        position: 'absolute',
        left: `${props.small ? props.position.x * 0.66 : props.position.x}px`,
        top: `${props.small ? props.position.y * 0.66 : props.position.y}px`,
        zIndex: 15,
        touchAction: 'none',
        ...style,
        ...fixedCenter,
      }}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <ResizableBox
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onResizeStart={() => {
          props.setDisableDrag(true);
        }}
        onResize={(e: any, data: any) => {
          setCurrentSize(data.size.width + 'px');
        }}
        onResizeStop={(e: any, data: any) => saveSize(data)}
        width={resizableBoxMin * props.position.diameter}
        height={resizableBoxMin * props.position.diameter}
        maxConstraints={[resizableBoxMax, resizableBoxMax]}
        minConstraints={[resizableBoxMin, resizableBoxMin]}
        lockAspectRatio
        handle={(h: any, ref: any) => (
          <Box
            sx={{
              position: 'absolute',
              width: '8px',
              height: '8px',
              opacity: '0.75',
              borderRadius: '4px',
              backgroundColor: theme.palette.primary.main,
              '&.sw': {
                bottom: resizableBoxSpacing,
                left: resizableBoxSpacing,
                cursor: 'sw-resize',
              },
              '&.se': {
                bottom: resizableBoxSpacing,
                right: resizableBoxSpacing,
                cursor: 'se-resize',
              },
              '&.nw': {
                top: resizableBoxSpacing,
                left: resizableBoxSpacing,
                cursor: 'nw-resize',
              },
              '&.ne': {
                top: resizableBoxSpacing,
                right: resizableBoxSpacing,
                cursor: 'ne-resize',
              },
            }}
            component={'span'}
            className={`eco-map-icon-resizable ${h}`}
            ref={ref}
          />
        )}
        resizeHandles={
          !props.disabled && !props.isIndex && showResizableBox
            ? ['sw', 'se', 'nw', 'ne']
            : []
        }
      >
        <>
          {(showResizableBox || props.expertMode || showLabel) && props.label && (
            <Typography
              sx={{
                fontSize: props.small ? '0.5rem' : '0.7rem',
                width: '100px',
                textAlign: 'center',
                top: '-30px',
                position: 'absolute',
              }}
            >
              {props.label}
            </Typography>
          )}
          {props.position.client.avatar && !props.expertMode ? (
            <AvatarDeadIcon
              sx={{
                width: '100%',
                height: '100%',
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 100,
                p: 0.5,
                filter: props.position.client.isDead ? 'grayscale(1)' : 'none',
                borderColor: props.borderColor
                  ? props.borderColor
                  : 'primary.main',
                '& .MuiBadge-badge': {
                  transform: 'translate(50%, -25%)',
                  width: currentSize,
                  height: currentSize,
                  display:
                    props.position.client.isDead === false ? 'none' : 'block',
                },
              }}
              id={props.id}
            >
              <Box
                component="img"
                sx={{
                  filter: props.position.client.isDead
                    ? 'grayscale(1)'
                    : 'none',
                  width: '100%',
                  height: '100%',
                }}
                src={props.position.client.avatar.iconPath}
              />
            </AvatarDeadIcon>
          ) : (
            renderEcoMapExpert()
          )}
        </>
      </ResizableBox>
    </Box>
  );
};

export default EcoMapIconDraggable;
