import { ReactElement, useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { HistoryBrowserRouter } from '../utils';
import { appHistory, isLocalhost } from '../utils/constants';
import {
  ClientDetails,
  ClientList,
  FormEdit,
  FormList,
  CreateOrg,
  Links,
  Login,
  ResetPassword,
  FamilyList,
  FamilyDetails,
  Abo,
  OrganizationData,
  AvatarDetails,
  AvatarEditor,
  EcoMapDetails,
  MobileClientList,
  MobileProtocols,
  PlanningDashboard,
  Billing,
  GenoGramm,
  AdminBilling,
  Contacts,
  Products,
  TeamDashboard,
} from '../views';
import ClientArchive from '../views/admin/client-archive/client-archive';
import UserList from '../views/admin/user-list/user-list';
import AdminRoute from './admin-route';
import AuthenticatedRoute from './authenticated-route';
import UnauthenticatedRoute from './unauthenticated-route';
import MobileRoute from './mobile-root';
import NonMobileRoute from './non-mobile-route';
import EcoMapPrint from '../views/eco-map/eco-map-print/eco-map-print';
import EcoMapCompare from '../views/eco-map/eco-map-compare/eco-map-compare';
import HelpForm from '../views/admin/help-form/help-form';
import { UserGroups } from '../views/admin/user-groups/user-groups';
import { AppContext } from '../hooks/context';
import { Box } from '@mui/material';
import AddMobileProtocol from '../views/mobile/add-protocol/add-protocol';
import HelpFormOrderer from '../views/admin/help-form-orderer/help-form-orderer';
import StorageList from '../views/storage/storage-list/storage-list';
import BetaBanner from '../components/core/beta-banner/beta-banner';
import { BillingType } from '../types/billing-type';

const App: React.FC = (): ReactElement => {
  const { user } = useContext(AppContext);
  const baseUrl = isLocalhost ? 'localhost' : 'CC_ENV_BASE_URL';

  useEffect(() => {
    if (user) {
      const orgHostname = `${user.subdomain}.${baseUrl}`;
      if (!isLocalhost) {
        if (window.location.hostname !== orgHostname) {
          window.location.replace(
            `${window.location.protocol}//${orgHostname}${window.location.pathname}`,
          );
        }
      }
    }
  }, [user]);

  return (
    <span>
      <HistoryBrowserRouter history={appHistory}>
        <Box
          sx={{ pb: process.env.REACT_APP_BETA_TEST_BANNER ? '40px' : '0px' }}
        >
          <Routes>
            <Route path="*" element={<AuthenticatedRoute />}>
              <Route path="mobile" element={<MobileRoute />}>
                <Route path="clients" element={<MobileClientList />} />
                <Route
                  path="protocols/:id/:isFamily"
                  element={<MobileProtocols />}
                />
                <Route
                  path="protocols/a/:id/:isFamily"
                  element={<AddMobileProtocol />}
                />
                <Route path="protocols/a/:id" element={<AddMobileProtocol />} />
                <Route path="protocols/:id" element={<MobileProtocols />} />
                <Route path="*" element={<Navigate to="clients" />} />
              </Route>
              <Route path="*" element={<NonMobileRoute />}>
                <Route path="todo" element={<PlanningDashboard />} />
                <Route path="todo-team" element={<TeamDashboard />} />
                <Route path="clients">
                  <Route path=":clientId/:tab" element={<ClientDetails />} />
                  <Route
                    path=":clientId/:tab/:subtab"
                    element={<ClientDetails />}
                  />
                  <Route
                    path="referencePerson/:clientId/:tab"
                    element={<ClientDetails isReferencePerson />}
                  />
                  <Route path="*" element={<ClientList />} />
                </Route>
                <Route path="families">
                  <Route path=":familyId/:tab" element={<FamilyDetails />} />
                  <Route
                    path=":familyId/:tab/:subtab"
                    element={<FamilyDetails />}
                  />
                  <Route path="*" element={<Navigate to="../../clients" />} />
                </Route>
                <Route path="geno-gramm/:clientId">
                  <Route path="*" element={<GenoGramm />} />
                </Route>
                <Route path="avatar">
                  <Route path="details">
                    <Route
                      path=":indexId/:clientId"
                      element={<AvatarDetails />}
                    />
                    <Route path=":indexId" element={<AvatarDetails />} />
                  </Route>
                  <Route
                    path="editor/:indexId/:clientId"
                    element={<AvatarEditor />}
                  />
                </Route>
                <Route path="eco-map">
                  <Route
                    path="details/:clientId/:indexId/"
                    element={<EcoMapDetails />}
                  />
                  <Route
                    path="print/:clientId/:indexId/"
                    element={<EcoMapPrint />}
                  />
                  <Route
                    path="compare/:clientId/"
                    element={<EcoMapCompare />}
                  />
                </Route>

                <Route path="billing">
                  <Route
                    path="review"
                    element={<Billing type={BillingType.REVIEW} />}
                  />
                  <Route
                    path="released"
                    element={<Billing type={BillingType.RELEASED} />}
                  />
                  <Route
                    path="rejected"
                    element={<Billing type={BillingType.REJECTED} />}
                  />
                  <Route path="*" element={<Navigate to="review" />} />
                </Route>

                <Route path="admin" element={<AdminRoute />}>
                  <Route path="users" element={<UserList />} />
                  <Route path="categories">
                    <Route
                      path=":categoryId/:subCategoryId"
                      element={<FormEdit />}
                    />
                    <Route path="*" element={<FormList />} />
                  </Route>
                  <Route path="help-form" element={<HelpForm />} />
                  <Route
                    path="help-form/orderer"
                    element={<HelpFormOrderer />}
                  />
                  <Route path="groups" element={<UserGroups />} />
                  <Route path="archive" element={<ClientArchive />} />
                  <Route path="billing">
                    <Route path="contacts" element={<Contacts />} />
                    <Route path="products" element={<Products />} />
                    <Route path="*" element={<AdminBilling />} />
                  </Route>
                  <Route path="abo" element={<Abo />} />
                  <Route path="data" element={<OrganizationData />} />

                  <Route path="*" element={<Navigate to="users" />} />
                </Route>

                <Route path="storage">
                  <Route
                    path=":storageItemFolderTypeId"
                    element={<StorageList />}
                  />
                  <Route
                    path=":storageItemFolderTypeId/:storageItemFolderId"
                    element={<StorageList />}
                  />
                  <Route path="*" element={<Navigate to="../../clients" />} />
                </Route>
                <Route path="*" element={<Navigate to="clients" />} />
              </Route>

              <Route path="*" element={<Navigate to="clients" />} />
            </Route>
            <Route path="*" element={<UnauthenticatedRoute />}>
              <Route path="createOrg" element={<CreateOrg />} />
              <Route path="link/:type/:id" element={<Links />} />
              <Route path="login" element={<Login />} />
              <Route path="login/expired" element={<Login expired />} />
              <Route path="resetPassword" element={<ResetPassword />} />
              <Route path="*" element={<Navigate to="login" />} />
            </Route>
          </Routes>
        </Box>
        <BetaBanner />
      </HistoryBrowserRouter>
    </span>
  );
};

export default App;
