export interface RiskAssessmentQuestion {
  question: string;
  id: number;
  ageGroups: {
    title: string;
    description: string;
  }[];
  type: RiskAssessmentQuestionType;
  options: {
    label: string;
    value: number;
  }[];
}

export enum RiskAssessmentQuestionType {
  EXTREME = 'EXTREME',
  INFO = 'INFO',
}
