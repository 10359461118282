import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';
import { faMagnifyingGlass, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../../utils';

interface ClientFilterToolBarProps {
  searchText: string;
  setSearchText: (searchText: string) => void;
}

const ClientFilterToolBar: FC<ClientFilterToolBarProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Box
      display="flex"
      sx={{
        p: 1,
        mt: 1,
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <TextField
        color="secondary"
        placeholder={context.i18n.clientSearch}
        value={props.searchText}
        onChange={(e) => props.setSearchText(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{ color: theme.palette.secondary.main }}
              position="end"
            >
              <FontAwesomeIcon
                onClick={() => props.setSearchText('')}
                icon={faX}
              />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment
              sx={{ color: theme.palette.secondary.main }}
              position="start"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ClientFilterToolBar;
