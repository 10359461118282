import { createBrowserHistory } from 'history';

export const appHistory = createBrowserHistory();
export const authCookieName = 'CC_ENV_AUTHCOOKIE';
export const hideOnSmallScreen = {
  xs: 'none',
  sm: 'none',
  md: 'inline-flex',
  lg: 'inline-flex',
  xl: 'inline-flex',
};
export const showOnlySmallScreen = {
  xs: 'inline-flex',
  sm: 'inline-flex',
  md: 'none',
  lg: 'none',
  xl: 'none',
};
export const hideOnExtraSmallScreen = {
  xs: 'none',
  sm: 'inline-flex',
  md: 'inline-flex',
  lg: 'inline-flex',
  xl: 'inline-flex',
};
export const titleSuffix = 'CaseCare';

export const drawerWidthNumber = 240;

export const drawerWidth = {
  xs: '0px',
  sm: '0px',
  md: '260px',
  lg: '260px',
  xl: '260px',
};

export const drawerWidthConst = '276px';

export const drawerWidthSpaced = {
  xs: '0px',
  sm: '0px',
  md: drawerWidthConst,
  lg: drawerWidthConst,
  xl: drawerWidthConst,
};

export const isLocalhost =
  window.location.hostname === 'localhost' ||
  window.location.hostname.includes('192.168.');

export const passwordSpecialCharacterRegex = new RegExp('(?=.*?[#?!@$%^&*-])');
export const passwordNumberRegex = new RegExp('(?=.*?[0-9])');
export const passwordUppercaseRegex = new RegExp('(?=.*?[A-Z])');
export const passwordLowercaseRegex = new RegExp('(?=.*?[a-z])');
