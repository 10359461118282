import { faCaretDown, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { theme } from '../../../../utils';
import { ContactTable } from '../contatc-table/contact-table';
import { EditOrganizationContactDialog } from '../../../dialogs';

interface ContactAccordionProps {
  name: string;
  id: string;
}

export const ContactAccordion: FC<ContactAccordionProps> = (props) => {
  const [open, setOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Accordion expanded={open} sx={{ m: 2, p: 2 }}>
      <AccordionSummary
        expandIcon={
          <FontAwesomeIcon
            size="2x"
            color={theme.palette.primary.main}
            icon={faCaretDown}
            onClick={() => setOpen(!open)}
          />
        }
      >
        <Typography>
          {props.name}

          <IconButton
            color="primary"
            disableRipple
            onClick={(e) => setOpenDialog(true)}
          >
            <FontAwesomeIcon icon={faPen} />
          </IconButton>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ContactTable orgId={props.id} />
      </AccordionDetails>
      <EditOrganizationContactDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        contactId={props.id}
      />
    </Accordion>
  );
};
