import { axiosInstance } from '../../../utils';

export default class TodoApi {
  static getTodos(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post(`planning/todo/get`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveTodo(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('planning/todo', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getTeamTodos(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post(`planning/todo/team/get`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveTeamTodo(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('planning/todo/team', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteTeamTodo(
    token: string | undefined,
    id: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .delete('planning/todo/team/' + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateTodo(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('planning/todo/change', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static newTodos(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('planning/todo/new', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
