import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Tooltip,
  IconButton,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../hooks/context';

interface AddClientHelpFormDialogProps {
  onClose: () => void;
  onAdd: (id: string) => void;
  helpForms: any;
}

const AddClientHelpFormDialog: FC<AddClientHelpFormDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [selected] = useState('');

  const onSelect = (tab: string) => {
    props.onAdd(tab);
    props.onClose();
  };

  return (
    <Box>
      <DialogTitle>{context.i18n.allocateClientHelpForm}</DialogTitle>
      <DialogContent>
        <FormControl color="secondary" sx={{ mt: 2, width: '22rem' }}>
          <InputLabel
            color="secondary"
            sx={{ alignSelf: 'center' }}
            id="add-help-form-select-label"
          >
            {context.i18n.helpForm}
          </InputLabel>
          <Select
            labelId="add-help-form-select-label"
            label={context.i18n.categories}
            value={selected}
            id="add-help-form-select"
          >
            {props.helpForms.map((helpForm: any) => (
              <MenuItem
                key={helpForm.id}
                value={helpForm.id}
                onClick={() => {
                  onSelect(helpForm.id);
                }}
              >
                {helpForm.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose.bind(this)} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Box>
  );
};

export default AddClientHelpFormDialog;
