import { LinkData } from '../../types';
import { ResendLink } from '../../types/link-dto';
import { axiosInstance } from '../../utils';

export default class LinksApi {
  static checkValid(body: LinkData): Promise<any> {
    return axiosInstance
      .get('/link/valid/' + body.type + '/' + body.id)
      .then((res) => {
        return res.data;
      });
  }

  static send(body: ResendLink): Promise<any> {
    return axiosInstance.post('/link/send', body).then((res) => {
      return res.data;
    });
  }

  static confirmEmail(id: string): Promise<any> {
    return axiosInstance.get('/link/EMAILCONFIRMATION/' + id).then((res) => {
      return res.data;
    });
  }
}
