import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Slide, Dialog } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { FormApi } from '../../../../../api';
import { AppContext } from '../../../../../hooks/context';
import snackbarUtils from '../../../../../utils/snackbar/snackbar-utils';
import { FormGroupDialog, DiscardDialog } from '../../../../dialogs';
import { FormListProps, FormListElement } from './form-list';
import FormListSlide from './form-list-slide';
import { FormGroupElement } from '../../../../../types';

const FormListSlides: FC<FormListProps> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [slide, setSlide] = useState(true);
  const [formGroups, setFormGroups] = useState(props.values);
  const [activeGroup, setActiveGroup] = useState<FormListElement>(
    props.values[0],
  );
  const [right, setRight] = useState(true);
  const [index, setIndex] = useState(0);
  const [primaryActive, setPrimaryActive] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (!props.addedDataset) {
      if (props.values[index]) {
        setActiveGroup(props.values[index]);
      } else {
        setActiveGroup(props.values[0]);
        setIndex(0);
      }
    }
  }, [props.values[index]]);

  useEffect(() => {
    if (props.addedDataset) {
      const newDataset = props.template;

      if (newDataset) {
        newDataset.children = newDataset.children.map(
          (child: FormGroupElement) => {
            return {
              ...child,
              value: '',
              dataset: props.values.length,
            };
          },
        );
        newDataset.dataset = props.values.length;

        setIndex(props.values.length);
        setSlide(!slide);
        setPrimaryActive(!primaryActive);
        setRight(false);
        setActiveGroup(newDataset);
        setTimeout(() => {
          setOpen(true);
        }, 100);
      }
    }
  }, [props.addedDataset]);

  useEffect(() => {
    setFormGroups(props.values);
  }, [props.values]);

  const onSave = async () => {
    const groupData = context.getAndRemoveGroupData(props.id).map((data) => {
      return {
        ...data,
        dataset: activeGroup.dataset ? +activeGroup.dataset : 0,
      };
    });

    if (groupData) {
      let errorMessage = '';
      groupData.map((data) => {
        if (data.error !== null) {
          errorMessage += data.error + '\n';
        }
      });

      if (errorMessage === '') {
        if (props.onSave) {
          props.onSave(groupData);
          setOpen(false);
          setDiscard(false);
        } else {
          try {
            const res = await FormApi.updateFormFields(
              context.clientId,
              context.authToken,
              groupData,
            );
            if (res.message) snackbarUtils.success(res.message);
            queryClient.invalidateQueries('formData');

            setOpen(false);
            setDiscard(false);
          } catch (e) {
            snackbarUtils.error(context.i18n.error);
          }
        }
      }
    }

    setUpdate(!update);
  };

  const onDiscard = () => {
    context.removeGroupData(props.id);

    setOpen(false);
    setDiscard(false);
  };

  const onClose = () => {
    if (context.getAndRemoveGroupData(props.id).length > 0) {
      setDiscard(true);
    } else {
      setOpen(false);
    }
  };

  const onLeftArrow = () => {
    setIndex(index - 1);
    setSlide(!slide);
    setPrimaryActive(!primaryActive);
    setRight(false);
    setActiveGroup(formGroups[index - 1]);
  };

  const onRightArrow = () => {
    setIndex(index + 1);
    setSlide(!slide);
    setPrimaryActive(!primaryActive);
    setRight(true);
    setActiveGroup(formGroups[index + 1]);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={1}
        sx={{ display: 'flex' }}
        alignItems="center"
        justifyContent="center"
      >
        <IconButton disabled={index === 0} onClick={onLeftArrow.bind(this)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </IconButton>
      </Grid>

      {right ? (
        <Grid id={props.id} item xs={10} sx={{ overflow: 'hidden' }}>
          {!slide ? (
            <Slide
              unmountOnExit
              mountOnEnter
              direction={primaryActive ? 'left' : 'right'}
              in={!slide}
            >
              <div>
                <FormListSlide
                  element={activeGroup}
                  onOpen={() => setOpen(true)}
                  update={update}
                  preview={props.preview}
                />
              </div>
            </Slide>
          ) : null}
          {slide ? (
            <Slide
              unmountOnExit
              mountOnEnter
              direction={primaryActive ? 'right' : 'left'}
              in={slide}
            >
              <div>
                <FormListSlide
                  element={activeGroup}
                  onOpen={() => setOpen(true)}
                  update={update}
                  preview={props.preview}
                />
              </div>
            </Slide>
          ) : null}
        </Grid>
      ) : (
        <Grid item xs={10} sx={{ overflow: 'hidden' }}>
          {!slide ? (
            <Slide
              unmountOnExit
              mountOnEnter
              direction={primaryActive ? 'right' : 'left'}
              in={!slide}
            >
              <div>
                <FormListSlide
                  element={activeGroup}
                  onOpen={() => setOpen(true)}
                  update={update}
                  preview={props.preview}
                />
              </div>
            </Slide>
          ) : null}

          {slide ? (
            <Slide
              unmountOnExit
              mountOnEnter
              direction={primaryActive ? 'left' : 'right'}
              in={slide}
            >
              <div>
                <FormListSlide
                  element={activeGroup}
                  onOpen={() => setOpen(true)}
                  update={update}
                  preview={props.preview}
                />
              </div>
            </Slide>
          ) : null}
        </Grid>
      )}
      <Grid item xs={1} sx={{ display: 'flex' }} alignItems="center">
        <IconButton
          disabled={index === formGroups.length - 1}
          onClick={onRightArrow.bind(this)}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconButton>
      </Grid>
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <FormGroupDialog
          id={props.id}
          elements={activeGroup.children}
          onSave={onSave}
          onClose={onClose}
          title={props.title}
        />
      </Dialog>
      <Dialog open={discard} onClose={() => setDiscard(false)}>
        <DiscardDialog
          onDiscard={onDiscard}
          onSave={onSave}
          onClose={() => setDiscard(false)}
        />
      </Dialog>
    </Grid>
  );
};

export default FormListSlides;
