import { Box, CircularProgress, Paper } from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientApi } from '../../../api';
import FamilyApi from '../../../api/family/family';
import { ProtocolTable } from '../../../components/client/documentation';
import { PermissionsTable } from '../../../components/client/permissions';
import {
  AddFamilyMemberDialog,
  CreateClientDialog,
} from '../../../components/dialogs';
import {
  ArchivedSubTabs,
  FamilyData,
  FamilyMemberTable,
  FamilyTabs,
} from '../../../components/family';
import { AppContext } from '../../../hooks/context';
import { useSavePrompt } from '../../../hooks/useBlocker';
import { Family, FamilyTabType } from '../../../types';
import { loadTitle, queryClient, setDocumentTitle } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { MenuWrapper } from '../../../components/core';
import TodoList from '../../../components/planning/todo-list/todo-list';
import SubTabNav from '../../../components/core/sub-tab-nav/sub-tab-nav';
import TabPanel from '../../../components/core/tab-panel/tab-panel';
import { FamilySubTabType } from '../../../types/family-sub-tab-type';
import { FamilyHelpForm } from '../family-help-form/family-help-form';
import { BreadCrumbSegment } from '../../../types/breadcrumb-segment';

const FamilyDetails: FC = () => {
  const context = useContext(AppContext);
  const { familyId, tab, subtab } = useParams();
  const navigate = useNavigate();

  const [family, setFamily] = useState<Family>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saved, setSaved] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [referencePersons, setReferencePersons] = useState([]);
  const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
  const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
  const [isReferencePerson, setIsReferencePerson] = useState(false);
  const [clientId, setClientId] = useState();
  const [selected, setSelected] = useState<string>('');
  const [selectedSubTab, setSelectedSubTab] = useState(subtab || '');

  const subTabNav = {
    [FamilyTabType.FAMILY_DATA]: [
      {
        id: FamilySubTabType.FAMILY_DATA,
        name: `${context.i18n.familyTabFamilyDataFamilyData}`,
      },
      {
        id: FamilySubTabType.FAMILY_HELPFORM,
        name: `${context.i18n.familyTabFamilyDataFamilyHelpform}`,
      },
    ],
  };

  useSavePrompt(
    () => setSaved(true),
    () => setUnsavedChanges(false),
    unsavedChanges,
  );

  const familyData = useQuery(
    ['family', familyId],
    () => FamilyApi.getFamily(context.authToken, familyId),
    {
      enabled: context.authToken !== undefined && familyId !== undefined,
      onSuccess: (res) => {
        setUnsavedChanges(false);
        setFamily(res.data.data);
        setFamilyMembers(res.data.familyMembers);
        setReferencePersons(res.data.referencePersons);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useEffect(() => {
    if (family) {
      setDocumentTitle(family?.name);
    }
  }, [family]);

  useEffect(() => {
    setSelected(tab ?? '');
  }, [tab]);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
  }, [saved]);

  useEffect(() => {
    return () => loadTitle();
  }, []);

  const onReactivate = async (id: any) => {
    const res = await ClientApi.updateClientArchiveState(
      context.authToken,
      id,
      false,
    );
    queryClient.invalidateQueries('family');

    if (res.message) snackbarUtils.success(res.message);
  };

  useEffect(() => {
    // custom breadcrumb //
    const breadCrumb: BreadCrumbSegment[] = [];
    breadCrumb.push({
      path: '/clients',
      name: context.i18n.siteTitleClients,
    });
    breadCrumb.push({
      path: `/families/${family?.id}/familyData`,
      name: family?.name ?? '',
    });
    const path = `/clients/${clientId}/${selected}`;
    const i18nKey =
      'familyTab' +
      `${(selected.charAt(0).toUpperCase() + selected.slice(1)).replace(
        /[_-]/g,
        '',
      )}`;
    breadCrumb.push({
      path: path,
      name: context.i18n[i18nKey],
    });
    if (selectedSubTab) {
      breadCrumb.push({
        path: `${path}/${selectedSubTab}`,
        name: context.i18n[
          `${i18nKey}${(
            selectedSubTab.charAt(0).toUpperCase() + selectedSubTab.slice(1)
          ).replace(/[_-]/g, '')}`
        ],
      });
    }
    context.setBreadCrumbSegments(breadCrumb);
  }, [family, selected, selectedSubTab]);

  return (
    <MenuWrapper
      title={family ? family.name : ''}
      subTitle={context.i18n.editFamily}
      selected="client"
      search
      addClient
      customBreadCrumb
    >
      <FamilyTabs
        selected={selected}
        familyId={familyId || ''}
        onSave={() => {
          setSaved(true);
          setUnsavedChanges(false);
        }}
        onDiscard={() => {
          setUnsavedChanges(false);
          queryClient.invalidateQueries('family');
        }}
        onChange={(id) => setSelected(id)}
        showSave={unsavedChanges}
      />
      {/* {showArchived ? (
        <ArchivedSubTabs
          showReferencePersons={showReferencePersons}
          onChangeTab={(show) => setShowReferencePersons(show)}
        />
      ) : (
        <Box sx={{ height: 10, mr: 3, ml: 3, bgcolor: 'primary.main' }} />
      )} */}
      <TabPanel
        value={selected}
        index={FamilyTabType.TODO}
        defaultSubTab={() => setSelectedSubTab('')}
      >
        <SubTabNav subTabs={[]}></SubTabNav>
        <Paper
          sx={{
            maxHeight: '80vh',
            m: 3,
            mt: 0,
            flexGrow: 1,
            overflowY: 'auto',
            borderRadius: 0,
            p: 5,
          }}
        >
          <TodoList
            clientId={clientId}
            title={context.i18n.todoListTitle}
            done={false}
          />
          <TodoList
            clientId={clientId}
            title={context.i18n.doneListTitle}
            done={true}
          />
        </Paper>
      </TabPanel>
      <TabPanel
        value={selected}
        index={FamilyTabType.FAMILY_DATA}
        defaultSubTab={() =>
          setSelectedSubTab(subtab || FamilySubTabType.FAMILY_DATA)
        }
      >
        <SubTabNav
          subTabs={subTabNav[FamilyTabType.FAMILY_DATA]}
          setSelected={setSelectedSubTab}
          selected={selectedSubTab}
        />
        <TabPanel index={FamilySubTabType.FAMILY_DATA} value={selectedSubTab}>
          {family && (
            <FamilyData
              onChanges={(changes) => setUnsavedChanges(changes)}
              family={family}
              saved={saved}
              familyMembers={familyMembers}
            />
          )}
        </TabPanel>
        <TabPanel
          index={FamilySubTabType.FAMILY_HELPFORM}
          value={selectedSubTab}
        >
          <FamilyHelpForm familyId={familyId || ''} />
        </TabPanel>
      </TabPanel>
      <TabPanel value={selected} index={FamilyTabType.FAMILY_MEMBERS}>
        <SubTabNav subTabs={[]}></SubTabNav>
        <Paper
          sx={{
            m: 3,
            mt: 0,
            flexGrow: 1,
            borderRadius: 0,
          }}
        >
          {familyMembers && (
            <FamilyMemberTable
              familyMembers={familyMembers}
              isReferencePersonTable={false}
              onArchiveClient={() => queryClient.invalidateQueries('family')}
              onCreateClient={() => {
                setCreateClientDialogOpen(true);
                setIsReferencePerson(false);
              }}
              onAddClient={() => {
                setAddClientDialogOpen(true);
              }}
              onOpenClientDialog={(id) => {
                setClientId(id);
                setCreateClientDialogOpen(true);
              }}
            />
          )}
        </Paper>
      </TabPanel>
      <TabPanel value={selected} index={FamilyTabType.REFERENCE_PERSONS}>
        <SubTabNav subTabs={[]}></SubTabNav>
        <Paper
          sx={{
            m: 3,
            mt: 0,
            flexGrow: 1,
            borderRadius: 0,
          }}
        >
          {referencePersons && (
            <FamilyMemberTable
              isReferencePersonTable
              onArchiveClient={() => queryClient.invalidateQueries('family')}
              familyMembers={referencePersons}
              onCreateClient={() => {
                setCreateClientDialogOpen(true);
                setIsReferencePerson(true);
              }}
              onOpenClientDialog={(id) => {
                setClientId(id);
                setCreateClientDialogOpen(true);
              }}
            />
          )}
        </Paper>
      </TabPanel>
      <TabPanel value={selected} index={FamilyTabType.DOCUMENTATION}>
        <SubTabNav subTabs={[]}></SubTabNav>
        {family && (
          <ProtocolTable
            familyId={familyId}
            familyIds={[family.id]}
            supervisorIds={family.data.SUPERVISOR}
            name={family.name}
          />
        )}
      </TabPanel>
      <TabPanel value={selected} index={FamilyTabType.PERMISSIONS}>
        <SubTabNav subTabs={[]}></SubTabNav>
        <PermissionsTable familyId={familyId} />
      </TabPanel>
      <AddFamilyMemberDialog
        open={addClientDialogOpen}
        onClose={() => setAddClientDialogOpen(false)}
        familyId={familyId}
        onAddClient={() => {
          setAddClientDialogOpen(false);
          queryClient.invalidateQueries('family');
        }}
      />
      <CreateClientDialog
        open={createClientDialogOpen}
        isReferencePerson={isReferencePerson}
        // initialFamilies={family ? [family] : []}
        // selectedFamilies={family ? [family] : []}
        onClose={() => {
          queryClient.invalidateQueries('family');
          setCreateClientDialogOpen(false);
        }}
        clientFamily={family}
        clientId={clientId}
        onFinish={() => queryClient.invalidateQueries('family')}
        disableFamilyToggle
      />
    </MenuWrapper>
  );
};

export default FamilyDetails;
