import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface AvatarTemplateDialogProps {
  open: boolean;
  avatars: { url: string; id: string }[];
  templates: { url: string; id: string }[];
  onSelect: (id: string, isTemplate: boolean) => void;
}

const AvatarTemplateDialog: FC<AvatarTemplateDialogProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [shownAvatars, setShownAvatars] = useState<
    { url: string; id: string }[]
  >([]);
  const [tabValue, setTabeValue] = useState('templates');

  useEffect(() => {
    setOpen(props.open);
    setShownAvatars(props.templates);
  }, [props.open]);

  return (
    <Dialog fullScreen open={open}>
      <DialogTitle>Avatar Templates</DialogTitle>
      <DialogContent>
        <Tabs value={tabValue}>
          <Tab
            value="templates"
            label="Vorlagen"
            onClick={() => {
              setTabeValue('templates');
              setShownAvatars(props.templates);
            }}
          />
          <Tab
            value="avatars"
            label="Bereits erstellte Avatare"
            sx={{ display: props.avatars.length > 0 ? 'inherit' : 'none' }}
            onClick={() => {
              setTabeValue('avatars');
              setShownAvatars(props.avatars);
            }}
          />
        </Tabs>
        <Grid container>
          {shownAvatars.map((a) => (
            <Grid key={a.id} item xs={4}>
              <Button
                onClick={() => props.onSelect(a.id, tabValue === 'templates')}
              >
                <img src={a.url} width="50%" />
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AvatarTemplateDialog;
