import { LoginUser } from '../../types';
import { axiosInstance } from '../../utils';

export default class LoginApi {
  static login(body: LoginUser): Promise<any> {
    return axiosInstance.post('/auth/login', body).then((res) => {
      return res.data;
    });
  }
}
