import { ReferencePersonRole, RelationshipType } from '@casecare/types';
import {
  faArchive,
  faBoxArchive,
  faBoxOpen,
  faCirclePlay,
  faEye,
  faPenSquare,
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Tooltip,
  IconButton,
  Box,
  ListItemText,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Link,
  Stack,
  Autocomplete,
  TextField,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { FamilyMember, FamilyMemberDataGridElement } from '../../../types';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { ClientArchiveDialog } from '../../dialogs';

interface FamilyMemberTableProps {
  familyMembers: FamilyMember[];
  onCreateClient?: () => void;
  onAddClient?: () => void;
  isReferencePersonTable: boolean;
  isClientTable?: boolean;
  onAddRelation?: (id: any, type: RelationshipType) => void;
  onAddRole?: (id: any, type: ReferencePersonRole) => void;
  onOpenClientDialog?: (id: any) => void;
  onReactivateClient?: (id: any) => void;
  archived?: boolean;
  onArchiveClient?: () => void;
}

const FamilyMemberTable: FC<FamilyMemberTableProps> = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [rows, setRows] = useState<FamilyMemberDataGridElement[]>([]);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchor);
  const [archiveId, setArchiveId] = useState<any | undefined>(undefined);

  const onArchive = async () => {
    const res = await ClientApi.updateClientArchiveState(
      context.authToken,
      archiveId,
      true,
    );

    if (res.message) snackbarUtils.success(res.message);

    setArchiveId(undefined);
    props.onArchiveClient && props.onArchiveClient();
  };

  useEffect(() => {
    setRows(props.familyMembers);
  }, [props.familyMembers]);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: context.i18n.name,
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <Link
          onClick={() =>
            !props.archived &&
            !props.isReferencePersonTable &&
            navigate('/clients/' + params.id + '/clientData')
          }
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'role',
      headerName: context.i18n.role,
      flex: 1,
    },
    {
      field: 'relationship',
      headerName: context.i18n.relationShip,
      flex: 1,
      cellClassName: () => {
        return 'select';
      },
      renderCell: (param) => (
        <Autocomplete
          fullWidth
          disableClearable
          value={param.value}
          onChange={(evetn, value: any) => {
            props.onAddRelation && props.onAddRelation(param.id, value);
          }}
          options={Object.keys(RelationshipType)}
          getOptionLabel={(option) => context.i18n[option]}
          renderInput={(params) => <TextField {...params} />}
        />
      ),
    },
    {
      field: 'indexName',
      headerName: context.i18n.indexName,
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <Link
          onClick={() =>
            navigate('/clients/' + params.row.indexId + '/clientData')
          }
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box
          display={
            props.archived ||
            (props.isReferencePersonTable && !props.isClientTable)
              ? 'none'
              : 'inherit'
          }
        >
          <Tooltip title={context.i18n.addFamilyMember}>
            <IconButton
              disableRipple
              color="primary"
              id="add-family-member"
              data-id="add-family-member"
              onClick={(event) =>
                props.isReferencePersonTable
                  ? props.onCreateClient && props.onCreateClient()
                  : setMenuAnchor(event.currentTarget)
              }
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={() => setMenuAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transitionDuration={0}
          >
            <MenuItem
              id="add-existing-family"
              onClick={() => {
                setMenuAnchor(null);
                props.onAddClient && props.onAddClient();
              }}
            >
              <ListItemText>{context.i18n.addNewFamilyMember}</ListItemText>
            </MenuItem>

            <MenuItem
              id="create-new-family"
              onClick={() => {
                setMenuAnchor(null);
                props.onCreateClient && props.onCreateClient();
              }}
            >
              <ListItemText>{context.i18n.createNewFamilyMember}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      ),
      renderCell: (params) => (
        <Stack direction={'row'}>
          <Tooltip
            title={context.i18n.formatString(
              props.archived
                ? context.i18n.unArchiveClient
                : context.i18n.archiveClient,
              rows.find((r) => r.id === params.id)?.name,
            )}
          >
            <IconButton
              disableRipple
              color={'primary'}
              sx={{ height: 39 }}
              onClick={() => {
                if (props.archived) {
                  props.onReactivateClient &&
                    props.onReactivateClient(params.id);
                } else {
                  setArchiveId(params.id);
                }
              }}
            >
              <FontAwesomeIcon
                icon={props.archived ? faBoxOpen : faBoxArchive}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={context.i18n.formatString(
              context.i18n.openDetailsForClient,
              rows.find((r) => r.id === params.id)?.name,
            )}
          >
            <IconButton
              disableRipple
              color="primary"
              id={'edit-button-' + params.id}
              sx={{ height: 39 }}
              onClick={() =>
                props.onOpenClientDialog && props.onOpenClientDialog(params.id)
              }
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <DataGridPro
        sx={{
          fontSize: 17,
          borderRadius: 0,
          '& .select': {
            padding: 0,
          },
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        disableColumnSelector={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        columnVisibilityModel={{
          indexName:
            props.isReferencePersonTable && props.isClientTable === undefined,
          relationship:
            !props.isReferencePersonTable && props.isClientTable !== undefined,
          role: props.isReferencePersonTable,
        }}
        pageSizeOptions={[20]}
        columnBufferPx={4}
        autoHeight={true}
      />
      <ClientArchiveDialog
        archive
        onArchive={() => onArchive()}
        open={archiveId !== undefined}
        onClose={() => setArchiveId(undefined)}
        clientName={rows.find((r) => r.id === archiveId)?.name || ''}
      />
    </>
  );
};

export default FamilyMemberTable;
