import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement } from '../../client';
import { FormFieldTextType, FormFieldType } from '@casecare/types';
import { AdminApi } from '../../../api';
import { FormFieldStyle } from '../../../types/form-element-props';

interface CreateHelpFormDialogProps {
  open: boolean;
  selected: any;
  onClose: () => void;
}

const CreateHelpFormDialog: FC<CreateHelpFormDialogProps> = (props) => {
  const context = useContext(AppContext);
  const { selected } = props;
  const [open, setOpen] = useState(props.open);

  const [name, setName] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [street, setStreet] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (selected) {
      setName(selected.name);
      setVatNumber(selected.vatNumber);
      setStreet(selected.street);
      setAdditionalDetails(selected.additionalDetails);
      setZipcode(selected.zipcode);
      setCity(selected.city);
      setEmail(selected.email);
      setPhone(selected.phone);
    }
  }, [selected]);

  const onSave = async () => {
    try {
      const body = Object.assign(
        {
          id: selected.id,
          clear: [],
        },
        name && name !== selected.name && { name },
        vatNumber && vatNumber !== selected.vatNumber && { vatNumber },
        street && street !== selected.street && { street },
        additionalDetails &&
          additionalDetails !== selected.additionalDetails && {
            additionalDetails,
          },
        zipcode && zipcode !== selected.zipcode && { zipcode },
        city && city !== selected.city && { city },
        email && email !== selected.email && { email },
        phone && phone !== selected.phone && { phone },
      );

      if (
        selected.additionalDetails &&
        !additionalDetails &&
        selected.additionalDetails !== additionalDetails
      )
        body.clear.push('additionalDetails');
      if (selected.email && !email && selected.email !== email)
        body.clear.push('email');
      if (selected.phone && !phone) body.clear.push('phone');

      if (Object.keys(body).length === 2 && body.clear.length === 0) return;

      const res = await AdminApi.editHelpFormOrderer(context.authToken, body);

      if (res.message) {
        snackbarUtils.success(res.message);
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = async () => {
    setName('');
    setStreet('');
    setAdditionalDetails('');
    setZipcode('');
    setCity('');
    setEmail('');
    setPhone('');
    props.onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{context.i18n.editOrderer}</DialogTitle>
      <DialogContent>
        <FormElement
          dialog={true}
          hideExport
          type={FormFieldType.TEXT}
          value={name}
          title={context.i18n.name + ' *'}
          onChange={setName}
          id={'name'}
          key={'name'}
          disableGlobalOnChange
          style={FormFieldStyle.OUTLINED}
        />
        <FormElement
          dialog={true}
          hideExport
          type={FormFieldType.TEXT}
          value={vatNumber}
          title={context.i18n.vatNumber + ' *'}
          onChange={setVatNumber}
          id={'vatNumber'}
          key={'vatNumber'}
          disableGlobalOnChange
          style={FormFieldStyle.OUTLINED}
        />
        <FormElement
          dialog={true}
          hideExport
          type={FormFieldType.TEXT}
          value={street}
          title={context.i18n.street + ' *'}
          onChange={setStreet}
          id={'street'}
          key={'street'}
          disableGlobalOnChange
          style={FormFieldStyle.OUTLINED}
        />
        <FormElement
          dialog={true}
          hideExport
          type={FormFieldType.TEXT}
          value={additionalDetails}
          title={context.i18n.additionalDetails}
          onChange={setAdditionalDetails}
          id={'additionalDetails'}
          key={'additionalDetails'}
          disableGlobalOnChange
          style={FormFieldStyle.OUTLINED}
        />
        <FormElement
          dialog={true}
          hideExport
          type={FormFieldType.TEXT}
          value={zipcode}
          title={context.i18n.zipcode + ' *'}
          onChange={setZipcode}
          id={'zipcode'}
          key={'zipcode'}
          disableGlobalOnChange
          style={FormFieldStyle.OUTLINED}
        />
        <FormElement
          dialog={true}
          hideExport
          type={FormFieldType.TEXT}
          value={city}
          title={context.i18n.city + ' *'}
          onChange={setCity}
          id={'city'}
          key={'city'}
          disableGlobalOnChange
          style={FormFieldStyle.OUTLINED}
        />
        <FormElement
          dialog={true}
          hideExport
          type={FormFieldType.TEXT}
          dataType={FormFieldTextType.EMAIL}
          value={email}
          title={context.i18n.email}
          onChange={setEmail}
          id={'email'}
          key={'email'}
          disableGlobalOnChange
          style={FormFieldStyle.OUTLINED}
        />
        <FormElement
          dialog={true}
          hideExport
          type={FormFieldType.TEXT}
          dataType={FormFieldTextType.EMAIL}
          value={phone}
          title={context.i18n.phone}
          onChange={setPhone}
          id={'phone'}
          key={'phone'}
          disableGlobalOnChange
          style={FormFieldStyle.OUTLINED}
        />
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton
              onClick={onSave}
              disableRipple
              color="primary"
              disabled={
                name === selected.name &&
                vatNumber === selected.vatNumber &&
                street === selected.street &&
                additionalDetails === selected.additionalDetails &&
                zipcode === selected.zipcode &&
                city === selected.city &&
                email === selected.email &&
                phone === selected.phone
              }
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateHelpFormDialog;
