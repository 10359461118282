import { FC, useState, useEffect } from 'react';
import { FormElementProps, FormGroupElement } from '../../../../../types';
import FormList, { FormListElement } from '../form-list/form-list';

interface FormListMultiDataProps extends FormElementProps {
  value: FormGroupElement[];
}

const FormListMultiData: FC<FormListMultiDataProps> = (props) => {
  const [listDatasets, setListDatasets] = useState<FormListElement[]>([]);
  const [templateDataset, setTemplateDataset] = useState<any>();
  const [show, setShow] = useState(false);
  const [highestDataset, setHighestDataset] = useState(0);
  const [addedDataset, setAddedDataset] = useState(false);

  useEffect(() => {
    const mappedValues: any = [];
    props.value.forEach((child: FormGroupElement) => {
      if (Array.isArray(child.value)) {
        child.value.forEach((data: any) => {
          if (data.dataset || data.dataset === 0) {
            if (mappedValues[data.dataset]) {
              mappedValues[data.dataset].push({
                ...child,
                value: data.value,
                dataset: data.dataset,
              });
            } else {
              mappedValues[data.dataset] = [
                {
                  ...child,
                  value: data.value,
                  dataset: data.dataset,
                },
              ];
            }
            if (data.dataset > highestDataset) {
              setHighestDataset(data.dataset);
            }
          }
        });
      } else {
        if (mappedValues.length < 1 && !mappedValues[0]) {
          mappedValues[0] = [];
        }
        Object.keys(mappedValues).forEach((key: any) => {
          mappedValues[key].push({ ...child, dataset: key });
        });
      }
    });

    const mappedListChildren: any = [];

    Object.keys(mappedValues).forEach((key: any) => {
      if (!templateDataset) {
        setTemplateDataset({
          ...props.value,
          children: mappedValues[key] ? mappedValues[key] : [],
          dataset: 0,
        });
      }
      mappedListChildren.push({
        ...props.value,
        children: mappedValues[key],
        dataset: key,
      });
    });

    if (mappedListChildren.length < 1) {
      mappedListChildren.push({
        ...props.value,
        children: templateDataset,
        dataset: 0,
      });
      setHighestDataset(0);
    }

    setListDatasets(mappedListChildren);

    return setShow(true);
  }, [props.value]);

  const onAdd = () => {
    // const newDataset = templateDataset;

    // newDataset.children = newDataset.children.map((child: FormGroupElement) => {
    //   return {
    //     ...child,
    //     value: '',
    //     dataset: highestDataset + 1,
    //   };
    // });
    // newDataset.dataset = highestDataset + 1;

    // setListDatasets((datasets) => {
    //   datasets.push(newDataset);
    //   return datasets
    // })
    setAddedDataset(true);
    setTimeout(() => {
      setAddedDataset(false);
    }, 100);
  };

  return (
    <>
      {show ? (
        <FormList
          {...props}
          template={templateDataset}
          values={listDatasets}
          onAdd={() => (props.preview ? null : onAdd())}
          multyData
          addedDataset={addedDataset}
        />
      ) : null}
    </>
  );
};

export default FormListMultiData;
