import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { FC, useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle, i18n } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMap,
  faTransgender,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import EcoMapMenu from '../../../components/eco-map/eco-map-menu/eco-map-menu';
import { ClientApi } from '../../../api';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import EcoMapClientMenu from '../../../components/eco-map/eco-map-client-menu/eco-map-client-menu';
import { Client } from '../../../types';
import { ClientDataType } from '@casecare/types';
import GenderIcon from '../../../components/core/gender-icon/gender-icon';
import EcoMapApi from '../../../api/eco-map/eco-map';
import EcoMapIconMap from '../../../components/eco-map/eco-map-icon-map/eco-map-icon-map';

interface EcoMapCalculationProps {
  client: string | undefined;
  ecoMap: any;
}

const EcoMapCalculation: FC<EcoMapCalculationProps> = (props) => {
  const context = useContext(AppContext);
  const [ecoMap, setEcoMap] = useState<any>(undefined);
  const [results, setResults] = useState<any>({});

  const calcQuery = useQuery({
    queryKey: ['ecoMapCalculation', props.client, ecoMap],
    queryFn: () => {
      return EcoMapApi.getEcoMapCalculation(
        context.authToken,
        props.client,
        ecoMap.id,
      );
    },
    enabled:
      context.authToken !== undefined &&
      props.client !== undefined &&
      ecoMap !== undefined,
  });

  useEffect(() => {
    if (!calcQuery.isLoading) {
      if (calcQuery.error) {
        console.error(calcQuery.error);
      } else if (calcQuery.data && calcQuery.data.data) {
        setResults(calcQuery.data.data);
        console.log(calcQuery.data.data);
      }
    }
  }, [calcQuery.data, calcQuery.error, calcQuery.isLoading]);

  useEffect(() => {
    setEcoMap(props.ecoMap);
  }, [props.ecoMap]);

  return (
    <Grid container>
      {calcQuery.isLoading ||
      props.client === undefined ||
      ecoMap === undefined ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <>
          {results.networkSize && (
            <Typography sx={{ pb: 2 }}>
              {context.i18n.formatString(
                context.i18n.ecoMapCalculationNetworkSize,
                results.networkSize,
              )}
            </Typography>
          )}
          {results.density > 0 && (
            <Typography sx={{ pb: 2 }}>
              {context.i18n.formatString(
                context.i18n.ecoMapCalculationDensity,
                results.density,
              )}
            </Typography>
          )}
          {results.star && (
            <Typography sx={{ pb: 2 }}>
              {context.i18n.formatString(
                context.i18n.ecoMapCalculationStar,
                results.star,
              )}
            </Typography>
          )}
          {results.liaison && (
            <Typography sx={{ pb: 2 }}>
              {context.i18n.formatString(
                context.i18n.ecoMapCalculationLiaison,
                results.liaison,
              )}
            </Typography>
          )}
          {results.bridge && (
            <Typography sx={{ pb: 2 }}>
              {context.i18n.formatString(
                context.i18n.ecoMapCalculationBridge,
                results.bridge,
              )}
            </Typography>
          )}
          {results.gatekeeper && (
            <Typography sx={{ pb: 2 }}>
              {context.i18n.formatString(
                context.i18n.ecoMapCalculationGatekeeper,
                results.gatekeeper,
              )}
            </Typography>
          )}
          {results.isolated && (
            <Typography sx={{ pb: 2 }}>
              {context.i18n.formatString(
                context.i18n.ecoMapCalculationIsolated,
                results.isolated,
              )}
            </Typography>
          )}
        </>
      )}
    </Grid>
  );
};

export default EcoMapCalculation;
