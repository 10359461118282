import { axiosInstance } from '../../utils';

export class GenoGrammApi {
  static getGenoGramm(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('/geno-gramm/data/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveClient(
    token: string | undefined,
    clientId: string | undefined,
    sendData: any,
  ): Promise<any> {
    return axiosInstance
      .post('/geno-gramm/save/' + clientId, sendData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static savePregnancy(
    token: string | undefined,
    sendData: any,
    indexId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .post('/geno-gramm/save/pregnancy/' + indexId, sendData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deletePregnancy(token: string | undefined, id: string): Promise<any> {
    return axiosInstance
      .delete('/geno-gramm/delete/pregnancy/' + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editRelation(
    token: string | undefined,
    clientId: string | undefined,
    sendData: any,
  ): Promise<any> {
    return axiosInstance
      .post('/geno-gramm/relation/' + clientId, sendData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteRelation(
    token: string | undefined,
    clientId: string | undefined,
    sendData: any,
  ): Promise<any> {
    return axiosInstance
      .post('/geno-gramm/relation/delete/' + clientId, sendData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static importFamilyMembers(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('/geno-gramm/import/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static changeOrder(
    token: string | undefined,
    clientId: string | undefined,
    sendData: any,
  ): Promise<any> {
    return axiosInstance
      .post('/geno-gramm/order/' + clientId, sendData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
