import {
  faGear,
  faPlus,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, IconButton, Tooltip } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AdminApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { CategoryData, FormDatagridElement } from '../../../types';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { MovementButtons } from '../../core';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

interface FormTableProps {
  categories: CategoryData[];
  createCategory: () => void;
  editCategoryDetails: (id: any) => void;
}

const FormTable: FC<FormTableProps> = (props) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [categories, setCategories] = useState(
    props.categories.sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1)),
  );
  const [rows, setRows] = useState<FormDatagridElement[]>([]);

  useEffect(() => {
    setCategories(
      props.categories.sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1)),
    );
  }, [props.categories]);

  useEffect(() => {
    if (categories) {
      mapRows();
    }
  }, [categories]);

  const mapRows = () => {
    if (categories) {
      setRows(
        categories.map((category) => {
          return {
            id: category.id,
            name: category.label,
            subCategories: category.childCategories
              ? category.childCategories
                  ?.map((category: CategoryData) => category.label)
                  .join(',\n')
              : '',
            seq: category.seq,
            subCategoryLength: category.childCategories
              ? category.childCategories?.length
              : 1,
          };
        }),
      );
    }
  };

  const editForm = (id: any) => {
    navigate('/admin/categories/' + id);
  };

  const moveElement = async (
    up: boolean,
    elementId: any,
    allTheWay?: boolean,
  ) => {
    const updatedCategories: CategoryData[] = [];
    const newCategories = categories.sort((a, b) =>
      a.seq && b.seq ? a.seq - b.seq : 1,
    );
    newCategories.map((category, index) => {
      if (category.id === elementId) {
        const tmpSeq = newCategories[index].seq;
        if (up && index > 0) {
          if (allTheWay) {
            newCategories[index].seq = -1;
            updatedCategories.push(newCategories[index]);
            return;
          }
          newCategories[index].seq = newCategories[index - 1].seq;
          newCategories[index - 1].seq = tmpSeq;
          updatedCategories.push(newCategories[index]);
          updatedCategories.push(newCategories[index - 1]);
          return;
        } else if (!up && index + 1 !== newCategories.length) {
          if (allTheWay) {
            newCategories[index].seq = newCategories.length + 1;
            updatedCategories.push(newCategories[index]);
            return;
          }
          newCategories[index].seq = newCategories[index + 1].seq;
          newCategories[index + 1].seq = tmpSeq;
          updatedCategories.push(newCategories[index]);
          updatedCategories.push(newCategories[index + 1]);
          return;
        }
      }
    });
    try {
      const res = await AdminApi.updateCategories(
        updatedCategories,
        context.authToken,
      );
      const errors = res.data.errors;
      if (errors.length > 0) {
        errors.forEach((error: any) => {
          snackbarUtils.error(error.message);
        });
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
    queryClient.invalidateQueries('adminCategoryData');
  };

  const tableColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: context.i18n.name,
      sortable: false,
      minWidth: 150,
      flex: 1.5,
      hideable: false,
    },
    {
      field: 'subCategories',
      headerName: context.i18n.subCategories,
      sortable: false,
      flex: 1,
      hideable: true,
      renderCell: (params) => (
        <TextField
          value={params.value}
          variant="standard"
          InputProps={{ disableUnderline: true }}
          sx={{ border: 'none' }}
          fullWidth
          multiline
          rows={params.row.subCategoryLength}
        />
      ),
    },
    {
      field: 'seq',
      headerName: '',
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <MovementButtons
          moveElement={moveElement}
          id={params.id}
          first={params.value === 1}
          last={params.value === rows.length}
        />
      ),
    },
    {
      field: 'edit',
      flex: 0.5,
      hideable: true,
      align: 'center',
      headerName: '',
      sortable: false,
      renderCell: (params) => (
        <IconButton
          disableRipple
          color="primary"
          sx={{ height: 39 }}
          onClick={() => props.editCategoryDetails(params.id)}
        >
          <FontAwesomeIcon icon={faGear} />
        </IconButton>
      ),
    },
    {
      field: 'actions',
      flex: 0.5,
      hideable: false,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => (
        <Tooltip title={context.i18n.createCategory}>
          <IconButton
            disableRipple
            color="primary"
            id="create-category"
            data-id="create-category"
            onClick={() => props.createCategory()}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <IconButton
          disableRipple
          color="primary"
          sx={{ height: 39 }}
          id={params.id + '-edit-btn'}
          onClick={() => editForm(params.id)}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </IconButton>
      ),
    },
  ];

  return (
    <DataGridPro
      sx={{
        fontSize: 17,
        m: 5,
        height: '90%',
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'seq', sort: 'asc' }],
        },
      }}
      columns={tableColumns}
      rows={rows}
      getRowId={(row) => row.id}
      disableColumnSelector={true}
      disableColumnFilter={true}
      disableColumnMenu={true}
      pageSizeOptions={[15]}
      autoHeight
      getRowHeight={() => 'auto'}
    />
  );
};

export default FormTable;
