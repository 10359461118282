import { AvatarPropType } from '@casecare/types';
import { axiosInstance } from '../../utils';

export default class AvatarApi {
  static getAvatarProps(
    token: string | undefined,
    type: AvatarPropType | undefined,
    indexClientId: string,
    clientId: string,
  ): Promise<any> {
    return axiosInstance
      .get(`avatar/props/${type}/${indexClientId}/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getAvatar(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`avatar/${indexClientId}/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static listAvatars(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`avatar/list/${indexClientId}/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateAvatar(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`avatar/${indexClientId}/${clientId}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateAvatarPng(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`avatar/png/${indexClientId}/${clientId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createAvatar(
    token: string | undefined,
    indexClientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`avatar/edit/${indexClientId}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getAvailableFamilyMembers(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`avatar/family/${indexClientId}/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getAvatarDetails(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`avatar/details/${indexClientId}/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static listAvatarTemplates(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`avatar/templates`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getAvatarNotes(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`avatar/notes/${indexClientId}/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateAvatarNotes(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
    notes: string,
  ): Promise<any> {
    return axiosInstance
      .post(
        `avatar/notes/${indexClientId}/${clientId}`,
        {
          notes,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static updateAvatarMood(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
    mood: number,
  ): Promise<any> {
    return axiosInstance
      .post(
        `avatar/mood/${indexClientId}/${clientId}`,
        {
          mood,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getAvatarSymbols(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`avatar/symbols/${indexClientId}/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateAvatarSymbol(
    token: string | undefined,
    clientId: string | undefined,
    indexClientId: string | undefined,
    addedSymbolIds: string[],
    removedSymbolIds: string[],
  ): Promise<any> {
    return axiosInstance
      .post(
        `avatar/symbol/${indexClientId}/${clientId}`,
        {
          addedSymbolIds,
          removedSymbolIds,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }
}
