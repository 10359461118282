import ReactDOM from 'react-dom';
import { useContext, useEffect, useCallback } from 'react';
import { Blocker, History } from 'history';
import {
  UNSAFE_NavigationContext as NavigationContext,
  Navigator,
} from 'react-router-dom';
import { Dialog } from '@mui/material';
import DiscardDialog from '../components/dialogs/discard-dialog/discard-dialog';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../utils/core/misc-utils';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as Navigator & Pick<History, 'block'>).block(
      (tx) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      },
    );

    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * Prompts the user with a Dialog before they leave the current screen.
 *
 * @param saveChanges Function to call when saving changes.
 * @param discardChanges Function to call when discarding changes.
 * @param when Condition to trigger the blocker.
 */
export function useSavePrompt(
  saveChanges: () => void,
  discardChanges: (() => void) | undefined,
  when: boolean,
) {
  const blocker = useCallback(
    (tx) => {
      const element = document.createElement('div');
      element.setAttribute('id', 'prompt-dialog-container');
      element.setAttribute('aria-hidden', 'true');

      const onSave = () => {
        saveChanges();
        closePrompt(true);
      };

      const onDiscard = () => {
        discardChanges && discardChanges();
        closePrompt(true);
      };

      const closePrompt = (state: boolean) => {
        if (element) {
          ReactDOM.unmountComponentAtNode(element);
        }
        if (!state) {
          document.body.removeChild(element);
        } else {
          tx.retry();
        }
      };

      document.body.appendChild(element);
      ReactDOM.render(
        <ThemeProvider theme={theme}>
          <Dialog open={when} onClose={() => closePrompt(false)}>
            <DiscardDialog
              onDiscard={onDiscard}
              onSave={onSave}
              onClose={() => closePrompt(false)}
            />
          </Dialog>
        </ThemeProvider>,
        element,
      );
    },
    [when, saveChanges, discardChanges],
  );

  useBlocker(blocker, when);
}
