import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';

interface ClientArchiveDialogProgps {
  onArchive: () => void;
  onClose: () => void;
  open: boolean;
  archive?: boolean;
  clientName: string;
}

const ClientArchiveDialog: FC<ClientArchiveDialogProgps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        {props.archive ? context.i18n.archive : context.i18n.unArchive}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {context.i18n.formatString(
            props.archive
              ? context.i18n.archiveClientText
              : context.i18n.unArchiveClientText,
            props.clientName,
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.archive}>
          <IconButton onClick={props.onArchive.bind(this)} color="primary">
            <FontAwesomeIcon icon={faCheck} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose.bind(this)} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ClientArchiveDialog;
