import { ClientDataType, PregnancyState, Sex } from '@casecare/types';
import { Box, Paper, Tooltip, tooltipClasses } from '@mui/material';
import { attributes } from 'js-cookie';
import { listeners } from 'process';
import { FC, useContext } from 'react';
import client from '../../../api/client/client';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { AppContext } from '../../../hooks/context';
import {
  faCircle,
  faSquareXmark,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface GenoGrammPregnancyDragableProps {
  pregnancy: any;
}

export const GenoGrammPregnancyDragable: FC<GenoGrammPregnancyDragableProps> = (
  props,
) => {
  const { pregnancy } = props;
  const context = useContext(AppContext);

  const { attributes, listeners, setNodeRef, transform, over } = useDraggable({
    id: pregnancy.positionId,
    data: pregnancy,
  });
  const style = transform && {
    transform: `translate3d(${transform.x}px, 0, 0)`,
  };

  const renderSymbol = () => {
    switch (pregnancy.pregnancyState) {
      case PregnancyState.M_STILLBORN:
      case PregnancyState.F_STILLBORN:
        return (
          <FontAwesomeIcon
            style={{ width: '100%', height: '100%' }}
            icon={faSquareXmark}
          ></FontAwesomeIcon>
        );
      case PregnancyState.ABORTION:
        return (
          <FontAwesomeIcon
            style={{ width: '100%', height: '100%' }}
            icon={faCircle}
          ></FontAwesomeIcon>
        );
      case PregnancyState.MISCARRIAGE:
        return (
          <FontAwesomeIcon
            style={{ width: '100%', height: '100%' }}
            icon={faX}
          ></FontAwesomeIcon>
        );
      case PregnancyState.PREGNANT:
        return (
          <svg style={{ width: '100%', height: '100%' }}>
            <polygon
              points="20,0 0,40 40,40"
              style={{
                stroke: 'black',
                fill: 'none',
                strokeWidth: 2,
                strokeLinejoin: 'round',
              }}
            />
          </svg>
        );
    }
  };

  return (
    <>
      <Tooltip
        open
        arrow
        PopperProps={{
          sx: {
            zIndex: 99,
            marginTop: 0,
            [`& .${tooltipClasses.tooltip}`]: {
              background: 'transparent',
              color: 'black',
            },
          },
        }}
        title={`${pregnancy.name} (${context.i18n[pregnancy.pregnancyState]})`}
      >
        <Box
          position="relative"
          id={pregnancy.id}
          sx={Object.assign({
            position: 'relative',
            left: `${pregnancy.x}px`,
            touchAction: 'none',
            ...style,
            height: 40,
            width: 40,
          })}
          ref={setNodeRef}
          {...listeners}
          {...attributes}
        >
          {renderSymbol()}
        </Box>
      </Tooltip>
    </>
  );
};
