import { FormFieldTextType } from '@casecare/types';
import { FormElementProps } from './form-element-props';

export interface FormTextProps extends FormElementProps {
  dataType: FormFieldTextType;
  value: string;
  size: TextSize;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export enum TextSize {
  'LARGE',
  'MEDIUM',
  'NORMAL',
}
