import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SxProps,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Tooltip,
  LinearProgress,
} from '@mui/material';
import {
  useContext,
  useState,
  ForwardedRef,
  forwardRef,
  useEffect,
} from 'react';
import { AppContext } from '../../../hooks/context';
import React from 'react';
import zxcvbn from 'zxcvbn';
import {
  passwordLowercaseRegex,
  passwordNumberRegex,
  passwordSpecialCharacterRegex,
  passwordUppercaseRegex,
} from '../../../utils/constants';
import { TooltipWhite } from '../../../utils/styling/styling';

interface LoginInputProps {
  label: string;
  onChange: (value: string) => void;
  initialValue?: string;
  value?: string;
  password?: boolean;
  email?: boolean;
  sx?: SxProps;
  showForgotPassword?: boolean;
  onPasswordOpen?: () => void;
  error?: boolean;
  helperText?: string;
  onEnter?: () => void;
  disabled?: boolean;
  showPasswordCheck?: boolean;
  required?: boolean;
}

const LoginInput = forwardRef(
  (props: LoginInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const context = useContext(AppContext);
    const [inputValue, setInputValue] = useState(
      props.initialValue ? props.initialValue : '',
    );
    const [showPassword, setShowPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordValidation, setPasswordValidation] = useState({
      uppercase: false,
      lowercase: false,
      specialChar: false,
      number: false,
      minChar: false,
      maxChar: true,
    });

    useEffect(() => {
      if (props.password) {
        const result = zxcvbn(inputValue);
        setPasswordStrength((result.score + 1) * 20);

        if (inputValue.length < 8) passwordValidation.minChar = false;
        else passwordValidation.minChar = true;

        if (inputValue.length > 20) passwordValidation.maxChar = false;
        else passwordValidation.maxChar = true;

        if (!passwordSpecialCharacterRegex.test(inputValue))
          passwordValidation.specialChar = false;
        else passwordValidation.specialChar = true;

        if (!passwordNumberRegex.test(inputValue))
          passwordValidation.number = false;
        else passwordValidation.number = true;

        if (!passwordUppercaseRegex.test(inputValue))
          passwordValidation.uppercase = false;
        else passwordValidation.uppercase = true;

        if (!passwordLowercaseRegex.test(inputValue))
          passwordValidation.lowercase = false;
        else passwordValidation.lowercase = true;

        setPasswordValidation(passwordValidation);
      }
    }, [inputValue]);
    return (
      <Box display="flex" justifyContent="center">
        {props.password ? (
          <Box flexDirection="column">
            <Tooltip
              arrow
              PopperProps={{
                sx: TooltipWhite,
              }}
              title={
                props.showPasswordCheck && (
                  <React.Fragment>
                    <Box sx={{ width: 250 }}>
                      <Typography
                        color={
                          passwordValidation.uppercase
                            ? 'success.main'
                            : 'error.main'
                        }
                      >
                        {context.i18n.passwordUppercase}
                      </Typography>
                      <Typography
                        color={
                          passwordValidation.lowercase
                            ? 'success.main'
                            : 'error.main'
                        }
                      >
                        {context.i18n.passwordLowercase}
                      </Typography>
                      <Typography
                        color={
                          passwordValidation.number
                            ? 'success.main'
                            : 'error.main'
                        }
                      >
                        {context.i18n.passwordNumber}
                      </Typography>
                      <Typography
                        color={
                          passwordValidation.minChar
                            ? 'success.main'
                            : 'error.main'
                        }
                      >
                        {context.i18n.passwordMinChar}
                      </Typography>
                      <Typography
                        color={
                          passwordValidation.maxChar
                            ? 'success.main'
                            : 'error.main'
                        }
                      >
                        {context.i18n.passwordMaxChar}
                      </Typography>
                      <Typography
                        color={
                          passwordValidation.specialChar
                            ? 'success.main'
                            : 'error.main'
                        }
                      >
                        {context.i18n.passwordSpecialChar}
                      </Typography>
                      <Typography sx={{ marginTop: 2 }}>
                        {context.i18n.passwordStrength}:
                      </Typography>
                      <Typography
                        sx={{ textTransform: 'uppercase' }}
                        variant="caption"
                        color={
                          passwordStrength === 100
                            ? 'success.main'
                            : passwordStrength >= 60
                            ? 'info.main'
                            : passwordStrength >= 40
                            ? 'warning.main'
                            : 'error.main'
                        }
                      >
                        {passwordStrength === 100
                          ? context.i18n.passwordStrengthVeryStrong
                          : passwordStrength >= 60
                          ? context.i18n.passwordStrengthStrong
                          : passwordStrength >= 40
                          ? context.i18n.passwordStrengthWeak
                          : context.i18n.passwordStrengthVeryWeak}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={passwordStrength}
                        sx={{ marginBottom: 1 }}
                        color={
                          passwordStrength === 100
                            ? 'success'
                            : passwordStrength >= 60
                            ? 'info'
                            : passwordStrength >= 40
                            ? 'warning'
                            : 'error'
                        }
                      />
                    </Box>
                  </React.Fragment>
                )
              }
              placement="left"
              open={props.value !== '' ? true : false}
            >
              <TextField
                error={props.error}
                helperText={props.helperText}
                disabled={props.disabled}
                color="secondary"
                label={props.label}
                size="medium"
                sx={{ mt: 2, width: '22rem' }}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => {
                  props.onChange(e.currentTarget.value);
                  setInputValue(e.currentTarget.value);
                }}
                inputRef={ref}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') props.onEnter && props.onEnter();
                }}
                InputProps={{
                  sx: { backgroundColor: 'white' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={context.i18n.showPassword}>
                        <IconButton
                          edge="end"
                          id="showBtn"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
            {props.showForgotPassword ? (
              <a
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter')
                    props.onPasswordOpen && props.onPasswordOpen();
                }}
                onClick={props.onPasswordOpen}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '0.7rem', mt: 1, paddingLeft: '0.2rem' }}
                  className="clickable"
                >
                  {context.i18n.forgotPassword}
                </Typography>
              </a>
            ) : null}
          </Box>
        ) : (
          <TextField
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            color="secondary"
            label={props.label}
            size="medium"
            sx={{ mt: 2, width: '22rem' }}
            type={props.email ? 'email' : 'text'}
            value={props.value || inputValue}
            required={props.required}
            onChange={(e) => {
              props.onChange(e.currentTarget.value);
              setInputValue(e.currentTarget.value);
            }}
            inputProps={{
              sx: { backgroundColor: 'white' },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') props.onEnter && props.onEnter();
            }}
          />
        )}
      </Box>
    );
  },
);

LoginInput.displayName = 'login Input';

export default LoginInput;
