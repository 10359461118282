import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import FamilyApi from '../../../api/family/family';
import { AppContext } from '../../../hooks/context';
import { Family } from '../../../types';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';

interface AddFamilyMemberDialogProps {
  open: boolean;
  onClose: () => void;
  onAddClient?: () => void;
  familyId?: string;
  // family?: Family;
}

interface SimpleClient {
  id: string;
  name: string;
}

const AddFamilyMemberDialog: FC<AddFamilyMemberDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [clients, setClients] = useState<SimpleClient[]>([]);
  const [open, setOpen] = useState(props.open);
  const [familyId, setFamilyId] = useState(props.familyId);
  // const [families, setFamilies] = useState<Family[]>([]);

  const [selectedClient, setSelectedClient] = useState<any>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    setOpen(props.open);
    setFamilyId(props.familyId);
  }, [props.open]);

  useEffect(() => {
    setFamilyId(familyId);
  }, [props.familyId]);

  // useEffect(() => {
  //   if (props.families) {
  //     setFamilies(props.families);
  //   }
  // }, [props.families]);

  useQuery(
    ['availableClients'],
    () => FamilyApi.getAvailableClients(context.authToken),
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setClients(res.data);
      },
    },
  );

  const addClient = async (clientId: string) => {
    try {
      await FamilyApi.addClientToFamily(context.authToken, familyId, clientId);
      queryClient.invalidateQueries('availableClients');
      setSelectedClient(null);
      props.onAddClient && props.onAddClient();
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.addClientToFamily}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Autocomplete
            options={clients}
            sx={{ width: '22rem' }}
            value={selectedClient}
            onChange={(evetn, option: any) => {
              setSelectedClient(option);
            }}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            disabled={familyId === undefined}
            fullWidth
            noOptionsText={context.i18n.noClients}
            getOptionLabel={(option: any) => option.name || ''}
            renderInput={(params) => (
              <TextField {...params} placeholder={context.i18n.clients} />
            )}
          />
        </Stack>
      </DialogContent>
      <ConfirmAddFamilyMemberDialog
        client={selectedClient}
        add={() => addClient(selectedClient.id)}
        cancel={() => setSelectedClient(null)}
      />
    </Dialog>
  );
};

const ConfirmAddFamilyMemberDialog: FC<{
  client?: any;
  add: () => void;
  cancel: () => void;
}> = (props) => {
  const { client, add, cancel } = props;
  const context = useContext(AppContext);

  return (
    <Dialog open={client ? true : false}>
      <DialogTitle>{context.i18n.confirmAddClientToFamilyTitle}</DialogTitle>
      <DialogContent>
        <Typography>
          {context.i18n.formatString(
            context.i18n.confirmAddClientToFamily,
            client?.name || '',
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton onClick={add} disableRipple color="primary">
              <FontAwesomeIcon icon={faCheck} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.cancel}>
          <IconButton onClick={cancel} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddFamilyMemberDialog;
