import { tabsClasses } from '@mui/material';
import { theme } from '../core/misc-utils';

export const AccordionStyle = (
  background: string | undefined,
  color: string | undefined,
  selectedColor?: string,
) => ({
  borderRadius: 3,
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionDetails-root': {
    overflow: 'scroll',
    maxHeight: '100vh',
  },
  '& .MuiAccordionSummary-root': {
    height: 65,
    border: '1px solid',
    borderColor: color,
    borderRadius: 3,
    textTransform: 'uppercase',
    backgroundColor: background,
    [theme.breakpoints.down('xl')]: {
      height: 45,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      mr: 2,
    },
    borderTopLeftRadius: 'unset',
    borderBottomLeftRadius: 'unset',
    '&.Mui-expanded, &.Mui-expanded svg': {
      backgroundColor: color,
      color: selectedColor ? selectedColor : 'white',
    },
    '& img': {
      borderColor: color,
    },
    '&.Mui-expanded img': {
      borderColor: selectedColor ? selectedColor : 'white',
    },
    '& .fa-icon, & .custom-icon': {
      padding: '0.5rem',
      marginLeft: '1rem',
      fontSize: '2rem',
      [theme.breakpoints.down('xl')]: {
        marginLeft: '0.75rem',
        fontSize: '1.5rem',
      },
    },
    '& .MuiBadge-badge': {
      transform: 'translate(50%, -25%)',
      width: '40px',
      height: '40px',
      [theme.breakpoints.down('xl')]: {
        width: '30px',
        height: '30px',
      },
    },
  },
});

export const TabStyle = {
  bgcolor: 'secondary.main',
  height: 48,
  transition: 'all .5s ease-in-out',
  boxShadow: '2px 3px 6px #00000029;',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  '&.Mui-selected': {
    bgcolor: 'primary.main',
    height: 58,
  },
  alignSelf: 'end',
};

export const TabScrollButtonStyle = {
  [`& .${tabsClasses.scrollButtons}`]: {
    '&.Mui-disabled': { opacity: 0.3 },
    bgcolor: '#f5f9fd',
    mb: 2,
    mt: 2,
    borderRadius: 2,
    border: '0.5px solid #96c1e9',
  },
};

export const AvatarTabButtonStyle = {
  border: '0.5px solid #96c1e9',
  borderRadius: 2,
  bgcolor: '#f5f9fd',
  '&.Mui-selected': {
    filter: 'brightness(65%)',
  },
  p: 2,
};

export const AvatarPropButtonStyle = {
  display: 'flex',
  mr: 1,
  width: '15vh',
  height: '15vh',
  ml: 1,
  borderRadius: 2,
};

export const FormGroupStyle = {
  '&.Mui-error': {
    borderColor: 'error.main',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 1,
    p: 1,
  },
};

export const RadioGroupStyle = {
  '.MuiButtonBase-root.MuiRadio-root': {
    marginLeft: '0px',
  },
  '&.Mui-error': {
    borderColor: 'error.main',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 1,
    p: 1,
  },
};

export const TooltipWhite = {
  zIndex: '1299',
  '& .MuiTooltip-tooltip': {
    border: 'solid black 1px',
    color: 'black',
    backgroundColor: 'white',
  },
};

export const TabScrollButtonEcoMapStyle = {
  [`& .${tabsClasses.scrollButtons}`]: {
    '&.Mui-disabled': { opacity: 0.3 },
    bgcolor: 'secondary.main',
    mb: 2,
    mt: 2,
    borderRadius: 0,
    border: '0.5px solid',
    borderColor: 'primary.main',
    width: '15%',
    marginLeft: 'auto',
    '&[direction="right"]': {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    '&[direction="left"]': {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
  },
};

export const EcoMapBackgroundWrapper = {
  '&': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    '& .circle-wrapper': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      '.MuiBox-root': {
        position: 'absolute',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
      },
      '.MuiBox-root:nth-child(1)': {
        width: '90%',
        paddingTop: '90%',
        zIndex: 1,
      },
      '.MuiBox-root:nth-child(2)': {
        width: '70%',
        paddingTop: '70%',
        zIndex: 2,
      },
      '.MuiBox-root:nth-child(3)': {
        width: '50%',
        paddingTop: '50%',
        zIndex: 3,
      },
    },
    '& .text-wrapper': {
      zIndex: 9,
      position: 'absolute',
      width: '100%',
      height: '100%',
      '.MuiTypography-root': {
        position: 'absolute',
        width: '100px',
      },
      //TOP LEFT//
      '.text-1': {
        top: '2.5%',
        left: '7.5%',
      },
      //TOP RIGHT//
      '.text-2': {
        top: '2.5%',
        right: '7.5%',
      },
      //BOTTOM LEFT//
      '.text-3': {
        bottom: '2.5%',
        left: '7.5%',
      },
      //BOTTOM RIGHT//
      '.text-4': {
        bottom: '2.5%',
        right: '7.5%',
      },
      //TOP LEFT - BOTTOM//
      '.text-5': {
        top: '10%',
        left: '-3.5%',
      },
      //TOP RIGHT - BOTTOM//
      '.text-6': {
        top: '10%',
        right: '-3.5%',
      },
      //BOTTOM LEFT - TOP//
      '.text-7': {
        bottom: '10%',
        left: '-3.5%',
      },
      //BOTTOM RIGHT - TOP//
      '.text-8': {
        bottom: '10%',
        right: '-3.5%',
      },
    },
  },
};

export const EcoMapUtilityButtons = {
  '&': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '.MuiButtonBase-root': {
      svg: {
        fontSize: 25,
        padding: 2,
      },
      [theme.breakpoints.down('xl')]: {
        svg: {
          fontSize: 15,
          padding: 1,
        },
      },
    },

    '.left-wrapper': {
      position: 'absolute',
      top: '50%',
      left: 0,
      zIndex: 25,
      transform: 'translate(0%, -50%)',
    },
    '.top-wrapper': {
      position: 'absolute',
      top: 0,
      left: '50%',
      zIndex: 25,
      transform: 'translate(-50%, 0%)',
    },
    '.right-wrapper': {
      position: 'absolute',
      top: '50%',
      right: 0,
      zIndex: 25,
      transform: 'translate(0%, -50%)',
    },
    '.bottom-wrapper': {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      zIndex: 25,
      transform: 'translate(-50%, 0%)',
    },
  },
};

export const EcoMapSplitter = {
  '&': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    '.splitter': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      margin: '-19px',
      border: 'solid white 10px',
      '&.splitted:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
        zIndex: -1,
      },
    },

    '.splitter-1': {
      top: '-50%',
      left: '-50%',
      '&.splitted:after': {
        background:
          'linear-gradient(to top right, transparent calc(50% - 10px), white,white,white,white,white,white,white, transparent calc(50% + 10px)  )',
      },
    },
    '.splitter-2': {
      top: '-50%',
      right: '-50%',
      '&.splitted:after': {
        background:
          'linear-gradient(to bottom right, transparent calc(50% - 10px), white,white,white,white,white,white,white, transparent calc(50% + 10px)  )',
      },
    },
    '.splitter-3': {
      bottom: '-50%',
      left: '-50%',
      '&.splitted:after': {
        background:
          'linear-gradient(to top left, transparent calc(50% - 10px), white,white,white,white,white,white,white, transparent calc(50% + 10px)  )',
      },
    },
    '.splitter-4': {
      bottom: '-50%',
      right: '-50%',
      '&.splitted:after': {
        background:
          'linear-gradient(to bottom left, transparent calc(50% - 10px), white,white,white,white,white,white,white, transparent calc(50% + 10px)  )',
      },
    },
  },
};

export const ClientHelpFormListColors = {
  '&': {
    alignItems: 'center',
    display: 'flex',
    '&.help-form-perfect': {
      color: 'success.main',
    },
    '&.help-form-good': {
      color: '#8bc34a',
    },
    '&.help-form-ok': {
      color: '#89c22d',
    },
    '&.help-form-not-ok': {
      color: 'warning.main',
    },
    '&.help-form-bad': {
      color: 'error.main',
    },
  },
};

export const DragAndDropFileUpload = {
  '&': {
    position: 'relative',
  },
  '& .drag-and-drop-container': {
    display: 'none',
  },
  '&.drag-and-drop': {
    '.drag-and-drop-container': {
      display: 'block',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      position: 'fixed',
      zIndex: 1,
      '.drag-and-drop-outer': {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'secondary.main',
        position: 'absolute',
        opacity: 0.5,
      },
      '.drag-and-drop-inner': {
        // borderWidth: 4,
        // borderStyle: 'dashed',
        // borderColor: 'primary.main',
        // position: 'absolute',
        margin: 2,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        textAlign: 'center',
        '.drag-and-drop-content': {
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'secondary.main',
          borderWidth: 4,
          borderStyle: 'dashed',
          borderColor: 'primary.main',
          margin: 2,
          padding: 4,
          '.MuiSvgIcon-root': {
            fontSize: 100,
            color: 'primary.main',
          },
        },
      },
    },
  },
};
