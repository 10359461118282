import {
  faCaretDown,
  faExclamationCircle,
  faCheck,
  faPen,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  CircularProgress,
  Fab,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { ProtocolApi } from '../../../api';
import { ProtocolFields } from '../../../components/client/documentation';
import {
  EditProtocolDialog,
  EditProtokollBulletPointsDialog,
  ReleaseProtocolHoursDialog,
} from '../../../components/dialogs';
import { MobileNavBar } from '../../../components/mobile';
import { AppContext } from '../../../hooks/context';
import { Protocol } from '../../../types';
import { theme, parseDateTimeString, queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AccordionStyle } from '../../../utils/styling/styling';

const MobileProtocols: FC = () => {
  const { id, isFamily } = useParams();
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [protocols, setProtocols] = useState<Protocol[]>([]);
  const [saveId, setSaveId] = useState<string | undefined>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditBulletPoints, setOpenEditBulletPoints] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState<Record<string, boolean>>(
    {},
  );
  const [clientName, setClientName] = useState('');
  const [openReviewDialog, setOpenReviewDialog] = useState(false);

  const { isLoading } = useQuery(
    ['protocolsMonth'],
    () =>
      ProtocolApi.getProtocols(
        context.authToken,
        Object.assign(
          {
            currentMonth: true,
          },
          isFamily
            ? {
                familyId: id,
              }
            : { clientId: id },
        ),
      ),
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setClientName(res.data.clientName);
        const tmpProtocolls = res.data.protocols.map((protocol: any) => {
          return {
            id: protocol.id,
            contentOfCare: protocol.contentOfCare,
            helpForm: protocol.helpForm?.name,
            helpFormId: protocol.helpForm?.id,
            supervisorIds: protocol.supervisorIds,
            supervisors: protocol.supervisorNames,
            endDate: protocol.endDate,
            startDate: protocol.startDate,
            hours: protocol.hours,
            place: protocol.place,
            personsPresent: protocol.personsPresent,
            billable: protocol.billable,
            inReview: protocol.inReview,
            isReleased: protocol.isReleased,
            rejected: protocol.rejected,
            rejectionReason: protocol.rejectionReason,
          };
        });
        setProtocols(tmpProtocolls);
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useEffect(() => {
    if (saveId && !openReviewDialog) {
      setSaveId(undefined);
    }
  });

  const onReviewProtocols = async () => {
    try {
      const body = Object.assign(
        {
          protocolIds: [saveId],
        },
        !isFamily && {
          clientId: id,
        },
        isFamily && {
          familyId: id,
        },
      );

      const res = await ProtocolApi.reviewProtocol(context.authToken, body);

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      setOpenReviewDialog(false);
      setSaveId(undefined);
      queryClient.invalidateQueries(['protocolsMonth']);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Box>
      <MobileNavBar
        back
        add
        onAdd={() =>
          navigate(`/mobile/protocols/a/${id}${isFamily ? '/family' : ''}`)
        }
        onBack={() => navigate('/mobile/clients', { replace: true })}
        title={context.i18n.protocols}
        subTitle={clientName}
      />
      <Box pb={6}>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          protocols.map((p) => (
            <Accordion
              key={p.id}
              onClick={() => {
                return;
              }}
              square
              disableGutters
              elevation={5}
              sx={{
                ...AccordionStyle('white', theme.palette.primary.main, 'white'),
              }}
            >
              <AccordionSummary
                expandIcon={
                  <FontAwesomeIcon
                    size="2x"
                    color={theme.palette.primary.main}
                    icon={faCaretDown}
                  />
                }
              >
                <Typography>
                  {parseDateTimeString(p.startDate)}
                  {p.inReview && (
                    <FontAwesomeIcon
                      style={{ marginLeft: theme.spacing(1) }}
                      color="error"
                      size="2x"
                      icon={faCircleCheck}
                    />
                  )}
                  {p.rejected && (
                    <FontAwesomeIcon
                      style={{ marginLeft: theme.spacing(1) }}
                      color="#d32f2f"
                      size="2x"
                      icon={faExclamationCircle}
                    />
                  )}
                  {/* {unsavedChanges[p.id] && (
                    <FontAwesomeIcon
                      style={{ marginLeft: theme.spacing(1) }}
                      color={theme.palette.warning.main}
                      icon={faExclamationCircle}
                    />
                  )} */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ m: 2, maxHeight: 'unset !important' }}>
                {p.rejectionReason && (
                  <Box sx={{ pb: 2 }}>
                    <Typography
                      sx={{ color: '#d32f2f', fontSize: '0.9rem !important' }}
                    >
                      {context.i18n.rejectionReason}:<br />
                      {p.rejectionReason}
                    </Typography>
                  </Box>
                )}
                <ProtocolFields
                  open
                  isEdit
                  protocol={p}
                  save={p.id === saveId}
                  disabled={p.inReview}
                  onChange={(changes) => {
                    unsavedChanges[p.id] = changes;
                    setUnsavedChanges({ ...unsavedChanges });
                  }}
                  clientId={isFamily ? undefined : id}
                  familyId={isFamily ? id : undefined}
                />
                {!p.inReview && (
                  <>
                    <Button
                      variant="contained"
                      sx={{ p: 2, mt: 2, width: '100%' }}
                      color="inherit"
                      size="large"
                      disabled={p.inReview}
                      endIcon={
                        <FontAwesomeIcon
                          color={theme.palette.success.main}
                          icon={faCheck}
                        />
                      }
                      onClick={() => {
                        unsavedChanges[p.id] = false;
                        setUnsavedChanges({ ...unsavedChanges });
                        setSaveId(p.id);
                      }}
                    >
                      {context.i18n.save}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ p: 2, mt: 2, width: '100%' }}
                      color="inherit"
                      size="large"
                      disabled={p.inReview}
                      endIcon={
                        <FontAwesomeIcon
                          color={theme.palette.success.main}
                          icon={faCheck}
                        />
                      }
                      onClick={() => {
                        unsavedChanges[p.id] = false;
                        setUnsavedChanges({ ...unsavedChanges });
                        setSaveId(p.id);
                        setOpenReviewDialog(true);
                      }}
                    >
                      {context.i18n.saveAndReview}
                    </Button>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>
      {/* <Fab
        onClick={() => setOpenEditBulletPoints(true)}
        sx={{ position: 'fixed', right: 16, bottom: 16 }}
        variant="extended"
        color="secondary"
      >
        <FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />
        {context.i18n.protocolBulletPoints}
      </Fab> */}
      <ReleaseProtocolHoursDialog
        open={openReviewDialog}
        onClose={() => setOpenReviewDialog(false)}
        onRelease={onReviewProtocols}
      />
      <EditProtokollBulletPointsDialog
        fullScreen
        open={openEditBulletPoints}
        onClose={() => setOpenEditBulletPoints(false)}
        clientId={isFamily ? undefined : id}
        familyId={isFamily ? id : undefined}
      />
      {/* <EditProtocolDialog
        clientId={isFamily ? undefined : id}
        familyId={isFamily ? id : undefined}
        fullScreen
        onSave={() => {
          queryClient.invalidateQueries('protocolsMonth');
        }}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        supervisorIds={[context.userId]}
      /> */}
    </Box>
  );
};

export default MobileProtocols;
