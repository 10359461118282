import { axiosInstance } from '../../utils';

export default class PermissionsApi {
  static getClientPermissions(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`client/permissions/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addClientPermission(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`client/permissions/${clientId}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static removeClientPermission(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`client/permissions/${clientId}/remove`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getFamilyPermissions(
    token: string | undefined,
    familyId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`family/permissions/${familyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addFamilyPermission(
    token: string | undefined,
    familyId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`family/permissions/${familyId}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static removeFamilyPermission(
    token: string | undefined,
    familyId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`family/permissions/${familyId}/remove`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
