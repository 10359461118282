import { Box, FormHelperText } from '@mui/material';
import { FC, useState, useRef, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';
import FormEdit from '../form-edit/form-edit';
import FormElementHeader from '../form-element-header/form-element-header';
import { FormWysiwygProps } from '../../../../../types/form-wysiwyg-props';
import { WysiwygEditor } from '../../../../core/wysiwyg-editor/wysiwyg-editor';

const FormWysiwyg: FC<FormWysiwygProps> = (props) => {
  const [disabled, setDisabled] = useState(true);
  const [inputValue, setInputValue] = useState(
    props.value === undefined ? '' : props.value,
  );
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const context = useContext(AppContext);

  const [error, setError] = useState<string | null>(null);

  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  const checkErrors = (value: any) => {
    let currentError = null;
    if (
      (value === null || value === undefined || value.trim() === '') &&
      props.required
    )
      currentError = context.i18n.inputRequiredError;
    setError(currentError);

    return currentError;
  };

  useEffect(() => {
    checkErrors(inputValue);
    props.dialog &&
      props.onChange &&
      props.onChange(inputValue, checkErrors(inputValue), true);
  }, []);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    props.saved && props.onSave && props.onSave(inputValue);
    props.saved ? setDisabled(props.saved) : null;
    context.saveAll ? setDisabled(context.saveAll) : null;
  }, [props.saved, context.saveAll]);

  const onSave = () => {
    if (error !== null) return;
    props.onSave ? props.onSave(inputValue) : null;
    setDisabled(true);
  };

  const onChange = (changedInput: string) => {
    setInputValue(changedInput);
    props.onChange && props.onChange(changedInput, checkErrors(changedInput));
  };

  const onCancel = () => {
    setDisabled(true);
    setFocus(false);
    setInputValue(props.value ? props.value : '');
    props.onCancel ? props.onCancel() : null;
  };

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    } else if (!focus) {
      setDisabled(false);
      // time out is needed because disabled items cannot be focused
      setTimeout(() => {
        inputRef.current?.focus();
      }, 10);
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ m: 2 }}>
      <>
        {props.title && (
          <FormElementHeader
            required={props.required}
            hideExport={props.hideExport}
            title={props.title}
          />
        )}

        <Box>
          <WysiwygEditor
            onClick={onClick.bind(this)}
            id={props.id}
            value={inputValue}
            // inputRef={inputRef}
            onChange={(v) => onChange(v)}
            disabled={(disabled && !props.dialog) || readOnly}
            noDisabledStyle={props.noDisabledStyle}
            error={error !== null || props.error}
            endAdornment={
              !props.dialog &&
              !props.preview &&
              !readOnly && (
                <FormEdit
                  disabled={disabled}
                  onCancel={onCancel.bind(this)}
                  onEdit={(disabled) => {
                    props.onClick ? props.onClick() : setDisabled(disabled);
                  }}
                  onSave={() => onSave()}
                />
              )
            }
          />
          <FormHelperText error>
            {error && error}
            {props.helperText && props.helperText}
          </FormHelperText>
        </Box>
      </>
    </Box>
  );
};

export default FormWysiwyg;
