import { SvgIconProps } from '@mui/material';

export enum CaseCareSvgIconType {
  DEFAULT = 0,
  OUTLINED = 1,
  FILLED = 2,
  BORDER = 3,
}

export interface CaseCareSvgIconProps extends SvgIconProps {
  iconType?: CaseCareSvgIconType;
}
