import { Avatar, Box, Button, CircularProgress, Stack } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AdminDrawer } from '../../../components/admin';
import Navbar from '../../../components/core/navbar/navbar';
import { AppContext } from '../../../hooks/context';
import { stripePromise } from '../../../utils/stripe/stripe';
import { Elements, AddressElement } from '@stripe/react-stripe-js';
import { OrgApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { useQuery } from 'react-query';
import { queryClient } from '../../../utils';
import { MenuWrapper } from '../../../components/core';

const OrganizationData: FC = () => {
  const context = useContext(AppContext);

  const [imageUrl, setImageUrl] = useState<string>();
  const [logo, setLogo] = useState<File>();
  const [address, setAddress] = useState<any>();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState<string>();
  const [complete, setComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSelectImage = (image: File) => {
    setImageUrl(URL.createObjectURL(image));
    setLogo(image);
  };

  const onUpdate = async () => {
    const res = await OrgApi.updateOrganizationData(context.authToken, {
      address,
      name,
      phone,
    });

    if (res.message) snackbarUtils.success(res.message);
  };

  useEffect(() => {
    onUploadLogo();
  }, [logo]);

  useQuery(['logo'], () => OrgApi.getLogo(context.authToken), {
    enabled: context.authToken !== undefined,
    onSuccess: (res) => {
      setImageUrl(res.data);
    },
  });

  useEffect(() => {
    setIsLoading(false);
  }, [address]);

  useQuery(
    ['organizationData'],
    () => OrgApi.getOrganizationData(context.authToken),
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setIsLoading(true);
        if (res.data.address) setAddress(res.data.address);
        else setAddress({ country: 'AT' });

        setName(res.data.name);
        setPhone(res.data.phone);
      },
    },
  );

  const onUploadLogo = async () => {
    if (logo) {
      const res = await OrgApi.uploadLogo(context.authToken, logo);

      if (res.message) snackbarUtils.success(res.message);

      queryClient.invalidateQueries('logo');
    }
  };

  return (
    <MenuWrapper
      title={context.i18n.orgData}
      selected="admin-data"
      addClient
      search
      admin
    >
      <Stack alignItems="center" spacing={2}>
        <img style={{ maxHeight: 100 }} src={imageUrl} />
        <Button variant="contained" component="label">
          {context.i18n.logoUpload}
          <input
            type="file"
            hidden
            accept="image/png, image/gif, image/jpeg"
            onClick={(e) => (e.currentTarget.value = '')}
            onChange={(e) => {
              e.target && e.target.files && onSelectImage(e.target.files[0]);
            }}
          />
        </Button>
        {!isLoading ? (
          <Elements
            stripe={stripePromise}
            options={{
              locale: 'auto',
            }}
          >
            <AddressElement
              onChange={(event) => {
                setComplete(event.complete);
                setAddress(event.value.address);
                setName(event.value.name);
                setPhone(event.value.phone);
              }}
              options={{
                mode: 'billing',
                fields: {
                  phone: 'always',
                },
                defaultValues: Object.assign(
                  {
                    name,
                  },
                  address && { address },
                  phone && { phone },
                ),
              }}
            />
            <Button
              disabled={!complete}
              variant="contained"
              component="label"
              onClick={onUpdate}
            >
              {context.i18n.saveBtnTooltip}
            </Button>
          </Elements>
        ) : (
          <CircularProgress
            sx={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        )}
      </Stack>
    </MenuWrapper>
  );
};

export default OrganizationData;
