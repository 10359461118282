import { FC, useContext, useEffect, useState } from 'react';
import { StyledMenu } from '../../core';
import {
  Box,
  Button,
  MenuItem,
  MenuList,
  alpha,
  useTheme,
} from '@mui/material';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import moment from 'moment';
import { ProtocolApi } from '../../../api';
import { useQuery, useQueryClient } from 'react-query';
import {
  CreateInvoiceDialog,
  EditProtocolDialog,
  RejectProtocolDialog,
  ReleaseProtocolDialog,
} from '../../dialogs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileArrowDown,
  faFileCircleXmark,
  faFileExport,
  faFileInvoice,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Protocol, ReleaseProtocol } from '../../../types';
import { uniq } from 'underscore';

interface ProtocolBillingMenuProps {
  anchorEl: Element | null | undefined;
  open: boolean;
  onClose: () => void;
  selectedProtocol?: ReleaseProtocol;
  selectedProtocols?: ReleaseProtocol[];
  rejected: boolean;
  released: boolean;
  isToolbar?: boolean;
  editOpen?: boolean;
  setEditOpen?: (v: any) => void;
  availableOrderers?: any[];
  selectedMonth?: moment.Moment;
}

export const ProtocolBillingMenu: FC<ProtocolBillingMenuProps> = (props) => {
  const {
    anchorEl,
    open,
    onClose,
    selectedProtocol,
    selectedProtocols,
    rejected,
    released,
    isToolbar,
    editOpen,
    setEditOpen,
    availableOrderers,
    selectedMonth,
  } = props;
  const theme = useTheme();
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [releaseId, setReleaseId] = useState<string>();

  // const [clientId, setClientId] = useState<string | undefined>();
  // const [familyId, setFamilyId] = useState<string | undefined>();

  const uniqueKey = selectedProtocol ? selectedProtocol.id : 'toolbar';

  const [openRejectionDialog, setOpenRejectionDialog] = useState(false);

  const [openReleaseDialog, setOpenReleaseDialog] = useState(false);
  const [releaseProtocolBody, setReleaseProtocolBody] = useState({});

  const [openCreateInvoice, setOpenCreateInvoice] = useState(false);

  const [protocolUsers, setProtocolUsers] = useState([]);

  const [clientBilling, setClientBilling] = useState(false);

  const [protocol, setProtocol] = useState<Protocol | undefined>();
  const [protocolId, setProtocolId] = useState();

  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (editOpen !== undefined) setEditDialogOpen(editOpen);
  }, [editOpen]);

  const { isLoading: protocolLoading } = useQuery(
    ['getProtocol', protocolId],
    () => ProtocolApi.getProtocol(context.authToken, protocolId),
    {
      enabled:
        context.authToken !== undefined &&
        protocolId !== undefined &&
        editDialogOpen != false,
      onSuccess: (res) => {
        setProtocol(res.data);
      },
    },
  );

  const onReleaseProtocols = async (body?: any) => {
    try {
      const res = await ProtocolApi.releaseProtocol(
        context.authToken,
        body || releaseProtocolBody,
      );

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      onClose();
      queryClient.invalidateQueries('billing');
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onExport = async () => {
    try {
      const performedExports: string[] = [];
      if (!selectedProtocols && selectedProtocol) {
        performExport(selectedProtocol);
      } else if (selectedProtocols) {
        for (const i in selectedProtocols) {
          const p = selectedProtocols[i];
          if (
            (p.familyId && !performedExports.includes(p.familyId)) ||
            (!p.familyId &&
              p.clientId &&
              !performedExports.includes(p.clientId))
          ) {
            performExport(p);
            if (p.familyId) {
              performedExports.push(p.familyId);
            } else if (p.clientId) {
              performedExports.push(p.clientId);
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const performExport = async (p: ReleaseProtocol) => {
    if (p) {
      let res;
      if (p.familyId) {
        res = await ProtocolApi.exportFamilyProtocols(
          context.authToken,
          p.familyId,
          moment(p.startDate).toDate(),
          // p.id,
        );
      } else {
        res = await ProtocolApi.exportClientProtocols(
          context.authToken,
          p.clientId,
          moment(p.startDate).toDate(),
          // p.id,
        );
      }

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `${context.i18n.protocol}_${p.fullName.replaceAll(
        ' ',
        '_',
      )}_${moment(p.startDate).format('MMYYYY')}.docx`;
      a.click();
    }
  };

  const onRejectProtocol = async (rejectText: string) => {
    try {
      const body = Object.assign(
        {
          releaseProtocolId: releaseId,
          rejectText,
        },
        selectedProtocol && {
          releaseProtocolId: selectedProtocol.id,
        },
        selectedProtocols && {
          releaseProtocolIds: uniq(
            selectedProtocols.filter((p) => !p.invoiceId).map((p) => p.id),
          ),
        },
        selectedProtocol &&
          selectedProtocol.clientId && {
            clientId: selectedProtocol.clientId,
          },
        selectedProtocol &&
          selectedProtocol.familyId && {
            familyId: selectedProtocol.familyId,
          },
      );

      const res = await ProtocolApi.rejectProtocol(context.authToken, body);

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      setOpenRejectionDialog(false);
      onClose();
      queryClient.invalidateQueries('billing');
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  useEffect(() => {
    const p: any = selectedProtocol
      ? selectedProtocol
      : selectedProtocols
      ? selectedProtocols[0]
      : {};
    setProtocolId(p?.protocolId);
    // setProtocol(p);
  }, [selectedProtocol, selectedProtocols]);

  const onReleaseBtnClick = () => {
    const releaseProtocolBody = Object.assign(
      {},
      selectedProtocols &&
        selectedProtocols.length > 0 && {
          releaseProtocolIds: uniq(selectedProtocols.map((p) => p.id)),
        },
      selectedProtocol && {
        releaseProtocolId: selectedProtocol.id,
        clientId: selectedProtocol.clientId,
        familyId: selectedProtocol.familyId,
      },
    );
    // setReleaseProtocolBody(releaseProtocolBody);
    // if (context.sevDeskActive) {
    //   setOpenReleaseDialog(true);
    // } else {
    onReleaseProtocols(releaseProtocolBody);
    //}
  };

  const menuItems = [
    !rejected &&
      released &&
      !selectedProtocol &&
      selectedProtocols &&
      selectedProtocols.length > 0 && (
        <MenuItem
          disableRipple
          id={'export-button-' + uniqueKey}
          key={'export-button-' + uniqueKey}
          sx={{ height: 39, color: theme.palette.primary.main }}
          disabled={!context.basicActive}
          onClick={() => {
            onExport();
            onClose();
          }}
        >
          <FontAwesomeIcon icon={faFileArrowDown} />
          {context.i18n.export}
        </MenuItem>
      ),
    !rejected && (
      <MenuItem
        disableRipple
        id={'deny-button-' + uniqueKey}
        key={'deny-button-' + uniqueKey}
        sx={{
          height: 39,
          color: theme.palette.error.main,
        }}
        disabled={!context.basicActive}
        onClick={() => {
          setOpenRejectionDialog(true);
        }}
      >
        <FontAwesomeIcon icon={faFileCircleXmark} />
        {context.i18n.reject}
      </MenuItem>
    ),
    !rejected &&
      !released &&
      (selectedProtocol ||
        (selectedProtocols && selectedProtocols.length === 1)) && (
        <MenuItem
          disableRipple
          id={'edit-button-' + uniqueKey}
          key={'edit-button-' + uniqueKey}
          sx={{ height: 39, color: theme.palette.primary.main }}
          disabled={!context.basicActive}
          onClick={() => {
            setEditDialogOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
          {context.i18n.edit}
        </MenuItem>
      ),
    selectedProtocol && selectedProtocol.invoiceId && (
      <MenuItem
        disableRipple
        disabled={!context.sevDeskActive}
        color="primary"
        id={'release-button-' + uniqueKey}
        key={'release-button-' + uniqueKey}
        sx={{ height: 39, color: theme.palette.primary.main }}
        onClick={() => {
          window.open(
            'https://my.sevdesk.de/fi/edit/type/RE/id/' +
              selectedProtocol.invoiceId,
            '_blank',
          );
          onClose();
        }}
      >
        <FontAwesomeIcon icon={faFileInvoice} />
        {context.i18n.openInvoice}
      </MenuItem>
    ),
    !released && !rejected && (
      <MenuItem
        disableRipple
        id={'release-button-' + uniqueKey}
        key={'release-button-' + uniqueKey}
        sx={{ height: 39, color: theme.palette.success.main }}
        disabled={!context.basicActive}
        onClick={() => {
          onReleaseBtnClick();
        }}
      >
        <FontAwesomeIcon icon={faFileExport} />
        {context.i18n.release}
      </MenuItem>
    ),
  ];

  return (
    <>
      {isToolbar ? (
        open && (
          <Box
            sx={{
              borderRadius: 3,
              backgroundColor: 'lightgray',
              display: 'inline-block',
            }}
          >
            <Box
              display="flex"
              sx={{
                flexDirection: 'row',
                '& .MuiMenuItem-root': {
                  '& .svg-inline--fa ': {
                    fontSize: 20,
                    marginRight: theme.spacing(1.5),
                  },
                  '&:active': {
                    backgroundColor: alpha(
                      theme.palette.primary.main,
                      theme.palette.action.selectedOpacity,
                    ),
                  },
                },
              }}
            >
              {menuItems}
            </Box>
          </Box>
        )
      ) : (
        <StyledMenu anchorEl={anchorEl} open={open} onClose={onClose}>
          {menuItems}
        </StyledMenu>
      )}
      {props.isToolbar && released && !rejected && (
        <Button variant="outlined" onClick={() => setOpenCreateInvoice(true)}>
          Rechnung erstellen
        </Button>
      )}
      <EditProtocolDialog
        open={editDialogOpen}
        protocol={protocol}
        onClose={() => {
          setTimeout(() => {
            setProtocol(undefined);
            setProtocolId(undefined);
            setEditDialogOpen(false);
            setEditOpen !== undefined && setEditOpen(false);
            queryClient.invalidateQueries('billing');
          }, 100);
        }}
        clientId={protocol?.clientId}
        familyId={protocol?.familyId}
        loading={protocolLoading || !protocol}
        showReleaseButton={!released && !rejected}
        onRelease={() => onReleaseBtnClick()}
        isEdit
      />
      <ReleaseProtocolDialog
        onCreateInvoice={() => {
          setOpenReleaseDialog(false);
          // setOpenCreateInvoice(true);
        }}
        open={openReleaseDialog}
        onClose={() => setOpenReleaseDialog(false)}
      />
      <RejectProtocolDialog
        open={openRejectionDialog}
        onClose={() => setOpenRejectionDialog(false)}
        onReturn={onRejectProtocol}
      />
      <CreateInvoiceDialog
        open={openCreateInvoice}
        onClose={() => setOpenCreateInvoice(false)}
        // protocolUsers={protocolUsers}
        // releaseProtocolBody={releaseProtocolBody}
        clientBilling={clientBilling}
        availableOrderers={availableOrderers || []}
        selectedMonth={selectedMonth}
      />
    </>
  );
};
