import { ModuleType } from '@casecare/types';
import { Box, Grid, Link, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { AboApi } from '../../../api';
import { AboCard } from '../../../components/admin';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { MenuWrapper } from '../../../components/core';

const Abo: FC = () => {
  const context = useContext(AppContext);

  const [familyActive, setFamilyActive] = useState(false);
  const [riskActive, setRiskActive] = useState(false);
  const [basicActive, setBasicActive] = useState(false);
  const [billingActive, setBillingActive] = useState(false);
  const [genoGrammActive, setGenoGrammActive] = useState(false);

  const openStripeUrl = async () => {
    const res = await AboApi.getStripeUrl(
      context.authToken,
      window.location.href,
    );

    window.location.href = res.data;
  };

  useQuery(['abos'], () => AboApi.listAbos(context.authToken), {
    enabled: context.authToken !== undefined,
    onSuccess: (res) => {
      setFamilyActive(res.data.riskActive);
      setRiskActive(res.data.riskActive);
      setBasicActive(res.data.basicActive);
      setBillingActive(res.data.billingActive);
      setGenoGrammActive(res.data.genoGrammActive);
    },
    onError: (e) => {
      const err = e as AxiosError;
      const errorData = err.response?.data;
      errorData.detail.forEach((message: string) => {
        snackbarUtils.error(message);
      });
    },
  });

  const aboModules = [
    {
      title: context.i18n.basicModule,
      status: basicActive,
      type: ModuleType.BASIC,
      description: context.i18n.basicModuleDescription,
    },
    {
      title: context.i18n.familyModule,
      status: familyActive,
      type: ModuleType.FAMILY,
      description: context.i18n.familyModuleDescription,
    },
    {
      title: context.i18n.riskModule,
      status: riskActive,
      type: ModuleType.RISK,
      description: context.i18n.riskModuleDescription,
    },
    {
      title: context.i18n.genoGrammModule,
      status: genoGrammActive,
      type: ModuleType.GENO_GRAMM,
      description: context.i18n.genoGrammModuleDescription,
    },
    {
      title: context.i18n.billingModule,
      status: billingActive,
      type: ModuleType.BILLING,
      description: context.i18n.billingModuleDescription,
    },
  ];

  return (
    <MenuWrapper
      search
      addClient
      selected="admin-abo"
      title={context.i18n.abos}
      admin
    >
      <Box
        display="flex"
        alignContent="center"
        flexDirection="column"
        sx={{ height: '100%', position: 'fixed' }}
      >
        <Grid container direction="row">
          {aboModules.map((abo, index) => (
            <Grid key={index} p={2} xs={4} item>
              <AboCard
                title={abo.title}
                aboState={abo.status}
                type={abo.type}
                description={abo.description}
              />
            </Grid>
          ))}
        </Grid>
        <Link m={2} onClick={openStripeUrl}>
          <Typography variant="h5">{context.i18n.manageAbos}</Typography>
        </Link>
      </Box>
    </MenuWrapper>
  );
};

export default Abo;
