import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { useQuery } from 'react-query';
import { ClientApi, FamilyApi } from '../../../api';

interface ExportProtocolDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (date: Date, helpFormId?: string) => void;
  clientId?: string;
  familyId?: string;
  month?: moment.Moment;
}

const ExportProtocolDialog: FC<ExportProtocolDialogProps> = (props) => {
  const context = useContext(AppContext);

  const { clientId, familyId, month } = props;

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [selectedHelpForm, setSelectedHelpForm] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [availableHelpForms, setAvailableHelpForms] = useState([]);

  useEffect(() => {
    if (month) {
      setDate(moment(month));
    }
  }, [month]);

  const { isLoading: hfLoading } = useQuery(
    ['exportGetAvailableHelpForms', clientId, familyId],
    () => {
      if (familyId) {
        return FamilyApi.getMinimalFamilyHelpForms(context.authToken, familyId);
      } else if (clientId) {
        return ClientApi.getMinimalClientHelpForms(context.authToken, clientId);
      }
    },
    {
      enabled:
        context.authToken !== undefined &&
        props.open &&
        (familyId !== undefined || clientId !== undefined),
      onSuccess: (d) => setAvailableHelpForms(d.data),
    },
  );

  useEffect(() => {
    setOpen(props.open);
    if (!props.open) {
      setSelectedHelpForm(null);
    }
    setLoading(false);
  }, [props.open]);

  const onSelect = () => {
    setLoading(true);
    props.onSelect(
      date.toDate(),
      selectedHelpForm !== null ? selectedHelpForm.id : undefined,
    );
  };

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.exportProtocol}</DialogTitle>
      <DialogContent sx={{ display: 'flex', height: '70%' }}>
        {loading || hfLoading ? (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              color: 'primary',
            }}
          />
        ) : (
          <Box display="flex" flexDirection="column">
            <FormControl color="secondary" sx={{ mt: 2, width: '22rem' }}>
              <DatePicker
                openTo="month"
                views={['year', 'month']}
                label={context.i18n.exportMonth}
                value={date}
                onChange={(value) => value !== null && setDate(value)}
                slotProps={{
                  textField: {
                    onKeyDown: (e) => {
                      e.preventDefault();
                    },
                    fullWidth: true,
                  },
                }}
              />
            </FormControl>
            {availableHelpForms.length > 1 && (
              <Autocomplete
                disablePortal
                options={availableHelpForms}
                value={selectedHelpForm || null}
                getOptionLabel={(option: any) => option.name || ''}
                onChange={(e, v) => setSelectedHelpForm(v)}
                isOptionEqualToValue={(o: any, v: any) => o.id === v.id}
                renderInput={(params) => (
                  <TextField {...params} label={context.i18n.clientHelpForm} />
                )}
                sx={{ mt: 2 }}
              />
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.export}>
          <IconButton
            disableRipple
            color="primary"
            onClick={onSelect}
            disabled={
              availableHelpForms.length > 1 ? selectedHelpForm === null : false
            }
          >
            <FontAwesomeIcon icon={faFileExport} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ExportProtocolDialog;
