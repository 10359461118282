import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  faFileCircleXmark,
  faFloppyDisk,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface RetunrProtocolDialogProps {
  open: boolean;
  onClose: () => void;
  onReturn: (text: string) => void;
}

export const RejectProtocolDialog: FC<RetunrProtocolDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [rejectionReason, setRejectionReason] = useState('');

  useEffect(() => {
    setRejectionReason('');
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.rejectProtocol}</DialogTitle>
      <DialogContent>
        <TextField
          required
          sx={{ mt: 2, width: '22rem' }}
          color="secondary"
          label={context.i18n.rejectionReason}
          fullWidth
          type={'text'}
          value={rejectionReason}
          onChange={(e) => {
            setRejectionReason(e.currentTarget.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.rejectProtocol}>
          <IconButton
            onClick={() => props.onReturn(rejectionReason)}
            color="error"
            disabled={rejectionReason === ''}
          >
            <FontAwesomeIcon icon={faFileCircleXmark} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose.bind(this)} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
