import { axiosInstance } from '../../utils';

export default class AdminBillingApi {
  static saveToken(
    token: string | undefined,
    sevDeskToken: string,
  ): Promise<any> {
    return axiosInstance
      .post(
        '/admin/billing/token',
        {
          token: sevDeskToken,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getSevDeskStatus(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/admin/billing/status', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editOrCreateSevDeskContact(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('/admin/billing/contact', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getSevDeskContacts(
    token: string | undefined,
    orgId?: string,
  ): Promise<any> {
    return axiosInstance
      .get(
        orgId ? '/admin/billing/contacts/' + orgId : '/admin/billing/contacts',
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getSevDeskOrganizationContacts(
    token: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('/admin/billing/contacts/org', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteSevDeskContact(
    token: string | undefined,
    id: string,
  ): Promise<any> {
    return axiosInstance
      .delete('/admin/billing/contact/' + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getSevDeskContact(
    token: string | undefined,
    id: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('/admin/billing/contact/' + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createSevDeskInvoice(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('/admin/billing/invoice', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editOrCreateSevDeskProduct(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('/admin/billing/product', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getSevDeskProducts(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/admin/billing/product', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getSevDeskProduct(
    token: string | undefined,
    id: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('/admin/billing/product/' + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
