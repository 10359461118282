import {
  faCaretLeft,
  faCaretRight,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Paper, Slide, Stack } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { RiskApi } from '../../../../api';
import { AppContext } from '../../../../hooks/context';
import { RiskAssessmentAnswer } from '../../../../types';
import { RiskAssessmentSurveyResult } from '../../../../types/risk-assesment';
import { RiskAssessmentQuestion } from '../../../../types/risk-assessment-question';
import { evaluateChildRiskAssessment } from '../../../../utils';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { AddRiskSubtypeDialog } from '../../../dialogs';
import RiksAssessmentResult from '../risk-assessment-result/risk-assessment-result';
import RiskAssesmentSlide from './risk-assessment-slide';

interface RiskAssessmentProps {
  questions: RiskAssessmentQuestion[];
  answers: RiskAssessmentAnswer[];
  surveyId: string;
  riskId: string;
  updateResult: (result: RiskAssessmentSurveyResult, riskId: string) => void;
  changeSubRiskassement?: (next: boolean) => void;
  disableNextRiskChange?: boolean;
  disablePreviousRiskChange?: boolean;
  availableSubTypes: { name: string; id: string }[];
  onAdd: (id: string) => void;
  name?: string;
  readOnly?: boolean;
}

const RiskAssesmentForm: FC<RiskAssessmentProps> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [enter, setEnter] = useState(true);
  const [nextClicked, setNextClicked] = useState(true);
  const [riskAssessmentQuestions, setRiskassessmentQuestions] = useState<
    RiskAssessmentQuestion[]
  >(props.questions);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [value, setValue] = useState<number | string>('');
  const [surveyId, setSurveyId] = useState(props.surveyId);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<number>();
  const [result, setResult] = useState(RiskAssessmentSurveyResult.UNFINISHED);
  const [availableSubTypes, setAvailableSubtypes] = useState<any[]>([]);
  const [addSubtypeOpen, setAddSubtypeOpen] = useState(false);
  const [name, setName] = useState(props.name);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);
  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  useEffect(() => {
    setAvailableSubtypes(props.availableSubTypes);
  }, [props.availableSubTypes]);

  useEffect(() => {
    setSurveyId(props.surveyId);
    evaluateResult();
  }, [props.surveyId]);

  useEffect(() => {
    setActiveQuestionIndex(0);
    evaluateResult();
  }, [props.riskId]);

  useEffect(() => {
    setRiskassessmentQuestions(props.questions);
  }, [props.questions]);

  useEffect(() => {
    const answer = props.answers.find(
      (answer) =>
        answer.question === riskAssessmentQuestions[activeQuestionIndex].id,
    );
    setValue(answer ? answer.value : '');
    return evaluateResult();
  }, [props.answers, activeQuestionIndex]);

  const onChangeQuestion = (newActiveIndex: number, next: boolean) => {
    setEnter(!enter);
    setNextClicked(next);
    setActiveQuestionIndex(newActiveIndex);
  };

  const evaluateResult = () => {
    const newResult = evaluateChildRiskAssessment(
      riskAssessmentQuestions,
      props.answers,
    );
    if (result !== newResult) {
      props.updateResult(
        newResult !== RiskAssessmentSurveyResult.REPEAT
          ? newResult
          : RiskAssessmentSurveyResult.UNFINISHED,
        props.riskId,
      );
    }
    setResult(newResult);
  };

  const changeValue = async (newValue: number) => {
    if (!readOnly) {
      setValue(newValue);
      try {
        const res = await RiskApi.updateAnswers(
          context.authToken,
          props.riskId,
          surveyId,
          [
            {
              question: riskAssessmentQuestions[activeQuestionIndex].id,
              value: newValue,
            },
          ],
        );
        if (res.message) snackbarUtils.success(res.message);
      } catch (e) {
        snackbarUtils.error(context.i18n.error);
      }
      setTimeout(() => {
        queryClient.invalidateQueries('survey');
      }, 100);
    }
  };

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        borderRadius: 0,
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Slide
        direction={nextClicked ? 'left' : 'right'}
        mountOnEnter
        unmountOnExit
        in={enter}
      >
        <Box>
          <RiskAssesmentSlide
            enter={enter}
            pagination={
              activeQuestionIndex + 1 + '/' + riskAssessmentQuestions.length
            }
            question={riskAssessmentQuestions[activeQuestionIndex]}
            value={value}
            setValue={(value: number) => changeValue(value)}
            selectedAgeGroup={selectedAgeGroup}
            setSelectedAgeGroup={(ageGroup) => setSelectedAgeGroup(ageGroup)}
          />
        </Box>
      </Slide>
      <Slide
        direction={nextClicked ? 'left' : 'right'}
        mountOnEnter
        unmountOnExit
        in={!enter}
      >
        <Box>
          <RiskAssesmentSlide
            pagination={
              activeQuestionIndex + 1 + '/' + riskAssessmentQuestions.length
            }
            enter={!enter}
            question={riskAssessmentQuestions[activeQuestionIndex]}
            value={value}
            setValue={(value: number) => changeValue(value)}
            selectedAgeGroup={selectedAgeGroup}
            setSelectedAgeGroup={(ageGroup) => setSelectedAgeGroup(ageGroup)}
          />
        </Box>
      </Slide>
      <RiksAssessmentResult result={result} name={name ? name : ''} />
      <Stack spacing={2} direction="row" justifyContent="center" sx={{ m: 3 }}>
        {activeQuestionIndex === 0 ? (
          <Button
            variant="contained"
            onClick={() =>
              props.changeSubRiskassement
                ? props.changeSubRiskassement(false)
                : null
            }
            disabled={props.disablePreviousRiskChange}
            startIcon={<FontAwesomeIcon icon={faCaretLeft} />}
            color="success"
          >
            {context.i18n.previousRiskAssessment}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => onChangeQuestion(activeQuestionIndex - 1, false)}
            startIcon={<FontAwesomeIcon icon={faCaretLeft} />}
          >
            {context.i18n.previousQuestion}
          </Button>
        )}
        {riskAssessmentQuestions.length === activeQuestionIndex + 1 ? (
          props.disableNextRiskChange ? (
            <Button
              variant="contained"
              onClick={() => setAddSubtypeOpen(true)}
              disabled={availableSubTypes.length === 0}
              endIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              {context.i18n.addSubRiskAssessment}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() =>
                props.changeSubRiskassement
                  ? props.changeSubRiskassement(true)
                  : null
              }
              disabled={props.disableNextRiskChange}
              endIcon={<FontAwesomeIcon icon={faCaretRight} />}
              color="success"
            >
              {context.i18n.nextRiskAssessment}
            </Button>
          )
        ) : (
          <Button
            variant="contained"
            onClick={() => onChangeQuestion(activeQuestionIndex + 1, true)}
            endIcon={<FontAwesomeIcon icon={faCaretRight} />}
          >
            {context.i18n.nextQuestion}
          </Button>
        )}
      </Stack>
      <AddRiskSubtypeDialog
        open={addSubtypeOpen}
        onClose={() => setAddSubtypeOpen(false)}
        risks={availableSubTypes}
        surveyId={surveyId}
        onAdd={props.onAdd}
      />
    </Paper>
  );
};

export default RiskAssesmentForm;
