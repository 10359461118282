export enum FormFieldStyle {
  DEFAULT = 'DEFAULT',
  NORMAL = 'NORMAL',
  OUTLINED = 'OUTLINED',
  COLOR_PICKER = 'COLOR_PICKER',
}

export interface FormElementProps {
  id: string;
  title?: string;
  hideExport: boolean | undefined;
  saved?: boolean;
  onSave?: (value: any) => void;
  onCancel?: () => void;
  onChange?: (value: any, error: string | null, onLoad?: boolean) => void;
  required?: boolean;
  dialog?: boolean;
  preview?: boolean;
  readOnly?: boolean;
  groupId?: string;
  style?: FormFieldStyle;
  disabled?: boolean;
  disableCloseOnSelect?: boolean;
}
