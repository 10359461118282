import { FC, useContext, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AppContext } from '../../../hooks/context';
import { AdminApi } from '../../../api';
import LoginInput from '../../public/login-input/login-input';
import { useQueryClient } from 'react-query';
import { selectableRoles } from '../../../types/roles';

export interface User {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  role: string;
  creationDate: string;
  academicGradePre: string;
  academicGradeAft: string;
  companyPhoneNumber: string;
}

interface InviteUserDialogProps {
  open: boolean;
  onClose: () => void;
}

const InviteUserDialog: FC<InviteUserDialogProps> = (
  props: InviteUserDialogProps,
) => {
  const { open, onClose } = props;
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('USER');
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const queryClient = useQueryClient();
  const [academicGradePre, setAcademicGradePre] = useState('');
  const [academicGradeAft, setAcademicGradeAft] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');

  const resetState = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setRole('USER');
    setAcademicGradeAft('');
    setAcademicGradePre('');
    setCompanyPhoneNumber('');
  };

  const inviteUser = async () => {
    setLoading(true);
    await AdminApi.inviteUser(context.authToken, {
      email,
      firstName,
      lastName,
      role,
      academicGradeAft,
      academicGradePre,
      companyPhoneNumber,
    })
      .then((res) => {
        snackbarUtils.success(res.message || context.i18n.success);
        closeAndReload();
      })
      .catch((error) => {
        const errorData = error.response.data;
        errorData.detail?.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      });
    setLoading(false);
  };

  const closeAndReload = () => {
    resetState();
    queryClient.invalidateQueries('getUsers');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetState();
        onClose();
      }}
    >
      <DialogTitle>{context.i18n.inviteUser}</DialogTitle>
      <DialogContent>
        <LoginInput
          label={context.i18n.academicPre}
          onChange={setAcademicGradePre.bind(this)}
          initialValue={academicGradePre}
        />
        <LoginInput
          label={context.i18n.firstName}
          onChange={setFirstName.bind(this)}
          initialValue={firstName}
        />
        <LoginInput
          label={context.i18n.lastName}
          onChange={setLastName.bind(this)}
          initialValue={lastName}
        />
        <LoginInput
          label={context.i18n.academicAft}
          onChange={setAcademicGradeAft.bind(this)}
          initialValue={academicGradeAft}
        />
        <LoginInput
          label={context.i18n.phoneNumberCompany}
          onChange={setCompanyPhoneNumber.bind(this)}
          initialValue={companyPhoneNumber}
        />
        <LoginInput
          onChange={setEmail.bind(this)}
          label={context.i18n.email}
          initialValue={email}
        />
        <FormControl color="secondary" sx={{ mt: 2, width: '22rem' }}>
          <InputLabel sx={{ alignSelf: 'center' }} id="role-slect-label">
            {context.i18n.role}
          </InputLabel>
          <Select
            labelId="role-select-label"
            label={context.i18n.role}
            value={role}
          >
            {selectableRoles.map((r) => (
              <MenuItem key={r} value={r} onClick={() => setRole(r)}>
                {context.i18n[r.toLowerCase()]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{ mt: 2 }}
        >
          <Button
            color="primary"
            variant="contained"
            id="loginBtn"
            onClick={inviteUser}
            className="formBtn"
            disabled={
              email === '' || firstName === '' || lastName === '' || role === ''
            }
            sx={{ color: 'white', mr: 'auto', ml: 'auto' }}
          >
            {loading ? (
              <CircularProgress sx={{ color: 'white' }} />
            ) : (
              context.i18n.inviteUser
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserDialog;
