import { ModuleType } from '@casecare/types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { FC, useContext, useState } from 'react';
import { AboApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { stripePromise } from '../../../utils/stripe/stripe';
import { PaymentDialog } from '../../dialogs';

interface AboCardProps {
  title: string;
  aboState: boolean;
  type: ModuleType;
  description: string;
}

const AboCard: FC<AboCardProps> = (props) => {
  const context = useContext(AppContext);

  const [clientSecret, setClientSecret] = useState('');

  const addAbo = async () => {
    const res = await AboApi.addAbo(context.authToken, props.type);

    setClientSecret(res.data);
  };

  return (
    <Card sx={{ minHeight: '400px' }}>
      <CardHeader title={props.title} sx={{ textAlign: 'center' }} />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography>{props.description}</Typography>
          <Typography variant="h5" component="p">
            € 9,99 / Monat
          </Typography>
          {props.aboState ? (
            <Typography>{context.i18n.active}</Typography>
          ) : (
            <Button onClick={() => addAbo()} variant="outlined" color="success">
              <Typography>{context.i18n.activate}</Typography>
            </Button>
          )}
        </Box>
      </CardContent>
      {clientSecret !== '' && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
          }}
        >
          <PaymentDialog
            onClose={() => {
              setClientSecret('');
              window.location.reload();
            }}
          />
        </Elements>
      )}
    </Card>
  );
};

export default AboCard;
