import { Tabs, Tab, Avatar, Badge } from '@mui/material';
import { FC } from 'react';
import { TabScrollButtonStyle } from '../../../utils/styling/styling';
import ghost from '../../../assets/avatar/ghost.svg';

interface AvatarTabsProps {
  avatars: { url: string; id: string; name: string; isDead: boolean }[];
  onChange: (id: string) => void;
}

const AvatarTabs: FC<AvatarTabsProps> = (props) => {
  return (
    <Tabs
      sx={{
        maxWidth: 500,
        mr: 2,
        ...TabScrollButtonStyle,
      }}
      variant="scrollable"
      value={false}
      scrollButtons={props.avatars.length > 3}
    >
      {props.avatars.map((a) => (
        <Tab
          key={a.id}
          label={a.name}
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
          onClick={() => props.onChange(a.id)}
          icon={
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  transform: 'translate(-40px, 10px)',
                  display: a.isDead ? 'inherit' : 'none',
                },
              }}
              badgeContent={<img width={50} src={ghost} />}
            >
              <Avatar
                src={a.url}
                sx={{
                  width: 100,
                  height: 150,
                  filter: a.isDead ? 'grayscale(1)' : 'inherit',
                }}
              />
            </Badge>
          }
        />
      ))}
    </Tabs>
  );
};

export default AvatarTabs;
