import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RiskAssessmentQuestion } from '../../../../types';

interface RiskAssesmentSlideProps {
  enter: boolean;
  question: RiskAssessmentQuestion;
  value: number | string;
  setValue: (value: number) => void;
  setSelectedAgeGroup: (ageGroup: number) => void;
  selectedAgeGroup: number | undefined;
  pagination: string;
}

const RiskAssesmentSlide: FC<RiskAssesmentSlideProps> = (props) => {
  const [enter, setEnter] = useState(props.enter);

  useEffect(() => {
    setEnter(props.enter);
  }, [props.enter]);

  const openAcordion = (index: number) => {
    if (props.selectedAgeGroup === index) {
      props.setSelectedAgeGroup(-1);
    } else {
      props.setSelectedAgeGroup(index);
    }
  };

  return (
    <Box
      sx={{
        justifyItems: 'center',
        alignItems: 'center',
        display: enter ? 'flex' : 'none',
        flexDirection: 'column',
        m: 3,
      }}
    >
      <Typography>{props.pagination}</Typography>
      <Typography variant="h6" sx={{ mb: 3, mt: 1 }}>
        {props.question.question}
      </Typography>
      <Box
        sx={{ mt: 2, overflow: 'scroll' }}
        display="contents"
        justifyContent="center"
      >
        {props.question.ageGroups.map((ageGroup, index) => (
          <Accordion
            expanded={index === props.selectedAgeGroup}
            key={index}
            className={
              index === props.selectedAgeGroup
                ? 'risk-accordion-expanded'
                : 'risk-accordion'
            }
            sx={Object.assign({ width: '50rem', mt: 0 })}
          >
            <AccordionSummary
              onClick={() => openAcordion(index)}
              expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
              className="risk-accordion-summary"
            >
              <Typography
                textAlign="left"
                sx={
                  index === props.selectedAgeGroup ? { fontWeight: 'bold' } : {}
                }
              >
                {ageGroup.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="risk-accordion-details">
              <Typography sx={{ maxWidth: '100vh', mr: 2 }}>
                {ageGroup.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <RadioGroup
        value={props.value}
        sx={{ flexDirection: 'row', justifyContent: 'center' }}
      >
        {props.question.options.map((answer) => (
          <FormControlLabel
            key={answer.label}
            value={answer.value}
            control={<Radio disableRipple />}
            label={answer.label}
            onClick={() => props.setValue(answer.value)}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RiskAssesmentSlide;
