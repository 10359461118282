import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';

interface ReleaseProtocolDialogProps {
  open: boolean;
  onClose: () => void;
  onCreateInvoice: () => void;
}

export const ReleaseProtocolDialog: FC<ReleaseProtocolDialogProps> = (
  props,
) => {
  const context = useContext(AppContext);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.createInvoiceQuestion}</DialogTitle>
      <DialogActions>
        <Button onClick={props.onCreateInvoice}>{context.i18n.yes}</Button>
        <Button onClick={props.onClose}>{context.i18n.no}</Button>
      </DialogActions>
    </Dialog>
  );
};
