import { TextField } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../../hooks/context';

interface ContactAddressFields {
  cityError: boolean;
  city: string;
  setCity: (city: string) => void;
  streetError: boolean;
  street: string;
  setStreet: (street: string) => void;
  zipcodeError: boolean;
  zipcode: string;
  setZipcode: (zipCode: string) => void;
}

export const ContactAddressFields: FC<ContactAddressFields> = (props) => {
  const context = useContext(AppContext);

  return (
    <>
      <TextField
        label={context.i18n.street}
        value={props.street}
        error={props.streetError}
        onChange={(e) => props.setStreet(e.currentTarget.value)}
      />
      <TextField
        label={context.i18n.city}
        value={props.city}
        error={props.cityError}
        onChange={(e) => props.setCity(e.currentTarget.value)}
      />
      <TextField
        label={context.i18n.zipcode}
        value={props.zipcode}
        error={props.zipcodeError}
        onChange={(e) => props.setZipcode(e.currentTarget.value)}
      />
    </>
  );
};
