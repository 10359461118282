import LocalizedStrings from 'react-localization';

import de from './de.json';

export const i18n = new LocalizedStrings(
  {
    de: de,
  },
  {
    pseudo: true,
  },
);
