import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  Grid,
  Popper,
  Paper,
  Typography,
  Grow,
  Fade,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';

import { AvatarColorOptions, AvatarProp } from '../../../types';

import { useQuery } from 'react-query';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AxiosError } from 'axios';
import { AvatarApi } from '../../../api';
import { AvatarColors, AvatarPropType } from '@casecare/types';
import { AvatarCanvas, AvatarColorPicker, AvatarPropButton } from '..';
import { AvatarColorPickerDialog } from '../../dialogs';

interface AvatarEditorDialogPopper {
  open: boolean;
  onClose: () => void;
  propType: AvatarPropType | undefined;
  elements: Partial<Record<AvatarPropType, any>>;
  colors: Record<AvatarColors, string>;
  indexClientId: string;
  clientId: string;
  anchorEl: HTMLElement | null;
}

const AvatarEditorPopper: FC<AvatarEditorDialogPopper> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [selectedPropId, setSelectedPropId] = useState<
    string | null | undefined
  >(null);
  const [updatedPropIds, setUpdatedPropIds] = useState<
    Partial<Record<AvatarPropType, string | null>>
  >({});
  const [avatarProps, setAvatarProps] = useState<AvatarProp[]>([]);
  const [reload, setReload] = useState(false);
  const [showNoneOption, setShowNoneOption] = useState(false);
  const [showAdditionalColors, setShowAdditionalColors] = useState(false);
  const [save, setSave] = useState(false);
  const [elements, setElements] = useState<
    Partial<Record<AvatarPropType, string>>
  >(props.elements);
  const [colors, setColors] = useState<Record<AvatarColors, string>>(
    props.colors,
  );
  const [selectedColor, setSelectedColor] = useState('');
  const [secondSelectedColor, setSecondSelectedColor] = useState('');
  const [thirdSelectedColor, setThirdSelectedColor] = useState('');

  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [isSecondColorPicker, setIsSecondColorPicker] = useState(false);
  const [isThirdColorPicker, setIsThirdColorPicker] = useState(false);
  const [propType, setPropType] = useState(props.propType);
  const [colorType, setColorType] = useState<AvatarColors>();
  const [colorOptions, setColorOptions] = useState<string[] | undefined>([]);
  const [showProps, setShowProps] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useQuery(
    ['getAvatarProps', propType],
    () =>
      AvatarApi.getAvatarProps(
        context.authToken,
        propType,
        props.indexClientId,
        props.clientId,
      ),
    {
      enabled: propType !== undefined && context.authToken !== undefined,
      onSuccess: async (res) => {
        if (propType) {
          if (!updatedPropIds || !updatedPropIds[propType]) {
            setSelectedPropId(res.data.selectedId);
          } else {
            setSelectedPropId(updatedPropIds[propType]);
          }
          setAvatarProps(res.data.props);
          setShowProps(true);
        }
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useEffect(() => {
    setElements(props.elements);
  }, [props.elements]);

  useEffect(() => {
    setColors(props.colors);
  }, [props.colors]);

  useEffect(() => {
    setUpdatedPropIds((propIds) => {
      if (propIds && propType) {
        propIds[propType] = selectedPropId;
      }
      return propIds;
    });
  }, [selectedPropId]);

  useEffect(() => {
    setColors((colors) => {
      if (colorType) {
        colors[colorType] = selectedColor;
      }
      return colors;
    });
    setReload(!reload);
  }, [selectedColor]);

  useEffect(() => {
    if (colorType) {
      setColorOptions(AvatarColorOptions[colorType]);
      setSelectedColor(
        colors[colorType] !== null ? colors[colorType] : '#fffff',
      );
      setSecondSelectedColor(colors.PANTS);
      setThirdSelectedColor(colors.SHOES);
    }
  }, [colorType]);

  useEffect(() => {
    setOpen(props.open);
    if (!props.open) {
      setUpdatedPropIds({});
    }
  }, [props.open]);

  useEffect(() => {
    setShowProps(false);
    setShowNoneOption(
      propType === AvatarPropType.BEARD ||
        propType === AvatarPropType.GLASSES ||
        propType === AvatarPropType.HAIR,
    );
    setShowAdditionalColors(propType === AvatarPropType.BODY);

    switch (propType) {
      case AvatarPropType.EARS:
        setColorType(AvatarColors.SKIN);
        break;
      case AvatarPropType.EYEBROWS:
        setColorType(AvatarColors.EYEBROWS);
        break;
      case AvatarPropType.EYES:
        setColorType(AvatarColors.EYE);
        break;
      case AvatarPropType.MOUTH:
        setColorType(AvatarColors.MOUTH);
        break;
      case AvatarPropType.NOSE:
        setColorType(AvatarColors.SKIN);
        break;
      case AvatarPropType.FACE:
        setColorType(AvatarColors.SKIN);
        break;
      case AvatarPropType.BEARD:
        setColorType(AvatarColors.BEARD);
        break;
      case AvatarPropType.BODY:
        setColorType(AvatarColors.SHIRT);
        break;
      case AvatarPropType.GLASSES:
        setColorType(AvatarColors.GLASSES);
        break;
      case AvatarPropType.HAIR:
        setColorType(AvatarColors.HAIR);
        break;
    }
  }, [propType]);

  useEffect(() => {
    setPropType(props.propType);
  }, [props.propType]);

  const onSelectProp = (id: string | null, url?: string) => {
    if (propType) {
      setSelectedPropId(id);
      if (!url) {
        delete elements[propType];
      } else {
        elements[propType] = url;
      }
      setReload(!reload);
    }
  };

  const onSave = async (pngFile: File, iconFile: File) => {
    setIsLoading(true);

    const body = Object.assign({
      colors,
      updatedPropIds,
    });

    const formData = new FormData();

    formData.append('files', pngFile);
    formData.append('files', iconFile);

    const res = await AvatarApi.updateAvatar(
      context.authToken,
      props.clientId,
      props.indexClientId,
      body,
    );
    await AvatarApi.updateAvatarPng(
      context.authToken,
      props.clientId,
      props.indexClientId,
      formData,
    );

    if (res.statusCode !== 201) {
      snackbarUtils.error(context.i18n.updateAvatarError);
    } else if (res.statusCode === 201) {
      snackbarUtils.success(res.message);
    }

    setSave(false);
    props.onClose();
    setIsLoading(false);
  };

  return (
    <Popper
      anchorEl={props.anchorEl}
      open={props.open}
      disablePortal
      placement="right-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [56, 0], // [x, y] offset values
          },
        },
      ]}
      style={{
        zIndex: 999,
      }}
    >
      <Fade in={open} timeout={500}>
        <Paper
          sx={{
            display: 'flex',
            direction: 'row',
            justifyContent: 'space-around',
            m: 2,
          }}
        >
          <Box mt={2} height="50%" width="50vh">
            <Grow in={showProps} timeout={300}>
              <Grid container rowGap={2}>
                {showNoneOption && (
                  <Grid
                    sx={{ display: showProps ? 'inherit' : 'none' }}
                    item
                    xs={4}
                  >
                    <AvatarPropButton
                      selected={selectedPropId === null}
                      id="none"
                      onSelect={() => onSelectProp(null)}
                      noneOption
                    />
                  </Grid>
                )}
                {avatarProps.map((prop) => (
                  <Grid
                    sx={{ display: showProps ? 'inherit' : 'none' }}
                    key={prop.id}
                    item
                    xs={4}
                  >
                    <AvatarPropButton
                      selected={selectedPropId === prop.id}
                      id={prop.id}
                      onSelect={onSelectProp}
                      url={prop.url}
                      colorlessUrl={prop.colorlessUrl}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grow>
          </Box>
          <Box m={2} flexDirection="row" display="flex">
            <AvatarColorPicker
              color={selectedColor}
              onClickPicker={() => {
                setIsSecondColorPicker(false);
                setIsThirdColorPicker(false);
                setOpenColorPicker(true);
              }}
              header={context.i18n.tShirt}
              onChangeColor={(color) => setSelectedColor(color)}
              showHeader={showAdditionalColors}
              colorOptions={colorOptions}
            />
            {showAdditionalColors && (
              <>
                <AvatarColorPicker
                  color={secondSelectedColor}
                  onClickPicker={() => {
                    setIsSecondColorPicker(true);
                    setIsThirdColorPicker(false);
                    setOpenColorPicker(true);
                  }}
                  header={context.i18n.pants}
                  onChangeColor={(color) => {
                    setSecondSelectedColor(color);
                    setColors({
                      ...colors,
                      PANTS: color,
                    });
                  }}
                  showHeader={showAdditionalColors}
                />
                <AvatarColorPicker
                  color={thirdSelectedColor}
                  onClickPicker={() => {
                    setIsSecondColorPicker(false);
                    setIsThirdColorPicker(true);
                    setOpenColorPicker(true);
                  }}
                  header={context.i18n.shoes}
                  onChangeColor={(color) => {
                    setThirdSelectedColor(color);
                    setColors({
                      ...colors,
                      SHOES: color,
                    });
                  }}
                  showHeader={showAdditionalColors}
                />
              </>
            )}
          </Box>
          <Box>
            <Box
              flexDirection="row"
              justifyContent="flex-end"
              display="flex"
              maxHeight={60}
            >
              <IconButton
                sx={{
                  flexDirection: 'column',
                  borderRadius: 0,
                  bgcolor: '#f5f5f5',
                }}
                disableRipple
                onClick={() => {
                  setSave(true);
                }}
              >
                <FontAwesomeIcon color="green" icon={faFloppyDisk} />
                <Typography>{context.i18n.saveBtnTooltip}</Typography>
              </IconButton>
              <IconButton
                sx={{
                  flexDirection: 'column',
                  borderRadius: 0,
                  bgcolor: '#f5f5f5',
                }}
                disableRipple
                onClick={() => {
                  props.onClose();
                }}
              >
                <FontAwesomeIcon color="red" icon={faX} />
                <Typography>{context.i18n.cancelBtnTooltip}</Typography>
              </IconButton>
            </Box>
            <AvatarCanvas
              onSave={onSave}
              colors={colors}
              elements={elements}
              propType={propType}
              reload={reload}
              save={save}
              isLoading={isLoading}
            />
          </Box>
        </Paper>
      </Fade>
      <AvatarColorPickerDialog
        onSave={(color) => {
          if (isSecondColorPicker) {
            setSecondSelectedColor(color);
            setColors({
              ...colors,
              PANTS: color,
            });
          } else if (isThirdColorPicker) {
            setThirdSelectedColor(color);
            setColors({
              ...colors,
              SHIRT: color,
            });
          } else {
            setSelectedColor(color);
          }
          setOpenColorPicker(false);
        }}
        open={openColorPicker}
        onClose={() => setOpenColorPicker(false)}
      />
    </Popper>
  );
};

export default AvatarEditorPopper;
