import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRowId,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { FC, useContext, useEffect, useState } from 'react';
import {
  ReleaseProtocol,
  ReleaseProtocolDatagridElement,
} from '../../../types';
import { AppContext } from '../../../hooks/context';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { ClientApi, ProtocolApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import moment from 'moment';
import { faEllipsisVertical, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, IconButton, Typography, Grid, Link } from '@mui/material';
import { parseDateTimeString, queryClient } from '../../../utils';
import { ProtocolBillingTableFilterToolbar } from './protocol-billing-table-filter-toolbar';
import { difference } from 'underscore';
import { ProtocolBillingMenu } from './protocol-billing-menu';
import ProtocolBillingDialog from './protocol-billing-dialog';

interface ProtocolBillingTableProps {
  released: boolean;
  rejected?: boolean;
  availableOrderers?: boolean;
}

export const ProtocolBillingTable: FC<ProtocolBillingTableProps> = (props) => {
  const context = useContext(AppContext);

  const apiRef = useGridApiRef();

  const [rows, setRows] = useState<ReleaseProtocolDatagridElement[]>([]);
  const [rowMapping, setRowMapping] = useState<{
    [key: string]: {
      name: string;
      protocols: string[];
      row?: ReleaseProtocol;
      topId: string;
    };
  }>({});

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rowCount, setRowCount] = useState(0);

  const [userIds, setUserIds] = useState([]);
  const [filterDate, setFilterDate] = useState<moment.Moment>(moment());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [protocolMenuId, setProtocolMenuId] = useState('');

  const [editProtocolOpen, setEditProtocolOpen] = useState(false);

  const [selectedOrderers, setSelectedOrderers] = useState<
    { id: string; name: string }[]
  >([]);
  const [availableOrderers, setAvailableOrderers] = useState<
    { id: string; name: string }[]
  >([]);

  const [selectedRow, setSelectedRow] = useState(undefined);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: context.i18n.client,
      flex: 0.5,
      hideable: false,
      renderCell: (params) => {
        return (
          <>
            <Link
              href="#"
              onClick={() => setSelectedRow(params.row)}
              sx={{ color: '#000' }}
            >
              {params.value}
            </Link>
          </>
        );
      },
    },
    {
      field: 'supervisors',
      headerName: context.i18n.supervisor,
      flex: 0.5,
      hideable: false,
      sortable: false,
      valueFormatter: (value: any) => (value ? value.join(', ') : ''),
    },
    {
      field: 'spacer',
      headerName: '',
      flex: 2,
      hideable: false,
      sortable: false,
      resizable: false,
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.5,
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: (params) => (
        <>
          <Tooltip title={context.i18n.reviewProtocols}>
            <IconButton
              disableRipple
              color="primary"
              id={'edit-button-' + params.id}
              sx={{ height: 39 }}
              onClick={() => {
                setSelectedRow(params.row);
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  useQuery(
    ['getOrdererInstitutions'],
    () => ClientApi.getHelpFormOrderer(context.authToken),
    {
      enabled: context.authToken !== undefined,
      onSuccess: async (res) => {
        if (res.data) {
          setAvailableOrderers(
            res.data.map((o: any) => ({
              id: o.id,
              name: o.name,
              sevDeskContactId: o.iId,
            })),
          );
        }
      },
      onError: (e) => {
        console.error(e);
      },
    },
  );

  const { isLoading } = useQuery(
    [
      'billing',
      pageSize,
      pageNum,
      sortModel,
      userIds,
      filterDate,
      selectedOrderers,
      props.released,
      props.rejected,
    ],
    () => {
      const data: any = Object.assign(
        {
          pageNum,
          pageSize,
          sortModel,
          date: filterDate,
          userIds,
          released: props.released,
          rejected: props.rejected,
        },
        selectedOrderers.length > 0 && {
          ordererInstitutionIds: selectedOrderers.map((o) => o.id),
        },
        !props.rejected &&
          !props.released && {
            billingShowAll: true,
          },
      );
      return ProtocolApi.getReleasedProtocols(context.authToken, data);
    },
    {
      enabled: context.authToken !== undefined,
      onSuccess: async (res) => {
        setRows(res.data.hasPendingProtocols);
        setRowCount(res.data.totalCount);
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  return (
    <Grid sx={{ width: '100%' }}>
      <DataGridPro
        apiRef={apiRef}
        sx={{
          fontSize: 17,
          m: 5,
        }}
        getRowHeight={() => 50}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.familyId || row.clientId}
        onRowClick={(params) => setSelectedRow(params.row)}
        slotProps={{
          toolbar: {
            setUserIds: setUserIds,
            date: filterDate,
            setDate: setFilterDate,
            released: props.released,
            rejected: props.rejected,
            selectedRows: rowSelectionModel
              ? rowSelectionModel
                  .filter((i) => !i.toString().startsWith('top/'))
                  .map((i) => rowMapping[i]?.row)
              : [],
            clearSelectedRows: () => setRowSelectionModel([]),
            editOpen: editProtocolOpen,
            setEditOpen: setEditProtocolOpen,
            selectedOrderers,
            setSelectedOrderers,
            availableOrderers,
          },
        }}
        slots={{
          toolbar(props: any) {
            return ProtocolBillingTableFilterToolbar(props);
          },
        }}
        loading={isLoading}
        columnBufferPx={4}
        // Pagination settings
        paginationMode="client"
        onPaginationModelChange={(model) => {
          setPageNum(model.page);
          setPageSize(model.pageSize);
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={{ pageSize, page: pageNum }}
        rowCount={rowCount}
        pagination
        // End Pagination settings
        // Sorting settings
        sortingMode="client"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
        autosizeOnMount
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: false,
        }}
        defaultGroupingExpansionDepth={1}
        // Checkbox selection options
        // checkboxSelection
        // disableRowSelectionOnClick
        // rowSelectionModel={rowSelectionModel}
        // onRowSelectionModelChange={(p) => {
        //   // Check if is removed or added
        //   const removed = difference(rowSelectionModel, p)[0];
        //   const added = difference(p, rowSelectionModel)[0];

        //   // Get changed
        //   const change = removed || added;

        //   if (change) {
        //     // Handling top level selection
        //     if (change.toString().startsWith('top/')) {
        //       const protocols = rowMapping[change].protocols;
        //       // Add or remove all child protocol elements from selection
        //       p = added
        //         ? p.concat(protocols)
        //         : p.filter((i) => !protocols.includes(i.toString()));
        //     } else {
        //       if (rowMapping[change]) {
        //         const topId = rowMapping[change].topId;
        //         const protocols = rowMapping[topId].protocols;
        //         /**
        //          * Element added:
        //          *  check if
        //          *  - top protocol is not already selected
        //          *  - all child protocols are selected
        //          *  then
        //          *  - add top element
        //          *
        //          * Element removed:
        //          *  check if
        //          *  - top element is selected
        //          *    -> this indicates that previously all child elements were previously selected, but one is being removed now
        //          *  then
        //          *  - remove top ele,ent
        //          */
        //         if (
        //           (added &&
        //             !p.includes(topId) &&
        //             protocols.every((i) => p.includes(i))) ||
        //           (removed && p.includes(topId))
        //         ) {
        //           if (added) p.push(topId);
        //           if (removed) p = p.filter((u) => u != topId);
        //         }
        //       }
        //     }
        //   }
        //   setRowSelectionModel(p);
        // }}
        // onCellDoubleClick={(params, event) => {
        //   if (
        //     !props.released &&
        //     !props.rejected &&
        //     !params.row.id.toString().startsWith('top/')
        //   ) {
        //     setEditProtocolOpen(true);
        //     setRowSelectionModel([params.row.id]);
        //   }
        // }}
      />
      <ProtocolBillingDialog
        row={selectedRow}
        date={filterDate}
        onClose={() => {
          setSelectedRow(undefined);
          queryClient.invalidateQueries('billing');
          queryClient.invalidateQueries('entries');
        }}
        billingShowAll={!props.rejected && !props.released}
        rejected={props.rejected}
        released={props.released}
      />
    </Grid>
  );
};
