import { Autocomplete, Box, Checkbox, TextField } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { UserSelect } from '../../client';
import { AppContext } from '../../../hooks/context';
import { ReleaseProtocol } from '../../../types';
import { useQuery } from 'react-query';
import { AdminApi, ClientApi } from '../../../api';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

export interface SocialWorkerOption {
  clientHelpFormIds: string;
  name: string;
}

export interface HelpFormOrdererOption {
  id: string;
  name: string;
}

interface ClientTableFilterToolbarProps {
  supervisorId: string;
  setSupervisorId: (id: string) => void;
  selectedSocialWorkers: SocialWorkerOption[];
  setSelectedSocialWorkers: (ids: SocialWorkerOption[]) => void;
  selectedHelpFormOrderer: HelpFormOrdererOption[];
  setSelectedHelpFormOrderer: (
    helpFormOrderers: HelpFormOrdererOption[],
  ) => void;
  // protocolDate: moment.Moment;
  // setProtocolDate: (date: moment.Moment) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ClientTableFilterToolbar: FC<ClientTableFilterToolbarProps> = (
  props,
) => {
  const context = useContext(AppContext);

  const {
    supervisorId,
    setSupervisorId,
    selectedSocialWorkers,
    setSelectedSocialWorkers,
    selectedHelpFormOrderer,
    setSelectedHelpFormOrderer,
    // protocolDate,
    // setProtocolDate,
  } = props;
  const [socialWorkerOptions, setSocialWorkerOptions] = useState<
    SocialWorkerOption[]
  >([]);

  const [helpFormOrdererOptions, setHelpFormOrdererOptions] = useState<
    HelpFormOrdererOption[]
  >([]);

  const { isLoading: swLoading } = useQuery({
    queryKey: ['getSocialWorkers'],
    queryFn: () => ClientApi.getAllSocialWorkers(context.authToken),
    enabled: context.authToken !== undefined,
    onSuccess: (data) => setSocialWorkerOptions(data.data),
  });

  const { isLoading: hfoLoading } = useQuery({
    queryKey: ['getHelpFormOrderers'],
    queryFn: () => AdminApi.getHelpFormOrderer(context.authToken),
    enabled: context.authToken !== undefined && context.isAdmin,
    onSuccess: (data) =>
      setHelpFormOrdererOptions(
        data.data?.orderer.map((o: any) => ({ id: o.id, name: o.name })),
      ),
  });

  return (
    <Box
      display="flex"
      sx={{
        p: 1,
        mt: 1,
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        display="flex"
        sx={{
          width: '50%',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box
          sx={{
            width: '15rem',
            flex: 1.5,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <UserSelect
            userId={supervisorId}
            setId={(id) => setSupervisorId(id)}
            title={context.i18n.supervisor}
            error={false}
            allowClearable
          />
        </Box>
        <Box
          sx={{
            flex: 2,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Autocomplete
            multiple
            disableCloseOnSelect
            disabled={selectedHelpFormOrderer.length > 0}
            value={selectedSocialWorkers}
            noOptionsText={context.i18n.noSocialWorkers}
            getOptionLabel={(option: any) => option.name || ''}
            onChange={(e, v) => setSelectedSocialWorkers(v)}
            isOptionEqualToValue={(o, v) =>
              o.clientHelpFormIds === v.clientHelpFormIds
            }
            renderOption={(props: any, option: any) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selectedSocialWorkers.indexOf(option) > -1}
                />
                {option.name}
              </li>
            )}
            loading={swLoading}
            options={socialWorkerOptions}
            renderInput={(params) => (
              <TextField {...params} label={context.i18n.socialworker} />
            )}
          />
        </Box>
        <Box
          sx={{
            flex: 2,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Autocomplete
            multiple
            disableCloseOnSelect
            disabled={selectedSocialWorkers.length > 0}
            value={selectedHelpFormOrderer}
            noOptionsText={context.i18n.noOrderers}
            getOptionLabel={(option: any) => option.name || ''}
            onChange={(e, v) => setSelectedHelpFormOrderer(v)}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderOption={(props: any, option: any) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selectedHelpFormOrderer.indexOf(option) > -1}
                />
                {option.name}
              </li>
            )}
            loading={swLoading}
            options={helpFormOrdererOptions}
            renderInput={(params) => (
              <TextField {...params} label={context.i18n.orderer} />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
