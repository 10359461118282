import { FC, useContext, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { AppContext } from '../../../../hooks/context';

interface AvatarNotesFilterToolbarProps {
  setClientIds: (ids: string[] | undefined) => void;
  clients: { name: string; id: string }[];
}

const AvatarNotesFilterToolbar: FC<AvatarNotesFilterToolbarProps> = (props) => {
  const context = useContext(AppContext);

  const handleSearchResultSelection = (
    options:
      | {
          id: string;
          name: string;
        }[]
      | null,
  ) => {
    props.setClientIds(options?.map((o) => o.id));
  };

  return (
    <Box width="60vh" m={1} mb={3}>
      <Autocomplete
        multiple
        limitTags={3}
        onChange={(evetn, values) => handleSearchResultSelection(values)}
        options={props.clients}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} placeholder={context.i18n.personSearch} />
        )}
      />
    </Box>
  );
};

export default AvatarNotesFilterToolbar;
