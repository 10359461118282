import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Popper,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AvatarApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import AvatarSymbolButton from '../avatar-symbol-button/avatar-symbol-button';

interface AvatarSymbolsPopperProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  ids: string[];
  onClose: () => void;
  onNote: (id: string) => void;
  clientId: string | undefined;
  indexId: string | undefined;
}

const AvatarSymbolsPopper: FC<AvatarSymbolsPopperProps> = (props) => {
  const context = useContext(AppContext);
  const [symbols, setSymbols] =
    useState<{ url: string; id: string; hasNotes: boolean }[]>();

  const [selected, setSelected] = useState<string[]>([]);
  const [addedSymbols, setAddedSymbols] = useState<string[]>([]);
  const [removedSymbols, setRemovedSymbols] = useState<string[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [removedSymbolId, setRemovedSymbolId] = useState('');

  useQuery(
    ['avatarSymbols', props.indexId, props.clientId],
    () =>
      AvatarApi.getAvatarSymbols(
        context.authToken,
        props.clientId,
        props.indexId,
      ),
    {
      onSuccess: (res) => {
        setSymbols(res.data);
      },
      enabled: context.authToken !== undefined,
    },
  );

  useEffect(() => {
    if (props.ids !== undefined) {
      setSelected(props.ids);
    }
  }, [props.ids]);

  const onSelect = (id: string) => {
    props.onClose();
    if (selected.includes(id)) {
      setRemovedSymbolId(id);
      setOpenConfirmDialog(true);
    } else {
      setRemovedSymbols(removedSymbols.filter((s) => s !== id));
      selected.push(id);
      addedSymbols.push(id);
      saveSymbols();
    }
  };

  const saveSymbols = async () => {
    const res = await AvatarApi.updateAvatarSymbol(
      context.authToken,
      props.clientId,
      props.indexId,
      addedSymbols,
      removedSymbols,
    );

    if (res.statusCode !== 201) {
      snackbarUtils.error(context.i18n.updateAvatarError);
    } else if (res.statusCode === 201) {
      snackbarUtils.success(res.message);
    }
  };

  return (
    <Popper
      placement="left-start"
      open
      anchorEl={props.anchorEl}
      sx={{
        background: 'rgb(255,255,255,0.7)',
        borderRadius: 2,
        padding: 1,
      }}
    >
      <ButtonGroup>
        {symbols?.map((s) => (
          <AvatarSymbolButton
            key={s.id}
            onNote={props.onNote}
            onSelect={onSelect}
            hideIcon={!props.open && !selected.includes(s.id)}
            greyNote={!s.hasNotes}
            greyIcon={!selected.includes(s.id)}
            id={s.id}
            url={s.url}
          />
        ))}
      </ButtonGroup>
      <Dialog
        onClose={() => setOpenConfirmDialog(false)}
        open={openConfirmDialog}
      >
        <DialogTitle>{context.i18n.removeSymbol}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {context.i18n.realyRemoveSymbol}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelected(selected.filter((s) => s !== removedSymbolId));
              setAddedSymbols(
                addedSymbols.filter((s) => s !== removedSymbolId),
              );
              removedSymbols.push(removedSymbolId);
              setOpenConfirmDialog(false);
              saveSymbols();
            }}
          >
            {context.i18n.yes}
          </Button>
          <Button onClick={() => setOpenConfirmDialog(false)} autoFocus>
            {context.i18n.no}
          </Button>
        </DialogActions>
      </Dialog>
    </Popper>
  );
};

export default AvatarSymbolsPopper;
