import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ReportApi } from '../../../../api';
import { AppContext } from '../../../../hooks/context';

interface ReportSelectProps {
  templateId?: string;
  setReportId: (reportId: string) => void;
  disabled?: boolean;
}

const ReportSelect: FC<ReportSelectProps> = (props) => {
  const context = useContext(AppContext);

  const [templateId, setTemplateId] = useState(
    props.templateId ? props.templateId : '',
  );
  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (props.templateId) {
      setTemplateId(props.templateId);
    }
  }, [props.templateId]);

  useQuery(
    ['reportTemplates'],
    () => ReportApi.listReportTemplates(context.authToken),
    {
      onSuccess: (res) => {
        setReports(res.data);
      },
      enabled: context.authToken !== undefined,
    },
  );

  return (
    <FormControl fullWidth color="secondary">
      <InputLabel sx={{ alignSelf: 'center' }} id="type-slect-label">
        {context.i18n.type}
      </InputLabel>
      <Select
        disabled={props.disabled}
        fullWidth
        value={templateId}
        labelId="type-select-label"
        label={context.i18n.type}
      >
        {reports.map((t: any) => (
          <MenuItem
            key={t.id}
            value={t.id}
            onClick={() => props.setReportId(t.id)}
          >
            {t.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReportSelect;
