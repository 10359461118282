import React from 'react';
import { BrowserHistory, Action, Location } from 'history';
import { Router } from 'react-router-dom';

interface HistoryRouterProps {
  basename?: string;
  children?: React.ReactNode;
  history: BrowserHistory;
}

interface HistoryRouterState {
  action: Action;
  location: Location;
}

export const HistoryBrowserRouter: React.FC<HistoryRouterProps> = (
  props: HistoryRouterProps,
) => {
  const [state, setState] = React.useState<HistoryRouterState>({
    action: props.history.action,
    location: props.history.location,
  });

  React.useLayoutEffect(() => props.history.listen(setState), [props.history]);
  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={props.history}
      {...props}
    />
  );
};
