import { FormFieldTextType, FormFieldType } from '@casecare/types';
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../../hooks/context';
import { FormDialogProps } from '../../../../types';

const FormTextDialog: FC<FormDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [dataTypeError, setDataTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [dataType, setDataType] = useState<FormFieldTextType>(
    props.element?.textType ? props.element?.textType : FormFieldTextType.TEXT,
  );
  const [size, setSize] = useState<FormFieldType | undefined>(
    props.element?.type,
  );
  const [element] = useState(props.element);

  useEffect(() => {
    if (!props.saving) {
      return;
    }
    let errors = false;
    if (dataType === undefined) {
      errors = true;
      setDataTypeError(true);
    }
    if (size === undefined) {
      errors = true;
      setSizeError(true);
    }

    props.onSave(
      {
        ...element,
        type: size,
        textType: dataType,
        fieldOptions: [],
      },
      errors,
    );
  }, [props.saving]);

  const selectSize = (size: FormFieldType) => {
    setSizeError(false);
    setSize(size);
  };

  const selectDataType = (dataType: FormFieldTextType) => {
    setDataTypeError(false);
    setDataType(dataType);
  };

  return (
    <Stack direction="column" sx={{ width: '22rem' }} spacing={2}>
      <FormControl error={sizeError} color="secondary">
        <InputLabel sx={{ alignSelf: 'center' }} id="size-select-label">
          {context.i18n.size}
        </InputLabel>
        <Select
          labelId="size-select-label"
          id="size-select"
          label={context.i18n.size}
          // when the value is 0 nothing is selected so this is needed to show the small type
          value={
            size !== undefined
              ? size !== FormFieldType.TEXT
                ? size
                : 'text'
              : ''
          }
        >
          <MenuItem
            value={'text'}
            onClick={() => selectSize(FormFieldType.TEXT)}
          >
            {context.i18n.small}
          </MenuItem>
          <MenuItem
            value={FormFieldType.TEXT_MEDIUM}
            onClick={() => selectSize(FormFieldType.TEXT_MEDIUM)}
          >
            {context.i18n.middle}
          </MenuItem>
          <MenuItem
            value={FormFieldType.TEXT_LARGE}
            onClick={() => selectSize(FormFieldType.TEXT_LARGE)}
          >
            {context.i18n.large}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl error={dataTypeError} color="secondary">
        <InputLabel sx={{ alignSelf: 'center' }} id="data-select-label">
          {context.i18n.dataType}
        </InputLabel>
        <Select
          labelId="data-select-label"
          id="data-type-select"
          label={context.i18n.dataType}
          // when the value is 0 nothing is selected so this is needed to show the text type
          value={
            dataType !== undefined
              ? dataType !== FormFieldTextType.TEXT
                ? dataType
                : 'text'
              : ''
          }
        >
          <MenuItem
            value={'text'}
            onClick={() => selectDataType(FormFieldTextType.TEXT)}
          >
            {context.i18n.text}
          </MenuItem>
          <MenuItem
            value={FormFieldTextType.EMAIL}
            onClick={() => selectDataType(FormFieldTextType.EMAIL)}
          >
            {context.i18n.email}
          </MenuItem>
          <MenuItem
            value={FormFieldTextType.NUMBER}
            onClick={() => selectDataType(FormFieldTextType.NUMBER)}
          >
            {context.i18n.number}
          </MenuItem>
          <MenuItem
            value={FormFieldTextType.PHONE}
            onClick={() => selectDataType(FormFieldTextType.PHONE)}
          >
            {context.i18n.phone}
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

export default FormTextDialog;
