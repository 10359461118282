import { FC, useContext, useEffect, useState } from 'react';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { AvatarMoodButton } from '../../avatar';
import { AppContext, initalContext } from '../../../hooks/context';
import FormText from '../forms/form-elements/form-text/form-text';
import { FormFieldTextType, ClientDataType } from '@casecare/types';
import { TextSize } from '../../../types/form-text-props';
import { ClientApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { queryClient } from '../../../utils';

interface MoodProps {
  saved: boolean;
  initialValues: any;
  clientId: string | undefined;
}

export const Mood: FC<MoodProps> = (props) => {
  const context = useContext(AppContext);

  const allMoods = Array.from({ length: 11 }, (_, index) => index);

  const [mood, setMood] = useState<number | undefined>(
    props.initialValues ? props.initialValues.mood : undefined,
  );
  const [whatNeedsToHappen, setWhatNeedToHappen] = useState(
    props.initialValues ? props.initialValues.whatNeedsToHappen : '',
  );
  const [whatWorries, setWhatWorries] = useState(
    props.initialValues ? props.initialValues.whatWorries : '',
  );
  const [wichRessources, setWichResources] = useState(
    props.initialValues ? props.initialValues.wichRessources : '',
  );
  const [copingStrategies, setCopingStrategies] = useState(
    props.initialValues ? props.initialValues.copingStrategies : '',
  );
  const [mood2, setMood2] = useState<number | undefined>(
    props.initialValues ? props.initialValues.mood2 : undefined,
  );
  const [whatNeedsToHappen2, setWhatNeedToHappen2] = useState(
    props.initialValues ? props.initialValues.whatNeedsToHappen2 : '',
  );
  const [whatWorries2, setWhatWorries2] = useState(
    props.initialValues ? props.initialValues.whatWorries2 : '',
  );
  const [wichRessources2, setWichResources2] = useState(
    props.initialValues ? props.initialValues.wichRessources2 : '',
  );
  const [copingStrategies2, setCopingStrategies2] = useState(
    props.initialValues ? props.initialValues.copingStrategies2 : '',
  );
  const [mood3, setMood3] = useState<number | undefined>(
    props.initialValues ? props.initialValues.mood3 : undefined,
  );
  const [whatNeedsToHappen3, setWhatNeedToHappen3] = useState(
    props.initialValues ? props.initialValues.whatNeedsToHappen3 : '',
  );
  const [whatWorries3, setWhatWorries3] = useState(
    props.initialValues ? props.initialValues.whatWorries3 : '',
  );
  const [wichRessources3, setWichResources3] = useState(
    props.initialValues ? props.initialValues.wichRessources3 : '',
  );
  const [copingStrategies3, setCopingStrategies3] = useState(
    props.initialValues ? props.initialValues.copingStrategies3 : '',
  );
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const onSaveFields = async () => {
    try {
      const body = {
        mood,
        mood2,
        mood3,
        wichRessources,
        wichRessources2,
        wichRessources3,
        copingStrategies,
        copingStrategies2,
        copingStrategies3,
        whatNeedsToHappen,
        whatNeedsToHappen2,
        whatNeedsToHappen3,
        whatWorries,
        whatWorries2,
        whatWorries3,
      };

      if (unsavedChanges) {
        const res = await ClientApi.saveClientSuffering(
          context.authToken,
          props.clientId,
          body,
        );

        if (res.message) snackbarUtils.success(res.message);

        queryClient.invalidateQueries('suffering');
        context.setUnsavedChanges(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!props.saved) {
      setTimeout(() => {
        onSaveFields();
      }, 500);
    }
  }, [props.saved]);

  useEffect(() => {
    if (
      mood === (props.initialValues ? props.initialValues.mood : undefined) &&
      mood2 === (props.initialValues ? props.initialValues.mood2 : undefined) &&
      mood3 === (props.initialValues ? props.initialValues.mood3 : undefined) &&
      wichRessources ===
        (props.initialValues ? props.initialValues.wichRessources : '') &&
      wichRessources2 ===
        (props.initialValues ? props.initialValues.wichRessources2 : '') &&
      wichRessources3 ===
        (props.initialValues ? props.initialValues.wichRessources3 : '') &&
      copingStrategies ===
        (props.initialValues ? props.initialValues.copingStrategies : '') &&
      copingStrategies2 ===
        (props.initialValues ? props.initialValues.copingStrategies2 : '') &&
      copingStrategies3 ===
        (props.initialValues ? props.initialValues.copingStrategies3 : '') &&
      whatNeedsToHappen ===
        (props.initialValues ? props.initialValues.whatNeedsToHappen : '') &&
      whatNeedsToHappen2 ===
        (props.initialValues ? props.initialValues.whatNeedsToHappen2 : '') &&
      whatNeedsToHappen3 ===
        (props.initialValues ? props.initialValues.whatNeedsToHappen3 : '') &&
      whatWorries ===
        (props.initialValues ? props.initialValues.whatWorries : '') &&
      whatWorries2 ===
        (props.initialValues ? props.initialValues.whatWorries2 : '') &&
      whatWorries3 ===
        (props.initialValues ? props.initialValues.whatWorries3 : '')
    ) {
      context.setUnsavedChanges(false);
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
      context.setUnsavedChanges(true);
    }
  }, [
    mood,
    mood2,
    mood3,
    wichRessources,
    wichRessources2,
    wichRessources3,
    copingStrategies,
    copingStrategies2,
    copingStrategies3,
    whatNeedsToHappen,
    whatNeedsToHappen2,
    whatNeedsToHappen3,
    whatWorries,
    whatWorries2,
    whatWorries3,
  ]);

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        overflow: 'auto',
        maxHeight: 500,
        flexGrow: 1,
        borderRadius: 0,
      }}
    >
      <Grid container>
        <Grid item xs={12} m={1}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {context.i18n.firstMessurement}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} m={1} display="flex" justifyContent="center">
          {allMoods.map((m) => (
            <AvatarMoodButton
              big={m === mood}
              onCLick={() => setMood(m)}
              key={m}
              mood={m}
              showNote={false}
              greyNote={false}
            />
          ))}
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'what-needs-to-happen'}
            size={TextSize.NORMAL}
            title={context.i18n.whatNeedsToHappen}
            value={
              props.initialValues ? props.initialValues.whatNeedsToHappen : ''
            }
            onChange={(value) => setWhatNeedToHappen(value)}
            saved={props.saved}
            onSave={(value) => {
              setWhatNeedToHappen(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'what-worries'}
            size={TextSize.NORMAL}
            title={context.i18n.whatWorries}
            value={props.initialValues ? props.initialValues.whatWorries : ''}
            onChange={(value) => setWhatWorries(value)}
            onSave={(value) => {
              setWhatWorries(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
            saved={props.saved}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'wich-ressources'}
            size={TextSize.NORMAL}
            title={context.i18n.wichRessources}
            value={
              props.initialValues ? props.initialValues.wichRessources : ''
            }
            onChange={(value) => setWichResources(value)}
            saved={props.saved}
            onSave={(value) => {
              setWichResources(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'coping-strategies'}
            size={TextSize.NORMAL}
            title={context.i18n.copingStrategies}
            value={
              props.initialValues ? props.initialValues.copingStrategies : ''
            }
            onChange={(value) => setCopingStrategies(value)}
            saved={props.saved}
            onSave={(value) => {
              setCopingStrategies(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {context.i18n.secondMessurement}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} m={1} display="flex" justifyContent="center">
          {allMoods.map((m) => (
            <AvatarMoodButton
              big={m === mood2}
              onCLick={() => setMood2(m)}
              key={m}
              mood={m}
              showNote={false}
              greyNote={false}
            />
          ))}
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'what-needs-to-happen-2'}
            size={TextSize.NORMAL}
            title={context.i18n.whatNeedsToHappen}
            value={
              props.initialValues ? props.initialValues.whatNeedsToHappen2 : ''
            }
            onChange={(value) => setWhatNeedToHappen2(value)}
            saved={props.saved}
            onSave={(value) => {
              setWhatNeedToHappen2(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'what-worries-2'}
            size={TextSize.NORMAL}
            title={context.i18n.whatWorries}
            value={props.initialValues ? props.initialValues.whatWorries2 : ''}
            onChange={(value) => setWhatWorries2(value)}
            saved={props.saved}
            onSave={(value) => {
              setWhatWorries2(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'wich-ressources-2'}
            size={TextSize.NORMAL}
            title={context.i18n.wichRessources}
            value={
              props.initialValues ? props.initialValues.wichRessources2 : ''
            }
            onChange={(value) => setWichResources2(value)}
            saved={props.saved}
            onSave={(value) => {
              setWichResources2(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'coping-strategies-2'}
            size={TextSize.NORMAL}
            title={context.i18n.copingStrategies}
            value={
              props.initialValues ? props.initialValues.copingStrategies2 : ''
            }
            onChange={(value) => setCopingStrategies2(value)}
            saved={props.saved}
            onSave={(value) => {
              setCopingStrategies2(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {context.i18n.thirdMessurement}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} m={1} display="flex" justifyContent="center">
          {allMoods.map((m) => (
            <AvatarMoodButton
              big={m === mood3}
              onCLick={() => setMood3(m)}
              key={m}
              mood={m}
              showNote={false}
              greyNote={false}
            />
          ))}
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'what-needs-to-happen-3'}
            size={TextSize.NORMAL}
            title={context.i18n.whatNeedsToHappen}
            value={
              props.initialValues ? props.initialValues.whatNeedsToHappen3 : ''
            }
            onChange={(value) => setWhatNeedToHappen3(value)}
            saved={props.saved}
            onSave={(value) => {
              setWhatNeedToHappen3(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'what-worries-3'}
            size={TextSize.NORMAL}
            title={context.i18n.whatWorries}
            value={props.initialValues ? props.initialValues.whatWorries3 : ''}
            onChange={(value) => setWhatWorries3(value)}
            saved={props.saved}
            onSave={(value) => {
              setWhatWorries3(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'wich-ressources-3'}
            size={TextSize.NORMAL}
            title={context.i18n.wichRessources}
            value={
              props.initialValues ? props.initialValues.wichRessources3 : ''
            }
            onChange={(value) => setWichResources3(value)}
            saved={props.saved}
            onSave={(value) => {
              setWichResources3(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} m={1}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id={'coping-strategies-3'}
            size={TextSize.NORMAL}
            title={context.i18n.copingStrategies}
            value={
              props.initialValues ? props.initialValues.copingStrategies3 : ''
            }
            onChange={(value) => setCopingStrategies3(value)}
            saved={props.saved}
            onSave={(value) => {
              setCopingStrategies3(value);
              if (!props.saved) {
                onSaveFields();
              }
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
