import { FrontendFormImportField } from '@casecare/types';
import {
  faTrash,
  faFloppyDisk,
  faX,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  DialogActions,
  Dialog,
  DialogContentText,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AdminApi } from '../../../../api';
import { AppContext } from '../../../../hooks/context';
import {
  CategoryData,
  ChildImportCategories,
  ImportCategorie,
} from '../../../../types';
import { getImportCategories, parseDateTimeString } from '../../../../utils';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { MovementButtons } from '../../../core';
import DeleteCategoryDialog from '../delete-category-dialog/delete-category-dialog';

interface ChildRow {
  name: string;
  id: string;
  seq: number;
}

interface CategoryDetailsDialogProps {
  categories: CategoryData[];
  onClose: () => void;
  onCreate: (
    categoryName: string,
    isSubCategory: boolean,
    isDefault: boolean,
    parentId?: string,
  ) => void;
  onImport: (
    importFields: FrontendFormImportField[],
    categoryName: string,
    isDefault: boolean,
    childCategories?: ChildImportCategories[],
  ) => void;
  onEdit: (changedCategory: CategoryData) => void;
  category?: CategoryData;
  import?: boolean;
  loading?: boolean;
  onDelete?: () => void;
}

const CategoryDetailsDialog: FC<CategoryDetailsDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [isSubCategory, setIsSubCategory] = useState(false);
  const [categoryName, setCategoryName] = useState(
    props.category ? props.category.label : '',
  );
  const [categoryNameError, setCategoryNameError] = useState(false);
  const [categoryNameHelpelerText, setCategoryNameHelperText] = useState('');
  const [isDefault, setIsDefault] = useState(
    props.category?.default ? props.category?.default : false,
  );
  const [parentCategoryId, setParentCategoryId] = useState('');
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [categorySelectionError, setCategorySelectionError] = useState(false);
  const [importCategories, setImportCategories] = useState<ImportCategorie[]>(
    [],
  );
  const [selectedImportCategory, setSelectedImportCategory] =
    useState<FrontendFormImportField[]>();
  const [importCategoryName, setImportCategoryName] = useState('');
  const [importCategoryError, setImportCategoryError] = useState(false);
  const [childCategories, setChildCategories] = useState(
    props.category?.childCategories ? [...props.category.childCategories] : [],
  );
  const [childRows, setChildRows] = useState<ChildRow[]>();
  const [importChildCategories, setImportChildCategories] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<
    string | undefined
  >();

  const onCategorySelect = (importCategory: any) => {
    setSelectedImportCategory(importCategory.fields);
    setImportCategoryName(importCategory.templateName);
    setImportChildCategories(importCategory.childCategories);
    if (categoryName === '') {
      setCategoryName(importCategory.templateName);
    }
  };

  useEffect(() => {
    const getAndSetImportCategories = async () => {
      try {
        const importCategories = await AdminApi.getImportCategories(
          context.authToken,
        );
        const importCategoryData: ImportCategorie[] = await getImportCategories(
          importCategories.data,
        );
        setImportCategories(importCategoryData);
      } catch (e: any) {
        console.error(e);
        throw new Error(e.message);
      }
    };

    getAndSetImportCategories();
  }, []);

  useEffect(() => {
    mapRows();
  }, [childCategories]);

  const onSave = () => {
    let errors = false;
    setCategoryNameHelperText('');

    if (importCategoryName === '' && props.import) {
      setImportCategoryError(true);
      errors = true;
    }

    if (categoryName === '') {
      setCategoryNameError(true);
      errors = true;
    }

    if (!isSubCategory) {
      if (
        props.categories.find((category) => category.label === categoryName) &&
        !props.category
      ) {
        setCategoryNameError(true);
        setCategoryNameHelperText(context.i18n.categoryWithNameExists);
        errors = true;
      }
    }

    if (isSubCategory) {
      const parentCategory = props.categories.find(
        (category) => category.id === parentCategoryId,
      );

      if (!parentCategoryId) {
        setCategorySelectionError(true);
        errors = true;
      }

      if (
        parentCategory?.childCategories?.find(
          (category: CategoryData) => category.label === categoryName,
        )
      ) {
        setCategoryNameError(true);
        setCategoryNameHelperText(context.i18n.subCategoryWithNameExists);
        errors = true;
      }

      if (!parentCategory?.childCategories && !errors) {
        setOpenWarningDialog(true);
        errors = true;
      }
    }

    if (!errors) {
      if (props.import) {
        props.onImport(
          selectedImportCategory ? selectedImportCategory : [],
          categoryName,
          isDefault,
          importChildCategories,
        );
      } else if (!props.category) {
        props.onCreate(
          categoryName,
          isSubCategory,
          isDefault,
          parentCategoryId,
        );
      } else {
        props.onEdit({
          ...props.category,
          label: categoryName,
          isDefault: isDefault ? isDefault : false,
          childCategories: childCategories,
        });
      }
    }
  };

  const mapRows = () => {
    if (childCategories && childCategories.length > 0) {
      setChildRows(
        childCategories.map((category: CategoryData) => {
          return {
            id: category.id,
            name: category.label,
            seq: category.seq,
          };
        }),
      );
    }
  };

  const moveElement = (up: boolean, elementId: any, allTheWay?: boolean) => {
    const newCategories = childCategories
      ? childCategories.sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1))
      : [];
    newCategories.forEach((category, index) => {
      if (category.id === elementId) {
        const tmpSeq = newCategories[index].seq;
        if (up && index > 0) {
          if (allTheWay) {
            newCategories[index].seq = newCategories[0].seq;
            newCategories[0].seq = tmpSeq;
            return;
          }
          newCategories[index].seq = newCategories[index - 1].seq;
          newCategories[index - 1].seq = tmpSeq;
          return;
        } else if (!up && index + 1 !== newCategories.length) {
          if (allTheWay) {
            newCategories[index].seq =
              newCategories[newCategories.length - 1].seq;
            newCategories[newCategories.length - 1].seq = tmpSeq;
            return;
          }
          newCategories[index].seq = newCategories[index + 1].seq;
          newCategories[index + 1].seq = tmpSeq;
          return;
        }
      }
    });
    setChildCategories(
      newCategories.sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1)),
    );
    mapRows();
  };

  const onDeltePressed = (id?: string) => {
    setShowDeleteDialog(true);
    setDeleteCategoryId(id);
  };

  const onDeleteCategory = async () => {
    setShowDeleteDialog(false);
    try {
      const res = await AdminApi.deleteCategory(
        deleteCategoryId ? deleteCategoryId : props.category?.id,
        context.authToken,
      );

      if (res.data.errors.length > 0) {
        res.data.errors.forEach((error: any) => {
          console.error(error.message);
          snackbarUtils.error(error.message);
        });
      } else {
        snackbarUtils.success(context.i18n.deletedCategory);
        if (deleteCategoryId) {
          setChildCategories((prevChildCategories) => {
            if (prevChildCategories?.length === 1) {
              setChildRows(undefined);
            }
            return prevChildCategories?.filter(
              (category) => category.id !== deleteCategoryId,
            );
          });
        } else {
          props.onDelete ? props.onDelete() : null;
        }
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e.message);
    }
  };

  const setSubCategoryName = (id: string, value: any) => {
    setChildCategories((prevChildCategories) => {
      return prevChildCategories.map((category) => {
        if (category.id === id) {
          return {
            ...category,
            label: value,
          };
        } else {
          return category;
        }
      });
    });
  };

  return (
    <Box>
      <DialogTitle>{context.i18n.createCategory}</DialogTitle>
      <DialogContent
        sx={
          props.loading ? { display: 'flex', justifyContent: 'center' } : null
        }
      >
        {props.loading ? (
          <CircularProgress />
        ) : (
          <>
            <Stack
              direction="column"
              sx={{ width: '22rem', mt: 2 }}
              spacing={2}
            >
              {props.import && (
                <FormControl error={importCategoryError} color="secondary">
                  <InputLabel
                    sx={{ alignSelf: 'center' }}
                    id="import-select-label"
                  >
                    {context.i18n.importCategory}
                  </InputLabel>
                  <Select
                    labelId="import-select-label"
                    label={context.i18n.importCategory}
                    value={importCategoryName}
                  >
                    {importCategories &&
                      importCategories.map((importCategory) => (
                        <MenuItem
                          key={importCategory.templateName}
                          value={importCategory.templateName}
                          onClick={() => onCategorySelect(importCategory)}
                        >
                          {importCategory.templateName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              <TextField
                autoFocus
                color="secondary"
                error={categoryNameError}
                helperText={categoryNameHelpelerText}
                label={context.i18n.categoryName}
                size="medium"
                type={'text'}
                value={categoryName}
                onChange={(e) => {
                  setCategoryName(e.currentTarget.value);
                  setCategoryNameError(false);
                  setCategoryNameHelperText('');
                }}
              />
              {props.category && (
                <>
                  <Typography variant="h6">
                    {context.i18n.creationDate}
                  </Typography>
                  <Typography>
                    {parseDateTimeString(props.category?.creationDate)}
                  </Typography>
                </>
              )}
            </Stack>
            {!isSubCategory && (
              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel
                  value={isDefault}
                  control={
                    <Checkbox
                      checked={isDefault}
                      onClick={() => setIsDefault(!isDefault)}
                    />
                  }
                  label={context.i18n.defaultCategory}
                />
              </FormGroup>
            )}
            {childRows && (
              <TableContainer sx={{ maxHeight: 210 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell color="primary">{context.i18n.name}</TableCell>
                      <TableCell color="primary" align="center">
                        #
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {childRows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <TextField
                            color="secondary"
                            size="small"
                            type={'text'}
                            value={row.name}
                            onChange={(e) => {
                              setSubCategoryName(row.id, e.currentTarget.value);
                            }}
                          />
                        </TableCell>
                        <TableCell>{row.seq}</TableCell>
                        <TableCell align="center">
                          <MovementButtons
                            moveElement={moveElement}
                            id={row.id}
                            first={row.seq === 1}
                            last={row.seq === childRows.length}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title={context.i18n.deleteSubCategory}>
                            <IconButton
                              disableRipple
                              color="error"
                              onClick={() => onDeltePressed(row.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!props.category && !props.import && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={() => setIsSubCategory(!isSubCategory)}
                    />
                  }
                  label={context.i18n.subCategory}
                />
              </FormGroup>
            )}
            {isSubCategory ? (
              <FormControl color="secondary" sx={{ mt: 2, width: '22rem' }}>
                <InputLabel
                  sx={{ alignSelf: 'center' }}
                  id="category-slect-label"
                >
                  {context.i18n.parentCategory}
                </InputLabel>
                <Select
                  labelId="category-slect-label"
                  label={context.i18n.parentCategory}
                  error={categorySelectionError}
                  value={parentCategoryId}
                >
                  {props.categories.map((category) => (
                    <MenuItem
                      key={category.id}
                      value={category.id}
                      onClick={() => {
                        setParentCategoryId(category.id);
                        setCategorySelectionError(false);
                      }}
                    >
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={() => onSave()} color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        {props.category && (
          <Tooltip title={context.i18n.deleteCategory}>
            <IconButton
              disableRipple
              color="error"
              onClick={() => onDeltePressed()}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton
            disableRipple
            onClick={props.onClose.bind(this)}
            color="primary"
          >
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
      <Dialog
        open={openWarningDialog}
        onClose={() => setOpenWarningDialog(false)}
      >
        <DialogTitle>{context.i18n.createCategory}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {context.i18n.formatString(
              context.i18n.areYouSureYouWantToImportIntoNewCategory,
              categoryName,
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip title={context.i18n.import}>
            <IconButton
              disableRipple
              onClick={() =>
                props.onCreate(
                  categoryName,
                  isSubCategory,
                  isDefault,
                  parentCategoryId,
                )
              }
              color="primary"
            >
              <FontAwesomeIcon icon={faFileImport} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.cancelBtnTooltip}>
            <IconButton
              disableRipple
              onClick={() => setOpenWarningDialog(false)}
              color="primary"
            >
              <FontAwesomeIcon icon={faX} />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
      >
        <DeleteCategoryDialog
          onClose={() => setShowDeleteDialog(false)}
          onDelete={() => onDeleteCategory()}
        />
      </Dialog>
    </Box>
  );
};

export default CategoryDetailsDialog;
