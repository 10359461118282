import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPeace,
  faPen,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import { AdminBillingApi } from '../../../../api';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { queryClient } from '../../../../utils';
import { EditIndividualContactDialog } from '../../../dialogs/edit-individual-contact-dialog/edit-individual-contact-dialog';

interface ContactTableProps {
  orgId: string;
}

export const ContactTable: FC<ContactTableProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(false);

  const [rows, setRows] = useState([]);

  const [contactId, setContactId] = useState<string | undefined>();

  useQuery(
    ['sevDeskContacts', props.orgId],
    () => AdminBillingApi.getSevDeskContacts(context.authToken, props.orgId),
    {
      onSuccess: (res) => {
        setRows(res.data);
      },
      enabled: context.authToken !== undefined && context.sevDeskActive,
    },
  );

  const onDelete = async (id: string) => {
    try {
      const res = await AdminBillingApi.deleteSevDeskContact(
        context.authToken,
        id,
      );

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      queryClient.invalidateQueries(['sevDeskContacts']);
    } catch (e) {
      console.error(e);
    }
  };

  const tableColumns: GridColDef[] = [
    {
      headerName: context.i18n.name,
      field: 'name',
      flex: 1,
    },
    {
      field: 'actions',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Tooltip title={context.i18n.addCustomer}>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
            color="primary"
            disableRipple
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params: any) => (
        <Box>
          <Tooltip title={context.i18n.editCustomer}>
            <IconButton
              onClick={() => {
                setContactId(params.id);
                setOpen(true);
              }}
              color="primary"
              disableRipple
            >
              <FontAwesomeIcon icon={faPen} />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={context.i18n.deleteCustomer}>
            <IconButton
              onClick={() => {
                onDelete(params.id);
              }}
              color="error"
              disableRipple
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip> */}
        </Box>
      ),
    },
  ];

  return (
    <>
      <DataGridPro
        sx={{
          fontSize: 17,
          m: 5,
          height: '90%',
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        disableColumnSelector={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        pageSizeOptions={[15]}
        autoHeight
      />
      <EditIndividualContactDialog
        contactId={contactId}
        open={open}
        orgId={props.orgId}
        onClose={() => {
          setOpen(false);
          setContactId(undefined);
        }}
      />
    </>
  );
};
