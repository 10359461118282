import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { selectableRoles } from '../../../types/roles';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdminBillingApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { queryClient } from '../../../utils';
import { ContactAddressFields } from '../../admin/billing';
import { useQuery } from 'react-query';

interface EditOrganizationContactDialogProps {
  open: boolean;
  onClose: () => void;
  contactId?: string;
}

export const EditOrganizationContactDialog: FC<
  EditOrganizationContactDialogProps
> = (props) => {
  const context = useContext(AppContext);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const [street, setStreet] = useState('');
  const [streetError, setStreetError] = useState(false);
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState(false);

  const [vatNumber, setVatNumber] = useState('');
  const [vatNumberError, setVatNumberError] = useState(false);

  useEffect(() => {
    if (!props.contactId) {
      setName('');
      setVatNumber('');
      setCity('');
      setStreet('');
      setZipcode('');
    }

    setNameError(false);
    setVatNumberError(false);
    setCityError(false);
    setZipcodeError(false);
    setStreetError(false);
  }, [props.open, props.contactId]);

  useQuery(
    ['getSevDeskContact', props.contactId],
    () => AdminBillingApi.getSevDeskContact(context.authToken, props.contactId),
    {
      enabled:
        context.authToken !== undefined &&
        props.contactId !== undefined &&
        context.sevDeskActive,
      onSuccess: (res) => {
        setName(res.data.name);
        setCity(res.data.city);
        setZipcode(res.data.zipcode);
        setStreet(res.data.street);
        setVatNumber(res.data.vatNumber);
      },
    },
  );

  const onSave = async () => {
    try {
      setNameError(name === '');
      setCityError(city === '');
      setStreetError(street === '');
      setZipcodeError(zipcode === '');
      setVatNumberError(vatNumber === '');

      if (
        name === '' ||
        zipcode === '' ||
        street === '' ||
        city === '' ||
        vatNumber === ''
      ) {
        return;
      }

      const res = await AdminBillingApi.editOrCreateSevDeskContact(
        context.authToken,
        Object.assign(
          { name, street, zipcode, city, vatNumber },
          props.contactId && {
            id: props.contactId,
          },
        ),
      );

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      queryClient.invalidateQueries(['sevDeskOrganizations']);
      props.onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.addContact}</DialogTitle>
      <DialogContent>
        <Stack m={2} spacing={2} width="22rem">
          <TextField
            value={name}
            error={nameError}
            label={context.i18n.name}
            onChange={(e) => {
              setNameError(false);
              setName(e.currentTarget.value);
            }}
          />
          <TextField
            value={vatNumber}
            error={vatNumberError}
            label={context.i18n.vatNumber}
            onChange={(e) => {
              setVatNumberError(false);
              setVatNumber(e.currentTarget.value);
            }}
          />
          <ContactAddressFields
            cityError={cityError}
            city={city || ''}
            setCity={(c) => {
              setCityError(false);
              setCity(c);
            }}
            streetError={streetError}
            street={street || ''}
            setStreet={(s) => {
              setStreetError(false);
              setStreet(s);
            }}
            zipcodeError={zipcodeError}
            zipcode={zipcode || ''}
            setZipcode={(z) => {
              setZipcodeError(false);
              setZipcode(z);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={onSave} disableRipple color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
