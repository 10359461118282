import { ReferencePersonRole, RelationshipType } from '@casecare/types';
import { axiosInstance } from '../../utils';

export default class EcoMapApi {
  static createEcoMap(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('eco-map/create/' + clientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editEcoMap(
    token: string | undefined,
    ecoMapId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('eco-map/edit/' + ecoMapId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteEcoMap(
    token: string | undefined,
    ecoMapId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .delete('eco-map/' + ecoMapId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static listEcoMap(
    token: string | undefined,
    clientId: string | undefined,
    body: any | undefined,
  ): Promise<any> {
    return axiosInstance
      .post('eco-map/list/' + clientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getEcoMap(
    token: string | undefined,
    clientId: string | undefined,
    indexId: string | undefined,
  ) {
    return axiosInstance
      .get('eco-map/' + clientId + '/' + indexId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getEcoMapClients(
    token: string | undefined,
    clientId: string | undefined,
    indexId: string | undefined,
  ) {
    return axiosInstance
      .get('eco-map/clients/' + clientId + '/' + indexId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getEcoMapCalculation(
    token: string | undefined,
    clientId: string | undefined,
    indexId: string | undefined,
  ) {
    return axiosInstance
      .get('eco-map/calculation/' + clientId + '/' + indexId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveClient(
    token: string | undefined,
    indexClientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('eco-map/save/client/' + indexClientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createConnection(
    token: string | undefined,
    ecoMapId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('eco-map/connection/' + ecoMapId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editConnection(
    token: string | undefined,
    connectionId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .put('eco-map/connection/' + connectionId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteConnection(
    token: string | undefined,
    connectionId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .delete('eco-map/connection/' + connectionId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editPosition(
    token: string | undefined,
    positionId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .put('eco-map/position/' + positionId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addEcoMapRemovedClient(
    token: string | undefined,
    clientId: string | undefined,
    ecoMapId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .post('eco-map/removed/client/' + clientId + '/' + ecoMapId, undefined, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteEcoMapRemovedClient(
    token: string | undefined,
    clientId: string | undefined,
    ecoMapId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .delete('eco-map/removed/client/' + clientId + '/' + ecoMapId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
