import { FormFieldType } from '@casecare/types';
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../../hooks/context';
import { FormDialogProps } from '../../../../types';

const FormDateDialog: FC<FormDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [formatError, setFormatError] = useState(false);
  const [format, setFormat] = useState<FormFieldType | undefined>(
    props?.element?.type,
  );
  const [element] = useState(props.element);

  useEffect(() => {
    if (!props.saving) {
      return;
    }
    let errors = false;
    if (format === undefined) {
      errors = true;
      setFormatError(true);
    }

    props.onSave({ ...element, type: format, fieldOptions: [] }, errors);
  }, [props.saving]);

  return (
    <Box>
      <Stack direction="column" sx={{ width: '22rem' }} spacing={2}>
        <FormControl error={formatError} color="secondary">
          <InputLabel sx={{ alignSelf: 'center' }} id="format-select-label">
            {context.i18n.format}
          </InputLabel>
          <Select
            labelId="format-select-label"
            id="date-format-select"
            label={context.i18n.format}
            value={format ? format : ''}
          >
            <MenuItem
              value={FormFieldType.DATE}
              onClick={() => setFormat(FormFieldType.DATE)}
            >
              {context.i18n.normal}
            </MenuItem>
            <MenuItem
              value={FormFieldType.DATE_NO_DAY}
              onClick={() => setFormat(FormFieldType.DATE_NO_DAY)}
            >
              {context.i18n.formElementTypeDateNoDay}
            </MenuItem>
            <MenuItem
              value={FormFieldType.DATETIME}
              onClick={() => setFormat(FormFieldType.DATETIME)}
            >
              {context.i18n.formElementTypeDateTime}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default FormDateDialog;
