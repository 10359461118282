import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClientApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { queryClient } from '../../../utils';

interface RemoveSupervisorDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  removedSupervisorId?: string;
}

const RemoveSupervisorDialog: FC<RemoveSupervisorDialogProps> = (props) => {
  const context = useContext(AppContext);

  const onRemove = async () => {
    const res = await ClientApi.removeSupervisor(
      context.authToken,
      context.clientId,
      props.removedSupervisorId,
    );

    if (res.message) snackbarUtils.success(res.message);

    queryClient.invalidateQueries('clientData');

    props.onClose();
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>{}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {context.i18n.removeSupervisorText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.removeSupervisor}>
          <IconButton disableRipple onClick={onRemove} color="error">
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton disableRipple onClick={props.onCancel} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveSupervisorDialog;
