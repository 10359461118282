import {
  Autocomplete,
  Box,
  Button,
  Grow,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import 'moment/locale/de';
import { theme } from '../../../../utils';
import { ProtocolApi } from '../../../../api';
import { useQuery } from 'react-query';

interface ProtocolFilterToolbarProps {
  startDate: moment.Moment;
  setStartDate: (date: moment.Moment) => void;
  endDate: moment.Moment;
  setEndDate: (date: moment.Moment) => void;
  setSupervisorIds: (ids: string[]) => void;
  selectedClientHelpForms: any;
  setSelectedClientHelpForms: (ids: any) => void;
  onExport: () => void;
  onReleaseHours: () => void;
  disableRelease: boolean;
  familyId?: string;
  clientId?: string;
  billing?: boolean;
}

const ProtocolFilterToolbar: FC<ProtocolFilterToolbarProps> = (props) => {
  const context = useContext(AppContext);
  const {
    setStartDate,
    endDate,
    setEndDate,
    setSupervisorIds,
    selectedClientHelpForms,
    setSelectedClientHelpForms,
    onExport,
    onReleaseHours,
    disableRelease,
    billing,
  } = props;

  const [clientHelpForms, setClientHelpForms] = useState([]);

  const clientHelpFormsQuery = useQuery({
    queryKey: ['getHelpForms', props.clientId, props.familyId],
    queryFn: () => {
      return props.clientId
        ? ProtocolApi.getHelpForms(context.authToken, props.clientId)
        : ProtocolApi.getFamilyHelpForms(context.authToken, props.familyId);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!clientHelpFormsQuery.isLoading) {
      if (clientHelpFormsQuery.error) {
        console.error(clientHelpFormsQuery.error);
      } else if (
        clientHelpFormsQuery.data &&
        clientHelpFormsQuery.data.data &&
        clientHelpFormsQuery.data.data.data
      ) {
        setClientHelpForms(clientHelpFormsQuery.data.data.data);
      }
    }
  }, [
    clientHelpFormsQuery.data,
    clientHelpFormsQuery.error,
    clientHelpFormsQuery.isLoading,
  ]);

  return billing ? (
    <Box
      display="flex"
      sx={{
        p: 1,
        mt: 1,
        mr: 'auto',
      }}
    >
      <Box
        sx={{
          mr: 2,
          mt: { xs: 2, sm: 0 },
        }}
      >
        <DateTimePicker
          label={context.i18n.protocolTime}
          onChange={(v: any) => {
            setStartDate(v.clone().startOf('month'));
            setEndDate(v.clone().endOf('month'));
          }}
          value={endDate}
          views={['month']}
          format="MMMM"
        />
      </Box>
      <Button
        disableRipple
        color="primary"
        variant="contained"
        onClick={onExport}
      >
        {context.i18n.export}
      </Button>
    </Box>
  ) : (
    <>
      <Box
        display="flex"
        sx={{
          p: 1,
          mt: 1,
          width: '70%',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box
          sx={{
            width: '22rem',
            flex: 1,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <DateTimePicker
            label={context.i18n.protocolTime}
            onChange={(v: any) => {
              setStartDate(v.clone().startOf('month'));
              setEndDate(v.clone().endOf('month'));
            }}
            value={endDate}
            views={['month']}
            format="MMMM"
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Box>

        <Box
          sx={{
            width: '22rem',
            flex: 1,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          {context.isAdmin && (
            <Autocomplete
              value={selectedClientHelpForms}
              noOptionsText={context.i18n.noHelpForms}
              getOptionLabel={(option: any) => option.name || ''}
              renderInput={(params) => (
                <TextField {...params} label={context.i18n.helpForm} />
              )}
              isOptionEqualToValue={(option: any, value: any) =>
                option.id === value.id
              }
              onChange={(e, value) => {
                setSelectedClientHelpForms(value);
              }}
              multiple
              options={clientHelpForms}
            />
          )}
        </Box>

        {/* <Box
          sx={{
            width: '11rem',
            flex: 1,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <UserSelect
            setId={(ids) => setSupervisorIds(ids)}
            title={context.i18n.supervisor}
            userId={context.userId}
            multiple
            error={false}
          />
        </Box> */}
      </Box>
      <Box m={2}>
        <Button
          disableRipple
          color="primary"
          variant="contained"
          onClick={onExport}
        >
          {context.i18n.export}
        </Button>
        <Button
          disableRipple
          color="primary"
          variant="contained"
          disabled={disableRelease}
          // disabled={isInReview}
          onClick={onReleaseHours}
          sx={{
            ml: { sm: 5 },
            display: context.billingActive ? 'initial' : 'none',
          }}
        >
          {context.i18n.releaseHours}
        </Button>
      </Box>
      <Box m={2}>
        <Typography>
          <span style={{ color: theme.palette.error.main }}>*</span>{' '}
          {context.i18n.redHoursNotBillable}
        </Typography>
        {/* {isInReview && (
          <Typography color={theme.palette.warning.main}>
            {context.i18n.submittedForRelease}
          </Typography>
        )} */}
      </Box>
    </>
  );
};

export default ProtocolFilterToolbar;
