import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface HeaderProps {
  title: string;
  subTitle?: string;
  m?: number;
}

const Header: FC<HeaderProps> = (props) => {
  return (
    <Box sx={{ m: props.m, mt: 0 }}>
      <Typography variant="h5" component="h5" color="primary" fontWeight="bold">
        {props.title}
        <Typography color="primary" sx={{ lineHeight: 0.5, mt: 1 }}>
          {props.subTitle}
        </Typography>
      </Typography>
    </Box>
  );
};

export default Header;
