import {
  Box,
  Button,
  FormHelperText,
  OutlinedInput,
  Popover,
  TextField,
} from '@mui/material';
import { FC, useState, useRef, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';
import { FormTextProps } from '../../../../../types';
import { TextSize } from '../../../../../types/form-text-props';
import { getTextType, theme } from '../../../../../utils';
import FormEdit from '../form-edit/form-edit';
import FormElementHeader from '../form-element-header/form-element-header';
import { FormFieldTextType } from '@casecare/types';
import isNumeric from 'validator/lib/isNumeric';
import { FormFieldStyle } from '../../../../../types/form-element-props';
import { ChromePicker } from 'react-color';

const FormText: FC<FormTextProps> = (props) => {
  const [disabled, setDisabled] = useState(true);
  const [inputValue, setInputValue] = useState(
    props.value === undefined ? '' : props.value,
  );
  const [bigInput] = useState(props.size !== TextSize.NORMAL);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const context = useContext(AppContext);

  const [error, setError] = useState<string | null>(null);

  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  const checkErrors = (value: any) => {
    let currentError = null;
    if (
      (value === null || value === undefined || value.trim() === '') &&
      props.required
    )
      currentError = context.i18n.inputRequiredError;
    // else if (props.dataType === FormFieldTextType.EMAIL && !isEmail(value))
    //   currentError = context.i18n.inputEmailError;
    // else if (
    //   props.dataType === FormFieldTextType.PHONE &&
    //   !isMobilePhone(value)
    // )
    //   currentError = context.i18n.inputPhoneError;
    else if (props.dataType === FormFieldTextType.NUMBER && !isNumeric(value))
      currentError = context.i18n.inputNumberError;
    setError(currentError);

    return currentError;
  };

  useEffect(() => {
    checkErrors(inputValue);
    props.dialog &&
      props.onChange &&
      props.onChange(inputValue, checkErrors(inputValue), true);
  }, []);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    props.saved && props.onSave && props.onSave(inputValue);
    props.saved ? setDisabled(props.saved) : null;
    context.saveAll ? setDisabled(context.saveAll) : null;
  }, [props.saved, context.saveAll]);

  const onSave = () => {
    if (error !== null) return;
    props.onSave ? props.onSave(inputValue) : null;
    setDisabled(true);
  };

  const onChange = (changedInput: string) => {
    setInputValue(changedInput);
    props.onChange && props.onChange(changedInput, checkErrors(changedInput));
  };

  const onCancel = () => {
    setDisabled(true);
    setFocus(false);
    setInputValue(props.value ? props.value : '');
    props.onCancel ? props.onCancel() : null;
  };

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    } else if (!focus) {
      setDisabled(false);
      // time out is needed because disabled items cannot be focused
      setTimeout(() => {
        inputRef.current?.focus();
      }, 10);
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const renderStyle = () => {
    switch (props.style) {
      case FormFieldStyle.NORMAL:
      case FormFieldStyle.OUTLINED:
        return (
          <>
            <Box>
              <TextField
                variant={
                  props.style === FormFieldStyle.OUTLINED
                    ? 'outlined'
                    : 'standard'
                }
                label={props.title}
                onClick={onClick.bind(this)}
                error={error ? true : false}
                fullWidth
                disabled={props.disabled}
                id={props.id}
                type={getTextType(props.dataType)}
                value={inputValue}
                inputRef={inputRef}
                sx={bigInput ? { display: 'flex', alignItems: 'start' } : {}}
                multiline={
                  props.size === TextSize.MEDIUM ||
                  props.size === TextSize.LARGE
                }
                rows={props.size === TextSize.MEDIUM ? 3 : 10}
                onChange={(e) => onChange(e.currentTarget.value)}
              />
              <FormHelperText error>{error && error}</FormHelperText>
            </Box>
          </>
        );
      case FormFieldStyle.COLOR_PICKER:
        return (
          <Box>
            <Button
              startIcon={props.icon}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                handleClick(event);
              }}
              sx={{
                '& .MuiButton-startIcon svg': {
                  fontSize: '2.5rem',
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '1.5rem',
                  },
                },
              }}
              variant="text"
            >
              <Box
                sx={{
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: theme.palette.primary.main,
                  backgroundColor: inputValue,
                  p: 1.5,
                  width: '30px',
                }}
              ></Box>
            </Button>
            <Popover
              id={props.id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <ChromePicker
                disableAlpha
                onChange={(color: any) => {
                  onChange(color.hex);
                }}
                color={inputValue}
              />
            </Popover>
          </Box>
        );
      default:
        return (
          <>
            {props.title && (
              <FormElementHeader
                required={props.required}
                hideExport={props.hideExport}
                title={props.title}
              />
            )}

            <Box>
              <OutlinedInput
                onClick={onClick.bind(this)}
                error={error ? true : false}
                fullWidth
                id={props.id}
                type={getTextType(props.dataType)}
                value={inputValue}
                inputRef={inputRef}
                sx={bigInput ? { display: 'flex', alignItems: 'start' } : {}}
                multiline={
                  props.size === TextSize.MEDIUM ||
                  props.size === TextSize.LARGE
                }
                rows={props.size === TextSize.MEDIUM ? 3 : 10}
                onChange={(e) => onChange(e.currentTarget.value)}
                readOnly={(disabled && !props.dialog) || readOnly}
                disabled={props.disabled}
                endAdornment={
                  !props.dialog &&
                  !props.preview &&
                  !readOnly && (
                    <FormEdit
                      disabled={disabled}
                      onCancel={onCancel.bind(this)}
                      onEdit={(disabled) => {
                        props.onClick ? props.onClick() : setDisabled(disabled);
                      }}
                      onSave={() => onSave()}
                      big={bigInput}
                    />
                  )
                }
              />
              <FormHelperText error>{error && error}</FormHelperText>
            </Box>
          </>
        );
    }
  };

  return <Box sx={{ m: 2 }}>{renderStyle()}</Box>;
};

export default FormText;
