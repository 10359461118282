import { Box } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { OrgApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import logo from '../../../assets/logo.png';

interface LogoProps {
  onClick?: () => void;
}

const Logo: FC<LogoProps> = (props) => {
  const context = useContext(AppContext);

  const [imageUrl, setImageUrl] = useState<string>();

  useQuery(['logo'], () => OrgApi.getLogo(context.authToken), {
    enabled: context.authToken !== undefined,
    onSuccess: (res) => {
      setImageUrl(res.data);
    },
  });

  return (
    <Box
      onClick={props.onClick}
      sx={{ display: 'flex', justifyContent: 'center', m: 1, ml: 2 }}
    >
      <img style={{ maxHeight: 50, maxWidth: 100 }} src={logo} />
    </Box>
  );
};

export default Logo;
