import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Link,
  Paper,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { useQuery } from 'react-query';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { TodoApi } from '../../../api';
import { parseDateString, queryClient, theme } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faPen,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import TodoDialog from '../add-todo-dialog/add-todo-dialog';
import { useNavigate } from 'react-router-dom';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import exp from 'constants';
import moment from 'moment';

interface TodoListProps {
  done: boolean;
  title: string;
  clientId?: string;
  familyId?: string;
}

const TodoList: FC<TodoListProps> = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [todoOpen, setTodoOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(!props.done);

  const [todoValues, setTodoValues] = useState();

  const { data, isLoading, error } = useQuery(
    [props.done ? 'doneTodos' : 'todo'],
    () => {
      return TodoApi.getTodos(
        context.authToken,
        Object.assign(
          { done: props.done },
          props.clientId && { clientId: props.clientId },
          props.familyId && { familyId: props.familyId },
        ),
      );
    },
    {
      enabled: context.authToken !== undefined,
    },
  );

  useEffect(() => {
    if (!error && data && data.data) {
      setRows(data.data);
      queryClient.invalidateQueries(['entries']);
    }
  }, [data, error]);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'title',
      headerName: context.i18n.todoTitle,
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <Box overflow="auto" maxHeight={80} m={1}>
          <Typography
            sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'dueDate',
      headerName: context.i18n.todoDueDate,
      flex: 1,
      hideable: false,
      valueFormatter: (value) => parseDateString(value),
    },
    {
      field: 'client',
      headerName: context.i18n.client,
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate('/clients/' + params.row.clientId + '/clientData');
            }}
          >
            {params.value}
          </Link>
        </Box>
      ),
    },
    {
      field: 'family',
      headerName: context.i18n.family,
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate('/families/' + params.row.familyId + '/familyData');
            }}
          >
            {params.value}
          </Link>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Tooltip title={context.i18n.addTodo}>
          <IconButton
            disableRipple
            color="primary"
            onClick={() => {
              setTodoValues(undefined);
              setTodoOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Tooltip title={context.i18n.edit}>
          <IconButton
            disableRipple
            color="primary"
            disabled={params.row.isTeamTodo}
            onClick={() => {
              setTodoValues(params.row);
              setTodoOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPen} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const updateTodo = async (id: string) => {
    try {
      const body = Object.assign({
        todoId: id,
        done: !props.done,
      });
      const res = await TodoApi.updateTodo(context.authToken, body);
      if (res.message) {
        snackbarUtils.success(res.message);
      }
      queryClient.invalidateQueries('todo');
      queryClient.invalidateQueries('doneTodos');
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        sx={{
          '& .MuiAccordionSummary-content': { justifyContent: 'center' },
        }}
      >
        <Typography variant="h1" fontSize="2em" textAlign="center">
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [{ field: 'dueDate', sort: 'asc' }],
            },
          }}
          columnVisibilityModel={{
            actions: !props.done,
          }}
          sx={{
            m: 2,
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            borderRadius: 0,
            display: 'flex',
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
              {
                display: 'none',
              },
            '& .MuiDataGrid-row.urgent': {
              color: theme.palette.warning.main,
            },
            '& .MuiDataGrid-row.late': {
              color: theme.palette.error.main,
            },
          }}
          columns={tableColumns}
          rows={rows}
          checkboxSelection
          isRowSelectable={(params) => !params.row.isSynced && !params.row.done}
          getRowId={(row) => row.id}
          rowSelectionModel={props.done ? rows.map((r: any) => r.id) : []}
          onRowSelectionModelChange={(ids: any[]) => {
            if (!props.done && ids.length > 0) {
              updateTodo(ids[0]);
            } else {
              const removedRow: any = rows.filter(
                (r: any) => !ids.includes(r.id),
              )[0];
              updateTodo(removedRow.id);
            }
          }}
          loading={isLoading}
          columnBufferPx={4}
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          autoHeight
          rowHeight={80}
          pageSizeOptions={[10, 25]}
          getRowClassName={(params) => {
            if (moment(moment()).isAfter(params.row.dueDate) && !props.done) {
              return 'late';
            } else if (
              moment(params.row.dueDate).diff(moment(), 'hours') <= 24 &&
              !props.done
            ) {
              return 'urgent';
            } else {
              return '';
            }
          }}
        />
      </AccordionDetails>
      <TodoDialog
        todoValues={todoValues}
        open={todoOpen}
        onClose={() => setTodoOpen(false)}
        familyId={props.familyId}
        clientId={props.clientId}
      />
    </Accordion>
  );
};

export default TodoList;
