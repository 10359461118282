import { Box, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { FC, useContext } from 'react';
import { ReportSelect } from '..';
import { AppContext } from '../../../../hooks/context';
import UserSelect from '../../user-select/user-select';

interface ReportFilterToolbarProps {
  creationDate: moment.Moment;
  setCreationDate: (date: moment.Moment) => void;
  setSupervisorIds: (ids: string[]) => void;
  setTemplateId: (id: string) => void;
  templateId: string;
}

const ReportFilterToolbar: FC<ReportFilterToolbarProps> = (props) => {
  const context = useContext(AppContext);
  const {
    creationDate,
    setCreationDate,
    setSupervisorIds,
    setTemplateId,
    templateId,
  } = props;

  return (
    <>
      <Box
        display="flex"
        sx={{
          p: 1,
          mt: 1,
          width: '70%',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box sx={{ width: '22rem', flex: 1, mr: { sm: 5 } }}>
          <DatePicker
            format="DD.MM.YYYY"
            label={context.i18n.creationDate}
            onChange={(v: any) => setCreationDate(v)}
            value={creationDate}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Box>
        <Box
          sx={{
            width: '22rem',
            flex: 1,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <UserSelect
            setId={(ids) => setSupervisorIds(ids)}
            title={context.i18n.supervisor}
            multiple
            error={false}
          />
        </Box>
        <Box
          sx={{
            width: '22rem',
            flex: 1,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <ReportSelect
            templateId={templateId}
            setReportId={(id) => setTemplateId(id)}
          />
        </Box>
      </Box>
    </>
  );
};

export default ReportFilterToolbar;
