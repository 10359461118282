export enum ClientSubTabType {
  CLIENT_DATA = 'clientData',
  CLIENT_DATA_EXTENDED = 'clientDataExtended',
  CLIENT_DATA_HELP_FORM = 'clientDataHelpForm',
  CLIENT_DATA_CARE = 'clientDataCare',
  FAMILY = 'familyData',
  REFERENCE = 'referenceData',
  AVATAR = 'avatar',
  ECOMAP = 'ecoMap',
  GENOGRAMM = 'genoGramm',
  MOOD = 'mood',
}
