import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { FormElement } from '../../../client';
import {
  ClientDataType,
  FormFieldType,
  ReferencePersonRole,
  RelationshipType,
  Sex,
} from '@casecare/types';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { FormFieldStyle } from '../../../../types/form-element-props';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPen,
  faTrash,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../../../utils';
import EcoMapApi from '../../../../api/eco-map/eco-map';
import { useQuery } from 'react-query';
import { FamilyApi } from '../../../../api';
import AvatarDeadIcon from '../../../avatar/avatar-dead-icon/avatar-dead-icon';
import EcoMapAvatarIcon from '../../eco-map-avatar-icon/eco-map-avatar-icon';
import { EcoMapConnectionColorType } from '../../../../types/eco-map-connection-type';

interface EcoMapClientConnectionsProps {
  client: any;
  connectedClients: any;
}

const EcoMapClientConnection: FC<EcoMapClientConnectionsProps> = (props) => {
  const context = useContext(AppContext);
  const [client, setClient] = useState(props.client);
  const [clientList, setClientList] = useState(props.connectedClients);
  const groupId = 'Eco-Map-Client-Connections-' + client.id;

  const viewToClientData = (connection: any, index: any) => {
    const clientData = props.connectedClients.find(
      (c: any) => c.id === connection.clientToId,
    );
    if (clientData)
      return (
        <Box sx={{ mt: 5, mb: 2 }}>
          <Grid container>
            <Grid item xs={10}>
              <Divider textAlign="left">
                <Typography>
                  {clientData.avatar && (
                    <AvatarDeadIcon
                      invisible={clientData.isDead ? false : true}
                      sx={{
                        '& .MuiBadge-badge': {
                          transform: 'translate(50%, -25%)',
                          width: '40px',
                          height: '40px',
                        },
                      }}
                    >
                      <EcoMapAvatarIcon
                        isdead={clientData.isDead}
                        src={clientData.avatar.iconPath}
                        width="25px"
                        height="25px"
                      ></EcoMapAvatarIcon>
                    </AvatarDeadIcon>
                  )}{' '}
                  {clientData[ClientDataType.FIRST_NAME]}{' '}
                  {clientData[ClientDataType.LAST_NAME]}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={2}>
              <Divider textAlign="left">
                <Button
                  onClick={() => {
                    client.ecoMapFromConnections[index] = {
                      id: connection.id,
                    };
                    filterClientList();
                  }}
                  color={'error'}
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </Button>
              </Divider>
            </Grid>
          </Grid>
        </Box>
      );
    else return <></>;
  };

  const filterClientList = () => {
    let newList = clientList.filter(
      (cl: any) =>
        !client.ecoMapFromConnections.find(
          (conn: any) => conn.clientToId === cl.id,
        ),
    );

    //remove yourself
    newList = newList.filter((cl: any) => client.id !== cl.id);

    //remove removed client
    newList = newList.filter((cl: any) => cl.removedInEcoMaps.length <= 0);

    setClientList([...newList]);
  };

  useEffect(() => {
    setClient(props.client);
  }, [props.client]);

  useEffect(() => {
    setClientList(props.connectedClients);
    filterClientList();
  }, [props.connectedClients]);

  return (
    <>
      <Box sx={{ m: -2 }}>
        <FormElement
          type={FormFieldType.DROPDOWN}
          value={undefined}
          id={client.id + '-select'}
          onChange={(val: any) => {
            client.ecoMapFromConnections.push({
              clientToId: val,
              color: EcoMapConnectionColorType.NEUTRAL,
              clientFromId: client.id,
            });
            setClient(
              Object.assign({
                ...client,
              }),
            );
            filterClientList();
          }}
          fieldOptions={clientList.map((connectedClient: any) => {
            return {
              key: connectedClient.id,
              label: (
                <>
                  <Typography>
                    {connectedClient.avatar && (
                      <AvatarDeadIcon
                        invisible={connectedClient.isDead ? false : true}
                        sx={{
                          '& .MuiBadge-badge': {
                            transform: 'translate(50%, -25%)',
                            width: '40px',
                            height: '40px',
                          },
                        }}
                      >
                        <EcoMapAvatarIcon
                          isdead={connectedClient.isDead}
                          src={connectedClient.avatar.iconPath}
                          width="25px"
                          height="25px"
                        ></EcoMapAvatarIcon>
                      </AvatarDeadIcon>
                    )}{' '}
                    {connectedClient[ClientDataType.FIRST_NAME]}{' '}
                    {connectedClient[ClientDataType.LAST_NAME]}
                  </Typography>
                </>
              ),
            };
          })}
        ></FormElement>
      </Box>
      {client.ecoMapFromConnections.map((connection: any, index: any) => (
        <>
          {connection.clientToId && (
            <Box key={connection.clientToId}>
              {viewToClientData(connection, index)}
              <Box sx={{ m: -2 }}>
                <FormElement
                  type={FormFieldType.RADIO}
                  value={connection.color}
                  id={connection.clientToId}
                  groupId={groupId}
                  required
                  fieldOptions={Object.entries(EcoMapConnectionColorType).map(
                    (color) => {
                      return {
                        key: color[1],
                        label: color[0],
                        labelElement: (
                          <Box
                            sx={{
                              p: 2,
                              pl: 5,
                              pr: 5,
                              border: 1,
                              backgroundColor: color[1],
                            }}
                          ></Box>
                        ),
                      };
                    },
                  )}
                  onChange={(val: any) => {
                    client.ecoMapFromConnections[index] = {
                      id: connection.id,
                      clientToId: connection.clientToId,
                      clientFromId: connection.clientFromId
                        ? connection.clientFromId
                        : client.id,
                      color: val,
                    };
                    setClient(
                      Object.assign({
                        ...client,
                      }),
                    );
                  }}
                ></FormElement>
              </Box>
            </Box>
          )}
        </>
      ))}
    </>
  );
};
export default EcoMapClientConnection;
