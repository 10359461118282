import { FC, useContext } from 'react';
import { ClientTable } from '../../../components/client';
import { MobileNavBar } from '../../../components/mobile';
import { AppContext } from '../../../hooks/context';
import { Box } from '@mui/material';

const MobileClientList: FC = () => {
  const context = useContext(AppContext);

  return (
    <Box>
      <MobileNavBar title={context.i18n.clients} logoutBtn />
      <ClientTable archive={false} mobile />
    </Box>
  );
};

export default MobileClientList;
