import { ModuleType } from '@casecare/types';
import { axiosInstance } from '../../utils';

export default class AboApi {
  static getStripeUrl(token: string | undefined, url: string): Promise<any> {
    return axiosInstance
      .post(
        'abo/stripe',
        {
          redirectUrl: url,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static listAbos(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('abo/list', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addAbo(token: string | undefined, type: ModuleType): Promise<any> {
    return axiosInstance
      .post(
        'abo/add',
        {
          type,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }
}
