import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { ClientApi, FamilyApi, ProtocolApi, TodoApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { useQuery, useQueryClient } from 'react-query';
import { SearchBar } from '../../core';
import { FamilySelectDialog } from '../../dialogs';
import { FormFieldType } from '@casecare/types';
import client from '../../../api/client/client';
import { FormFieldStyle } from '../../../types/form-element-props';
import { FormElement } from '../../client';

interface EditProtocolDialogProps {
  open: boolean;
  onClose: () => void;
  todoValues?: any;
  clientId?: string;
  familyId?: string;
}

const TodoDialog: FC<EditProtocolDialogProps> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [todo, setTodo] = useState('');
  const [todoId, setTodoId] = useState();
  const [dueDate, setDueDate] = useState(moment());
  const [clientId, setClientId] = useState<string | undefined>();
  const [familyId, setFamilyId] = useState<string | undefined>();

  const [open, setOpen] = useState(props.open);
  const [families, setFamilies] = useState<any[]>([]);

  useQuery(['families', open], () => FamilyApi.getFamilies(context.authToken), {
    onSuccess: (res) => {
      setFamilies(res.data);
    },
    enabled: context.authToken !== undefined && open,
  });

  useEffect(() => {
    if (props.todoValues) {
      setTodo(props.todoValues.title);
      setFamilyId(props.todoValues.family || undefined);
      setClientId(props.todoValues.clientId || undefined);
      setDueDate(moment(props.todoValues.dueDate));
      setTodoId(props.todoValues.id);
    } else {
      setTodo('');
      setDueDate(moment);
      setTodoId(undefined);
      setClientId(props.clientId);
      setFamilyId(props.familyId);
    }
    setOpen(props.open);
  }, [props.open]);

  const onChangeDueDate = (date: any) => {
    if (date.isValid()) {
      setDueDate(date);
    }
  };

  const onSave = async () => {
    try {
      const body = Object.assign(
        {
          title: todo,
          dueDate: dueDate.toISOString(),
        },
        clientId !== undefined && {
          clientId,
        },
        familyId !== undefined &&
          familyId !== '' && {
            familyId,
          },
        todoId && {
          id: todoId,
        },
      );
      const res = await TodoApi.saveTodo(context.authToken, body);
      if (res.message) {
        snackbarUtils.success(res.message);
        setClientId(undefined);
      }
      queryClient.invalidateQueries(['todo', 'entries']);
      onClose();
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onClose = () => {
    setTodo('');
    props.onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{context.i18n.addTodo}</DialogTitle>
      <DialogContent>
        <Stack mt={2} spacing={2}>
          <TextField
            value={todo}
            onChange={(e) => {
              setTodo(e.currentTarget.value);
            }}
            label={context.i18n.todoTitle}
          />
          <DateTimePicker
            format="DD.MM.YYYY HH:mm"
            label={context.i18n.todoDueDate}
            minDate={moment()}
            onChange={(v: any) => onChangeDueDate(v)}
            value={dueDate}
            slotProps={{ textField: { fullWidth: true } }}
          />
          <SearchBar
            clientId={clientId}
            onSelect={(id) => setClientId(id)}
            clearable
            mediumSize
          />
          <FormElement
            dialog={true}
            hideExport
            type={FormFieldType.DROPDOWN}
            value={familyId}
            title={context.i18n.family}
            id={'family'}
            fieldOptions={families.map((family) => {
              return { key: family.id, label: family.name };
            })}
            style={FormFieldStyle.NORMAL}
            onChange={(value, error, onLoad) => {
              if (!onLoad) {
                setFamilyId(value);
              }
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={onSave} disableRipple color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TodoDialog;
