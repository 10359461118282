import { FormFieldType, ClientDataType, PregnancyState } from '@casecare/types';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { context } from 'msw';
import { FC, useContext, useState } from 'react';
import { FormElement } from '../../client';
import { AppContext } from '../../../hooks/context';
import { FormFieldStyle } from '../../../types/form-element-props';

interface GenoGrammPregnancyDataProps {
  clientList: any[];
  pregnancy: any;
  isLoading?: boolean;
}

export const GenoGrammPregnancyData: FC<GenoGrammPregnancyDataProps> = (
  props,
) => {
  const context = useContext(AppContext);

  const groupId =
    'Geno-Gramm-Pregnancy-Data-' + (props.pregnancy.id ?? 'CREATE');

  return (
    <Box sx={{ m: -2, display: 'flex', justifyContent: 'center' }}>
      {props.isLoading ? (
        <CircularProgress sx={{ m: 2 }} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormElement
              title={context.i18n.MOTHER}
              hideHeader
              dialog
              groupId={groupId}
              required
              type={FormFieldType.DROPDOWN}
              value={props.pregnancy.motherId}
              style={FormFieldStyle.NORMAL}
              noNoneOption
              id={'mother-select'}
              fieldOptions={props.clientList.map((c: any) => {
                return {
                  key: c.id,
                  label: `${c[ClientDataType.FIRST_NAME]} ${
                    c[ClientDataType.LAST_NAME]
                  }`,
                  labelElement: (
                    <>
                      <Typography></Typography>
                    </>
                  ),
                };
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormElement
              title={context.i18n.FATHER}
              hideHeader
              style={FormFieldStyle.NORMAL}
              type={FormFieldType.DROPDOWN}
              value={
                props.pregnancy.fatherId ? props.pregnancy.fatherId : undefined
              }
              id={'father-select'}
              dialog
              groupId={groupId}
              fieldOptions={props.clientList.map((c: any) => {
                return {
                  key: c.id,
                  label: `${c[ClientDataType.FIRST_NAME]} ${
                    c[ClientDataType.LAST_NAME]
                  }`,
                  labelElement: (
                    <>
                      <Typography></Typography>
                    </>
                  ),
                };
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormElement
              title={context.i18n.pregnancyState}
              hideHeader
              required
              style={FormFieldStyle.NORMAL}
              noNoneOption
              type={FormFieldType.DROPDOWN}
              value={props.pregnancy.pregnancyState}
              id={'pregnancy-select'}
              dialog
              groupId={groupId}
              fieldOptions={Object.entries(PregnancyState).map((p) => {
                return { key: p[0], label: context.i18n[p[0]] };
              })}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
