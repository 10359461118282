import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement } from '../../client';
import { FormFieldTextType, FormFieldType } from '@casecare/types';
import { AdminApi } from '../../../api';
import { FormFieldStyle } from '../../../types/form-element-props';

interface CreateHelpFormOrdererDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateHelpFormOrdererDialog: FC<CreateHelpFormOrdererDialogProps> = (
  props,
) => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(props.open);

  const [name, setName] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [street, setStreet] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const helpFormOrdererFields = [
    {
      id: 'name',
      name: context.i18n.name + ' *',
      required: false,
      type: FormFieldType.TEXT,
      value: name,
      onChange: setName,
    },
    {
      id: 'vatNumber',
      name: context.i18n.vatNumber + ' *',
      required: false,
      type: FormFieldType.TEXT,
      value: vatNumber,
      onChange: setVatNumber,
    },
    {
      id: 'street',
      name: context.i18n.street + ' *',
      required: false,
      type: FormFieldType.TEXT,
      value: street,
      onChange: setStreet,
    },
    {
      id: 'additionalDetails',
      name: context.i18n.additionalDetails,
      required: false,
      type: FormFieldType.TEXT,
      value: additionalDetails,
      onChange: setAdditionalDetails,
    },
    {
      id: 'zipcode',
      name: context.i18n.zipcode + ' *',
      required: false,
      type: FormFieldType.TEXT,
      value: zipcode,
      onChange: setZipcode,
    },
    {
      id: 'city',
      name: context.i18n.city + ' *',
      required: false,
      type: FormFieldType.TEXT,
      value: city,
      onChange: setCity,
    },
    {
      id: 'email',
      name: context.i18n.email,
      required: false,
      type: FormFieldType.TEXT,
      dataType: FormFieldTextType.EMAIL,
      value: email,
      onChange: setEmail,
    },
    {
      id: 'phone',
      name: context.i18n.phone,
      required: false,
      type: FormFieldType.TEXT,
      dataType: FormFieldTextType.PHONE,
      value: phone,
      onChange: setPhone,
    },
  ];

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onSave = async () => {
    try {
      if (name && street && zipcode && city) {
        const res = await AdminApi.createHelpFormOrderer(
          context.authToken,
          Object.assign(
            {
              name,
              vatNumber,
              street,
              additionalDetails,
              zipcode,
              city,
            },
            email && { email },
            phone && { phone },
          ),
        );

        if (res.message) {
          snackbarUtils.success(res.message);
          onClose();
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onClose = async () => {
    for (const helpFormOrdererField of helpFormOrdererFields) {
      context.removeChangedCategoryData(helpFormOrdererField.id);
    }
    props.onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{context.i18n.createOrderer}</DialogTitle>
      <DialogContent>
        {helpFormOrdererFields.map((field) => (
          <FormElement
            dialog={true}
            hideExport
            type={field.type}
            value={field.value}
            title={field.name}
            required={field.required}
            dataType={field.dataType || undefined}
            onChange={field.onChange}
            id={field.id}
            key={field.id}
            disableGlobalOnChange
            style={FormFieldStyle.OUTLINED}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton
              onClick={onSave}
              disableRipple
              color="primary"
              disabled={!name || !vatNumber || !street || !zipcode || !city}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateHelpFormOrdererDialog;
