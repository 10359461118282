import { useMediaQuery, Paper, Grid } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { FormCategoryData } from '../../../../types';
import { theme, splitArray } from '../../../../utils';
import {
  hideOnExtraSmallScreen,
  hideOnSmallScreen,
} from '../../../../utils/constants';
import FormCategoryRow from './form-category-row';

interface FormCategoryProps {
  data?: FormCategoryData[];
}

const FormCategory: FC<FormCategoryProps> = (props) => {
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [firstCol, setFirstCol] = useState<FormCategoryData[]>([]);
  const [secondCol, setSecondCol] = useState<FormCategoryData[]>([]);
  const [thirdCol, setThirdCol] = useState<FormCategoryData[]>([]);

  const [halfCol, setHalfCol] = useState<FormCategoryData[]>([]);
  const [otherHalfCol, setOtherHalfCol] = useState<FormCategoryData[]>([]);

  const [singleCol, setSingleCol] = useState<FormCategoryData[]>([]);

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      const splitData = splitArray(props.data, 3);

      setFirstCol(splitData[0]);
      setSecondCol(splitData[1]);
      setThirdCol(splitData[2]);

      const halfData = splitArray(props.data, 2);

      setHalfCol(halfData[0]);
      setOtherHalfCol(halfData[1]);

      setSingleCol(props.data);
    }
  }, [props.data]);

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
      }}
    >
      <Grid container sx={{ mt: 2 }}>
        {firstCol ? (
          <Grid item xs={isBigScreen ? 4 : isMediumScreen ? 6 : 12}>
            <FormCategoryRow
              array={
                isBigScreen ? firstCol : isMediumScreen ? halfCol : singleCol
              }
            />
          </Grid>
        ) : null}
        {secondCol && (isMediumScreen || isBigScreen) ? (
          <Grid
            item
            xs={isBigScreen ? 4 : 6}
            sx={{ display: hideOnExtraSmallScreen }}
          >
            <FormCategoryRow array={isBigScreen ? secondCol : otherHalfCol} />
          </Grid>
        ) : null}
        {thirdCol && isBigScreen ? (
          <Grid item xs={4} sx={{ display: hideOnSmallScreen }}>
            <FormCategoryRow array={thirdCol} />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default FormCategory;
