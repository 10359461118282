import {
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Tooltip,
} from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';
import { ProtocolTable } from '../../client/documentation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const ProtocolBillingDialog: FC<{
  row:
    | undefined
    | {
        clientId?: string;
        familyId?: string;
        name: string;
        supervisors: string[];
      };
  date: moment.Moment;
  billingShowAll?: boolean;
  rejected?: boolean;
  released?: boolean;
  onClose: () => void;
}> = (props) => {
  const context = useContext(AppContext);
  const { row, date, onClose, billingShowAll, rejected, released } = props;
  return (
    <Dialog
      open={typeof row !== 'undefined'}
      sx={{ ml: 'auto', mr: 'auto', pt: 8 }}
      onClose={onClose}
      fullScreen
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {row && (
          <Link
            href={
              row.familyId
                ? `/families/${row.familyId}/documentation`
                : `/clients/${row.clientId}/documentation`
            }
            target="_blank"
          >
            {row.name}
          </Link>
        )}
        <Tooltip title={context.i18n.closeBtnTooltip}>
          <FontAwesomeIcon
            icon={faX}
            onClick={onClose}
            style={{
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <ProtocolTable
          clientId={row?.clientId}
          familyId={row?.familyId}
          name={row?.name}
          supervisorIds={[]}
          date={date}
          billingShowAll={billingShowAll}
          rejected={rejected}
          released={released}
          hideHeader
          noPaper
          billing
        />
      </DialogContent>
    </Dialog>
  );
};

export default ProtocolBillingDialog;
