import { FormFieldType } from '@casecare/types';
import { FC } from 'react';
import { FormDialogProps } from '../../../../types';
import FormDateDialog from '../form-date-dialog/form-date-dialog';
import FormSelectionDialog from '../form-selection-dialog/form-selection-dialog';
import FormTextDialog from '../form-text-dialog/form-text-dialog';

interface FormElementDialogContentProps extends FormDialogProps {
  type: FormFieldType | undefined;
}

const FormElementDialogContent: FC<FormElementDialogContentProps> = (props) => {
  switch (props.type) {
    case FormFieldType.TEXT:
      return (
        <FormTextDialog
          onSave={props.onSave}
          saving={props.saving}
          element={props.element}
        />
      );
    case FormFieldType.DATE:
      return (
        <FormDateDialog
          onSave={props.onSave}
          saving={props.saving}
          element={props.element}
        />
      );
    case FormFieldType.DROPDOWN:
      return (
        <FormSelectionDialog
          onSave={props.onSave}
          saving={props.saving}
          element={props.element}
        />
      );
    case FormFieldType.FORM_GROUP:
      return <></>;
    case FormFieldType.LIST:
      return <></>;
    default:
      return <></>;
  }
};

export default FormElementDialogContent;
