import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FC, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { copyStyles } from '../../../utils';
import createCache from '@emotion/cache';

interface Portalprops {
  onClose?: () => void;
  url?: string;
}

const Portal: FC<Portalprops> = (props) => {
  const elRef = useRef<HTMLElement | null>(null);
  if (!elRef.current) elRef.current = document.createElement('div');
  const stylesInsertionPoint = document.createComment('');
  const cache = createCache({ key: 'external', container: elRef.current });

  useEffect(() => {
    const el = elRef.current!; // non-null assertion because it will never be null
    const currentWindow = window.open(
      props.url ? props.url : 'about:blank',
      '',
      'width=600,height=400,left=200,top=200',
    )!;
    el.style.display = 'flex';
    el.style.alignItems = 'center';
    el.style.height = '100%';
    currentWindow.document.body.appendChild(el);
    currentWindow.document.body.appendChild(stylesInsertionPoint);
    currentWindow.addEventListener('beforeunload', () => {
      props.onClose ? props.onClose() : null;
    });
    copyStyles(document, currentWindow.document);

    return () => {
      currentWindow.close();
    };
  }, []);

  return ReactDOM.createPortal(
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CacheProvider value={cache}>{props.children}</CacheProvider>
    </LocalizationProvider>,
    elRef.current,
  );
};

export default Portal;
