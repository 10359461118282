import { FC, useContext, useEffect, useState } from 'react';
import { MenuWrapper } from '../../../components/core';
import { AppContext } from '../../../hooks/context';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdminApi } from '../../../api';
import { useQuery } from 'react-query';
import { EditUserGroupsDialog } from '../../../components/admin/user-groups/edit-user-group-dialog';

export const UserGroups: FC = (props) => {
  const context = useContext(AppContext);

  const [rows, setRows] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState();

  useEffect(() => {
    setDocumentTitle('Benutzergruppen');
    return () => loadTitle();
  }, []);

  const { isLoading, error, data } = useQuery({
    queryKey: ['getUserGroups'],
    queryFn: () => AdminApi.getUserGroups(context.authToken),
    enabled: context.authToken !== undefined && context.isAdmin,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (data && data.data && data.data.groups) {
      setRows(data.data.groups);
      setTotalRowCount(data.data.groups.length);
    }
  }, [data, error]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: context.i18n.name,
      editable: false,
      flex: 1,
    },
    {
      field: 'groupLeaders',
      headerName: context.i18n.groupLeader,
      editable: false,
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column" overflow="auto" maxHeight="100px">
          {params.value.map((v: any) => (
            <Box key={v.id}>{`${v.email}`}</Box>
          ))}
        </Stack>
      ),
    },
    {
      field: 'members',
      headerName: context.i18n.groupMembers,
      editable: false,
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column" overflow="auto" maxHeight="100px">
          {params.value.map((v: any) => (
            <Box key={v.id}>{`${v.email}`}</Box>
          ))}
        </Stack>
      ),
    },
    {
      field: context.i18n.actions,
      headerName: '',
      flex: 0.3,
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <Tooltip title={context.i18n.addGroup}>
          <IconButton
            disableRipple
            color="primary"
            id="add-group-button"
            data-id="add-group-button"
            onClick={() => setOpenEditDialog(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Box>
          <Tooltip title={context.i18n.editGroup}>
            <IconButton
              disableRipple
              color="primary"
              sx={{ height: 39 }}
              onClick={() => {
                setSelectedGroupId(params.row.id);
                setOpenEditDialog(true);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <MenuWrapper
      title={context.i18n.userGroups}
      selected="admin-groups"
      addClient
      search
      admin
    >
      <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
        <DataGridPro
          sx={{
            m: 5,
          }}
          columns={columns}
          rows={rows}
          loading={isLoading}
          getRowId={(row) => row.id}
          rowCount={totalRowCount}
          autoHeight
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
        />
      </Box>
      <EditUserGroupsDialog
        open={openEditDialog}
        onClose={() => {
          setSelectedGroupId(undefined);
          setOpenEditDialog(false);
        }}
        groupId={selectedGroupId}
      />
    </MenuWrapper>
  );
};
