import { axiosInstance } from '../../utils';

export default class ReportApi {
  static listReports(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('report/list', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static listReportTemplates(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/report/list', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static listReportFields(
    token: string | undefined,
    templateId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`/report/list/${templateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateReport(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('report', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getReport(
    token: string | undefined,
    reportId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`/report/${reportId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static export(
    token: string | undefined,
    reportId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .post(
        `report/export`,
        {
          reportId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        },
      )
      .then((res) => {
        return res;
      });
  }
}
