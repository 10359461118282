import { FrontendFormImportField } from '@casecare/types';
import { FC, useState, useEffect } from 'react';
import { FormCategoryData } from '../../../types';
import { FormCategory } from '../../client';

interface FormPreviewProps {
  elements: FrontendFormImportField[];
}

const FormPreview: FC<FormPreviewProps> = (props) => {
  const [formData, setFormData] = useState<FormCategoryData[]>();

  useEffect(() => {
    setFormData(
      props.elements.map((element) => {
        return {
          id: element.id ? element.id : '',
          label: element.label ? element.label : '',
          seq: element.seq ? element.seq : 1,
          type: element.type ? element.type : 0,
          children: element.children,
          fieldOptions: element.fieldOptions ? element.fieldOptions : [],
          preview: true,
        };
      }),
    );
  }, [props.elements]);

  return <FormCategory data={formData} />;
};

export default FormPreview;
