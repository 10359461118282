import { Box } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { FormElementProps, FormGroupElement } from '../../../../../types';
import FormElementHeader from '../form-element-header/form-element-header';
import FormListScroll from './form-list-scroll';
import FormListSlides from './form-list-slides';
import { AppContext } from '../../../../../hooks/context';

export interface FormListProps extends FormElementProps {
  values: FormListElement[];
  template?: FormListElement;
  multyData?: boolean;
  addedDataset?: boolean;
  onAdd?: () => void;
}

export interface FormListElement {
  id: number;
  children: FormGroupElement[];
  label?: string;
  dataset?: number;
  title?: string;
  subTitle?: string;
}

const FormList: FC<FormListProps> = (props) => {
  const [scrollView, setScrollView] = useState(false);
  const context = useContext(AppContext);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  return (
    <Box sx={{ overflow: 'hidden ' }}>
      {props.title && (
        <FormElementHeader
          formList
          multiDataFormList={props.multyData}
          title={props.title}
          onList={() => setScrollView(!scrollView)}
          onAdd={readOnly ? () => null : props.onAdd}
          required={props.required}
          add
        />
      )}
      {scrollView ? (
        <FormListScroll {...props} values={props.values ? props.values : []} />
      ) : (
        <FormListSlides {...props} values={props.values ? props.values : []} />
      )}
    </Box>
  );
};

export default FormList;
