export enum StorageItemFolderType {
  MEMBER = 'MEMBER',
  MEMBER_PRIVATE = 'MEMBER_PRIVATE',
  CLIENT_FAMILY = 'CLIENT_FAMILY',
  ADMIN = 'ADMIN',
  ADMIN_MEMBERS = 'ADMIN_MEMBERS',
  CLIENT_FAMILY_PROTOCOLS = 'CLIENT_FAMILY_PROTOCOLS',
  CLIENT_FAMILY_REPORTS = 'CLIENT_FAMILY_REPORTS',
  CLIENT_FAMILY_UPLOADS = 'CLIENT_FAMILY_UPLOADS',
  CUSTOM = 'CUSTOM',
}
