import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FC,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AppContext } from '../../../hooks/context';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BillingContactsAutoComplete } from '../../billing';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { AdminBillingApi, ClientApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { useQuery } from 'react-query';

interface CreateInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  //protocolUsers: any[];
  //releaseProtocolBody: any;
  clientBilling?: boolean;
  availableOrderers: any[];
  selectedMonth?: moment.Moment;
}

export const CreateInvoiceDialog: FC<CreateInvoiceDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [contact, setContact] = useState<any>(undefined);
  const [contactError, setContactError] = useState(false);
  const [clientBilling, setClientBilling] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(moment());
  const [invoiceDateError, setInvoiceDateError] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(moment());
  const [deliveryDateError, setDeliveryDateError] = useState(false);
  const [deliveryDateUntil, setDeliveryDateUntil] = useState<Moment | null>(
    null,
  );
  const [timeToPay, setTimeToPay] = useState<number | null>(null);
  const [timeToPayDate, setTimeToPayDate] = useState<Moment | null>(null);
  const [userProducts, setUserProducts] = useState<Record<string, any[]>>({});

  const [protocolUsers, setProtocolUsers] = useState<any[]>([]);
  const [userHours, setUserHours] = useState<Record<string, number[]>>({});
  const [maxUserHours, setMaxUserHours] = useState<Record<string, number[]>>(
    {},
  );

  const [netHourlyRate, setNetHourlyRate] = useState(92.11);

  const [isDateRange, setIsDateRange] = useState(false);

  const [protocols, setProtocols] = useState([]);

  const [totalHours, setTotalHours] = useState(0);
  const [taxRate, setTaxRate] = useState(20);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.selectedMonth) {
      setIsDateRange(true);
      setDeliveryDate(moment(props.selectedMonth).startOf('month'));
      setDeliveryDateUntil(moment(props.selectedMonth).endOf('month'));
      setInvoiceDate(moment(props.selectedMonth).endOf('month'));
    }
  }, [props.selectedMonth]);

  useEffect(() => {
    if (!props.open) {
      setContact(undefined);
      setContactError(false);
      setClientBilling(props.clientBilling || false);
      setInvoiceDate(moment());
      setInvoiceDateError(false);
      // setDeliveryDate(moment());
      setDeliveryDateError(false);
      // setDeliveryDateUntil(null);
      setTimeToPay(null);
      setTimeToPayDate(null);
      setUserHours({});
      setProtocols([]);
      setTotalHours(0);
      setTaxRate(20);
      setNetHourlyRate(92.11);
    }
    // initializeUserHours();
  }, [props.open]);

  // const initializeUserHours = () => {
  //   props.protocolUsers.map((u) => {
  //     setUserHours((uh) => {
  //       return {
  //         ...uh,
  //         [u.id]: [u.hours],
  //       };
  //     });
  //     setMaxUserHours((muh) => {
  //       return {
  //         ...muh,
  //         [u.id]: [u.hours],
  //       };
  //     });
  //   });

  //   setProtocolUsers(props.protocolUsers);
  // };

  const { isLoading } = useQuery({
    queryKey: [
      'ordererInvoiceProtocols',
      contact,
      deliveryDate,
      deliveryDateUntil,
    ],
    queryFn: () =>
      ClientApi.getOrdererInvoiceProtocols(
        context.authToken,
        Object.assign(
          {
            ordererId: contact.id,
            startDate: deliveryDate.toISOString(),
          },
          deliveryDateUntil && { endDate: deliveryDateUntil.toISOString() },
        ),
      ),
    enabled: context.authToken !== undefined && contact !== undefined,
    onSuccess: (data) => {
      setProtocols(data.data.protocols);
      setTotalHours(data.data.totalHours);
    },
  });

  const onSave = async () => {
    setContactError(contact === undefined && !clientBilling);
    setInvoiceDateError(invoiceDate === null);
    setDeliveryDateError(deliveryDate === null);

    if (
      (contact === undefined && !clientBilling) ||
      invoiceDate === null ||
      deliveryDate === null // ||
      //Object.entries(userProducts).length !== props.protocolUsers.length
    ) {
      snackbarUtils.error(context.i18n.checkFields);
      return;
    }

    try {
      setLoading(true);
      const res = await AdminBillingApi.createSevDeskInvoice(
        context.authToken,
        {
          //...props.releaseProtocolBody,
          deliveryDate,
          invoiceDate,
          deliveryDateUntil,
          contactId: contact.sevDeskContactId,
          // useClientData: clientBilling,
          // userProducts,
          // userHours,
          timeToPay,
          // clientBilling,
          netHourlyRate,
          invoicePositions: protocols,
        },
      );

      if (res.message) {
        snackbarUtils.success(res.message);
        if (res.data.invoiceId) {
          const url = `https://my.sevdesk.de/fi/edit/type/RE/id/${res.data.invoiceId}`;

          const w = window.open(url, '_blank');
          if (w) w.focus();
        }
        props.onClose();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const onHoursChange = (
    userId: string,
    index: number,
    hours: number,
    maxHours: number,
  ) => {
    setUserHours((uh) => {
      uh[userId][index] = hours;
      if (maxHours - hours === 0 && uh[userId][index + 1]) {
        uh[userId] = uh[userId].splice(index + 1);
        uh[userId][index] = hours;
      } else if (uh[userId][index + 1] !== undefined) {
        uh[userId][index + 1] = maxHours - hours;
      } else {
        uh[userId].push(maxHours - hours);
      }
      return { ...uh };
    });

    setMaxUserHours((muh) => {
      muh[userId][index + 1] = maxHours - hours;

      return { ...muh };
    });
  };

  const onProductChange = (userId: string, index: number, product: any) => {
    if (product.id) {
      setUserProducts((up) => {
        if (!up[userId]) {
          up[userId] = [];
        }
        if (up[userId][index]) {
          up[userId][index] = product;
        } else {
          up[userId].push(product);
        }

        return {
          ...up,
        };
      });
    } else {
      setUserProducts((up) => {
        delete up[userId][index];
        return { ...up };
      });
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
      <DialogTitle>{context.i18n.createInvoice}</DialogTitle>
      <DialogContent>
        <Grid p={2} container spacing={2}>
          <Grid item xs={6}>
            <BillingContactsAutoComplete
              required
              error={contactError}
              disabled={clientBilling}
              title={context.i18n.name}
              onSelect={(contact: any) => {
                setContact(contact);
                setContactError(false);
              }}
              availableOrderers={
                props.availableOrderers
                  ? props.availableOrderers.filter(
                      (o) => o.sevDeskContactId !== undefined,
                    )
                  : []
              }
            />
          </Grid>
          {/* <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={clientBilling}
                  onClick={() => {
                    setClientBilling(!clientBilling);
                    setContactError(false);
                  }}
                />
              }
              label={context.i18n.useClientData}
            />
          </Grid> */}
          <Grid item xs={12}>
            <DatePicker
              format="DD.MM.YYYY"
              label={context.i18n.invoiceDate}
              views={['day']}
              onChange={(v: any) => {
                setInvoiceDate(v);
                setInvoiceDateError(false);
              }}
              slotProps={{
                textField: {
                  error: invoiceDateError,
                  required: true,
                  fullWidth: true,
                },
              }}
              value={invoiceDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant={isDateRange ? 'text' : 'contained'}
              onClick={() => setIsDateRange(false)}
            >
              {context.i18n.deliveryTime}
            </Button>
            <Button
              variant={!isDateRange ? 'text' : 'contained'}
              onClick={() => setIsDateRange(true)}
            >
              {context.i18n.performancePeriod}
            </Button>
          </Grid>
          <Grid item xs={isDateRange ? 6 : 12}>
            <DatePicker
              format="DD.MM.YYYY"
              views={['day']}
              label={
                isDateRange ? context.i18n.startDate : context.i18n.deliveryDate
              }
              onChange={(v: any) => {
                setDeliveryDate(v);
                setDeliveryDateError(false);
              }}
              slotProps={{
                textField: {
                  error: deliveryDateError,
                  required: true,
                  fullWidth: true,
                },
              }}
              value={deliveryDate}
            />
          </Grid>
          {isDateRange && (
            <Grid item xs={6}>
              <DatePicker
                format="DD.MM.YYYY"
                views={['day']}
                label={context.i18n.deliveryDateUntil}
                minDate={deliveryDate}
                onChange={(v: any) => setDeliveryDateUntil(v)}
                slotProps={{ textField: { fullWidth: true } }}
                value={deliveryDateUntil}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              label={context.i18n.timeToPay}
              type={'number'}
              fullWidth
              value={timeToPay}
              onChange={(e: any) => {
                setTimeToPay(e.currentTarget.value);
                setTimeToPayDate(moment().add(e.currentTarget.value, 'day'));
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                min: 0,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              format="DD.MM.YYYY"
              label={context.i18n.timeToPayDate}
              views={['day']}
              minDate={moment()}
              onChange={(v: any) => {
                setTimeToPayDate(v);
                setTimeToPay(v.diff(moment(), 'days') + 1);
              }}
              slotProps={{ textField: { fullWidth: true } }}
              value={timeToPayDate}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={context.i18n.netHourlyRate}
              type={'number'}
              fullWidth
              value={netHourlyRate}
              onChange={(e: any) => {
                setNetHourlyRate(e.currentTarget.value);
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                min: 0,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={context.i18n.taxRate}
              type={'number'}
              fullWidth
              value={taxRate}
              onChange={(e: any) => {
                setTaxRate(e.currentTarget.value);
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                min: 0,
              }}
            />
          </Grid>
          {!isLoading && protocols && (
            <Grid item xs={12}>
              <Divider />
              <Box
                sx={{ mt: 1, mb: 1, textAlign: 'left' }}
                className="invoice-table"
              >
                <table>
                  <thead>
                    <tr>
                      <th className="first">
                        <Typography>Klient</Typography>
                      </th>
                      <th>
                        <Typography>Aktenzahl</Typography>
                      </th>
                      <th>
                        <Typography>Genehmigte Stunden</Typography>
                      </th>
                      <th className="last">
                        <Typography>Verrechnete Stunden</Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {protocols.map((p: any) => (
                      <tr key={p.id}>
                        <td className="first">
                          <Typography>{p.name}</Typography>
                        </td>
                        <td>
                          <Typography>{p.clientNumber}</Typography>
                        </td>
                        <td>
                          <Typography>{p.monthlyHours}</Typography>
                        </td>
                        <td className="last">
                          <Typography>
                            {p.hours.toString().replace('.', ',')}{' '}
                            {context.i18n.hours}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
              <Divider />
              <Typography sx={{ fontSize: '1.2em', mt: 1 }}>
                <b>
                  {context.i18n.total}:{' '}
                  {totalHours.toString().replace('.', ',')} {context.i18n.hours}{' '}
                  = {(totalHours * +netHourlyRate).toFixed(2)} €
                </b>
              </Typography>
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <Divider />
            {protocolUsers.map((u) => (
              <TableContainer key={u.id}>
                <Table>
                  {userHours[u.id] &&
                    userHours[u.id].map((hours, index) => (
                      <TableRow sx={{ width: '100%' }} key={index}>
                        <UserProductTable
                          index={index}
                          hours={hours}
                          maxHours={maxUserHours[u.id][index] || hours}
                          user={u}
                          product={
                            userProducts[u.id]
                              ? userProducts[u.id][index]
                              : null
                          }
                          onHoursChange={onHoursChange}
                          onProductChange={onProductChange}
                        />
                      </TableRow>
                    ))}
                </Table>
              </TableContainer>
            ))}
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton
            onClick={onSave}
            disableRipple
            color="primary"
            disabled={loading}
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
