import { faX, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField, InputAdornment } from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';
import { theme } from '../../../utils';

interface ICD10FilterToolbarProps {
  searchText: string;
  setSerachText: (searchText: string) => void;
}

const ICD10FilterToolbar: FC<ICD10FilterToolbarProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Box
      display="flex"
      sx={{
        p: 1,
        mt: 1,
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <TextField
        color="secondary"
        placeholder={context.i18n.codeSearch}
        value={props.searchText}
        onChange={(e) => props.setSerachText(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{ color: theme.palette.secondary.main }}
              position="end"
            >
              <FontAwesomeIcon
                onClick={() => props.setSerachText('')}
                icon={faX}
              />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment
              sx={{ color: theme.palette.secondary.main }}
              position="start"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ICD10FilterToolbar;
