import { Grid, Paper } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { NotesApi } from '../../../../api';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { FormFieldTextType, NoteType } from '@casecare/types';
import { FormText } from '../..';
import { TextSize } from '../../../../types/form-text-props';

interface GeneralNotesProps {
  saved: boolean;
}

const GeneralNotes: FC<GeneralNotesProps> = (props) => {
  const context = useContext(AppContext);
  const [note, setNote] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  useQuery(
    ['getGeneralNotes'],
    () =>
      NotesApi.getNote(
        context.authToken,
        NoteType.CLIENT_DATA,
        context.clientId,
        context.clientId,
      ),
    {
      enabled:
        context.clientId !== undefined && context.authToken !== undefined,
      onSuccess: async (res) => {
        setIsLoading(true);
        setNote(res.data);
        setIsLoading(false);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const onSave = async (value: string) => {
    const res = await NotesApi.updateNote(
      context.authToken,
      NoteType.CLIENT_DATA,
      context.clientId,
      value,
      context.clientId,
    );

    if (res.statusCode !== 201) {
      snackbarUtils.error(context.i18n.avatarNoteErrorToast);
    } else if (res.statusCode === 201 && !props.saved) {
      context.setUnsavedChanges(false);
      snackbarUtils.success(res.message);
    }
  };

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          {!isLoading && (
            <FormText
              value={note}
              title={context.i18n.notes}
              dataType={FormFieldTextType.TEXT}
              id={'clientDataNote'}
              size={TextSize.LARGE}
              hideExport
              saved={props.saved}
              onChange={(value) => {
                setNote(value);
                context.setUnsavedChanges(true);
              }}
              onCancel={() => context.setUnsavedChanges(false)}
              onSave={onSave}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GeneralNotes;
