import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { queryClient, theme } from '../../../utils';
import { AccordionStyle } from '../../../utils/styling/styling';
import { AppContext } from '../../../hooks/context';
import { ClientDataType } from '@casecare/types';
import { faCaretDown, faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { c } from 'msw/lib/SetupApi-b2f0e5ac';
import { GenoGrammPregnancyData } from '../geno-gramm-pregnancy-data/geno-gramm-pregnancy-data';
import { GenoGrammApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface GenoGrammPregnanciesProps {
  pregnancies: any[];
  clientList: any[];
  indexId: string | undefined;
}

export const GenoGrammPregnancies: FC<GenoGrammPregnanciesProps> = (props) => {
  const context = useContext(AppContext);

  const [pregnancies, setPregnancies] = useState(props.pregnancies);

  useEffect(() => {
    setPregnancies(props.pregnancies);
  }, [props.pregnancies]);

  const onSavePregnancies = async (id: string) => {
    try {
      const groupId = 'Geno-Gramm-Pregnancy-Data-' + id;

      const groupErrors = context.getGroupDataError(groupId),
        error = groupErrors.length > 0;

      if (!error) {
        const groupData = context.getGroupData(groupId);

        const sendData: Record<string, any> = {};
        sendData.pregnancyId = id;

        for (const data of groupData) {
          if (data.value && data.value !== '') {
            if (data.id === 'mother-select') {
              sendData.motherId = data.value;
            } else if (data.id === 'father-select') {
              sendData.fatherId = data.value;
            } else if (data.id === 'pregnancy-select') {
              sendData.state = data.value;
            }
          }
        }

        const res = await GenoGrammApi.savePregnancy(
          context.authToken,
          sendData,
          props.indexId,
        );

        if (res.message) snackbarUtils.success(res.message);

        context.removeGroupData(groupId);

        queryClient.invalidateQueries(['geno-gramm-data']);
      } else snackbarUtils.error(context.i18n.redFieldsNeedToBeFilledOut);
    } catch (e) {
      console.error(e);
    }
  };

  const onDeletePregnancy = async (id: string) => {
    try {
      const res = await GenoGrammApi.deletePregnancy(context.authToken, id);

      if (res.message) snackbarUtils.success(res.message);

      queryClient.invalidateQueries(['geno-gramm-data']);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {pregnancies.map((p) => (
        <Accordion
          onClick={() => {
            return;
          }}
          square
          disableGutters
          elevation={0}
          key={p.id}
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            ...AccordionStyle(undefined, theme.palette.primary.main),
          }}
        >
          <AccordionSummary
            expandIcon={
              <FontAwesomeIcon
                size="2x"
                color={theme.palette.primary.main}
                icon={faCaretDown}
              />
            }
          >
            <Typography sx={{ ml: 2 }}>
              {`${p.name} (${context.i18n[p.pregnancyState]})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: 'unset !important' }}>
            <Paper sx={{ p: 2 }}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <GenoGrammPregnancyData
                    pregnancy={p}
                    clientList={props.clientList}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    sx={{ p: 2, width: '100%' }}
                    color="inherit"
                    onClick={() => onDeletePregnancy(p.id)}
                    endIcon={
                      <FontAwesomeIcon
                        color={theme.palette.error.main}
                        icon={faX}
                      />
                    }
                  >
                    {context.i18n.removePerson}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    sx={{ p: 2, width: '100%' }}
                    color="inherit"
                    size="large"
                    endIcon={
                      <FontAwesomeIcon
                        color={theme.palette.success.main}
                        icon={faCheck}
                      />
                    }
                    onClick={() => onSavePregnancies(p.id)}
                  >
                    {context.i18n.save}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
