/* eslint @typescript-eslint/no-var-requires: "off" */
import { ImportCategorie } from '../../types';

interface ImportCategorieData {
  id: string;
  title: string;
  categoryUrl: string;
}

export async function getImportCategories(
  importCategoryData: ImportCategorieData[],
): Promise<any> {
  const importCategories: ImportCategorie[] = [];

  await Promise.all(
    importCategoryData.map(async (categoryData) => {
      importCategories.push(
        await fetch(categoryData.categoryUrl).then(function (response) {
          return response.json();
        }),
      );
    }),
  );

  return importCategories;
}
