import axios from 'axios';
import { authCookieName, isLocalhost } from '../constants';
import { deleteCookie } from '../cookie/cookie-utils';
import { i18n } from '../i18n/i18n';
import snackbarUtils from '../snackbar/snackbar-utils';

export const backendUrl = isLocalhost
  ? `http://${window.location.hostname}:3000/`
  : 'CC_ENV_BACKEND_URL';

export const axiosInstance = axios.create({
  baseURL: backendUrl,
  headers: {
    'Accept-Language': navigator.language.slice(0, 2),
    'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export const testInstance = axios.create({
  baseURL: 'http://localhost:3004/',
  headers: { 'Accept-Language': navigator.language.slice(0, 2) },
});

const useFrontendError: any[] = ['EMAIL_NOT_VERIFIED'];

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (
      err.response?.status === 401 &&
      err.response?.data.status === 'auth-expired'
    ) {
      deleteCookie(authCookieName);
      window.location.href = '/login/expired';
    } else if (err.response?.data?.detail) {
      const detail = err.response?.data?.detail;
      const clientAction = err.response?.data?.clientAction;
      let message = '';
      if (Array.isArray(detail)) {
        for (let index = 0; index < detail.length; index++) {
          if (
            !Array.isArray(clientAction) ||
            !useFrontendError.includes(clientAction[index])
          )
            message += detail[index] + '\n';
        }
      } else message = detail;

      snackbarUtils.error(message);
    } else {
      snackbarUtils.error(i18n.errorTryAgainLater);
    }

    return Promise.reject(err);
  },
);
