import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';

interface RiskAssessmentInformationDialogProps {
  open: boolean;
  onClose: () => void;
}

const RiskAssessmentInformationDialog: FC<
  RiskAssessmentInformationDialogProps
> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={open}>
      <DialogTitle>{context.i18n.riskAssessmentInfo}</DialogTitle>
      <DialogContent>
        <Typography>
          {context.i18n.riskAssessmentInfoMessage1}
          <br />
          <br />
          {context.i18n.riskAssessmentInfoMessage2}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.close}>
          <IconButton
            color="primary"
            disableRipple
            onClick={() => props.onClose()}
          >
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default RiskAssessmentInformationDialog;
