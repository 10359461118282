import { FormFieldTextType, FormFieldType } from '@casecare/types';
import moment from 'moment';
import { FormCategoryData } from '../../types/form-category-data';
import { FormGroupElement } from '../../types/form-group-element';

function splitArray(array: FormCategoryData[], n: number) {
  const length = array.length,
    splitArray = [];
  let i = 0,
    size;

  while (i < length) {
    size = Math.ceil((length - i) / n--);
    splitArray.push(array.slice(i, (i += size)));
  }

  return splitArray;
}

function getTextType(type: FormFieldTextType) {
  switch (type) {
    case 0:
      return 'text';
    case 1:
      return 'number';
    case 2:
      return 'email';
    case 3:
      return 'tel';
  }
}

function getValueString(element: FormGroupElement) {
  if (element.value) {
    if (
      element.type === FormFieldType.TEXT ||
      element.type === FormFieldType.TEXT_MEDIUM ||
      element.type === FormFieldType.TEXT_LARGE
    ) {
      return element.value;
    }
    if (element.type === FormFieldType.DATETIME) {
      return moment(element.value).format('DD.MM.YYYY | HH:mm');
    }
    if (element.type === FormFieldType.DATE) {
      return moment(element.value).format('DD.MM.YYYY');
    }
    if (element.type === FormFieldType.DATE_NO_DAY) {
      return moment(element.value).format('MM.YYYY');
    }
    if (
      element.type === FormFieldType.RADIO ||
      element.type === FormFieldType.DROPDOWN
    ) {
      return element.fieldOptions
        ? element.fieldOptions.filter((option) => {
            return element.value === 0
              ? option.key === +element.value
              : option.key === element.value;
          })[0].label
        : '';
    }
    if (element.type === FormFieldType.CHECKBOX) {
      const selectedValues: string[] = [];
      element.value.forEach((key: number) => {
        selectedValues.push(
          element.fieldOptions ? element.fieldOptions[key].label : '',
        );
      });
      return selectedValues.join(',');
    }
  }
}

export { splitArray, getTextType, getValueString };
