import { Box, CircularProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface TabPanelProps {
  index: string | undefined;
  value: string | undefined;
  defaultSubTab?: () => void;
}
const TabPanel: FC<TabPanelProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    props.value === props.index && props.defaultSubTab && props.defaultSubTab();
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, [props.value]);

  return (
    <Box
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`${props.index}`}
      aria-labelledby={`${props.index}`}
    >
      {isLoading ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        props.value === props.index && <Box>{props.children}</Box>
      )}
    </Box>
  );
};

export default TabPanel;
