import { Box, Button, Grow, TextField } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import 'moment/locale/de';
import { ProtocolApi } from '../../../../api';
import { useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import FormElement from '../../forms/form-elements/form-element/form-element';
import { FormFieldType } from '@casecare/types';
import { FooterPropsOverrides } from '@mui/x-data-grid-pro';
import { useSavePrompt } from '../../../../hooks/useBlocker';

declare module '@mui/x-data-grid-pro' {
  interface FooterPropsOverrides {
    endDate: moment.Moment;
    clientId?: string;
    familyId?: string;
  }
}

const ProtocolTableFooter: FC<FooterPropsOverrides> = (props) => {
  const context = useContext(AppContext);
  const { endDate, clientId, familyId } = props;

  //const initialSummary = `${context.i18n.workRelationProtocol}\n\t-\n${context.i18n.themeProtocol}\n\t-\n${context.i18n.developmentsProtocol}\n\t-\n${context.i18n.nextStepsProtocol}\n\t-\n${context.i18n.agreementsProtocol}\n\t-\n${context.i18n.remarksProtocol}\n\t-\n`;
  const initialSummary = `<b>${context.i18n.workRelationProtocol}</b><br/><ul><li></li></ul><b>${context.i18n.themeProtocol}</b><br/><ul><li></li></ul><b>${context.i18n.developmentsProtocol}</b><br/><ul><li></li></ul><b>${context.i18n.nextStepsProtocol}</b><br/><ul><li></li></ul><b>${context.i18n.agreementsProtocol}</b><br/><ul><li></li></ul><b>${context.i18n.remarksProtocol}</b><br/><ul><li></li></ul>`;
  const [protocolSummary, setProtocolSummary] = useState('');
  const [originalSummary, setOriginalSummary] = useState('');
  const [showingLastMonth, setShowingLastMonth] = useState(false);

  const queryClient = useQueryClient();

  useSavePrompt(
    () => onSaveSummary(),
    undefined,
    protocolSummary !== originalSummary,
  );

  useQuery(
    ['protocolSummary', endDate],
    () =>
      props.clientId
        ? ProtocolApi.getProtocolSummary(
            context.authToken,
            Object.assign(
              {
                date: endDate.toISOString(),
              },
              clientId && { clientId },
              familyId && { familyId },
            ),
          )
        : ProtocolApi.getProtocolSummary(context.authToken, {
            date: endDate.toISOString(),
            familyId: props.familyId,
          }),
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        if (res.data !== '' && res.data !== undefined) {
          let summary = res.data.summary;
          if (!summary) {
            if (res.data.lastMonthSummary) {
              summary = res.data.lastMonthSummary;
              setShowingLastMonth(true);
            } else {
              summary = initialSummary;
              setShowingLastMonth(false);
            }
          } else {
            setShowingLastMonth(false);
          }
          summary = summary.replace(/\n/g, '<br />');
          setProtocolSummary(summary);
          setOriginalSummary(summary);
        } else {
          setProtocolSummary(initialSummary);
          setShowingLastMonth(false);
        }
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const onSaveSummary = async () => {
    try {
      const body = Object.assign(
        {
          date: props.endDate.toISOString(),
          summary: protocolSummary,
        },
        clientId && {
          clientId: clientId,
        },
        familyId && {
          familyId: familyId,
        },
      );

      const res = await ProtocolApi.editProtocolSummary(
        context.authToken,
        body,
      );

      if (res.message) {
        snackbarUtils.success(res.message);
        queryClient.invalidateQueries('protocolSummary');
      }
    } catch (e) {
      console.error(e);
      // snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <>
      <Box>
        <FormElement
          type={FormFieldType.WYSIWYG}
          value={protocolSummary}
          onSave={onSaveSummary}
          onChange={(v) => setProtocolSummary(v)}
          title={`${context.i18n.protocolSummary} - ${endDate.format(
            'MMMM YYYY',
          )}`}
          disableGlobalOnChange
          noDisabledStyle
          id="protocolSummary"
          error={showingLastMonth}
          helperText={
            showingLastMonth ? context.i18n.showingLastMonthSummary : ''
          }
        />
      </Box>
    </>
  );
};

export default ProtocolTableFooter;
