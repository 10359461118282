import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { context } from 'msw';
import { FC, useContext, useState } from 'react';
import { MenuWrapper } from '../../../components/core';
import { AppContext } from '../../../hooks/context';
import {
  ContactAccordion,
  ContactTable,
} from '../../../components/admin/billing';
import { theme } from '../../../utils';
import { useQuery } from 'react-query';
import { AdminBillingApi } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { EditOrganizationContactDialog } from '../../../components/dialogs';

export const Contacts: FC = () => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [organizations, setOrganisations] = useState([]);

  useQuery(
    'sevDeskOrganizations',
    () => AdminBillingApi.getSevDeskOrganizationContacts(context.authToken),
    {
      onSuccess: (res) => {
        setOrganisations(res.data);
      },
      enabled: context.authToken !== undefined && context.sevDeskActive,
    },
  );

  return (
    <MenuWrapper
      admin
      search
      addClient
      title={context.i18n.billingDetails}
      selected="admin-billing-contracts"
      adminBilling
    >
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
      >
        <Box m={2}>
          <Typography
            color={theme.palette.primary.main}
            align="left"
            variant="h5"
            justifyContent="space-between"
            display="flex"
            m={2}
          >
            {context.i18n.contacts}
            <IconButton onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Typography>
          {organizations.map((o: any) => (
            <ContactAccordion key={o.id} id={o.id} name={o.name} />
          ))}
        </Box>
      </Box>
      <EditOrganizationContactDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </MenuWrapper>
  );
};
