import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext } from 'react';
import { ICD10Code } from '../../../types';
import { context } from 'msw';
import { AppContext } from '../../../hooks/context';
import { FormElementHeader } from '../../client';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ICD10DetailsDialogProps {
  open: boolean;
  onClose: () => void;
  code: ICD10Code;
}

const ICD10DetailsDialog: FC<ICD10DetailsDialogProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Dialog fullWidth open={props.open} onClose={props.onClose}>
      <DialogTitle>{props.code.code}</DialogTitle>
      <DialogContent>
        <FormElementHeader title={context.i18n.definition} />
        <TextField
          color="secondary"
          fullWidth
          multiline
          rows={5}
          type={'text'}
          disabled
          value={props.code.definition}
        />
        <Grid container mt={2}>
          {props.code.exclutions.length > 0 && (
            <Grid item xs={6}>
              <FormElementHeader title={context.i18n.exclusions} />
              <List sx={{ overflow: 'auto', maxHeight: 200 }}>
                {props.code.exclutions.map((e, index) => (
                  <ListItem key={index}>
                    <ListItemText>{e}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
          {props.code.inclusions.length > 0 && (
            <Grid item xs={6}>
              <FormElementHeader title={context.i18n.inclusions} />
              <List sx={{ overflow: 'auto', maxHeight: 200 }}>
                {props.code.inclusions.map((i, index) => (
                  <ListItem key={index}>
                    <ListItemText>{i}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.close}>
          <IconButton disableRipple onClick={props.onClose}>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ICD10DetailsDialog;
