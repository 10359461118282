import { FormFieldType } from '@casecare/types';
import { i18n } from '..';

const formTypeTranslation = (type: FormFieldType): any => {
  switch (type) {
    case FormFieldType.TEXT:
      return i18n.formElementTypeText;
    case FormFieldType.TEXT_MEDIUM:
      return i18n.formElementTypeTextMiddle;
    case FormFieldType.TEXT_LARGE:
      return i18n.formElementTypeTextLarge;
    case FormFieldType.RADIO:
      return i18n.formElementTypeRadio;
    case FormFieldType.DATE:
      return i18n.formElementTypeDate;
    case FormFieldType.DATETIME:
      return i18n.formElementTypeDateTime;
    case FormFieldType.DATE_NO_DAY:
      return i18n.formElementTypeDateNoDay;
    case FormFieldType.DROPDOWN:
      return i18n.formElementTypeDropdown;
    case FormFieldType.CHECKBOX:
      return i18n.formElementTypeCheckbox;
    case FormFieldType.FORM_GROUP:
      return i18n.formElementTypeGroup;
    case FormFieldType.LIST:
      return i18n.formElementTypeList;
    case FormFieldType.LIST_MULTI_DATA:
      return i18n.formElementTypeListMultiData;
  }
};

export default formTypeTranslation;
