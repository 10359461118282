import { faFloppyDisk, faX, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';

interface FormEditProps {
  disabled?: boolean;
  onEdit: (disabled: boolean) => void;
  onSave?: () => void;
  onCancel?: () => void;
  big?: boolean;
  readOnly?: boolean;
}

const FormEdit: FC<FormEditProps> = (props) => {
  const context = useContext(AppContext);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onCancel) {
      //Prevent parent elements to trigger function
      e.stopPropagation();
      props.onCancel();
    }
  };

  const onSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onSave) {
      //Prevent parent elements to trigger function
      e.stopPropagation();
      props.onSave();
    }
  };

  return !readOnly ? (
    <>
      {!props.disabled ? (
        <Box sx={props.big ? {} : { display: 'flex' }}>
          <IconButton color="primary" onClick={onSave}>
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
          <IconButton color="primary" onClick={onCancel}>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Box>
      ) : (
        <IconButton color="primary" onClick={() => props.onEdit(false)}>
          <FontAwesomeIcon icon={faPen} />
        </IconButton>
      )}
    </>
  ) : (
    <></>
  );
};

export default FormEdit;
