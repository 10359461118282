import { Box, FormHelperText, Autocomplete, TextField } from '@mui/material';
import { FC, useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';
import { FormElementProps, FormOptions } from '../../../../../types';
import FormElementHeader from '../form-element-header/form-element-header';

interface FormAutocompleteProps extends FormElementProps {
  options: any;
  value?: string | string[];
  multiple?: boolean;
  hideHeader?: boolean;
  noNoneOption?: boolean;
  clearOnSelect?: boolean;
}

const FormAutocomplete: FC<FormAutocompleteProps> = (props) => {
  const [value, setValue] = useState<string | string[] | null>(
    props.value !== undefined ? props.value : props.multiple ? [] : null,
  );
  const [save, setSave] = useState(false);
  const context = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  const checkErrors = (value: any) => {
    let currentError = null;
    if (props.required) {
      if (value === undefined || !value) {
        currentError = context.i18n.inputRequiredError;
      } else if (value.length && value.length === 0) {
        currentError = context.i18n.inputRequiredError;
      }
    }
    setError(currentError);
    return currentError;
  };

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.clearOnSelect) {
      if (props.multiple) setValue(Object.assign([]));
      else setValue(null);
    }
  }, [value]);

  useEffect(() => {
    checkErrors(value);
    props.dialog &&
      props.onChange &&
      props.onChange('', checkErrors(value), true);

    return () => {
      props.dialog && props.onChange && props.onChange('', null);
    };
  }, []);

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    props.saved ? setSave(!props.saved) : null;
    context.saveAll ? setSave(!context.saveAll) : null;
    props.saved && props.onSave && props.onSave(value);
  }, [props.saved, context.saveAll]);

  const onSave = () => {
    if (error !== null) return;
    props.onSave ? props.onSave(value) : null;
    setSave(false);
  };

  const onCancel = () => {
    setValue(
      props.value !== undefined ? props.value : props.multiple ? [] : '',
    );
    setSave(false);
    props.onCancel ? props.onCancel() : null;
  };

  const onChange = (key: string | null) => {
    setSave(true);
    setValue(key || null);
    props.onChange && props.onChange(key, checkErrors(key));
  };

  const onChangeMultiple = (value: any) => {
    setSave(true);
    setValue(value);
    props.onChange && props.onChange(value, checkErrors(value));
  };

  return (
    <Box sx={{ m: 2 }}>
      {props.title && (
        <FormElementHeader
          save={
            props.dialog || props.preview || readOnly || props.hideHeader
              ? false
              : save
          }
          onSave={onSave}
          onCancel={onCancel}
          hideExport={props.hideExport}
          title={props.title}
          required={props.required}
        />
      )}
      <Autocomplete
        disableCloseOnSelect={props.disableCloseOnSelect}
        value={value}
        id={props.id}
        fullWidth
        readOnly={readOnly}
        options={props.options}
        multiple={props.multiple}
        isOptionEqualToValue={(o: any, v: any) => {
          if (typeof v === 'string') {
            return o.key === v;
          }
          return o.key === v.key;
        }}
        onChange={(e, v: any) => {
          if (props.multiple) {
            onChangeMultiple(v);
            if (props.clearOnSelect) setValue(Object.assign([]));
          } else {
            onChange(v);
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              error={error !== null}
              {...params}
              required={props.required}
              helperText={error}
              disabled={props.disabled}
            />
          );
        }}
      />

      <FormHelperText error>{error && error}</FormHelperText>
    </Box>
  );
};

export default FormAutocomplete;
