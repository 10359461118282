import { RiskAssessmentAnswer } from '../../types';
import { RiskAssessmentSurveyResult } from '../../types/risk-assesment';
import { axiosInstance } from '../../utils/api/api-utils';

export default class RiskApi {
  static getSurvey(
    token: string | undefined,
    riskId: string | undefined,
    surveyId: string,
  ): Promise<any> {
    return axiosInstance
      .get('/risk/' + riskId + '/' + surveyId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getSurveyRisks(
    token: string | undefined,
    surveyId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('/risk/' + surveyId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getRiskAssessments(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/risk', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createRiskSurvey(
    token: string | undefined,
    clientId: string | undefined,
    riskIds: string[] | undefined,
  ): Promise<any> {
    return axiosInstance
      .put(
        '/risk/' + clientId,
        {
          risks: riskIds,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static updateAnswers(
    token: string | undefined,
    riskId: string | undefined,
    surveyId: string,
    answers: RiskAssessmentAnswer[],
  ): Promise<any> {
    return axiosInstance.post(
      '/risk/' + riskId + '/' + surveyId,
      {
        answers,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  static updateResult(
    token: string | undefined,
    surveyId: string,
    result: RiskAssessmentSurveyResult,
    atRiskSubTypes: (string | undefined)[],
  ): Promise<any> {
    return axiosInstance.post(
      '/risk/' + surveyId,
      {
        result,
        atRiskSubTypes,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  static getAvailableRisks(
    token: string | undefined,
    surveyId: string,
  ): Promise<any> {
    return axiosInstance.get('/risk/available/' + surveyId, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  static addRiskSubtype(
    token: string | undefined,
    riskId: string | undefined,
    surveyId: string,
  ): Promise<any> {
    return axiosInstance.post(
      '/risk/' + riskId + '/' + surveyId + '/add',
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  static deleteRiskAssessment(
    token: string | undefined,
    surveyId: string,
  ): Promise<any> {
    return axiosInstance.delete('/risk/' + surveyId, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}
