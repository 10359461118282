import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement } from '../../client';
import { FormFieldType } from '@casecare/types';
import { EcoMapAddDialogType } from '../../../types/eco-map-add-dialog-type';
import EcoMapApi from '../../../api/eco-map/eco-map';

interface AddEcoMapDialogProps {
  open: boolean;
  clientId: string | undefined;
  onClose: () => void;
}

const AddEcoMapDialog: FC<AddEcoMapDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(props.open);
  const groupId = 'Eco-Map-Add-Dialog';
  const ecoMapFields = [
    {
      id: 'name',
      name: context.i18n.name,
      required: true,
      type: FormFieldType.TEXT,
      value: '',
    },
  ];

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onSave = async () => {
    try {
      let error = '';
      const groupData = context.getAndRemoveGroupData(groupId);
      const sendData: Record<string, any> = {};
      for (const data of groupData) {
        if (data.error !== null) error += data.error + '\n';
        sendData[data.id] = data.value;
      }
      if (error === '') {
        sendData.segments = [
          {
            label: context.i18n.friends,
            seq: 1,
          },
          {
            label: context.i18n.family,
            seq: 2,
          },
          {
            label: context.i18n.sportsClub,
            seq: 3,
          },
          {
            label: context.i18n.work,
            seq: 4,
          },
        ];
        sendData.innerCircleColor = '#D6FDDB';
        sendData.middleCircleColor = '#A9FCB6';
        sendData.outerCircleColor = '#F4FDF5';

        const res = await EcoMapApi.createEcoMap(
          context.authToken,
          context.clientId,
          sendData,
        );

        if (res.message) {
          snackbarUtils.success(res.message);
          onClose();
          const newTab = window.open(
            '/eco-map/details/' + context.clientId + '/' + res.data,
            '_blank',
          ); // Replace '/new-route' with your desired route
          if (newTab) {
            newTab.focus();
          }
          window.open('someLink', '_blank');
        }
      } else snackbarUtils.error(error);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onClose = async () => {
    for (const ecoMapField of ecoMapFields) {
      context.removeChangedCategoryData(ecoMapField.id);
    }
    props.onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{context.i18n.ecoMapAddDialog}</DialogTitle>
      <DialogContent>
        {ecoMapFields.map((field) => (
          <FormElement
            dialog={true}
            hideExport
            type={field.type}
            value={field.value}
            title={field.name}
            required={field.required}
            id={field.id}
            key={field.id}
            groupId={groupId}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton onClick={onSave} disableRipple color="primary">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddEcoMapDialog;
