import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement, FormElementHeader } from '../../client';
import { FormFieldType } from '@casecare/types';
import { EcoMapAddDialogType } from '../../../types/eco-map-add-dialog-type';
import EcoMapApi from '../../../api/eco-map/eco-map';
import { StorageApi, UserApi } from '../../../api';
import { StorageItem } from '../../../types/storage-item';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { selectableRoles } from '../../../types/roles';
import { StorageItemPermissionType } from '../../../types/storage-item-permission-type';
import { assign } from 'underscore';
import { FormFieldStyle } from '../../../types/form-element-props';

interface StorageItemPermissionDialogProps {
  open: boolean;
  storageItem: StorageItem | null;
  onClose: () => void;
}

const StorageItemPermissionDialog: FC<StorageItemPermissionDialogProps> = (
  props,
) => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(props.open);
  const [usersAndRoles, setUsersAndRoles] = useState<any[]>([]);
  const [usersAndRolesFiltered, setUsersAndRolesFiltered] = useState<any[]>([]);
  const [storageItem, setStorageItem] = useState<StorageItem | null>(null);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [selectedUsersAndRoles, setSelectedUsersAndRoles] = useState(null);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setStorageItem(props.storageItem);
  }, [props.storageItem]);

  useEffect(() => {
    setSelectedUsersAndRoles(null);
  }, [selectedUsersAndRoles]);

  useEffect(() => {
    if (
      storageItem &&
      storageItem.userPermission &&
      storageItem.userPermission.type == StorageItemPermissionType.OWNER
    ) {
      setCanEdit(true);
      setUsersAndRolesFiltered(
        usersAndRoles.filter((search: any) => {
          return storageItem.permissions.find((ele: any) => {
            return ele.userRole == search.id || ele.userId == search.id;
          })
            ? false
            : true;
        }),
      );
    }
  }, [storageItem, usersAndRoles]);

  const onSave = async () => {
    try {
      const permissions = storageItem?.permissions.map((permission: any) => {
        return {
          userId: permission.userId,
          userRole: permission.userRole,
          type: permission.type,
        };
      });
      const res = await StorageApi.setPermissions(
        context.authToken,
        storageItem?.id ?? '',
        { permissions: permissions },
      );
      if (res.message) {
        snackbarUtils.success(res.message);
        onClose();
      }
      //   } else snackbarUtils.error(error);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onClose = async () => {
    // for (const staticField of staticFields) {
    //   context.removeChangedCategoryData(staticField.id);
    // }
    props.onClose();
  };

  const usersQuery = useQuery(
    ['users'],
    () => UserApi.getUsers(context.authToken),
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setUsersAndRoles([
          ...res.data.map((o: any) => ({
            ...o,
            type: 'USER',
          })),
          ...selectableRoles.map((o: any) => ({
            id: o,
            name: o,
            type: 'USER_ROLE',
          })),
        ]);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{context.i18n.editStorageDialog}</DialogTitle>
      <DialogContent>
        {usersAndRolesFiltered.length > 0 && canEdit && (
          <Box sx={{ m: 2 }}>
            <FormElementHeader
              title={context.i18n.storageItemPermissionUserAndRole}
            />
            <Autocomplete
              id={'storageItemPermissionUserAndRole'}
              fullWidth
              options={usersAndRolesFiltered.map((o: any) => ({
                key: o.id,
                label: o.name,
                type: o.type,
              }))}
              isOptionEqualToValue={(option, value) => {
                return true;
              }}
              value={selectedUsersAndRoles}
              groupBy={(option: any) => context.i18n[option.type]}
              onChange={(event, value: any) => {
                if (value) {
                  const role = selectableRoles.find(
                    (role) => role == value.key,
                  );
                  if (role) {
                    storageItem?.permissions.push({
                      userRole: role,
                      type: StorageItemPermissionType.VIEWER,
                    });
                  } else {
                    storageItem?.permissions.push({
                      userId: value.key,
                      userName: value.label,
                      type: StorageItemPermissionType.VIEWER,
                    });
                  }
                  setStorageItem(Object.assign({ ...storageItem }));
                  setSelectedUsersAndRoles(value);
                }
              }}
              renderInput={(params) => {
                return <TextField {...params} value={null} />;
              }}
            />
          </Box>
        )}
        {storageItem && storageItem.permissions.length > 0 && (
          <>
            {storageItem.permissions.map((permission, key) => {
              if (permission.userRole == 'SUPERADMIN') return;
              let name = null;
              if (permission.userRole) name = permission.userRole;
              else if (permission.userName) name = permission.userName;
              else if (permission.user)
                name =
                  permission.user.firstName + ' ' + permission.user.lastName;
              return (
                <FormElement
                  dialog={true}
                  key={key}
                  hideExport
                  disabled={
                    !canEdit ||
                    permission.userId == context.userId ||
                    permission.userRole == context.user?.role
                  }
                  type={FormFieldType.DROPDOWN}
                  value={permission.type}
                  title={name}
                  id={'storageItemPermission-' + key}
                  fieldOptions={[
                    ...Object.entries(StorageItemPermissionType).map((type) => {
                      return { key: type[0], label: context.i18n[type[0]] };
                    }),
                    {
                      key: 'DELETE',
                      label: context.i18n.storageRemovePermission,
                      color: 'error.light',
                    },
                  ]}
                  onChange={(value, error, onLoad) => {
                    if (value == 'DELETE') {
                      storageItem.permissions.splice(key, 1);
                      setStorageItem(
                        Object.assign({
                          ...storageItem,
                        }),
                      );
                    } else if (value) {
                      storageItem.permissions[key].type = value;
                      setStorageItem(Object.assign({ ...storageItem }));
                    }
                  }}
                />
              );
            })}
          </>
        )}
      </DialogContent>

      <DialogActions>
        {canEdit && (
          <Tooltip title={context.i18n.saveBtnTooltip}>
            <span>
              <IconButton onClick={onSave} disableRipple color="primary">
                <FontAwesomeIcon icon={faFloppyDisk} />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default StorageItemPermissionDialog;
