import { ClientDataType, Sex, RelationshipType } from '@casecare/types';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Autocomplete,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { context } from 'msw';
import { FC, useContext, useEffect, useState } from 'react';
import { UserSelect } from '../../client';
import { AppContext } from '../../../hooks/context';
import { CreateClientDialogProps } from './create-client-dialog';
import { useQuery } from 'react-query';
import { ClientApi, FamilyApi } from '../../../api';
import { Family } from '../../../types';
import { queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import moment from 'moment';

interface ClientDialogFieldsProps extends CreateClientDialogProps {
  save: boolean;
}

export const ClientDialogFields: FC<ClientDialogFieldsProps> = (props) => {
  const context = useContext(AppContext);

  const initialSex = Object.entries(Sex).slice(0, 3);

  const [clientNumber, setClientNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [sex, setSex] = useState('');
  const [sexError, setSexError] = useState('');
  const [edit, setEdit] = useState(false);
  const [diversSelected, setDiversSelected] = useState(false);
  const [selectSexOpen, setSelectSexOpen] = useState(false);
  const [supervisorIds, setSupervisorIds] = useState<string[]>([
    context.userId,
  ]);
  const [supervisorError, setSupervisorError] = useState('');
  // const [selectedFamilies, setSelectedFamilies] = useState<any>([]);
  const [role, setRole] = useState<string>();
  const [birthday, setBirthday] = useState(null);
  const [birthdayError, setBirthdayError] = useState('');
  const [openFamilySelect, setOpenFamilySelect] = useState(false);
  const [relationType, setRelationType] = useState();

  // useEffect(() => {
  //   if (props.families && props.families.length > 0) {
  //     setFamilies(props.families.slice());
  //   }
  // }, [props.families]);

  // useEffect(() => {
  //   if (props.initialFamilies) {
  //     setInitalFamilies(props.initialFamilies.map((f) => f.id));
  //   } else {
  //     setInitalFamilies([]);
  //   }
  // }, [props.initialFamilies]);

  // useEffect(() => {
  //   if (props.selectedFamilies) {
  //     setSelectedFamilies(props.selectedFamilies.slice());
  //   } else {
  //     setSelectedFamilies([]);
  //   }
  // }, [props.selectedFamilies]);

  useEffect(() => {
    if (props.open) {
      setDiversSelected(false);
      setEdit(false);
      setClientNumber('');
      setFirstName('');
      setFirstNameError('');
      setLastName('');
      setLastNameError('');
      setCity('');
      setZipcode('');
      setBirthday(null);
      setBirthdayError('');
      setSex('');
      setSexError('');
      setStreet('');
      setStreetNumber('');
      setSupervisorIds([context.userId]);
      setSupervisorError('');
      setRole(undefined);
      queryClient.invalidateQueries(['clientDialogData']);
    }
  }, [props.open]);

  useQuery(
    ['clientDialogData', props.clientId],
    () => ClientApi.getClientData(context.authToken, props.clientId),
    {
      enabled: props.clientId !== undefined,
      onSuccess: (res) => {
        const data = res.data.data;
        setClientNumber(data.CLIENT_NUMBER);
        setFirstName(data.FIRST_NAME);
        setLastName(data.LAST_NAME);
        setCity(data.CITY);
        setZipcode(data.ZIPCODE);
        setSex(data.SEX);
        setStreet(data.STREET);
        setStreetNumber(data.STREET_NUMBER);
        setBirthday(data.BIRTHDAY);
        setRole(data.role);
        setEdit(true);
        // setSelectedFamilies(res.data.families);
        setDiversSelected(true);
      },
    },
  );

  useEffect(() => {
    if (props.save) {
      handleSave();
    }
  });

  const clientFields = [
    {
      name: context.i18n.clientNumber,
      type: ClientDataType.CLIENT_NUMBER,
      value: clientNumber,
      setValue: (value: string) => setClientNumber(value),
    },
    {
      name: context.i18n.firstName,
      type: ClientDataType.FIRST_NAME,
      value: firstName,
      required: true,
      setValue: (value: string) => setFirstName(value),
      error: firstNameError,
      disableError: () => setFirstNameError(''),
    },
    {
      name: context.i18n.lastName,
      type: ClientDataType.LAST_NAME,
      value: lastName,
      required: props.isReferencePerson ? false : true,
      setValue: (value: string) => setLastName(value),
      error: lastNameError,
      disableError: () => setLastNameError(''),
    },
    {
      name: context.i18n.street,
      type: ClientDataType.STREET,
      value: street,
      setValue: (value: string) => setStreet(value),
    },
    {
      name: context.i18n.streetNumber,
      type: ClientDataType.STREET_NUMBER,
      value: streetNumber,
      setValue: (value: string) => setStreetNumber(value),
    },
    {
      name: context.i18n.zipcode,
      type: ClientDataType.ZIPCODE,
      value: zipcode,
      setValue: (value: string) => setZipcode(value),
    },
    {
      name: context.i18n.city,
      type: ClientDataType.CITY,
      value: city,
      setValue: (value: string) => setCity(value),
    },
    {
      name: context.i18n.sex,
      type: ClientDataType.SEX,
      value: sex,
      setValue: (value: any) => setSex(value),
      error: sexError,
      disableError: () => setSexError(''),
      required: true,
    },
    {
      name: context.i18n.birthday,
      type: ClientDataType.BIRTHDAY,
      value: birthday,
      setValue: (value: any) => setBirthday(value),
    },
  ];

  // useQuery(
  //   ['families', props.selectedFamilies === undefined],
  //   () => FamilyApi.getFamilies(context.authToken),
  //   {
  //     enabled: context.authToken !== undefined,
  //     onSuccess: (res) => {
  //       setFamilies(res.data);
  //     },
  //   },
  // );

  const handleSave = async () => {
    setFirstNameError(firstName === '' ? context.i18n.inputRequiredError : '');
    setSexError(sex === '' ? context.i18n.inputRequiredError : '');

    if (!props.isReferencePerson) {
      setLastNameError(lastName === '' ? context.i18n.inputRequiredError : '');

      if (lastName === '') return;
    }
    if (firstName === '' || sex === '') {
      return;
    }

    const data: Partial<Record<ClientDataType, any>> = Object.assign({
      CITY: city,
      STREET: street,
      STREET_NUMBER: streetNumber,
      ZIPCODE: zipcode,
      LAST_NAME: lastName,
      FIRST_NAME: firstName,
      CLIENT_NUMBER: clientNumber,
      SEX: sex,
      BIRTHDAY: birthday,
      SUPERVISOR: supervisorIds,
    });

    const body = Object.assign(
      { data },
      props.clientFamily && {
        familyId: props.clientFamily.id,
      },
      props.isReferencePerson && { isReferencePerson: true },
      role && { role },
      props.indexClientId && {
        indexClientId: props.indexClientId,
      },
      relationType && {
        relationType,
      },
    );

    if (edit) {
      await ClientApi.updateClientData(context.authToken, props.clientId, body);
    } else {
      const res = await ClientApi.createClient(context.authToken, body);

      if (props.indexClientId) {
        queryClient.invalidateQueries('clientData');
      }

      if (res.statusCode === 201) {
        if (res.data) {
          // Triggers reload to allow clientName to be shown
          context.setClientList(undefined);
          snackbarUtils.success(
            res.message || context.i18n.createClientDialogSuccessToast,
          );

          props.onFinish(res.data, false);
        }
      } else {
        snackbarUtils.error(context.i18n.createClientDialogErrorToast);
      }
    }

    props.onClose();
  };

  const importFamilyData = (family: Family) => {
    setOpenFamilySelect(false);
    setLastName(family.name);
    setCity(family.data.CITY);
    setStreet(family.data.STREET);
    setZipcode(family.data.ZIPCODE);
  };

  useEffect(() => {
    if (props.clientFamily && props.open) {
      importFamilyData(props.clientFamily);
    }
  }, [props.clientFamily, props.open]);

  return (
    <Grid container spacing={2} mt={2}>
      {/* {context.familyActive && (
          <Grid item xs={6}>
            {props.isReferencePerson ? (
              <FormControl sx={{ p: 0 }} fullWidth>
                <InputLabel
                  sx={{ alignSelf: 'center' }}
                  id="role-slect-label"
                >
                  {context.i18n.role}
                </InputLabel>
                <Select
                  id="role-slect-label"
                  label={context.i18n.role}
                  displayEmpty
                  value={role}
                >
                  {Object.entries(ReferencePersonRole).map((type) => (
                    <MenuItem
                      key={type[0]}
                      value={type[0]}
                      onClick={() => setRole(type[1])}
                    >
                      {context.i18n[type[0]]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <>
                <Autocomplete
                  options={families}
                  multiple
                  onChange={(evetn, option: any) => {
                    if (initialFamilies.length === 0) {
                      setSelectedFamilies(option);
                    } else if (option.length > 0) {
                      const filteredOptions: any =
                        props.selectedFamilies?.slice() || [];
                      option.map(
                        (o: any) =>
                          !initialFamilies.includes(o.id) &&
                          filteredOptions.push(o),
                      );
                      setSelectedFamilies(filteredOptions);
                    }
                  }}
                  noOptionsText={context.i18n.noFamilies}
                  getOptionLabel={(option: any) => option.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={context.i18n.family}
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={selectedFamilies}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((otption, index) => (
                      <Chip // eslint-disable-line
                        {...getTagProps({ index })}
                        label={otption.name}
                        disabled={initialFamilies.includes(otption.id)}
                      />
                    ))
                  }
                  defaultChecked={selectedFamilies}
                />
                <Button
                  onClick={() => {
                    if (selectedFamilies.length === 1) {
                      importFamilyData(selectedFamilies[0]);
                    } else {
                      setOpenFamilySelect(true);
                    }
                  }}
                  disableRipple
                  disabled={selectedFamilies.length === 0}
                  endIcon={<FontAwesomeIcon icon={faFileImport} />}
                >
                  {context.i18n.importFamilyData}
                </Button>
              </>
            )}
          </Grid>
        )} */}
      {!props.isReferencePerson && (
        <Grid item xs={6}>
          <UserSelect
            userIds={supervisorIds}
            title={context.i18n.supervisor}
            error={supervisorError !== ''}
            helperText={supervisorError !== '' ? supervisorError : ''}
            multiple
            required={true}
            setId={(id) => {
              setSupervisorIds(id);
              setSupervisorError('');
            }}
          />
        </Grid>
      )}
      {clientFields.map((field, index) => (
        <Grid key={index} item xs={6}>
          {field.type === ClientDataType.SEX ? (
            <FormControl
              color="secondary"
              error={field.error !== undefined ? field.error !== '' : false}
              fullWidth
              required={field.required}
            >
              <InputLabel sx={{ alignSelf: 'center' }} id="sex-slect-label">
                {field.name}
              </InputLabel>
              <Select
                labelId="sex-select-label"
                label={field.name}
                value={sex}
                open={selectSexOpen}
                onClick={(e) => {
                  if (!e.defaultPrevented) setSelectSexOpen(!selectSexOpen);
                }}
              >
                {!diversSelected
                  ? initialSex.map((sex) => (
                      <MenuItem
                        key={sex[1]}
                        value={sex[1]}
                        onClick={(e) => {
                          field.setValue(sex[0]);
                          setSexError('');
                          if (sex[1] === Sex.DIVERS) {
                            setDiversSelected(true);
                            e.preventDefault();
                          }
                        }}
                      >
                        {context.i18n[sex[0]]}
                      </MenuItem>
                    ))
                  : Object.entries(Sex).map((sex) => (
                      <MenuItem
                        key={sex[1]}
                        value={sex[1]}
                        onClick={() => {
                          field.setValue(sex[0]);
                          setSexError('');
                        }}
                      >
                        {context.i18n[sex[0]]}
                      </MenuItem>
                    ))}
              </Select>
              <FormHelperText>
                {field.error !== '' ? field.error : ''}
              </FormHelperText>
            </FormControl>
          ) : field.type === ClientDataType.BIRTHDAY ? (
            <DatePicker
              openTo="year"
              views={['year', 'month', 'day']}
              label={field.name}
              value={moment(field.value)}
              format="DD.MM.YYYY"
              maxDate={moment()}
              onChange={(value: any) => {
                field.disableError && field.disableError();
                field.setValue(value);
              }}
              slotProps={{
                textField: {
                  error: field.error !== undefined ? field.error !== '' : false,
                  helperText: field.error !== '' ? field.error : '',
                  required: field.required,
                  fullWidth: true,
                },
              }}
            />
          ) : (
            <TextField
              color="secondary"
              label={field.name}
              fullWidth
              type={'text'}
              value={field.value}
              error={field.error !== undefined ? field.error !== '' : false}
              helperText={field.error !== '' ? field.error : ''}
              required={field.required}
              onChange={(e) => {
                field.disableError && field.disableError();
                field.setValue(e.currentTarget.value);
              }}
            />
          )}
        </Grid>
      ))}
      {props.isReferencePerson && (
        <Grid item xs={6}>
          <TextField
            color="secondary"
            label={context.i18n.role}
            fullWidth
            value={role}
            onChange={(e) => {
              setRole(e.currentTarget.value);
            }}
          />
        </Grid>
      )}
      {props.clientFamilyDialog && (
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            disableClearable
            value={relationType}
            onChange={(evetn, value: any) => {
              setRelationType(value);
            }}
            options={Object.keys(RelationshipType)}
            getOptionLabel={(option) => context.i18n[option]}
            renderInput={(params) => (
              <TextField label={context.i18n.relationShip} {...params} />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
