import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LinksApi, LoginApi } from '../../../api';
import Navbar from '../../../components/core/navbar/navbar';
import LoginInput from '../../../components/public/login-input/login-input';
import { AppContext } from '../../../hooks/context';
import useFocus from '../../../hooks/useFocus';
import { LinkTypes } from '../../../types';
import { setDocumentTitle, loadTitle, queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { LoginBackgroundImage } from '../../../utils/background/background';
import RecoveryCodeDialog from '../../../components/dialogs/recovery-code-dialog/recovery-code-dialog';

interface LoginProps {
  expired?: true;
}

const Login: FC<LoginProps> = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState(
    typeof location.state === 'string' ? location.state : '',
  );
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [passwordRef, setPasswordFocus] = useFocus();

  useEffect(() => {
    setDocumentTitle(context.i18n.login);
    return () => loadTitle();
  }, []);

  useEffect(() => {
    if (props.expired) {
      snackbarUtils.error(context.i18n.authExpired);
    }
  }, []);

  const handleLogin = () => {
    setLoading(true);
    LoginApi.login({ email: email.toLowerCase(), password })
      .then((res) => {
        context.authenticate(res.data.token);
        if (res.message) snackbarUtils.success(res.message);
        queryClient.invalidateQueries('modules');
        navigate('/clients');
      })
      .catch((error) => {
        setLoading(false);
        const errorData = error.response.data;
        if (
          errorData.clientAction &&
          errorData.clientAction.find(
            (action: string) => action === 'EMAIL_NOT_VERIFIED',
          )
        ) {
          const action = () => (
            <Button variant="text" disableRipple onClick={() => resendLink()}>
              {context.i18n.sendAgain}
            </Button>
          );
          snackbarUtils.warning(context.i18n.emailNotConfirmed, action);
        }
      });
  };

  const resendLink = async () => {
    try {
      const res = await LinksApi.send({
        email,
        type: LinkTypes.EMAILCONFIRMATION,
      });
      if (res.message) snackbarUtils.success(res.message);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.error);
    }
  };

  const openPasswordReset = () => {
    navigate('/resetPassword', { state: email });
  };

  return (
    <Box sx={LoginBackgroundImage}>
      <Navbar hideMenuDrawer title={context.i18n.login} />
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <LoginInput
          onChange={setEmail.bind(this)}
          label={context.i18n.email}
          initialValue={email}
          onEnter={() => {
            setPasswordFocus();
          }}
        />
        <LoginInput
          label={context.i18n.inputPassword}
          onChange={setPassword.bind(this)}
          password
          showForgotPassword
          onPasswordOpen={openPasswordReset}
          onEnter={() => email !== '' && password !== '' && handleLogin()}
          ref={passwordRef}
        />
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{ mt: 2 }}
        >
          <Button
            color="primary"
            variant="contained"
            id="loginBtn"
            onClick={handleLogin.bind(this)}
            className="formBtn"
            disabled={email === '' || password === ''}
          >
            {loading ? (
              <CircularProgress sx={{ color: 'white' }} />
            ) : (
              context.i18n.loginBtn
            )}
          </Button>
          {/* <a
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') navigate('/createOrg');
            }}
            onClick={() => navigate('/createOrg')}
          >
            <Typography
              variant="subtitle1"
              className="clickable"
              sx={{ fontSize: '0.9rem', textAlign: 'center', mt: 1 }}
            >
              {context.i18n.createOrg}
            </Typography>
          </a> */}
        </Box>
      </Box>
      <RecoveryCodeDialog />
    </Box>
  );
};

export default Login;
