import { FrontendFormImportField, FormFieldType } from '@casecare/types';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  DialogContent,
  TextField,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../../hooks/context';
import { FormEditTable } from '../../../admin';
import DiscardDialog from '../../discard-dialog/discard-dialog';
import FormGroupEditDialog from '../form-group-edit-dialog/form-group-edit-dialog';

interface FormListEditDialogProps {
  open: boolean;
  categoryName: string;
  element?: FrontendFormImportField;
  onClose: () => void;
  onSave: (element: FrontendFormImportField, created: boolean) => void;
}

const FormListEditDialog: FC<FormListEditDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [openDialog, setOpenDialog] = useState(props.open);
  const [openFormGroupEditDialog, setOpenFormGroupEditDialog] = useState(false);
  const [elementName, setElementName] = useState(
    props.element?.label ? props.element.label : '',
  );
  const [elementNameError, setElementNameError] = useState(false);
  const [elements, setElements] = useState<FrontendFormImportField[]>(
    props.element?.children ? props.element.children : [],
  );
  const [changedIds, setChangedIds] = useState<string[]>(
    props.element
      ? props.element.changedIds
        ? props.element.changedIds
        : []
      : [],
  );
  const [editElement, setEditElement] = useState<FrontendFormImportField>();
  const [element, setElement] = useState(props.element);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  useEffect(() => {
    setOpenDialog(props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.element?.label) {
      setElementName(props.element?.label);
    }
  }, [props.element?.label]);

  useEffect(() => {
    setElement(props.element);
    setElements(props.element?.children ? props.element.children : []);
  }, [props.element]);

  const onSave = () => {
    setOpenDialog(false);
    if (elementName === '' || !elementName) {
      setElementNameError(true);
      return;
    }
    props.onSave(
      {
        ...element,
        label: elementName,
        fieldOptions: [],
        children: elements,
        changedIds,
        type: FormFieldType.LIST,
      },
      element?.id ? false : true,
    );
  };

  const onClose = () => {
    if (changedIds.length > 0) {
      setOpenDiscardDialog(true);
    } else {
      setOpenDialog(false);
      props.onClose();
    }
  };

  const onOpenAddElement = () => {
    setEditElement({
      type: FormFieldType.FORM_GROUP,
      fieldOptions: [],
      new: true,
    });
    setOpenFormGroupEditDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 100);
  };

  const onEditElement = (id: string) => {
    setEditElement(
      elements.find((element: FrontendFormImportField) => element.id === id),
    );
    setOpenFormGroupEditDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 100);
  };

  const onSaveElement = (
    newElement: FrontendFormImportField,
    createElement: boolean,
  ) => {
    setOpenDialog(true);
    setTimeout(() => {
      setOpenFormGroupEditDialog(false);
    }, 100);
    setChangedIds((prevChangedIds) => {
      if (newElement.id) {
        return [...prevChangedIds, newElement.id];
      }
      return prevChangedIds;
    });
    if (createElement) {
      setElements((prevElements) => {
        const seq =
          prevElements.length > 0
            ? prevElements.sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1))[
                prevElements.length - 1
              ].seq
            : 0;
        newElement.seq = seq ? seq + 1 : 1;
        newElement.id = newElement.label
          ? newElement.seq + newElement.label
          : '';
        newElement.new = true;
        return [...prevElements, newElement];
      });
      return;
    }
    setElements((prevElements) => {
      const newElements = prevElements.map((element) => {
        if (element.id === newElement.id) {
          return newElement;
        }
        return element;
      });

      return newElements;
    });
  };

  const onDeleteElement = (id: string) => {
    setChangedIds((prevChangedIds) => {
      return [...prevChangedIds, id];
    });
    setElements((prevElements) => {
      const newElements = prevElements.map((element) => {
        if (element.id === id) {
          return { ...element, delete: true };
        }
        return element;
      });

      return newElements;
    });
  };

  const moveElement = (up: boolean, elementId: any, allTheWay?: boolean) => {
    const newElements = elements.sort((a, b) =>
      a.seq && b.seq ? a.seq - b.seq : 1,
    );
    let finnished = false;
    newElements.forEach((element, index) => {
      const seq: any = newElements[index].seq;
      if (allTheWay && up && element.id !== elementId && !finnished) {
        newElements[index].seq = seq + 1;
      }
      if (allTheWay && !up && element.id !== elementId && finnished) {
        newElements[index].seq = seq - 1;
      }
      if (element.id === elementId) {
        const tmpSeq = newElements[index].seq;
        finnished = true;
        if (up && index > 0) {
          if (allTheWay) {
            newElements[index].seq = 1;
            setChangedIds((prevChangedIds) => {
              return [...prevChangedIds, elementId, newElements[0].id];
            });
            return;
          }
          newElements[index].seq = newElements[index - 1].seq;
          newElements[index - 1].seq = tmpSeq;
          setChangedIds((prevChangedIds) => {
            return [...prevChangedIds, elementId, newElements[index - 1].id];
          });
          return;
        } else if (!up && index + 1 !== newElements.length) {
          if (allTheWay) {
            newElements[index].seq = newElements.length;
            setChangedIds((prevChangedIds) => {
              return [
                ...prevChangedIds,
                elementId,
                newElements[newElements.length - 1].id,
              ];
            });
            return;
          }
          newElements[index].seq = newElements[index + 1].seq;
          newElements[index + 1].seq = tmpSeq;
          setChangedIds((prevChangedIds) => {
            return [...prevChangedIds, elementId, newElements[index + 1].id];
          });
          return;
        }
      }

      return newElements;
    });

    setElements(newElements);
  };

  return (
    <Box>
      <Dialog
        open={openDialog}
        onClose={() => {
          props.onClose();
        }}
        fullScreen
      >
        <AppBar sx={{ position: 'sticky' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.categoryName}
            </Typography>
            <Tooltip title={context.i18n.saveBtnTooltip}>
              <IconButton
                edge="start"
                color="inherit"
                sx={{ mr: 2 }}
                onClick={() => onSave()}
              >
                <FontAwesomeIcon icon={faFloppyDisk} />
              </IconButton>
            </Tooltip>
            <Tooltip title={context.i18n.cancelBtnTooltip}>
              <IconButton
                sx={{ mr: 2 }}
                edge="start"
                color="inherit"
                onClick={() => {
                  onClose();
                }}
              >
                <FontAwesomeIcon icon={faX} />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            autoFocus
            color="secondary"
            label={context.i18n.name}
            size="medium"
            type={'text'}
            sx={{ mb: 2, ml: 2 }}
            value={elementName}
            error={elementNameError}
            onChange={(e) => {
              setElementNameError(false);
              setElementName(e.currentTarget.value);
            }}
          />
          <FormEditTable
            onAddElement={() => onOpenAddElement()}
            onEditElement={(id) => onEditElement(id)}
            onDeleteElement={(id) => onDeleteElement(id)}
            onMoveElement={(up, id, allTheWay) =>
              moveElement(up, id, allTheWay)
            }
            elements={elements}
            formGroup
            showPreview
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openFormGroupEditDialog}
        fullScreen
        onClose={() => {
          setOpenDialog(true);
          setTimeout(() => {
            setOpenFormGroupEditDialog(false);
          }, 100);
        }}
      >
        <FormGroupEditDialog
          onClose={() => {
            setOpenDialog(true);
            setTimeout(() => {
              setOpenFormGroupEditDialog(false);
            }, 100);
          }}
          onSave={(element, createElement) =>
            onSaveElement(element, createElement)
          }
          element={editElement}
          categoryName={props.categoryName}
        />
      </Dialog>
      <Dialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog(false)}
      >
        <DiscardDialog
          onDiscard={() => {
            props.onClose();
            setOpenDiscardDialog(false);
          }}
          onSave={onSave}
          onClose={() => setOpenDiscardDialog(false)}
        />
      </Dialog>
    </Box>
  );
};

export default FormListEditDialog;
