import { Breadcrumbs, Link, Link as MuiLink, Typography } from '@mui/material';

import { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../../../hooks/context';

interface BreadCrumbProps {
  customBreadCrumb?: boolean;
}

const BreadCrumb: FC<BreadCrumbProps> = (props) => {
  const location = useLocation();
  const context = useContext(AppContext);

  useEffect(() => {
    if (!props.customBreadCrumb) {
      let cumulativePath = '';
      let cumulativeLanguage = 'siteTitle';
      // let first = true;
      const segments = location.pathname
        .split('/')
        .filter((s) => s.length)
        .map((s) => {
          cumulativeLanguage += `${(
            s.charAt(0).toUpperCase() + s.slice(1)
          ).replace(/[_-]/g, '')}`;
          cumulativePath += `/${s}`;
          const name = s.replace(/-/g, ' ');
          if (context.i18n[cumulativeLanguage])
            return {
              path: cumulativePath,
              name: context.i18n[cumulativeLanguage],
            };
          else
            return {
              path: cumulativePath,
              name: name.charAt(0).toUpperCase() + name.slice(1),
            };
        });

      context.setBreadCrumbSegments(segments);
    }
  }, [location, props.customBreadCrumb]);

  return (
    <Breadcrumbs
      maxItems={3}
      itemsBeforeCollapse={0}
      itemsAfterCollapse={3}
      separator="›"
      aria-label="breadcrumb"
      sx={{ p: 2, mb: 1, borderBottom: 0.5, borderColor: 'primary' }}
    >
      {context.breadCrumbSegments.map((segment, key, self) => {
        if (segment && segment.name) {
          return segment === self[self.length - 1] || !segment.path ? (
            <Typography key={key} color="text.primary">
              {segment.name}
            </Typography>
          ) : (
            <Link
              component={RouterLink}
              underline="hover"
              key={key}
              color="inherit"
              to={segment.path}
            >
              {segment.name}
            </Link>
          );
        } else return <></>;
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
