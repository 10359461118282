import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import moment from 'moment';
import {
  parseDateString,
  parseDateTimeString,
  queryClient,
  theme,
} from '../../../utils';
import { faPlus, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { AddTeamTodoDialog } from '../add-team-todo-dialog/add-team-todo-dialog';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { ProtocolApi, TodoApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { TeamTodoListFilterbar } from './team-todo-list-filterbar';

interface TeamTodoListProps {
  done: boolean;
  title: string;
}

export const TeamTodoList: FC<TeamTodoListProps> = (props) => {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [userIds, setUserIds] = useState([]);
  const [userGroupIds, setUserGroupIds] = useState([]);

  const { isLoading } = useQuery(
    ['team-todos', pageSize, pageNum, sortModel, userIds, userGroupIds],
    () => {
      const data: any = Object.assign({
        pageNum,
        pageSize,
        sortModel,
        userIds,
        userGroupIds,
      });
      return TodoApi.getTeamTodos(context.authToken, data);
    },
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setRows(res.data);
        setRowCount(res.data.totalCount);
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const onDelete = async (id: any) => {
    try {
      const res = await TodoApi.deleteTeamTodo(context.authToken, id);
      if (res.message) {
        snackbarUtils.success(res.message);
      }
      queryClient.invalidateQueries('team-todos');
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const tableColumns: Array<GridColDef> = [
    {
      field: 'title',
      headerName: context.i18n.todoTitle,
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <Box overflow="auto" maxHeight={80} m={1}>
          <Typography
            sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'usersOpen',
      headerName: context.i18n.userOpen,
      flex: 1.1,
      hideable: false,
      renderCell: (params) => (
        <Stack direction="column" overflow="auto" maxHeight="100px">
          {params.value.map((v: any) => (
            <Box key={v.id}>{`${v.name}`}</Box>
          ))}
        </Stack>
      ),
    },
    {
      field: 'usersDone',
      headerName: context.i18n.userDone,
      flex: 1.1,
      hideable: false,
      renderCell: (params) => (
        <Stack direction="column" overflow="auto" maxHeight="100px">
          {params.value.map((v: any) => (
            <Box key={v.id}>{`${v.name}`}</Box>
          ))}
        </Stack>
      ),
    },
    {
      field: 'dueDate',
      headerName: context.i18n.todoDueDate,
      flex: 0.9,
      hideable: false,
      valueFormatter: (value) => parseDateString(value),
    },
    {
      field: 'client',
      headerName: context.i18n.client,
      flex: 0.5,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate('/clients/' + params.row.clientId + '/clientData');
            }}
          >
            {params.value}
          </Link>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      sortable: false,
      flex: 0.4,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Tooltip title={context.i18n.addTodo}>
          <IconButton
            disableRipple
            color="primary"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Tooltip title={context.i18n.delete}>
          <IconButton
            disableRipple
            color="error"
            onClick={() => {
              onDelete(params.id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box>
      <DataGridPro
        initialState={{
          sorting: {
            sortModel: [{ field: 'dueDate', sort: 'asc' }],
          },
        }}
        sx={{
          m: 2,
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: 0,
          display: 'flex',
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
            {
              display: 'none',
            },
          '& .MuiDataGrid-row.urgent': {
            color: theme.palette.warning.main,
          },
          '& .MuiDataGrid-row.late': {
            color: theme.palette.error.main,
          },
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        loading={isLoading}
        slotProps={{
          toolbar: {
            userIds,
            setUserIds,
            userGroupIds,
            setUserGroupIds,
          },
        }}
        slots={{
          toolbar(props: any) {
            return TeamTodoListFilterbar(props);
          },
        }}
        // Pagination settings
        paginationMode="server"
        onPaginationModelChange={(model) => {
          setPageNum(model.page);
          setPageSize(model.pageSize);
        }}
        paginationModel={{ pageSize, page: pageNum }}
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={rowCount}
        // End Pagination settings
        // Sorting settings
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        columnBufferPx={4}
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
        rowHeight={80}
        getRowClassName={(params) => {
          if (moment(moment()).isAfter(params.row.dueDate) && !props.done) {
            return 'late';
          } else if (
            moment(params.row.dueDate).diff(moment(), 'hours') <= 24 &&
            !props.done
          ) {
            return 'urgent';
          } else {
            return '';
          }
        }}
      />
      <AddTeamTodoDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </Box>
  );
};
