import { NoteType } from '@casecare/types';
import { Paper, Typography, Box } from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { NotesApi } from '../../../../api';
import { AppContext } from '../../../../hooks/context';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import AvatarNotesFilterToolbar from './avatar-notes-filter-toolbar';
import { AvatarNoteDataGridElement } from '../../../../types';
import AvatarSymbolButton from '../../../avatar/avatar-symbol-button/avatar-symbol-button';
import { AvatarNoteDialog } from '../../../dialogs';
import { AvatarMoodButton } from '../../../avatar';
import { queryClient } from '../../../../utils';

interface AvatarNotesProps {
  saved: boolean;
}

const AvatarNotes: FC<AvatarNotesProps> = (props) => {
  const context = useContext(AppContext);
  const [rows, setRows] = useState<AvatarNoteDataGridElement[]>([]);

  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [noteClientId, setNoteClientId] = useState<string | undefined>();
  const [noteSymbolId, setNoteSymbolId] = useState('');
  const [noteType, setNoteType] = useState(NoteType.AVATAR);

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rowCount, setRowCount] = useState(0);

  const [clientIds, setClientIds] = useState<string[] | undefined>();
  const [clients, setClients] = useState([]);

  const { isLoading } = useQuery(
    ['getAvatarNotes', pageSize, pageNum, sortModel, clientIds],
    () => {
      const data: any = Object.assign({
        pageNum,
        pageSize,
        sortModel,
        clientIds,
      });

      return NotesApi.getAvatarNotes(context.authToken, context.clientId, data);
    },
    {
      enabled:
        context.clientId !== undefined && context.authToken !== undefined,
      onSuccess: async (res) => {
        setRows(res.data.clients);
        setRowCount(res.data.totalCount);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useQuery(
    ['getAvatarNotes', context.clientId],
    () => {
      const data: any = Object.assign({
        pageNum,
        pageSize,
        sortModel,
        clientIds,
      });

      return NotesApi.getAvatarNoteClients(context.authToken, context.clientId);
    },
    {
      enabled:
        context.clientId !== undefined && context.authToken !== undefined,
      onSuccess: async (res) => {
        setClients(res.data);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const tableColumns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: context.i18n.person,
      flex: 1,
      hideable: false,
      sortable: false,
    },
    {
      field: 'avatarNote',
      headerName: context.i18n.avatarNote,
      flex: 1,
      hideable: false,
      sortable: false,
      renderCell: (params: any) => (
        <Box
          width="100%"
          onClick={() => {
            setNoteClientId(params.id);
            setNoteType(NoteType.AVATAR);
            setOpenNoteDialog(true);
          }}
        >
          <Typography noWrap>{params.value}</Typography>
        </Box>
      ),
    },
    {
      field: 'symbols',
      headerName: context.i18n.symbols,
      flex: 1,
      hideable: false,
      sortable: false,
      renderCell: (params: any) =>
        params.row.symbols.map((s: any) => (
          <AvatarSymbolButton
            key={s.symbolId}
            onNote={() => {
              setNoteClientId(params.id);
              setNoteSymbolId(s.symbolId);
              setNoteType(NoteType.AVATAR_SYMBOL);
              setOpenNoteDialog(true);
            }}
            id={s.symbolId}
            url={s.symbolUrl}
            hideNote={!s.hasNote}
            disabled={!s.hasNote}
          />
        )),
    },
    {
      field: 'mood',
      headerName: context.i18n.mood,
      flex: 1,
      hideable: false,
      renderCell: (params: any) => (
        <AvatarMoodButton
          mood={params.row.mood}
          onNote={() => {
            setNoteClientId(params.id);
            setNoteType(NoteType.AVATAR_MOOD);
            setOpenNoteDialog(true);
          }}
          greyNote={false}
          showNote={params.row.hasMoodNote}
          disabled={!params.row.hasMoodNote}
        />
      ),
    },
  ];

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
      }}
    >
      <DataGridPro
        sx={{
          m: 2,
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: 0,
          display: 'flex',
        }}
        slotProps={{
          toolbar: {
            setClientIds,
            clients,
          },
        }}
        slots={{
          toolbar(props: any) {
            return AvatarNotesFilterToolbar(props);
          },
        }}
        columns={tableColumns}
        rows={rows}
        rowHeight={80}
        getRowId={(row) => row.id}
        loading={isLoading}
        columnBufferPx={4}
        // Pagination settings
        paginationMode="server"
        onPaginationModelChange={(model) => {
          setPageNum(model.page);
          setPageSize(model.pageSize);
        }}
        paginationModel={{ pageSize, page: pageNum }}
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={rowCount}
        // End Pagination settings
        // Sorting settings
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />
      <AvatarNoteDialog
        clientId={noteClientId}
        open={openNoteDialog}
        onClose={() => {
          queryClient.invalidateQueries('getAvatarNotes');
          setOpenNoteDialog(false);
        }}
        indexClientId={context.clientId}
        type={noteType}
        symbolId={noteSymbolId}
      />
    </Paper>
  );
};

export default AvatarNotes;
