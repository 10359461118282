import {
  AccordionDetails,
  Box,
  BoxProps,
  Grid,
  IconButton,
  Tabs,
  Typography,
} from '@mui/material';
import { FC, LegacyRef, useContext, useState } from 'react';
import EcoMapAvatarIcon from '../../eco-map-avatar-icon/eco-map-avatar-icon';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { ResizableBox } from 'react-resizable';
import { theme } from '../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faExclamationTriangle,
  faTriangleCircleSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Sex } from '@casecare/types';
import GenderIcon from '../../../core/gender-icon/gender-icon';

interface EcoMapIconCircleSliceProps extends BoxProps {
  id: string;
  index: number;
  isSplitted: boolean;
}

const EcoMapIconCircleSlice: FC<EcoMapIconCircleSliceProps> = (props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  return (
    <Box
      className={`splitter splitter-${props.index} ${
        props.isSplitted ? `splitted` : ``
      }`}
      ref={setNodeRef}
    ></Box>
  );
};

export default EcoMapIconCircleSlice;
