import {
  AccordionDetails,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import { theme } from '../../../utils';
import { DndContext, useDroppable } from '@dnd-kit/core';
import EcoMapAvatarIcon from '../eco-map-avatar-icon/eco-map-avatar-icon';
import EcoMapIconDraggable from './eco-map-icon-draggable/eco-map-icon-draggable';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import EcoMapApi from '../../../api/eco-map/eco-map';
import LeaderLine from 'leader-line-new';
import {
  EcoMapBackgroundWrapper,
  EcoMapSplitter,
  EcoMapUtilityButtons,
} from '../../../utils/styling/styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
} from '@fortawesome/free-solid-svg-icons';
import EcoMapIconCircleSlice from './eco-map-icon-circle-slice/eco-map-icon-circle-slice';
import { ClientDataType } from '@casecare/types';
interface EcoMapIconMapProps {
  ecoMap: any;
  indexClient: any;
  expertMode: boolean;
  disabled?: boolean;
  printMode?: boolean;
}
const EcoMapIconMap: FC<EcoMapIconMapProps> = (props) => {
  const context = useContext(AppContext);
  const [ecoMap, setEcoMap] = useState<any>();
  const [positions, setPositions] = useState<any>();
  const [connections, setConnections] = useState<any>();
  const [segments, setSegments] = useState<any>();
  const lines = useRef<LeaderLine[]>([]);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const iconIdPrefix = 'eco-map-drag-icon-';

  const [disableTransform, setDisableTransform] = useState(false);
  const [disableDrag, setDisableDrag] = useState(false);

  const rePositionLines = () => {
    for (const line of lines.current) {
      line.position();
    }
  };

  const removeLines = () => {
    for (const line of lines.current) {
      line.remove();
    }
  };

  const handleDragStart = (handle: any) => {
    setDisableTransform(true);
  };
  const handleDragEnd = async (handle: any) => {
    setDisableTransform(false);
    if (!disableDrag) {
      const item = handle.active.data.current;
      if (item) {
        if (handle.collisions.length > 0)
          item.ecoMapSegmentId = handle.collisions[0].id;
        item.x = item.x + handle.delta.x;
        item.y = item.y + handle.delta.y;
        if (item.x < 0) item.x = 0;
        if (item.y < 0) item.y = 0;
        const index = positions.findIndex((x: any) => x.id === item.id);
        if (index >= 0) positions[index] = item;
        setPositions(positions);
        try {
          await EcoMapApi.editPosition(context.authToken, item.id, item);
        } catch (e) {
          console.error(e);
          snackbarUtils.error(context.i18n.errorTryAgainLater);
        }
      }
    }
  };

  useEffect(() => {
    setEcoMap(props.ecoMap);
  }, [props.ecoMap]);

  useEffect(() => {
    setConnections(props.ecoMap.connections);
  }, [props.ecoMap.connections]);

  useEffect(() => {
    setPositions(props.ecoMap.positions);
  }, [props.ecoMap.positions]);

  useEffect(() => {
    setSegments(props.ecoMap.segments);
  }, [props.ecoMap.segments]);

  useEffect(() => {
    removeLines();
    lines.current = [];
    const lineArray = [];
    const removeDuplicated = [];
    if (connections) {
      for (const connection of connections) {
        const otherSide = connections.find(
          (conn: any) =>
            conn.clientFromId == connection.clientToId &&
            conn.clientToId == connection.clientFromId,
        );
        if (otherSide == undefined)
          lineArray.push({
            clientFromId: connection.clientFromId,
            clientToId: connection.clientToId,
            color: connection.color,
          });
        else {
          if (
            removeDuplicated.find((dup: any) => dup == connection.id) ==
            undefined
          ) {
            lineArray.push({
              clientFromId: connection.clientFromId,
              clientToId: otherSide.clientFromId,
              colorFrom: connection.color,
              colorTo: otherSide.color,
            });
            removeDuplicated.push(otherSide.id);
          }
        }
      }

      for (const connection of lineArray) {
        const start = document.getElementById(
          iconIdPrefix + connection.clientFromId,
        );
        const end = document.getElementById(
          iconIdPrefix + connection.clientToId,
        );
        if (start !== null && end !== null && start !== end)
          if (connection.colorFrom && connection.colorTo)
            lines.current.push(
              new LeaderLine(start, end, {
                startPlug: 'arrow3',
                endPlug: 'arrow3',
                path: 'straight',
                startPlugColor: connection.colorTo,
                endPlugColor: connection.colorFrom,
                size: 4,
                endPlugSize: 1.5,
                startPlugSize: 1.5,
                gradient: true,
              }),
            );
          else
            lines.current.push(
              new LeaderLine(start, end, {
                startPlug: 'behind',
                endPlug: 'arrow3',
                path: 'straight',
                color: connection.color,
                size: 4,
                endPlugSize: 1.5,
              }),
            );
      }
    }
  }, [lines.current, connections]);

  const scrollDirection = (x = 0, y = 0) => {
    if (transformComponentRef.current) {
      const { setTransform, instance } = transformComponentRef.current;
      const { state } = instance.getContext();
      const { bounds } = instance;
      let newX = state.positionX + x;
      let newY = state.positionY + y;
      // calc bounds if outside of bounds set to bounds.x/y //
      if (bounds) {
        if (bounds.maxPositionX < newX && Math.sign(newX) == 1)
          newX = bounds.maxPositionX;
        if (bounds.minPositionX > newX && Math.sign(newX) == -1)
          newX = bounds.minPositionX;
        if (bounds.maxPositionY < newY && Math.sign(newY) == 1)
          newY = bounds.maxPositionY;
        if (bounds.minPositionY > newY && Math.sign(newY) == -1)
          newY = bounds.minPositionY;
      }
      setTransform(newX, newY, state.scale);
    }
  };

  return positions === undefined ||
    connections === undefined ||
    segments === undefined ||
    ecoMap === undefined ? (
    <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />
  ) : (
    ecoMap && positions && connections && (
      <TransformWrapper
        initialScale={0.8}
        disabled={disableTransform || props.printMode || props.disabled}
        onTransformed={rePositionLines}
        centerOnInit
        maxScale={2.5}
        minScale={0.8}
        disablePadding
        ref={transformComponentRef}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <>
            {!props.printMode && (
              <Box sx={EcoMapUtilityButtons}>
                <Box className="left-wrapper">
                  <Button
                    onClick={() => scrollDirection(100, 0)}
                    variant="contained"
                    color="primary"
                  >
                    <FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon>
                  </Button>
                </Box>
                <Box className="top-wrapper">
                  <Button
                    onClick={() => scrollDirection(0, 100)}
                    variant="contained"
                    color="primary"
                  >
                    <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>
                  </Button>
                </Box>
                <Box className="right-wrapper">
                  <Button
                    onClick={() => scrollDirection(-100, 0)}
                    variant="contained"
                    color="primary"
                  >
                    <FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>
                  </Button>
                </Box>
                <Box className="bottom-wrapper">
                  <Button
                    onClick={() => zoomOut()}
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlassMinus}
                    ></FontAwesomeIcon>
                  </Button>
                  <Button
                    onClick={() => scrollDirection(0, -100)}
                    variant="contained"
                    color="primary"
                  >
                    <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => zoomIn()}
                    sx={{ ml: 1 }}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlassPlus}
                    ></FontAwesomeIcon>
                  </Button>
                </Box>
              </Box>
            )}
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.grey[200],
              }}
              contentStyle={{
                minHeight: '1000px',
                minWidth: '1000px',
                maxWidth: '1000px',
                maxHeight: '1000px',
                width: '100%',
                height: '100%',
              }}
            >
              <Box sx={EcoMapBackgroundWrapper}>
                <Box className="circle-wrapper">
                  <Box sx={{ backgroundColor: ecoMap.outerCircleColor }}></Box>
                  <Box sx={{ backgroundColor: ecoMap.innerCircleColor }}></Box>
                  <Box sx={{ backgroundColor: ecoMap.middleCircleColor }}></Box>
                </Box>
                <Box className="text-wrapper">
                  {ecoMap.segments.map((segment: any, index: any) => (
                    <Typography
                      key={segment.id}
                      className={`text-${segment.seq}`}
                    >
                      {segment.label}
                    </Typography>
                  ))}
                </Box>
              </Box>

              <Box sx={{ zIndex: 10 }}>
                {positions.length > 0 && (
                  <>
                    <DndContext
                      onDragEnd={handleDragEnd}
                      onDragStart={handleDragStart}
                      onDragMove={rePositionLines}
                    >
                      {positions.map((position: any) => (
                        <EcoMapIconDraggable
                          id={iconIdPrefix + position.client.id}
                          key={position.id}
                          position={position}
                          setDisableDrag={setDisableDrag}
                          disableDrag={disableDrag}
                          disabled={props.disabled || props.printMode}
                          isIndex={props.indexClient.id == position.client.id}
                          expertMode={props.expertMode}
                          label={position.client[ClientDataType.FIRST_NAME]}
                        ></EcoMapIconDraggable>
                      ))}
                      <Box sx={EcoMapSplitter}>
                        {segments.map((segment: any, index: any) => {
                          if (index >= 4) return <></>;
                          return (
                            <EcoMapIconCircleSlice
                              key={segment.id}
                              id={segment.id}
                              index={index + 1}
                              isSplitted={
                                segments.findIndex(
                                  (seg: any) => seg.seq === segment.seq + 4,
                                ) !== -1
                                  ? true
                                  : false
                              }
                            ></EcoMapIconCircleSlice>
                          );
                        })}
                      </Box>
                    </DndContext>
                  </>
                )}
              </Box>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    )
  );
};

export default EcoMapIconMap;
