import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { UserSelect } from '../../client';
import PermissionsApi from '../../../api/permissions/permissions';

interface AddPermissionDialogProps {
  open: boolean;
  clientId: string | undefined;
  familyId: string | undefined;
  userList: any[];
  onClose: () => void;
}

const AddPermissionDialog: FC<AddPermissionDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [userId, setUserId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onSave = async () => {
    try {
      let res;

      if (props.clientId) {
        res = await PermissionsApi.addClientPermission(
          context.authToken,
          props.clientId,
          { userId },
        );
      } else if (props.familyId) {
        res = await PermissionsApi.addFamilyPermission(
          context.authToken,
          props.familyId,
          { userId },
        );
      }

      if (res.message) {
        snackbarUtils.success(res.message);
      }
      setUserId(undefined);
      props.onClose();
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.addPermission}</DialogTitle>
      <DialogContent>
        <UserSelect
          error={false}
          setId={(id) => setUserId(id)}
          title={context.i18n.user}
          userId={userId}
          noShowUsers={props.userList.map((u) => u.id)}
          noAdmins
        />
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton
              disabled={!userId}
              onClick={onSave}
              disableRipple
              color="primary"
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton
            onClick={() => {
              setUserId('');
              props.onClose();
            }}
            disableRipple
          >
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddPermissionDialog;
