import { FrontendFormImportField, FormFieldType } from '@casecare/types';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  DialogContent,
  TextField,
  Dialog,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { FormEditTable } from '../../../admin';
import DiscardDialog from '../../discard-dialog/discard-dialog';
import FormGroupDialog from '../../form-group-dialog/form-group-dialog';
import FormElementDialog from '../form-element-dialog/form-element-dialog';

interface FormGroupEditDialogProps {
  element?: FrontendFormImportField;
  categoryName: string;
  onClose: () => void;
  onSave: (element: FrontendFormImportField, created: boolean) => void;
}

const FormGroupEditDialog: FC<FormGroupEditDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [openElementDialog, setOpenElementDialog] = useState(false);
  const [editElement, setEditElement] = useState<FrontendFormImportField>();
  const [editElementType, setEditElementType] = useState<FormFieldType>();
  const [elementName, setElementName] = useState(
    props.element?.label ? props.element.label : '',
  );
  const [elementNameError, setElementNameError] = useState(false);
  const [elements, setElements] = useState<FrontendFormImportField[]>(
    props.element?.children ? props.element.children : [],
  );
  const [changedIds, setChangedIds] = useState<string[]>(
    props.element
      ? props.element.changedIds
        ? props.element.changedIds
        : []
      : [],
  );
  const [element] = useState(props.element);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  const onOpenAddElement = () => {
    setEditElement(undefined);
    setEditElementType(undefined);
    setOpenElementDialog(true);
  };

  const onSave = () => {
    if (elementName === '' || !elementName) {
      setElementNameError(true);
      return;
    }
    props.onSave(
      {
        ...element,
        label: elementName,
        fieldOptions: [],
        children: elements,
        changedIds,
      },
      element?.id ? false : true,
    );
  };

  const onClose = () => {
    if (changedIds.length > 0) {
      setOpenDiscardDialog(true);
    } else {
      props.onClose();
    }
  };

  const onSaveElement = (
    newElement: FrontendFormImportField,
    createElement: boolean,
  ) => {
    setOpenElementDialog(false);
    setChangedIds((prevChangedIds) => {
      if (newElement.id) {
        return [...prevChangedIds, newElement.id];
      }
      return prevChangedIds;
    });
    if (createElement) {
      setElements((prevElements) => {
        const seq =
          prevElements.length > 0
            ? prevElements.sort((a, b) => (a.seq && b.seq ? a.seq - b.seq : 1))[
                prevElements.length - 1
              ].seq
            : 0;
        newElement.seq = seq ? seq + 1 : 1;
        newElement.id = newElement.label
          ? newElement.seq + newElement.label
          : '';
        newElement.new = true;
        return [...prevElements, newElement];
      });
      return;
    }
    setElements((prevElements) => {
      const newElements = prevElements.map((element) => {
        if (element.id === newElement.id) {
          return newElement;
        }
        return element;
      });

      return newElements;
    });
  };

  const onEditElement = (id: string) => {
    const element = elements.find(
      (element: FrontendFormImportField) => element.id === id,
    );
    if (
      element?.type === FormFieldType.TEXT_LARGE ||
      element?.type === FormFieldType.TEXT_MEDIUM
    ) {
      setEditElementType(FormFieldType.TEXT);
    } else if (
      element?.type === FormFieldType.DATETIME ||
      element?.type === FormFieldType.DATE_NO_DAY
    ) {
      setEditElementType(FormFieldType.DATE);
    } else if (
      element?.type === FormFieldType.CHECKBOX ||
      element?.type === FormFieldType.RADIO
    ) {
      setEditElementType(FormFieldType.DROPDOWN);
    } else {
      setEditElementType(element?.type);
    }
    setOpenElementDialog(true);
    setEditElement(element);
  };

  const onDeleteElement = (id: string) => {
    setChangedIds((prevChangedIds) => {
      return [...prevChangedIds, id];
    });
    setElements((prevElements) => {
      return prevElements.map((element) => {
        if (element.id === id) {
          return { ...element, delete: true };
        }
        return element;
      });
    });
  };

  const moveElement = (up: boolean, elementId: any, allTheWay?: boolean) => {
    const newElements = elements.sort((a, b) =>
      a.seq && b.seq ? a.seq - b.seq : 1,
    );
    let finnished = false;
    newElements.forEach((element, index) => {
      const seq: any = newElements[index].seq;
      if (allTheWay && up && element.id !== elementId && !finnished) {
        newElements[index].seq = seq + 1;
      }
      if (allTheWay && !up && element.id !== elementId && finnished) {
        newElements[index].seq = seq - 1;
      }
      if (element.id === elementId) {
        finnished = true;
        const tmpSeq = newElements[index].seq;
        if (up && index > 0) {
          if (allTheWay) {
            newElements[index].seq = 1;
            setChangedIds((prevChangedIds) => {
              return [...prevChangedIds, elementId, newElements[0].id];
            });
            return;
          }
          newElements[index].seq = newElements[index - 1].seq;
          newElements[index - 1].seq = tmpSeq;
          setChangedIds((prevChangedIds) => {
            return [...prevChangedIds, elementId, newElements[index - 1].id];
          });
          return;
        } else if (!up && index + 1 !== newElements.length) {
          if (allTheWay) {
            newElements[index].seq = newElements.length;
            setChangedIds((prevChangedIds) => {
              return [
                ...prevChangedIds,
                elementId,
                newElements[newElements.length - 1].id,
              ];
            });
            return;
          }
          newElements[index].seq = newElements[index + 1].seq;
          newElements[index + 1].seq = tmpSeq;
          setChangedIds((prevChangedIds) => {
            return [...prevChangedIds, elementId, newElements[index + 1].id];
          });
          return;
        }
      }
      return newElements;
    });

    setElements(newElements);
  };

  return (
    <Box>
      <AppBar sx={{ position: 'sticky' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.categoryName}
          </Typography>
          <Tooltip title={context.i18n.saveBtnTooltip}>
            <IconButton
              edge="start"
              color="inherit"
              sx={{ mr: 2 }}
              onClick={() => onSave()}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.cancelBtnTooltip}>
            <IconButton
              sx={{ mr: 2 }}
              edge="start"
              color="inherit"
              onClick={() => onClose()}
            >
              <FontAwesomeIcon icon={faX} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <TextField
          autoFocus
          color="secondary"
          label={context.i18n.name}
          size="medium"
          type={'text'}
          sx={{ mb: 2, ml: 2 }}
          value={elementName}
          error={elementNameError}
          onChange={(e) => {
            setElementNameError(false);
            setElementName(e.currentTarget.value);
          }}
        />
        <FormEditTable
          onOpenPreview={() => setOpenPreviewDialog(true)}
          onAddElement={() => onOpenAddElement()}
          onEditElement={(id) => onEditElement(id)}
          onDeleteElement={(id) => onDeleteElement(id)}
          onMoveElement={(up, id, allTheWay) => moveElement(up, id, allTheWay)}
          updateElements={(
            elements: FrontendFormImportField[],
            changedIds: string[],
          ) => {
            setElements(elements);
            setChangedIds((prevIds) => {
              const addedIds: any = changedIds.map((id) => {
                if (!prevIds.includes(id)) {
                  return id;
                }
              });

              return [...prevIds, ...addedIds];
            });
          }}
          elements={elements}
          formGroup
          multiData={element?.type === FormFieldType.LIST_MULTI_DATA}
        />
      </DialogContent>
      <Dialog
        open={openElementDialog}
        onClose={() => setOpenElementDialog(false)}
      >
        <FormElementDialog
          onClose={() => setOpenElementDialog(false)}
          onSave={(element, createElement) =>
            onSaveElement(element, createElement)
          }
          editElement={editElement}
          editElementType={editElementType}
          inFormGroup
        />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openPreviewDialog}
        onClose={() => setOpenPreviewDialog(false)}
      >
        <FormGroupDialog
          id={element?.id ? element.id : ''}
          elements={elements.map((element) => {
            return {
              id: element.id ? element.id : '',
              seq: element.seq ? element.seq : 0,
              type: element.type ? element.type : 0,
              required: element.required ? element.required : false,
              value: undefined,
              label: element.label,
              fieldOptions: element.fieldOptions,
            };
          })}
          onSave={() => setOpenPreviewDialog(false)}
          onClose={() => setOpenPreviewDialog(false)}
          title={element?.label ? element.label : ''}
          preivew={true}
        />
      </Dialog>
      <Dialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog(false)}
      >
        <DiscardDialog
          onDiscard={() => {
            props.onClose();
            setOpenDiscardDialog(false);
          }}
          onSave={onSave}
          onClose={() => setOpenDiscardDialog(false)}
        />
      </Dialog>
    </Box>
  );
};

export default FormGroupEditDialog;
