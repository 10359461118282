import { CreateNewOrg } from '../../types';
import { axiosInstance } from '../../utils';

export default class OrgApi {
  static create(body: CreateNewOrg): Promise<any> {
    return axiosInstance.post('/organization', body).then((res) => {
      return res.data;
    });
  }

  static getOrganizationData(token: string | undefined) {
    return axiosInstance
      .get('organization/data/', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateOrganizationData(token: string | undefined, body: any) {
    return axiosInstance
      .post('organization/data/', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static uploadLogo(token: string | undefined, logo: File) {
    const formData = new FormData();
    formData.append('logo', logo);

    return axiosInstance
      .post('organization/logo/', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getLogo(token: string | undefined) {
    return axiosInstance
      .get('organization/logo/', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
