import { Dialog, DialogContent } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { SwatchesPicker } from 'react-color';

interface AvatarColorPickerDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (color: string) => void;
}

const AvatarColorPickerDialog: FC<AvatarColorPickerDialogProps> = (props) => {
  const [open, setOpen] = useState(props.open);
  const [color] = useState('');

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogContent>
        <SwatchesPicker
          color={color}
          height={450}
          width={450}
          onChange={(color) => props.onSave(color.hex)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AvatarColorPickerDialog;
