import { faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  IconButton,
} from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../../hooks/context';

interface DeleteCategoryDialogProps {
  onClose: () => void;
  onDelete: () => void;
}

const DeleteCategoryDialog: FC<DeleteCategoryDialogProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Box>
      <DialogTitle>{context.i18n.deleteCategory}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {context.i18n.sureAboutCategoryDeletion}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.delete}>
          <IconButton onClick={props.onDelete.bind(this)} color="error">
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose.bind(this)} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Box>
  );
};

export default DeleteCategoryDialog;
