import { FC, useContext, useEffect } from 'react';
import { ClientTable } from '../../../components/client';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { MenuWrapper } from '../../../components/core';

const ClientList: FC = () => {
  const context = useContext(AppContext);

  useEffect(() => {
    setDocumentTitle(context.i18n.clientList);
    return () => loadTitle();
  }, []);

  return (
    <MenuWrapper
      title={context.i18n.clientManagement}
      selected="clients"
      search
      addClient
    >
      <ClientTable archive={false} />
    </MenuWrapper>
  );
};

export default ClientList;
