import { FC, useContext } from 'react';
import { MenuWrapper } from '../../../components/core';
import { AppContext } from '../../../hooks/context';
import { Box, Typography } from '@mui/material';
import { theme } from '../../../utils';
import { ProductTable } from '../../../components/admin/billing';

export const Products: FC = () => {
  const context = useContext(AppContext);

  return (
    <MenuWrapper
      admin
      search
      addClient
      title={context.i18n.billingDetails}
      selected="admin-billing-products"
      adminBilling
    >
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
      >
        <Box m={2}>
          <Typography
            color={theme.palette.primary.main}
            align="left"
            variant="h5"
            display="flex"
            m={2}
          >
            {context.i18n.contacts}
          </Typography>
          <ProductTable />
        </Box>
      </Box>
    </MenuWrapper>
  );
};
