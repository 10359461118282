import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { NotesApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { NoteType } from '@casecare/types';

interface AvatarNoteDialogProps {
  open: boolean;
  onClose: () => void;
  clientId: string | undefined;
  type: NoteType;
  indexClientId: string | undefined;
  symbolId: string | undefined;
}

const AvatarNoteDialog: FC<AvatarNoteDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useQuery(
    [
      'avatarNotes',
      props.type,
      props.clientId,
      props.indexClientId,
      props.symbolId,
    ],
    () =>
      NotesApi.getNote(
        context.authToken,
        props.type,
        props.clientId,
        props.indexClientId,
        props.type === NoteType.AVATAR_SYMBOL ? props.symbolId : undefined,
      ),
    {
      onSuccess: (res) => {
        setNotes(res.data ? res.data : '');
      },
      enabled: context.authToken !== undefined && open,
    },
  );

  const onSave = async () => {
    const res = await NotesApi.updateNote(
      context.authToken,
      props.type,
      props.clientId,
      notes,
      props.indexClientId,
      props.type === NoteType.AVATAR_SYMBOL ? props.symbolId : undefined,
    );

    if (res.statusCode !== 201) {
      snackbarUtils.error(context.i18n.avatarNoteErrorToast);
    } else if (res.statusCode === 201) {
      snackbarUtils.success(res.message);
      props.onClose();
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={props.onClose}>
      <DialogContent>
        <TextField
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          multiline
          fullWidth
          rows={5}
          label={context.i18n.additionalNotes}
        />
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={onSave} color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarNoteDialog;
