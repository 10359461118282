import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { FormFieldType } from '@casecare/types';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  Tooltip,
  IconButton,
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import todo from '../../../api/planning/todo/todo';
import { FormFieldStyle } from '../../../types/form-element-props';
import { FormElement, UserSelect } from '../../client';
import { SearchBar, UserGroupSelect } from '../../core';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import TodoApi from '../../../api/planning/todo/todo';
import { queryClient } from '../../../utils';

interface AddTeamTodoDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AddTeamTodoDialog: FC<AddTeamTodoDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [todo, setTodo] = useState('');
  const [todoError, setTodoError] = useState(false);
  const [dueDate, setDueDate] = useState(moment());
  const [clientId, setClientId] = useState<string | undefined>();
  const [userGroupIds, setUserGroupIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [userError, setUserError] = useState(false);
  const [synced, setSynced] = useState(false);

  useEffect(() => {
    setTodo('');
    setDueDate(moment());
    setClientId(undefined);
    setUserGroupIds([]);
    setUserIds([]);
    setTodoError(false);
    setUserError(false);
    setSynced(false);
  }, [props.open]);

  const onSave = async () => {
    setTodoError(todo === '');
    setUserError(userGroupIds.length === 0 && userIds.length === 0);

    if (todo === '' || (userGroupIds.length === 0 && userIds.length === 0)) {
      return;
    }
    try {
      const body = Object.assign(
        {
          title: todo,
          dueDate: dueDate.toISOString(),
          userGroupIds,
          userIds,
          synced,
        },
        clientId !== undefined && {
          clientId,
        },
      );

      const res = await TodoApi.saveTeamTodo(context.authToken, body);
      if (res.message) {
        snackbarUtils.success(res.message);
        setClientId(undefined);
      }
      queryClient.invalidateQueries('team-todos');
      props.onClose();
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog fullWidth open={props.open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.addTodo}</DialogTitle>
      <DialogContent>
        <Stack mt={2} spacing={2}>
          <TextField
            value={todo}
            error={todoError}
            onChange={(e) => {
              setTodoError(false);
              setTodo(e.currentTarget.value);
            }}
            label={context.i18n.todoTitle}
          />
          <DateTimePicker
            format="DD.MM.YYYY HH:mm"
            label={context.i18n.todoDueDate}
            minDate={moment()}
            onChange={(v: any) => setDueDate(v)}
            value={dueDate}
            slotProps={{ textField: { fullWidth: true } }}
          />
          <UserSelect
            error={userError}
            setId={(id) => {
              setUserError(false);
              setUserIds(id);
            }}
            multiple
            title={context.i18n.user}
            userIds={userIds}
          />
          <UserGroupSelect
            error={userError}
            setId={(id) => {
              setUserError(false);
              setUserGroupIds(id);
            }}
            userGroupIds={userGroupIds}
            multiple
            title={context.i18n.userGroups}
            helperText={userError ? context.i18n.userOrGroup : ''}
          />
          <SearchBar
            clientId={clientId}
            onSelect={(id) => setClientId(id)}
            clearable
            mediumSize
          />
          <Box>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={synced}
                    onChange={() => setSynced(!synced)}
                  />
                }
                label={`${context.i18n.syncedTodo}?`}
              />
            </FormControl>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={onSave} disableRipple color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
