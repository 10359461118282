import { Sex } from '@casecare/types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faGenderless,
  faMars,
  faTransgender,
  faVenus,
} from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { CSSProperties, FC } from 'react';

interface GenderIconProps {
  sex: Sex;
  color?: string;
  size?: SizeProp;
  id?: string;
  style?: CSSProperties;
}
//TODO: add gender https://fontawesome.com/icons/categories/gender
const GenderIcon: FC<GenderIconProps> = (props) => {
  const gender = () => {
    switch (props.sex) {
      case Sex.TRANS_F || Sex.TRANS_M:
        return <FontAwesomeIcon icon={faTransgender} {...props} />;
      case Sex.MALE:
        return <FontAwesomeIcon icon={faMars} {...props} />;
      case Sex.FEMALE:
        return <FontAwesomeIcon icon={faVenus} {...props} />;
      default:
        return <FontAwesomeIcon icon={faGenderless} {...props} />;
    }
  };
  return gender();
};

export default GenderIcon;
