import { Typography, Box } from '@mui/material';
import { FC, useRef, useState } from 'react';
import {
  Editor,
  BtnBold,
  BtnItalic,
  EditorProvider,
  Toolbar,
  Separator,
  BtnUnderline,
  BtnBulletList,
  BtnNumberedList,
} from 'react-simple-wysiwyg';
import './wysiwyg.css';

type CustomText = { text: string };

interface WysiwygEditorProps {
  value: string;
  onChange: (e: string) => void;
  label?: string;
  disabled?: boolean;
  noDisabledStyle?: boolean;
  onClick?: () => void;
  id?: any;
  endAdornment?: any;
  error?: boolean;
}

export const WysiwygEditor: FC<WysiwygEditorProps> = (props) => {
  const {
    value,
    onChange,
    label,
    disabled,
    onClick,
    id,
    endAdornment,
    noDisabledStyle,
    error,
  } = props;

  return (
    <Box
      id={id}
      className={
        !error
          ? disabled && !noDisabledStyle
            ? 'disabled-editor'
            : ''
          : 'error-editor'
      }
    >
      <EditorProvider>
        {label && <Typography>{label}</Typography>}
        <Box className={endAdornment ? 'editor-ay' : 'editor-an'}>
          <Editor
            onClick={onClick}
            disabled={disabled}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            containerProps={{
              style: {
                fontFamily: 'Roboto',
                minHeight: '10rem',
                fontSize: '1rem',
              },
            }}
          >
            {!disabled && (
              <Box display="flex">
                <Toolbar style={{ flex: 9 }}>
                  <BtnBold />
                  <BtnItalic />
                  <BtnUnderline />
                  <Separator />
                  <BtnBulletList />
                  <BtnNumberedList />
                </Toolbar>
                {endAdornment && (
                  <Toolbar style={{ flex: 0.2 }}>
                    <Box sx={{ alignSelf: 'flex-end' }}>{endAdornment}</Box>
                  </Toolbar>
                )}
              </Box>
            )}
          </Editor>
        </Box>
      </EditorProvider>
    </Box>
  );
};
