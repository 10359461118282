import { NoteType } from '@casecare/types';
import { axiosInstance } from '../../utils';

export default class NotesApi {
  static getNote(
    token: string | undefined,
    type: NoteType | undefined,
    clientId: string | undefined,
    indexClient?: string,
    symbolId = '',
  ): Promise<any> {
    return axiosInstance
      .get(`notes/${clientId}/${type}/${indexClient}/${symbolId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateNote(
    token: string | undefined,
    type: NoteType | undefined,
    clientId: string | undefined,
    value: string,
    indexClient?: string,
    symbolId = '',
  ): Promise<any> {
    return axiosInstance
      .post(
        `notes/${clientId}/${type}/${indexClient}/${symbolId}`,
        {
          value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getAvatarNotes(
    token: string | undefined,
    clientId: string | undefined,
    data: any,
  ): Promise<any> {
    return axiosInstance
      .post(`notes/avatar/${clientId}/`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getAvatarNoteClients(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`notes/avatar/${clientId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
