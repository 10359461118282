import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
} from '@mui/material';
import React, { FC, useContext, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { FormApi } from '../../../../../api';
import { AppContext } from '../../../../../hooks/context';
import { FormElementProps, FormGroupElement } from '../../../../../types';
import { getValueString } from '../../../../../utils';
import snackbarUtils from '../../../../../utils/snackbar/snackbar-utils';
import { FormGroupDialog, DiscardDialog } from '../../../../dialogs';
import FormElementHeader from '../form-element-header/form-element-header';

interface FormGroupProps extends FormElementProps {
  values: FormGroupElement[];
}

const FormGroup: FC<FormGroupProps> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [saved, setSaved] = useState<boolean>();
  const [groupValues, setGroupValues] = useState(props.values);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );
  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    formatValues();
  }, [props.values]);

  const formatValues = () => {
    const formatedValues = props.values.map((data: FormGroupElement) => {
      return { ...data, value: getValueString(data) };
    });

    setGroupValues(formatedValues);
  };

  const onSave = async () => {
    const groupData = context.getAndRemoveGroupData(props.id);
    let errorMessage = '';
    groupData.map((data) => {
      if (data.error !== null) {
        errorMessage += data.error + '\n';
      }
    });
    if (errorMessage === '') {
      if (props.onSave) {
        props.onSave(groupData);
        setOpen(false);
        setDiscard(false);
      } else {
        try {
          if (groupData) {
            const res = await FormApi.updateFormFields(
              context.clientId,
              context.authToken,
              groupData,
            );
            if (res.message) snackbarUtils.success(res.message);
          }

          setSaved(true);
          setOpen(false);
          setDiscard(false);
          setTimeout(() => {
            queryClient.invalidateQueries('formData');
            setSaved(undefined);
          }, 10);
        } catch (e) {
          snackbarUtils.error(context.i18n.error);
        }
      }

      formatValues();
    } else {
      snackbarUtils.error(errorMessage);
    }
  };

  const onDiscard = () => {
    context.removeGroupData(props.id);

    setOpen(false);
    setDiscard(false);
  };

  const onClose = () => {
    if (context.getAndRemoveGroupData(props.id).length > 0) {
      setDiscard(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <Box sx={{ m: 2 }}>
      {props.title && (
        <FormElementHeader
          edit
          onEdit={() => (props.preview || readOnly ? null : setOpen(true))}
          title={props.title}
          required={props.required}
        />
      )}
      <List
        sx={{
          maxHeight: 200,
          overflowY: props.values.length > 3 ? 'scroll' : 'hidden',
        }}
        id={props.id}
      >
        {groupValues.map((data, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    fontWeight="bold"
                    color="primary"
                  >
                    {data.label ? data.label : data.value}
                  </Typography>
                </React.Fragment>
              }
              secondary={data.label ? data.value : null}
            />
          </ListItem>
        ))}
      </List>
      <Dialog
        sx={{ zIndex: 9999 }}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
      >
        <FormGroupDialog
          id={props.id}
          elements={props.values}
          onSave={onSave}
          onClose={onClose}
          title={props.title}
          saved={saved}
        />
      </Dialog>
      <Dialog
        sx={{ zIndex: 9999 }}
        open={discard}
        onClose={() => setDiscard(false)}
      >
        <DiscardDialog
          onDiscard={onDiscard}
          onSave={onSave}
          onClose={() => setDiscard(false)}
        />
      </Dialog>
    </Box>
  );
};

export default FormGroup;
