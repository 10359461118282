import { faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';

interface DeleteRiskAssessmentDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteRiskAssessmentDialog: FC<DeleteRiskAssessmentDialogProps> = (
  props,
) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={open}>
      <DialogTitle>{context.i18n.deleteRiskAssessment}</DialogTitle>
      <DialogContent>
        <Typography>{context.i18n.areYouSure}</Typography>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.delete}>
          <IconButton onClick={props.onDelete} color="error">
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRiskAssessmentDialog;
