import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FC, useContext } from 'react';
import { UserSelect } from '../../client';
import { AppContext } from '../../../hooks/context';
import { UserGroupSelect } from '../../core';

interface TeamTodoListFilterbarProps {
  userIds: string[];
  setUserIds: (userIds: string[]) => void;
  userGroupIds: string[];
  setUserGroupIds: (userGroupIds: string[]) => void;
}

export const TeamTodoListFilterbar: FC<TeamTodoListFilterbarProps> = (
  props,
) => {
  const context = useContext(AppContext);

  const { userIds, userGroupIds, setUserGroupIds, setUserIds } = props;

  return (
    <Box
      display="flex"
      sx={{
        p: 1,
        mt: 1,
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box flex={1} m={2}>
        <UserSelect
          setId={(ids) => setUserIds(ids)}
          title={context.i18n.user}
          multiple
          userIds={userIds}
          error={false}
        />
      </Box>
      <Box flex={1} m={2}>
        <UserGroupSelect
          setId={(ids) => setUserGroupIds(ids)}
          title={context.i18n.userGroups}
          userGroupIds={userGroupIds}
          multiple
          error={false}
        />
      </Box>
      {/* <Box flex={1} m={2}>
      <FormControlLabel
      control={<Checkbox checked={hideFinnished} onChange={(e) => setHideFinnished(e.target.checked)}  />}
      label={context.i18n.hideFinnished}
    />
      </Box> */}
    </Box>
  );
};
