import {
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { RiskApi } from '../../../../api';
import { AppContext } from '../../../../hooks/context';
import { RiskAssessment } from '../../../../types';
import { RiskAssessmentSurvey } from '../../../../types/risk-assesment';
import { parseDateTimeString } from '../../../../utils';
import { getResultTranslation } from '../../../../utils/risk/risk';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { DeleteRiskAssessmentDialog } from '../../../dialogs';
import AddRiskAssessmentDialog from '../../../dialogs/add-risk-assessment-dialog/add-risk-assessment-dialog';

interface RiskAssessmentTableProps {
  openRiskAssessment: (id: string, newRisk?: boolean) => void;
  deleteRiskAssessment: (id: string) => void;
  riskData: RiskAssessmentSurvey[];
}

const RiskAssessmentTable: FC<RiskAssessmentTableProps> = (props) => {
  const context = useContext(AppContext);

  const [riskAssessments, setRiskassessments] = useState<RiskAssessment[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const headerStyle = { fontWeight: 500, fontSize: 20, color: 'priamry' };
  const bodyStyle = { fontSize: 17 };

  useQuery('getRisks', () => RiskApi.getRiskAssessments(context.authToken), {
    onSuccess: async (res) => {
      setRiskassessments(res.data);
    },
    onError: (e) => {
      const err = e as AxiosError;
      const errorData = err.response?.data;
      errorData.detail.forEach((message: string) => {
        snackbarUtils.error(message);
      });
    },
  });

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        borderRadius: 0,
        display: 'flex',
      }}
    >
      <TableContainer sx={{ m: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...headerStyle, minWidth: 150 }} color="primary">
                {context.i18n.name}
              </TableCell>
              <TableCell sx={headerStyle} color="primary">
                {context.i18n.creator}
              </TableCell>
              <TableCell sx={headerStyle} color="primary">
                {context.i18n.status}
              </TableCell>
              <TableCell sx={headerStyle} color="primary">
                {context.i18n.startedAt}
              </TableCell>
              <TableCell sx={headerStyle} color="primary" align="center">
                <Tooltip title={context.i18n.addRiskAssessment}>
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={() => setOpenDialog(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.riskData.map((data) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={data.id}
              >
                <TableCell sx={bodyStyle}>
                  {data.riskAssessments
                    .map((risk) => {
                      return risk.name;
                    })
                    .join(', ')}
                </TableCell>
                <TableCell sx={bodyStyle}>{data.creator}</TableCell>
                <TableCell sx={bodyStyle}>
                  {getResultTranslation(data.result, data.atRiskSubTypes)}
                </TableCell>
                <TableCell sx={bodyStyle}>
                  {parseDateTimeString(data.startDate)}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={context.i18n.editElement}>
                    <IconButton
                      disableRipple
                      color="primary"
                      onClick={() => props.openRiskAssessment(data.id)}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={context.i18n.deleteElement}>
                    <IconButton
                      disableRipple
                      color="error"
                      onClick={() => {
                        setDeleteId(data.id);
                        setOpenDeleteDialog(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteRiskAssessmentDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={() => {
          props.deleteRiskAssessment(deleteId);
          setOpenDeleteDialog(false);
        }}
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <AddRiskAssessmentDialog
          onAdd={(id) => {
            props.openRiskAssessment(id, true);
          }}
          onClose={() => setOpenDialog(false)}
          riskAssessments={riskAssessments}
        />
      </Dialog>
    </Paper>
  );
};

export default RiskAssessmentTable;
