import { ReportFieldType } from '@casecare/types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FC, useEffect, useState } from 'react';

interface ReportFieldProps {
  value: any;
  options: any;
  type: ReportFieldType;
  label: string;
  setValue: (value: any) => void;
}

const ReportField: FC<ReportFieldProps> = (props) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    props.setValue(value);
  }, [value]);

  switch (props.type) {
    case ReportFieldType.DROPDOWN:
      return (
        <FormControl fullWidth color="secondary">
          <InputLabel
            sx={{ alignSelf: 'center' }}
            id="report-field-slect-label"
          >
            {props.label}
          </InputLabel>
          <Select
            fullWidth
            value={value}
            labelId="report-field-select-label"
            label={props.label}
          >
            {props.options.map((t: any) => (
              <MenuItem key={t} value={t} onClick={() => setValue(t)}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
    case ReportFieldType.MONTH:
      return (
        <DatePicker
          format="MM.YYYY"
          views={['year', 'month']}
          label={props.label}
          value={value}
          onChange={(value: any) => setValue(value)}
          slotProps={{ textField: { fullWidth: true } }}
        />
      );
    case ReportFieldType.DATE:
      return (
        <DatePicker
          format="DD.MM.YYYY"
          views={['day', 'year', 'month']}
          label={props.label}
          value={value}
          onChange={(value: any) => setValue(value)}
          slotProps={{ textField: { fullWidth: true } }}
        />
      );
    default:
      return (
        <TextField
          multiline
          InputLabelProps={{ shrink: true }}
          maxRows={7}
          value={value}
          label={props.label}
          onChange={(e) => setValue(e.target.value)}
        />
      );
  }
};

export default ReportField;
