import { Autocomplete, Box, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FC, useContext } from 'react';
import { UserSelect } from '../../client';
import { AppContext } from '../../../hooks/context';
import { ProtocolBillingMenu } from './protocol-billing-menu';
import { ReleaseProtocol } from '../../../types';
import { CreateInvoiceDialog } from '../../dialogs';

interface ProtocolBillingTableFilterToolbarProps {
  date: moment.Moment;
  setDate: (date: moment.Moment) => void;
  setUserIds: (ids: string[]) => void;
  rejected: boolean;
  released: boolean;
  selectedRows: ReleaseProtocol[];
  clearSelectedRows: () => void;
  editOpen?: boolean;
  setEditOpen?: (v: any) => void;
  selectedOrderers: { id: string; name: string }[];
  setSelectedOrderers: (v: { id: string; name: string }[]) => void;
  availableOrderers: { id: string; name: string }[];
}

export const ProtocolBillingTableFilterToolbar: FC<
  ProtocolBillingTableFilterToolbarProps
> = (props) => {
  const context = useContext(AppContext);

  const {
    date,
    setDate,
    setUserIds,
    rejected,
    released,
    selectedRows,
    clearSelectedRows,
    selectedOrderers,
    setSelectedOrderers,
    availableOrderers,
  } = props;

  return (
    <Box
      display="flex"
      sx={{
        p: 1,
        mt: 1,
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        display="flex"
        sx={{
          width: '70%',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        {(props.rejected || props.released) && (
          <Box
            sx={{
              width: '22rem',
              flex: 1,
              mr: { sm: 5 },
              mt: { xs: 2, sm: 0 },
            }}
          >
            <DateTimePicker
              label={context.i18n.protocolTime}
              onChange={(v: any) => {
                setDate(v.clone().endOf('month'));
              }}
              value={date}
              views={['month']}
              format="MMMM"
              slotProps={{ textField: { fullWidth: true, placeholder: '' } }}
            />
          </Box>
        )}
        <Box
          sx={{
            width: '22rem',
            flex: 1,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <UserSelect
            setId={(ids) => setUserIds(ids)}
            title={context.i18n.supervisor}
            multiple
            error={false}
          />
        </Box>
        <Box
          sx={{
            width: '22rem',
            flex: 1,
            mr: { sm: 5 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Autocomplete
            disableClearable={selectedOrderers.length === 0}
            onChange={(e, options: any) => {
              setSelectedOrderers(options);
            }}
            multiple
            disableCloseOnSelect={true}
            value={selectedOrderers}
            noOptionsText={context.i18n.noUsers}
            getOptionLabel={(option: any) => option.name || ''}
            renderInput={(params) => (
              <TextField {...params} label={context.i18n.orderer} />
            )}
            options={availableOrderers}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: 'inline-block',
        }}
      >
        <ProtocolBillingMenu
          anchorEl={this}
          open={selectedRows.length > 0}
          onClose={() => {
            clearSelectedRows();
          }}
          selectedProtocols={selectedRows}
          rejected={rejected}
          released={released}
          selectedMonth={date}
          isToolbar
          editOpen={props.editOpen}
          setEditOpen={props.setEditOpen}
          availableOrderers={availableOrderers}
        />
      </Box>
    </Box>
  );
};
