import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { FamilyMember } from '../../../types';

interface TransferFamilyDataDialogProps {
  open: boolean;
  onClose: () => void;
  onTransfer: (transferAll: boolean, clientIds?: string[]) => void;
  familyMembers: FamilyMember[];
}

const TransferFamilyDataDialog: FC<TransferFamilyDataDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [transferSpecific, setTranfersSpecific] = useState(false);
  const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>([]);
  const [clientIds, setClientsIds] = useState<string[]>([]);

  useEffect(() => {
    setFamilyMembers(props.familyMembers);
  }, [props.familyMembers]);

  useEffect(() => {
    setOpen(props.open);
    setTranfersSpecific(false);
  }, [props.open]);

  return (
    <Dialog open={open}>
      <DialogTitle>{context.i18n.transferFamilyData}</DialogTitle>
      <DialogContent>
        <Typography>{context.i18n.transferFamilyDataQuestion}</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={transferSpecific}
                onClick={() => setTranfersSpecific(!transferSpecific)}
              />
            }
            label={context.i18n.selectTransferFamilyMembers}
          />
        </FormGroup>
        <Autocomplete
          options={familyMembers}
          multiple
          onChange={(evetn, option) =>
            setClientsIds(option.map((option) => option.id))
          }
          getOptionLabel={(option: any) => option.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                height: 30,
                mb: 2,
                display: transferSpecific ? 'inherit' : 'none',
              }}
              placeholder={context.i18n.familyMembers}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          color="primary"
          onClick={() => props.onTransfer(!transferSpecific, clientIds)}
        >
          <Typography>{context.i18n.yes}</Typography>
        </Button>
        <Button disableRipple color="primary" onClick={props.onClose}>
          <Typography>{context.i18n.no}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferFamilyDataDialog;
