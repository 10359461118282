import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClientApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { queryClient } from '../../../utils';

interface AddSupervisorDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  addedSupervisorId?: string;
}

const AddSupervisorDialog: FC<AddSupervisorDialogProps> = (props) => {
  const context = useContext(AppContext);

  const onAdd = async () => {
    const res = await ClientApi.addSupervisor(
      context.authToken,
      context.clientId,
      props.addedSupervisorId,
    );

    if (res.message) snackbarUtils.success(res.message);

    queryClient.invalidateQueries('clientData');

    props.onClose();
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>{context.i18n.addSupervisorQuestion}</DialogTitle>
      <DialogContent>
        <DialogContentText>{context.i18n.addSupervisorText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.addSupervisor}>
          <IconButton disableRipple onClick={onAdd} color="primary">
            <FontAwesomeIcon icon={faCheck} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton disableRipple onClick={props.onCancel} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddSupervisorDialog;
