import background from '../../assets/background_login.png';

export const LoginBackgroundImage = {
  // backgroundImage: `url(${background})`,
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'contain',
  // backgroundPosition: 'bottom right',
  height: '100%',
  width: '100%',
};

export const LoginBackgroundWave = {
  // backgroundImage: `url(${loginWave})`,
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'contain',
  // backgroundPosition: 'bottom right',
  width: '100%',
  height: '94%',
};

export const BackgroundWave = {
  // backgroundImage: `url(${backgroundWave})`,
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: '30%',
  // backgroundAttachment: 'scroll',
  // backgroundPosition: 'right bottom',
  width: '100%',
  height: '100%',
};
