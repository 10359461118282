import { FrontendFormImportField, FormFieldType } from '@casecare/types';
import { Box, CircularProgress, Dialog } from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState, useEffect } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AdminApi } from '../../../api';
import {
  AdminDrawer,
  FormEditTable,
  FormPreview,
} from '../../../components/admin';
import { MenuWrapper, Portal } from '../../../components/core';
import Navbar from '../../../components/core/navbar/navbar';
import {
  FormElementDialog,
  FormGroupEditDialog,
  FormListEditDialog,
} from '../../../components/dialogs';
import { AppContext } from '../../../hooks/context';
import { CategoryData } from '../../../types';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { BackgroundWave } from '../../../utils/background/background';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { BreadCrumbSegment } from '../../../types/breadcrumb-segment';

const FormEdit: FC = () => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const { categoryId, subCategoryId } = useParams();
  const [openElementDialog, setOpenElementDialog] = useState(false);
  const [elements, setElements] = useState<FrontendFormImportField[]>([]);
  const [editElement, setEditElement] = useState<FrontendFormImportField>();
  const [editElementType, setEditElementType] = useState<FormFieldType>();
  const [showPreview, setShowPreview] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');
  const [openFormGroupEditDialog, setOpenFormGroupEditDialog] = useState(false);
  const [openFormListEditDialog, setOpenFormListEditDialog] = useState(false);

  const uploadChanges = async (
    changedElements: FrontendFormImportField[],
    showSuccess: boolean,
  ): Promise<string | undefined> => {
    try {
      const res = await AdminApi.updateCategoryFormData(
        subCategoryId,
        changedElements,
        context.authToken,
      );

      if (res.data.errors.length > 0) {
        res.data.errors.forEach((error: any) => {
          console.error(error.message);
          snackbarUtils.error(error.message);
          throw new Error('creationError');
        });
      } else if (showSuccess) {
        snackbarUtils.success(context.i18n.categorySavedSuccessfully);
      } else {
        if (res.data.created[0]) {
          return res.data.created[0];
        } else {
          return res.data.updated[0];
        }
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e.message);
    }
  };

  useEffect(() => {
    setDocumentTitle(context.i18n.manageForm);
    return () => loadTitle();
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries('adminFormData');
  }, [subCategoryId]);

  const { data, status } = useQuery(
    ['adminFormData', subCategoryId],
    () => AdminApi.getCategoryData(subCategoryId, context.authToken),
    {
      enabled: subCategoryId !== undefined,
      onSuccess: (res) => {
        console.log(res);
        setElements(res.data.fields);
        setCategoryName(res.data.parentlabel);
        setSubCategoryName(res.data.categoryLabel);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useEffect(() => {
    // custom breadcrumb //
    const breadCrumb: BreadCrumbSegment[] = [];
    breadCrumb.push({
      path: '/admin',
      name: context.i18n.siteTitleAdmin,
    });
    breadCrumb.push({
      path: '/admin/categories',
      name: context.i18n.siteTitleAdminCategories,
    });
    breadCrumb.push({
      path: `/admin/categories`,
      name: categoryName,
    });
    breadCrumb.push({
      path: `/admin/categories/${subCategoryId}`,
      name: subCategoryName,
    });

    context.setBreadCrumbSegments(breadCrumb);
  }, [categoryName, subCategoryName]);

  const onOpenAddElement = () => {
    setEditElement(undefined);
    setEditElementType(undefined);
    setOpenElementDialog(true);
  };

  const onEditElement = (id: string) => {
    const element = elements.find(
      (element: FrontendFormImportField) => element.id === id,
    );
    if (
      element?.type === FormFieldType.TEXT_LARGE ||
      element?.type === FormFieldType.TEXT_MEDIUM
    ) {
      setEditElementType(FormFieldType.TEXT);
    } else if (
      element?.type === FormFieldType.DATETIME ||
      element?.type === FormFieldType.DATE_NO_DAY
    ) {
      setEditElementType(FormFieldType.DATE);
    } else if (
      element?.type === FormFieldType.CHECKBOX ||
      element?.type === FormFieldType.RADIO
    ) {
      setEditElementType(FormFieldType.DROPDOWN);
    } else if (
      element?.type === FormFieldType.FORM_GROUP ||
      element?.type === FormFieldType.LIST_MULTI_DATA
    ) {
      setEditElement(element);
      setOpenFormGroupEditDialog(true);
      return;
    } else if (element?.type === FormFieldType.LIST) {
      setEditElement(element);
      setOpenFormListEditDialog(true);
      return;
    } else {
      setEditElementType(element?.type);
    }
    setOpenElementDialog(true);
    setEditElement(element);
  };

  const onDeleteElement = async (id: string) => {
    const deleteElement = elements.find((element) => element.id === id)!;
    await uploadChanges([{ ...deleteElement, delete: true }], false);
    queryClient.invalidateQueries('adminFormData');
  };

  const onSaveElement = async (newElement: FrontendFormImportField) => {
    setOpenElementDialog(false);
    setOpenFormGroupEditDialog(false);
    setOpenFormListEditDialog(false);
    await uploadChanges([newElement], true);
    queryClient.invalidateQueries('adminFormData');
  };

  const moveElement = async (
    up: boolean,
    elementId: any,
    allTheWay?: boolean,
  ) => {
    const newElements = elements.sort((a, b) =>
      a.seq && b.seq ? a.seq - b.seq : 1,
    );
    const updatedElements: FrontendFormImportField[] = [];
    newElements.map((element, index) => {
      if (element.id === elementId) {
        const tmpSeq = newElements[index].seq;
        if (up && index > 0) {
          if (allTheWay) {
            newElements[index].seq = -1;
            updatedElements.push(newElements[index]);
            return;
          }
          newElements[index].seq = newElements[index - 1].seq;
          newElements[index - 1].seq = tmpSeq;
          updatedElements.push(newElements[index]);
          updatedElements.push(newElements[index - 1]);
          return;
        } else if (!up && index + 1 !== newElements.length) {
          if (allTheWay) {
            newElements[index].seq = newElements.length + 1;
            updatedElements.push(newElements[index]);
            return;
          }
          newElements[index].seq = newElements[index + 1].seq;
          newElements[index + 1].seq = tmpSeq;
          updatedElements.push(newElements[index]);
          updatedElements.push(newElements[index + 1]);
          return;
        }
      }
    });
    await uploadChanges(updatedElements, false);
    queryClient.invalidateQueries('adminFormData');
  };

  return (
    <MenuWrapper
      selected={subCategoryId}
      admin
      title={context.i18n.editFormFields}
      subTitle={status === 'success' ? data.data.categoryLabel : ''}
      showCategories
      customBreadCrumb
    >
      {status === 'loading' || status === 'error' ? (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : (
        <FormEditTable
          onOpenPreview={() => setShowPreview(!showPreview)}
          onAddElement={() => onOpenAddElement()}
          onEditElement={(id) => onEditElement(id)}
          onDeleteElement={(id) => onDeleteElement(id)}
          onMoveElement={(up, id, allTheWay) => moveElement(up, id, allTheWay)}
          elements={elements}
        />
      )}
      <Dialog
        open={openElementDialog}
        onClose={() => setOpenElementDialog(false)}
      >
        <FormElementDialog
          onClose={() => setOpenElementDialog(false)}
          onSave={(element) => onSaveElement(element)}
          editElement={editElement}
          editElementType={editElementType}
          onFormGroupSelect={(name) => {
            setOpenElementDialog(false);
            setOpenFormGroupEditDialog(true);
            setEditElement({
              label: name,
              fieldOptions: [],
              new: true,
              seq: elements.length + 1,
              type: FormFieldType.FORM_GROUP,
            });
          }}
          onListMultidataSelect={(name) => {
            setOpenElementDialog(false);
            setOpenFormGroupEditDialog(true);
            setEditElement({
              label: name,
              fieldOptions: [],
              new: true,
              seq: elements.length + 1,
              type: FormFieldType.LIST_MULTI_DATA,
            });
          }}
          onListSelect={(name) => {
            setOpenElementDialog(false);
            setOpenFormListEditDialog(true);
            setEditElement({
              label: name,
              fieldOptions: [],
              new: true,
              seq: elements.length + 1,
              type: FormFieldType.LIST,
            });
          }}
        />
      </Dialog>
      <Dialog open={openFormGroupEditDialog} fullScreen>
        <FormGroupEditDialog
          onClose={() => setOpenFormGroupEditDialog(false)}
          onSave={(element) => onSaveElement(element)}
          element={editElement}
          categoryName={status === 'success' ? data.data.categoryLabel : ''}
        />
      </Dialog>
      <FormListEditDialog
        open={openFormListEditDialog}
        element={editElement}
        categoryName={status === 'success' ? data.data.categoryLabel : ''}
        onClose={() => setOpenFormListEditDialog(false)}
        onSave={(element) => onSaveElement(element)}
      />
      {showPreview && (
        <Portal onClose={() => setShowPreview(false)}>
          <FormPreview elements={elements} />
        </Portal>
      )}
    </MenuWrapper>
  );
};

export default FormEdit;
