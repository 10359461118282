import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { RiskApi } from '../../../api';
import { AppContext } from '../../../hooks/context';

interface AddRiskAssessmentDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (id: string) => void;
  risks: { name: string; id: string }[];
  surveyId: string;
}

const AddRiskSubtypeDialog: FC<AddRiskAssessmentDialogProps> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');

  const onAdd = async (id: string) => {
    await RiskApi.addRiskSubtype(context.authToken, id, props.surveyId);
    queryClient.invalidateQueries('risk');
    props.onAdd(id);
    props.onClose();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogContent>
        <FormControl
          color="secondary"
          sx={{
            mt: 2,
            width: '22rem',
          }}
        >
          <InputLabel
            color="secondary"
            sx={{ alignSelf: 'center' }}
            id="risk-assessment-label"
          >
            {context.i18n.riskAssessmentSubtype}
          </InputLabel>
          <Select
            labelId="risk-assessment-slect-label"
            id="risk-assessment-select"
            label={context.i18n.riskAssessmentSubtype}
            value={selected}
          >
            {props.risks.map((risk) => (
              <MenuItem
                value={risk.id}
                key={risk.id}
                onClick={() => {
                  setSelected(risk.id);
                  onAdd(risk.id);
                }}
              >
                {risk.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default AddRiskSubtypeDialog;
