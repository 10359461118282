import { axiosInstance } from '../../utils';

export default class ExportApi {
  static clientExport(
    token: string | undefined,
    clientId: string | undefined,
    clientExportType: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`export/client/${clientId}/${clientExportType}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
      .then((res) => {
        return res;
      });
  }
}
