import { faCheck, faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement } from '../../client';
import { FormFieldType } from '@casecare/types';
import { EcoMapAddDialogType } from '../../../types/eco-map-add-dialog-type';
import EcoMapApi from '../../../api/eco-map/eco-map';
import { StorageApi } from '../../../api';
import { StorageItem } from '../../../types/storage-item';

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  content: any;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog fullWidth open={open} onClose={props.onCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.confirm}>
          <span>
            <IconButton onClick={props.onConfirm} disableRipple color="primary">
              <FontAwesomeIcon icon={faCheck} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.cancel}>
          <IconButton onClick={props.onCancel} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
