import {
  faFileExport,
  faFloppyDisk,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { Protocol } from '../../../types';
import { ProtocolFields } from '../../client/documentation';

interface EditProtocolDialogProps {
  open: boolean;
  onSave?: () => void;
  onClose: () => void;
  protocol?: Protocol;
  familyId?: string;
  clientId?: string;
  fullScreen?: boolean;
  isEdit?: boolean;
  loading?: boolean;
  showReleaseButton?: boolean;
  onRelease?: () => void;
  submitOnSaveEnabled?: boolean;
  adminView?: boolean;
  released?: boolean;
}

const EditProtocolDialog: FC<EditProtocolDialogProps> = (props) => {
  const context = useContext(AppContext);

  const { loading, showReleaseButton } = props;

  const [open, setOpen] = useState(props.open);
  const [save, setSave] = useState(false);
  const [submitOnSave, setSubmitOnSave] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (save) {
      setSave(false);
    }
  }, [save]);

  useEffect(() => {
    if (submitOnSave) {
      setSubmitOnSave(false);
    }
  }, [submitOnSave]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth
      open={open}
      onClose={props.onClose}
    >
      <DialogTitle>{context.i18n.editProtocol}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <ProtocolFields
            disabled={
              (props.protocol?.inReview && !props.adminView) ||
              (props.released && !props.adminView)
            }
            save={save}
            onDisableSave={setDisableSave}
            submitOnSave={submitOnSave}
            {...props}
          />
        )}
      </DialogContent>
      <DialogActions>
        {showReleaseButton &&
          (props.submitOnSaveEnabled || props.onRelease) &&
          !props.protocol?.isReleased && (
            <Tooltip
              title={
                props.submitOnSaveEnabled
                  ? context.i18n.saveAndSubmitBtnTooltip
                  : context.i18n.saveAndReleaseBtnTooltip
              }
            >
              <>
                <IconButton
                  onClick={() => {
                    if (props.submitOnSaveEnabled) {
                      setSubmitOnSave(true);
                    } else {
                      setSave(true);
                      if (props.onRelease) props.onRelease();
                    }
                  }}
                  disableRipple
                  disabled={
                    (props.protocol?.inReview && !props.adminView) ||
                    (props.protocol?.isReleased && !props.adminView) ||
                    disableSave ||
                    loading
                  }
                  color="primary"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                </IconButton>
              </>
            </Tooltip>
          )}
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <>
            <IconButton
              onClick={() => {
                setSave(true);
              }}
              disableRipple
              disabled={
                (props.protocol?.inReview && !props.adminView) ||
                (props.protocol?.isReleased && !props.adminView) ||
                disableSave ||
                loading
              }
              color="primary"
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default EditProtocolDialog;
