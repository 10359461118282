import { faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import PermissionsApi from '../../../api/permissions/permissions';

interface RemovePermissionDialogProps {
  open: boolean;
  user: any;
  clientId: string | undefined;
  familyId: string | undefined;
  onClose: () => void;
}

const RemovePermissionDialog: FC<RemovePermissionDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onDelete = async () => {
    try {
      let res;

      if (props.clientId) {
        res = await PermissionsApi.removeClientPermission(
          context.authToken,
          props.clientId,
          { userId: props.user.id },
        );
      } else if (props.familyId) {
        res = await PermissionsApi.removeFamilyPermission(
          context.authToken,
          props.familyId,
          { userId: props.user.id },
        );
      }

      if (res.message) {
        snackbarUtils.success(res.message);
      }
      props.onClose();
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.removePermission}</DialogTitle>
      <DialogContent>
        <Typography>
          {props.user ? `${props.user.firstName} ${props.user.lastName}` : ''}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.removePermission}>
          <IconButton onClick={onDelete} disableRipple color="primary">
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton
            onClick={() => {
              props.onClose();
            }}
            disableRipple
          >
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default RemovePermissionDialog;
