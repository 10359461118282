import {
  faCheck,
  faFloppyDisk,
  faTrash,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement } from '../../client';
import { FormFieldType } from '@casecare/types';
import { EcoMapAddDialogType } from '../../../types/eco-map-add-dialog-type';
import EcoMapApi from '../../../api/eco-map/eco-map';
import { StorageApi } from '../../../api';
import { StorageItem } from '../../../types/storage-item';

interface DeleteDialogPorps {
  open: boolean;
  title?: string;
  content?: any;
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteDialog: FC<DeleteDialogPorps> = (props) => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog fullWidth open={open} onClose={props.onCancel}>
      <DialogTitle>{props.title ?? context.i18n.deleteDialogTitle}</DialogTitle>
      <DialogContent>
        {props.content ?? (
          <Typography>{context.i18n.deleteDialogContent}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.delete}>
          <span>
            <IconButton onClick={props.onDelete} disableRipple color="error">
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.cancel}>
          <IconButton onClick={props.onCancel} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
