import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ReportApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { UserSelect } from '../../client';
import { ReportField, ReportSelect } from '../../client/documentation';

interface AddReportDialogProps {
  open: boolean;
  onClose: () => void;
  supervisorId: string | undefined;
  reportId: string | undefined;
}

const AddReportDialog: FC<AddReportDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [supervisorId, setSupervisorId] = useState(props.supervisorId);
  const [templateId, setTemplateId] = useState('');
  const [reportFields, setReportFields] = useState([]);
  const [reportValues, setReportValues] = useState<Record<string, string>>({});
  const [saveReady, setSaveReady] = useState(false);

  useEffect(() => {
    setSupervisorId(props.supervisorId);
  }, [props.supervisorId]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    return () => {
      if (!open) {
        setTemplateId('');
        setReportFields([]);
      } else {
        queryClient.invalidateQueries(['reportFields', 'getReport']);
      }
    };
  }, [open]);

  useEffect(() => {
    let fieldsFilledOut = true;

    for (const key in reportValues) {
      if (reportValues[key] === null) {
        fieldsFilledOut = false;
      }
    }

    setSaveReady(
      supervisorId !== undefined &&
        templateId !== undefined &&
        Object.keys(reportValues).length === reportFields.length &&
        fieldsFilledOut,
    );
  }, [templateId, supervisorId, reportValues]);

  useQuery(
    ['reportFields', templateId],
    () => ReportApi.listReportFields(context.authToken, templateId),
    {
      onSuccess: (res) => {
        setReportValues({});
        setReportFields(res.data);
      },
      enabled:
        context.authToken !== undefined &&
        templateId !== '' &&
        props.reportId === undefined,
    },
  );

  useQuery(
    ['getReport', props.reportId],
    () => ReportApi.getReport(context.authToken, props.reportId),
    {
      onSuccess: (res) => {
        setReportFields(res.data.fields);
        setTemplateId(res.data.templateId);
        setSupervisorId(res.data.supervisorId);
      },
      enabled: context.authToken !== undefined && props.reportId !== undefined,
    },
  );

  const onSave = async () => {
    const body = Object.assign(
      {
        userId: supervisorId,
        clientId: context.clientId,
        templateId,
        values: reportValues,
      },
      props.reportId && {
        reportId: props.reportId,
      },
    );

    try {
      const res = await ReportApi.updateReport(context.authToken, body);

      if (res.message) {
        snackbarUtils.success(res.message);
      }
      props.onClose();
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog open={open} onClose={props.onClose} fullWidth>
      <DialogTitle>{context.i18n.addReport}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <UserSelect
            error={false}
            setId={(id) => setSupervisorId(id)}
            title={context.i18n.supervisor}
            userId={supervisorId}
          />
          <ReportSelect
            disabled={props.reportId !== undefined}
            templateId={templateId}
            setReportId={(id) => setTemplateId(id)}
          />

          {reportFields.map((field: any) => (
            <ReportField
              key={field.id}
              {...field}
              setValue={(v) =>
                setReportValues((values) => {
                  return {
                    ...values,
                    [field.id]: v,
                  };
                })
              }
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        {/* disable until data is filled out */}
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton
            disabled={!saveReady}
            onClick={onSave}
            disableRipple
            color="primary"
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddReportDialog;
