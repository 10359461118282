import { Theme } from '@emotion/react';
import { faMagnifyingGlass, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SxProps,
  Autocomplete,
  TextField,
  InputAdornment,
  AutocompletePropsSizeOverrides,
} from '@mui/material';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ClientApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { Client, ClientTabType, FamilyTabType } from '../../../types';
import { theme } from '../../../utils';

interface SearchbarProps {
  sx?: SxProps<Theme>;
  focus?: boolean;
  onSelect?: (id: string | undefined) => void;
  clearable?: boolean;
  mediumSize?: boolean;
  clientId?: string;
}

const SearchBar: FC<SearchbarProps> = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [searchFieldKey, setSearchFieldKey] = useState<string>('search-field');
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<Client[]>([]);
  const searchRef = useRef<any>(null);

  const [client, setClient] = useState<Client | any>({});

  const { data, error, isLoading } = useQuery({
    queryKey: ['clientSearch', searchText],
    queryFn: () => {
      return ClientApi.listClientFamilyCollection(context.authToken, {
        searchText,
        minimal: true,
      });
    },
    enabled: context.authToken !== undefined && searchText.length >= 3,
  });

  // useQuery({
  //   queryKey: ['listSelectedClient', props.clientId],
  //   queryFn: () => {
  //     return ClientApi.listClients(context.authToken, {
  //       clientId: props.clientId,
  //     });
  //   },
  //   enabled: context.authToken !== undefined && props.clientId !== undefined,
  //   onSuccess: (res) => {
  //     setClient(res.data.clients[0]);
  //   },
  // });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error(error);
      } else if (data && data.data) {
        setSearchResults(data.data.collection);
      }
    }
  }, [data, error, isLoading]);

  useEffect(() => {
    if (searchRef.current && props.focus) {
      searchRef.current.focus();
    }
  }, [props.focus]);

  const handleSearchResultSelection = (
    event: React.SyntheticEvent,
    option: {
      id: string;
      name: string;
      isFamily: boolean;
    } | null,
  ) => {
    if (props.onSelect) {
      props.onSelect(option ? option.id : undefined);
    } else {
      if (!option) return;
      if (option.isFamily) {
        navigate(`/families/${option.id}/${FamilyTabType.FAMILY_DATA}`);
      } else {
        navigate(`/clients/${option.id}/${ClientTabType.DATA}`);
      }
      window.location.reload();
      // Set to trigger re-render with empty value
      setSearchFieldKey(option.id);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value.length <= 3 && searchResults.length > 0) {
      setSearchResults([]);
    }
  };

  return (
    <Autocomplete
      disabled={!context.basicActive}
      key={searchFieldKey}
      options={searchResults}
      value={client}
      getOptionLabel={(option) => option.name || ''}
      fullWidth
      sx={Object.assign(
        {
          backgroundColor: 'white',
          borderRadius: '4px',
          '& .MuiButtonBase-root': {
            color: theme.palette.secondary.main,
          },
        },
        props.sx || {},
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      onChange={(event, option) => handleSearchResultSelection(event, option)}
      onBlur={() => setSearchResults([])}
      clearOnBlur={true}
      disableClearable={!props.clearable}
      size={props.mediumSize ? 'medium' : 'small'}
      id="client-search-bar"
      noOptionsText={
        searchText.length < 3
          ? context.i18n.noOptions
          : isLoading
          ? context.i18n.loadingResults
          : context.i18n.noResults
      }
      color="secondary"
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={searchRef}
          color="secondary"
          placeholder={context.i18n.clientSearch}
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                sx={{ color: theme.palette.secondary.main }}
                position="start"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchBar;
