import {
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface PaymentDialogProps {
  onClose: () => void;
}

const PaymentDialog: FC<PaymentDialogProps> = (props) => {
  const context = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();

  const onPayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin.toString()}/login/`,
      },
      redirect: 'if_required',
    });

    if (result.error && result.error.message) {
      // Show error to your customer (for example, payment details incomplete)
      snackbarUtils.error(result.error.message);
    } else {
      snackbarUtils.success(context.i18n.subscriptionSucceded);
      props.onClose();
    }
  };

  return (
    <Dialog fullWidth open>
      <DialogContent>
        <Box>
          <PaymentElement />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onPayment()} disabled={!stripe}>
          {context.i18n.pay}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
