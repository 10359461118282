import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { FC, useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';
import { FormOptions, FormElementProps } from '../../../../../types';
import FormElementHeader from '../form-element-header/form-element-header';
import { FormGroupStyle } from '../../../../../utils/styling/styling';

interface CheckBoxOption extends FormOptions {
  checked?: boolean;
}

interface FormCheckBoxProps extends FormElementProps {
  options: CheckBoxOption[];
  value: (number | string)[];
}

const FormCheckBox: FC<FormCheckBoxProps> = (props) => {
  const [error, setError] = useState<string | null>(null);

  const [save, setSave] = useState(false);
  const [selected, setSelected] = useState<(number | string)[]>(
    props.value ? props.value.slice() : [],
  );
  const [options, setOptions] = useState(
    props.options.map((option) => {
      return {
        ...option,
        checked: props.value ? props.value.includes(option.key) : false,
      };
    }),
  );
  const context = useContext(AppContext);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  const checkErrors = (value: any[]) => {
    let currentError = null;
    if (value.length <= 0 && props.required)
      currentError = context.i18n.inputRequiredError;
    setError(currentError);
    return currentError;
  };

  useEffect(() => {
    checkErrors(selected);
  }, []);

  useEffect(() => {
    setSelected(props.value ? props.value.slice() : []);
    setOptions(
      props.options.map((option) => {
        return {
          ...option,
          checked: props.value ? props.value.includes(option.key) : false,
        };
      }),
    );
  }, [props.value]);

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    props.saved && onSave();
    props.saved ? setSave(!props.saved) : null;
    context.saveAll ? setSave(!context.saveAll) : null;
  }, [props.saved, context.saveAll]);

  const onSave = () => {
    if (error !== null) return;
    props.onSave ? props.onSave(selected) : null;
    setSave(false);
  };

  const onCancel = () => {
    setSelected(props.value ? props.value.slice() : []);
    setOptions(
      options.map((option) => {
        return {
          ...option,
          checked: props.value ? props.value.includes(option.key) : false,
        };
      }),
    );
    setSave(false);
    props.onCancel ? props.onCancel() : null;
  };

  const onChange = (newKey: number | string) => {
    if (!readOnly) {
      setSave(true);
      // Necessary to use a separate variable because using state doesn't work because of the state taking too long to update
      let newSelected = selected;

      if (newSelected?.includes(newKey)) {
        newSelected = selected.filter((key) => key !== newKey);
      } else {
        newSelected?.push(newKey);
      }

      setOptions(
        options.map((option) => {
          return { ...option, checked: newSelected.includes(option.key) };
        }),
      );
      setSelected(newSelected);
      props.onChange && props.onChange(newSelected, checkErrors(newSelected));
    }
  };

  return (
    <Box sx={{ m: 2 }}>
      {props.title && (
        <FormElementHeader
          save={props.dialog || props.preview || readOnly ? false : save}
          onSave={onSave}
          onCancel={onCancel}
          hideExport={props.hideExport}
          title={props.title}
          required={props.required}
        />
      )}

      <FormControl error={error !== null && true} required={true}>
        <FormGroup row={true} id={props.id} sx={FormGroupStyle}>
          {options.map((value) => (
            <FormControlLabel
              key={value.key}
              control={
                <Checkbox
                  id={props.id + '-' + value.key}
                  checked={value.checked}
                  onChange={() => onChange(value.key)}
                />
              }
              label={value.label}
            />
          ))}
        </FormGroup>
        <FormHelperText error>{error && error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default FormCheckBox;
