import { ReferencePersonRole, RelationshipType } from '@casecare/types';
import { Paper } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { ClientApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AddFamilyMemberDialog, CreateClientDialog } from '../../dialogs';
import { FamilyMemberTable } from '../../family';

interface ClientReferencePersonTableProps {
  referencePersons: any[];
  family: any;
}

const ClientReferencePersonTable: FC<ClientReferencePersonTableProps> = (
  props,
) => {
  const context = useContext(AppContext);

  const [referencePersons, setReferencePersons] = useState<any[]>([]);
  const [family, setFamily] = useState<any>(undefined);
  const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
  const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
  const [clientId, setClientId] = useState();

  useEffect(() => {
    setFamily(props.family);
  }, [props.family]);

  useEffect(() => {
    setReferencePersons(props.referencePersons);
  }, [props.referencePersons]);

  const addRelation = async (id: any, type: RelationshipType) => {
    try {
      await ClientApi.updateClientRelation(
        context.authToken,
        context.clientId,
        id,
        type,
      );
      queryClient.invalidateQueries('clientData');
    } catch (e) {
      snackbarUtils.error(context.i18n.error);
    }
  };

  const addRole = async (id: string, role: ReferencePersonRole) => {
    await ClientApi.updateClientRole(context.authToken, id, role);
    queryClient.invalidateQueries('clientData');
  };

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
      }}
    >
      <FamilyMemberTable
        isClientTable
        familyMembers={referencePersons}
        isReferencePersonTable={true}
        onCreateClient={() => {
          setClientId(undefined);
          setCreateClientDialogOpen(true);
        }}
        onAddClient={() => {
          setAddClientDialogOpen(true);
        }}
        onOpenClientDialog={(id) => {
          setClientId(id);
          setCreateClientDialogOpen(true);
        }}
        onAddRelation={addRelation}
        onAddRole={addRole}
        onArchiveClient={() => queryClient.invalidateQueries('clientData')}
      />
      {family && (
        <AddFamilyMemberDialog
          open={addClientDialogOpen}
          onClose={() => setAddClientDialogOpen(false)}
          familyId={family.id}
          onAddClient={() => {
            setAddClientDialogOpen(false);
            queryClient.invalidateQueries('clientData');
          }}
        />
      )}
      <CreateClientDialog
        open={createClientDialogOpen}
        // selectedFamilies={families}
        isReferencePerson={true}
        onClose={() => setCreateClientDialogOpen(false)}
        clientId={clientId}
        indexClientId={context.clientId}
        onFinish={() =>
          queryClient.invalidateQueries(['clientData', 'clientDialogData'])
        }
        disableFamilyToggle
      />
    </Paper>
  );
};

export default ClientReferencePersonTable;
