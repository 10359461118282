import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { FC, useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';
import { FormElementProps, FormOptions } from '../../../../../types';
import FormElementHeader from '../form-element-header/form-element-header';
import { RadioGroupStyle } from '../../../../../utils/styling/styling';

interface FormRadioProps extends FormElementProps {
  id: string;
  options: FormOptions[];
  value?: any;
}

const FormRadio: FC<FormRadioProps> = (props) => {
  const [save, setSave] = useState(false);
  const [value, setValue] = useState(props.value ? props.value : 'none');
  const context = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );

  const checkErrors = (value: any) => {
    let currentError = null;
    if (value === 'none' && props.required)
      currentError = context.i18n.inputRequiredError;
    setError(currentError);
    return currentError;
  };

  useEffect(() => {
    checkErrors(value);
  }, []);

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);

  useEffect(() => {
    props.saved && props.onSave && props.onSave(value);
    props.saved ? setSave(!props.saved) : null;
    context.saveAll ? setSave(!context.saveAll) : null;
  }, [props.saved, context.saveAll]);

  const onChange = (key: number | string) => {
    if (!readOnly) {
      setSave(true);
      setValue(key);
      props.onChange && props.onChange(key, checkErrors(key));
    }
  };
  const onSave = () => {
    if (error) return;
    props.onSave ? props.onSave(value) : null;
    setSave(false);
  };

  const onCancel = () => {
    setValue(props.value ? props.value : 'none');
    setSave(false);
    props.onCancel ? props.onCancel() : null;
  };

  return (
    <Box sx={{ m: 2 }}>
      {props.title && (
        <FormElementHeader
          required={props.required}
          save={props.dialog || props.preview || readOnly ? false : save}
          onCancel={onCancel}
          onSave={onSave}
          hideExport={props.hideExport}
          title={props.title}
        />
      )}
      <FormControl error={error ? true : false} required={true}>
        <RadioGroup id={props.id} value={value} row={true} sx={RadioGroupStyle}>
          {props.options.map((value) => (
            <FormControlLabel
              id={props.id + '-' + value.key}
              key={value.key}
              value={value.key}
              onClick={() => onChange(value.key)}
              control={
                <Radio
                  sx={{
                    ml: 2,
                  }}
                  id={'radio-' + value.key}
                />
              }
              label={value.labelElement ? value.labelElement : value.label}
            />
          ))}
          <FormControlLabel
            value="none"
            sx={{ display: 'none' }}
            control={<Radio />}
            label="none"
          />
        </RadioGroup>
        <FormHelperText error>{error && error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default FormRadio;
