import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Tooltip,
  IconButton,
  Button,
  Checkbox,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { RiskApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { RiskAssessment } from '../../../types';

interface AddRiskAssessmentDialogProps {
  onClose: () => void;
  onAdd: (surveyId: string) => void;
  riskAssessments: RiskAssessment[];
}

const AddRiskAssessmentDialog: FC<AddRiskAssessmentDialogProps> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [selectedSubtype, setSelectedSubType] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  const selectRiskAssessment = (riskId: string) => {
    if (!selectedSubtype.find((type) => type === riskId)) {
      setSelectedSubType((subTypes) => {
        return [...subTypes, riskId];
      });
    } else {
      setSelectedSubType((subTypes) => {
        return subTypes.filter((type) => type !== riskId);
      });
    }
  };

  const addRiskAssessment = async (riskIds?: string[]) => {
    const res = await RiskApi.createRiskSurvey(
      context.authToken,
      context.clientId,
      riskIds ? riskIds : selectedSubtype,
    );
    queryClient.invalidateQueries('categoryData');
    props.onAdd(res.data.id);
  };

  return (
    <Box>
      <DialogTitle>{context.i18n.addRiskAssessment}</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <FormControl
          color="secondary"
          sx={{
            mt: 2,
            width: '22rem',
            display: 'flex',
          }}
        >
          <InputLabel
            color="secondary"
            sx={{ alignSelf: 'center' }}
            id="sub-type-slect-label"
          >
            {context.i18n.riskAssessmentSubtype}
          </InputLabel>
          <Select
            labelId="sub-type-slect-label"
            id="sub-type-select"
            label={context.i18n.riskAssessmentSubtype}
            value={selectedSubtype}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            renderValue={(selected) =>
              selected
                .map((id) => {
                  return props.riskAssessments?.find((risk) => risk.id === id)
                    ?.name;
                })
                .join(', ')
            }
            multiple
          >
            <MenuItem
              onClick={() => {
                setOpen(false);
                addRiskAssessment(props.riskAssessments.map((risk) => risk.id));
              }}
            >
              {context.i18n.allRiskAssessmentSubtypes}
            </MenuItem>
            {props.riskAssessments.map((riskAssessment) => (
              <MenuItem
                value={riskAssessment.id}
                key={riskAssessment.id}
                onClick={() => selectRiskAssessment(riskAssessment.id)}
              >
                <Checkbox
                  disableRipple
                  checked={selectedSubtype.indexOf(riskAssessment.id) > -1}
                />
                {riskAssessment.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            mt: 2,
            justifyContent: 'center',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            id="add-risk-assesment-btn"
            onClick={() => addRiskAssessment()}
          >
            {context.i18n.addRiskAssessment}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton
            onClick={props.onClose.bind(this)}
            id="cancel-risk-assessment-btn"
            color="primary"
          >
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Box>
  );
};

export default AddRiskAssessmentDialog;
