import { faPen, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, SvgIcon, TextField } from '@mui/material';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { FC, useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../hooks/context';
import { FormDateProps, FormDateType } from '../../../../../types';
import FormElementHeader from '../form-element-header/form-element-header';
import * as moment from 'moment';

const FormDate: FC<FormDateProps> = (props) => {
  const [inputValue, setInputValue] = useState<moment.Moment | null>(
    props.value === null ? null : moment(props.value),
  );
  const [disabled, setDisabled] = useState(true);
  const [save, setSave] = useState(false);
  const [open, setOpen] = useState(false);
  const context = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const [readOnly, setReadOnly] = useState<boolean>(
    props.readOnly ? true : false,
  );
  const checkErrors = (value: moment.Moment | null) => {
    let currentError = null;
    if ((value === null || value === undefined) && props.required)
      currentError = context.i18n.inputRequiredError;
    setError(currentError);
    return currentError;
  };
  useEffect(() => {
    checkErrors(inputValue);
  }, []);

  useEffect(() => {
    props.readOnly && setReadOnly(props.readOnly);
    context.readOnly && setReadOnly(context.readOnly);
  }, [props.readOnly, context.readOnly]);
  useEffect(() => {
    props.saved && props.onSave && props.onSave(inputValue);
    props.saved ? setDisabled(props.saved) : null;
    context.saveAll ? setSave(!context.saveAll) : null;
  }, [props.saved, context.saveAll]);

  const handleChange = (newValue: moment.Moment | null) => {
    if (newValue != null) {
      setSave(true);
      setInputValue(newValue);
      props.onChange && props.onChange(newValue, checkErrors(newValue));
    }
  };

  const onSave = () => {
    props.onSave ? props.onSave(inputValue) : null;
    setSave(false);
  };

  const onCancel = () => {
    setSave(false);
    setInputValue(props.value ? moment(props.value) : null);
    props.onCancel ? props.onCancel() : null;
  };

  return (
    <Box
      sx={{ m: 2 }}
      onClick={() => {
        if (!readOnly && !open) {
          setOpen(true);
          setDisabled(false);
        }
      }}
    >
      {props.title &&
        !props.dialog &&
        (!props.preview || !readOnly ? (
          <FormElementHeader
            save={save}
            onSave={onSave}
            onCancel={onCancel}
            hideExport={props.hideExport}
            title={props.title}
            required={props.required}
          />
        ) : (
          <FormElementHeader
            required={props.required}
            hideExport={props.hideExport}
            title={props.title}
          />
        ))}
      {props.format === FormDateType.datetime ? (
        <DateTimePicker
          value={inputValue ? moment(inputValue) : null}
          onClose={() => {
            setDisabled(true);
            setOpen(false);
          }}
          format="DD.MM.YYYY HH:mm"
          onChange={handleChange}
          slotProps={{
            inputAdornment: {
              onClick: () => {
                setOpen(!open);
                setDisabled(!disabled);
              },
              id: props.id,
            },
            openPickerButton: {
              disableRipple: true,
            },
            openPickerIcon: () =>
              disabled && !props.dialog && readOnly ? (
                <SvgIcon color="primary" sx={{ mr: 2 }}>
                  <FontAwesomeIcon icon={faPen} />
                </SvgIcon>
              ) : (
                <SvgIcon color="primary" sx={{ mr: 2 }}>
                  <FontAwesomeIcon icon={faX} />
                </SvgIcon>
              ),
            textField: {
              required: props.required,
              fullWidth: true,
              error: error !== null,
              helperText: error || '',
            },
          }}
          readOnly={disabled || readOnly}
          open={open}
          onOpen={() => setDisabled(false)}
        />
      ) : (
        <DatePicker
          views={
            props.format === FormDateType.date
              ? ['year', 'month', 'day']
              : ['year', 'month']
          }
          format={
            props.format === FormDateType.date ? 'DD.MM.YYYY' : 'MMMM yyyy'
          }
          onClose={() => {
            setDisabled(true);
            setOpen(false);
          }}
          label={props.dialog && props.title}
          slotProps={{
            inputAdornment: {
              onClick: () => {
                setOpen(!open);
                setDisabled(!disabled);
              },
              id: props.id,
            },
            openPickerButton: {
              disableRipple: true,
            },
            openPickerIcon: () => {
              return !readOnly ? (
                <>
                  {disabled ? (
                    <SvgIcon color="primary" sx={{ mr: 2 }}>
                      <FontAwesomeIcon icon={faPen} />
                    </SvgIcon>
                  ) : (
                    <SvgIcon color="primary" sx={{ mr: 2 }}>
                      <FontAwesomeIcon color="primary" icon={faX} />
                    </SvgIcon>
                  )}
                </>
              ) : (
                <></>
              );
            },
            textField: {
              required: props.required,
              fullWidth: true,
              error: error !== null,
              helperText: error || '',
            },
          }}
          readOnly={disabled || readOnly}
          open={open}
          onOpen={() => setDisabled(false)}
          value={inputValue ? inputValue : null}
          onChange={handleChange}
        />
      )}
    </Box>
  );
};

export default FormDate;
