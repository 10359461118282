import { axiosInstance } from '../../utils';

export default class ProtocolApi {
  static getProtocols(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('protocol/list', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getProtocol(
    token: string | undefined,
    protocolId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`protocol/${protocolId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getReleasedProtocols(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('protocol/list/inReview', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getProtocolsOfCurrentMonth(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .post(
        `protocol/month/${clientId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getFamilyProtocolsOfCurrentMonth(
    token: string | undefined,
    familyId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .post(
        `protocol/month/family/${familyId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static createProtocol(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('protocol', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateProtocol(
    token: string | undefined,
    protocolId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`protocol/${protocolId}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static exportProtocols(
    token: string | undefined,
    date: Date,
    releaseProtocolId?: string,
    releaseProtocolIds?: string[],
  ): Promise<any> {
    return axiosInstance
      .post(
        `protocol/export`,
        Object.assign(
          {
            month: date,
          },
          releaseProtocolId && {
            releaseProtocolId,
          },
          releaseProtocolIds && {
            releaseProtocolIds,
          },
        ),
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        },
      )
      .then((res) => {
        return res;
      });
  }

  static exportClientProtocols(
    token: string | undefined,
    clientId: string | undefined,
    date: Date,
    data?: {
      releaseProtocolId?: string;
      releaseProtocolIds?: string[];
      helpFormId?: string;
    },
  ): Promise<any> {
    return axiosInstance
      .post(
        `protocol/export/${clientId}`,
        Object.assign(
          {
            month: date,
          },
          data &&
            data.releaseProtocolId && {
              releaseProtocolId: data.releaseProtocolId,
            },
          data &&
            data.helpFormId && {
              helpFormId: data.helpFormId,
            },
        ),
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        },
      )
      .then((res) => {
        return res;
      });
  }

  static exportFamilyProtocols(
    token: string | undefined,
    familyId: string | undefined,
    date: Date,
    data?: {
      releaseProtocolId?: string;
      releaseProtocolIds?: string[];
      helpFormId?: string;
    },
  ): Promise<any> {
    return axiosInstance
      .post(
        `protocol/export/family/${familyId}`,
        Object.assign(
          {
            month: date,
          },
          data &&
            data.releaseProtocolId && {
              releaseProtocolId: data.releaseProtocolId,
            },
          data &&
            data.helpFormId && {
              helpFormId: data.helpFormId,
            },
        ),
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        },
      )
      .then((res) => {
        return res;
      });
  }

  static getProtocolSummary(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`protocol/summary/getSummary`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editProtocolSummary(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post(`protocol/summary/editSummary`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getProtocolBulletPoints(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`protocol/bullet-points/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getFamilyProtocolBulletPoints(
    token: string | undefined,
    familyId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(`protocol/bullet-points/family/${familyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editProtocolBulletPoints(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('protocol/bullet-points/edit', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static reviewProtocol(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('protocol/review', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static withdrawProtocol(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('protocol/withdraw', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteProtocol(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('protocol/delete', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static releaseProtocol(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('protocol/release', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static rejectProtocol(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('protocol/reject', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getProtocolInfo(
    token: string | undefined,
    body: any | undefined,
  ): Promise<any> {
    return axiosInstance
      .post('protocol/info', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getHelpForms(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('protocol/help-form/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getHelpFormRemainingTime(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('protocol/help-form/remaining/' + clientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getFamilyHelpForms(
    token: string | undefined,
    familyId: string | undefined,
  ): Promise<any> {
    if (!familyId) return new Promise(() => undefined);
    return axiosInstance
      .get('protocol/help-form/family/' + familyId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getHelpFormRemainingTimeFamily(
    token: string | undefined,
    familyId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('protocol/help-form/family/remaining/' + familyId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
