import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { context } from 'msw';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { AdminBillingApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { queryClient } from '../../../utils';
import { useQuery } from 'react-query';

interface EditProductDialogProps {
  open: boolean;
  onClose: () => void;
  id?: string;
}

export const EditProductDialog: FC<EditProductDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [price, setPrice] = useState('');
  const [priceError, setPriceError] = useState(false);

  const [taxRate, setTaxRate] = useState('20');
  const [taxRateError, setTaxRateError] = useState(false);

  const [grossHourlyRate, setGrossHourlyRate] = useState('');

  useQuery(
    ['getSevDeskProduct', props.id],
    () => AdminBillingApi.getSevDeskProduct(context.authToken, props.id),
    {
      enabled:
        props.open &&
        props.id !== undefined &&
        context.authToken !== undefined &&
        context.sevDeskActive,
      onSuccess: (res) => {
        setPrice(res.data.price);
        setName(res.data.name);
        setTaxRate(res.data.taxRate);

        const tax = (+res.data.price * +res.data.taxRate) / 100;

        setGrossHourlyRate(`${+res.data.price + tax}€ (${tax} €)`);
      },
    },
  );

  useEffect(() => {
    setNameError(false);
    setPriceError(false);
    setTaxRateError(false);

    if (!props.id) {
      setPrice('');
      setName('');
      setTaxRate('20');
      setGrossHourlyRate('');
    }
  }, [props.open]);

  const onSave = async () => {
    try {
      setNameError(name === '');
      setPriceError(price === '');
      setTaxRateError(taxRate === '');

      if (name === '' || price === '' || taxRate === '') {
        return;
      }

      const res = await AdminBillingApi.editOrCreateSevDeskProduct(
        context.authToken,
        Object.assign(
          { name, price, taxRate },
          props.id && {
            id: props.id,
          },
        ),
      );

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      queryClient.invalidateQueries(['sevDeskProducts']);
      props.onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const onPriceChange = (price: string) => {
    const tax = (+price * +taxRate) / 100;

    setPriceError(false);
    setPrice(price);

    setGrossHourlyRate(`${+price + tax}€ (${tax} €)`);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {props.id ? context.i18n.editProduct : context.i18n.createProduct}
      </DialogTitle>
      <DialogContent>
        <Stack m={2} spacing={2} width="22rem">
          <TextField
            value={name}
            error={nameError}
            label={context.i18n.name}
            onChange={(e) => {
              setNameError(false);
              setName(e.currentTarget.value);
            }}
          />
          <TextField
            value={price}
            error={priceError}
            label={context.i18n.netHourlyRate}
            type={'number'}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: 0,
            }}
            onChange={(e) => {
              onPriceChange(e.currentTarget.value);
            }}
          />
          <TextField
            value={taxRate}
            error={taxRateError}
            label={context.i18n.taxRate}
            type={'number'}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: 0,
            }}
            onChange={(e) => {
              setTaxRateError(false);
              setTaxRate(e.currentTarget.value);
            }}
          />
          <TextField
            value={grossHourlyRate}
            label={context.i18n.grossHourlyRate}
            disabled
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={onSave} disableRipple color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
