import { faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, IconButton, Zoom } from '@mui/material';
import { FC } from 'react';

interface AvatarSymbolButtonProps {
  id: string;
  url: string;
  greyNote?: boolean;
  greyIcon?: boolean;
  hideIcon?: boolean;
  hideNote?: boolean;
  disabled?: boolean;

  onNote: (id: string) => void;
  onSelect?: (id: string) => void;
}

const AvatarSymbolButton: FC<AvatarSymbolButtonProps> = (props) => {
  return (
    <Zoom in={!props.hideIcon}>
      <Badge
        sx={{
          color: '#1876d2',
          '& .MuiBadge-badge': Object.assign(
            {
              transform: 'translate(1px, 5px)',
            },
            props.greyNote && {
              filter: 'grayscale(1)',
              opacity: 0.5,
            },
            props.hideNote && {
              display: 'none',
            },
          ),
          display: props.hideIcon ? 'none' : 'inherit',
        }}
        badgeContent={
          <FontAwesomeIcon
            onClick={() => props.onNote(props.id)}
            size="lg"
            style={{
              cursor: 'pointer',
            }}
            icon={faNoteSticky}
          />
        }
      >
        <IconButton
          disableRipple
          disabled={props.disabled}
          sx={
            props.greyIcon
              ? {
                  filter: 'grayscale(1)',
                  opacity: 0.5,
                }
              : {}
          }
          onClick={() => {
            props.onSelect ? props.onSelect(props.id) : props.onNote(props.id);
          }}
        >
          <img width="45vh" src={props.url} />
        </IconButton>
      </Badge>
    </Zoom>
  );
};

export default AvatarSymbolButton;
