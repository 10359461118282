import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Paper, Tooltip } from '@mui/material';
import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { AxiosError } from 'axios';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { AppContext } from '../../../../hooks/context';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import PermissionsApi from '../../../../api/permissions/permissions';
import AddPermissionDialog from '../../../dialogs/add-permission-dialog/add-permission-dialog';
import { queryClient } from '../../../../utils';
import RemovePermissionDialog from '../../../dialogs/remove-permission-dialog/remove-permission-dialog';

const PermissionsTable: FC<{
  clientId?: string;
  familyId?: string;
}> = (props) => {
  const context = useContext(AppContext);

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();

  const { isLoading } = useQuery(
    ['permissions', props.clientId, props.familyId],
    () => {
      if (props.clientId) {
        return PermissionsApi.getClientPermissions(
          context.authToken,
          props.clientId,
        );
      } else if (props.familyId) {
        return PermissionsApi.getFamilyPermissions(
          context.authToken,
          props.familyId,
        );
      }
    },
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setRows(res.data);
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const tableColumns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: context.i18n.permittedUsers,
      flex: 1,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return `${params.row.firstName} ${params.row.lastName} (${params.row.email})`;
      },
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box>
          <Tooltip title={context.i18n.addPermission}>
            <IconButton
              disableRipple
              color="primary"
              onClick={() => {
                setOpenAddDialog(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => (
        <Tooltip
          title={
            params.row.role !== 'USER' || params.row.role !== 'BILLING'
              ? context.i18n.permissionNotDeletable
              : context.i18n.removePermission
          }
        >
          <span>
            <IconButton
              disableRipple
              color="primary"
              id={'edit-button-' + params.id}
              disabled={
                params.row.role !== 'USER' || params.id === context.userId
              }
              sx={{ height: 39 }}
              onClick={() => {
                setOpenRemoveDialog(true);
                setSelectedUser(params.row);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  return (
    <Paper
      sx={{
        maxHeight: '80vh',
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
      }}
    >
      <DataGridPro
        sx={{
          m: 2,
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: 0,
          display: 'flex',
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        loading={isLoading}
        columnBufferPx={4}
        // Pagination settings
        onPaginationModelChange={(model) => {
          setPageNum(model.page);
          setPageSize(model.pageSize);
        }}
        paginationModel={{ pageSize, page: pageNum }}
        pageSizeOptions={[10, 25, 50, 100]}
        // End Pagination settings
        // Sorting settings
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />
      <AddPermissionDialog
        open={openAddDialog}
        clientId={props.clientId}
        familyId={props.familyId}
        userList={rows}
        onClose={() => {
          queryClient.invalidateQueries('permissions');
          setOpenAddDialog(false);
        }}
      />
      <RemovePermissionDialog
        open={openRemoveDialog}
        clientId={props.clientId}
        familyId={props.familyId}
        user={selectedUser}
        onClose={() => {
          queryClient.invalidateQueries('permissions');
          setSelectedUser(undefined);
          setOpenRemoveDialog(false);
        }}
      />
    </Paper>
  );
};

export default PermissionsTable;
