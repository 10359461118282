import { Box } from '@mui/material';
import { FC } from 'react';
import { FormCategoryData } from '../../../../types';
import FormElement from '../form-elements/form-element/form-element';

export const FormCategoryRow: FC<{ array: FormCategoryData[] }> = (props) => {
  return (
    <Box sx={{ width: 1 }}>
      {props.array.map((element) => (
        <FormElement
          key={element.id}
          type={element.type}
          value={element.value ? element.value : element.children}
          title={element.label}
          dataType={element.dataType}
          id={element.id ? element.id : ''}
          fieldOptions={element.fieldOptions}
          required={element.required}
          preview={element.preview}
        />
      ))}
    </Box>
  );
};

export default FormCategoryRow;
