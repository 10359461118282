import { ToggleButton } from '@mui/material';
import { FC } from 'react';
import { AvatarPropButtonStyle } from '../../../utils/styling/styling';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AvatarPropButtonProps {
  url?: string;
  colorlessUrl?: string;
  selected: boolean;
  onSelect: (id: string, url?: string) => void;
  id: string;
  noneOption?: boolean;
}

const AvatarPropButton: FC<AvatarPropButtonProps> = (props) => {
  return (
    <ToggleButton
      selected={props.selected}
      onClick={() => {
        props.onSelect(props.id, props.colorlessUrl);
      }}
      value={props.id}
      sx={AvatarPropButtonStyle}
    >
      {props.noneOption ? (
        <FontAwesomeIcon size="3x" icon={faBan} />
      ) : (
        <img height="100%" width="100%" src={props.url} />
      )}
    </ToggleButton>
  );
};

export default AvatarPropButton;
