import { FormFieldTextType } from '@casecare/types';
import { Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useContext, useEffect, useState } from 'react';
import FamilyApi from '../../../api/family/family';
import { AppContext } from '../../../hooks/context';
import { Family, FamilyMember } from '../../../types';
import { TextSize } from '../../../types/form-text-props';
import { queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElementHeader, FormText, UserSelect } from '../../client';
import { TransferFamilyDataDialog } from '../../dialogs';

interface FamilyDataProps {
  family: Family;
  onChanges: (changes: boolean) => void;
  saved: boolean;
  familyMembers: FamilyMember[];
}

const FamilyData: FC<FamilyDataProps> = (props) => {
  const context = useContext(AppContext);

  const [name, setName] = useState(props.family.name);
  const [street, setStreet] = useState(props.family.data.STREET);
  const [zipcode, setZipcode] = useState(props.family.data.ZIPCODE);
  const [city, setCity] = useState(props.family.data.CITY);
  const [familyNumber, setFamilyNumber] = useState(
    props.family.data.FAMILY_NUMBER,
  );
  const [supervisorIds, setSupervisorIds] = useState(
    props.family.data.SUPERVISOR,
  );
  const [saved, setSaved] = useState(false);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [body, setBody] = useState<any>();

  useEffect(() => {
    props.onChanges(getChanges());
  }, [name, street, zipcode, city, props.family, supervisorIds]);

  const getChanges = (): boolean => {
    return (
      name !== props.family.name ||
      street !== props.family.data.STREET ||
      zipcode !== props.family.data.ZIPCODE ||
      city !== props.family.data.CITY ||
      familyNumber !== props.family.data.FAMILY_NUMBER ||
      (supervisorIds.join('') !== props.family.data.SUPERVISOR.join('') &&
        supervisorIds.length !== 0)
    );
  };

  useEffect(() => {
    if (props.saved) {
      setSaved(true);
      onSave(
        Object.assign(
          {},
          city !== props.family.data.CITY && { CITY: city },
          zipcode !== props.family.data.ZIPCODE && { ZIPCODE: zipcode },
          street !== props.family.data.STREET && { STREET: street },
          familyNumber !== props.family.data.FAMILY_NUMBER && {
            FAMILY_NUMBER: familyNumber,
          },
          supervisorIds !== props.family.data.SUPERVISOR && {
            SUPERVISOR: supervisorIds,
          },
        ),
      );
    }
  }, [props.saved]);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
  }, [saved]);

  const onSave = (data?: any) => {
    if (!getChanges()) {
      return;
    }
    setOpenTransferDialog(true);
    const body = Object.assign(
      {},
      name !== props.family.name && { name },
      data !== undefined && {
        data,
      },
    );

    setBody(body);
  };

  const uploadChanges = async (clientIds: string[], transferData: boolean) => {
    try {
      const res = await FamilyApi.updateFamily(
        context.authToken,
        props.family.id,
        Object.assign(body, { clientIds, transferData }),
      );

      if (res.statusCode === 201) {
        snackbarUtils.success(res.message);
      } else {
        snackbarUtils.error(res.message);
      }
      queryClient.invalidateQueries('family');
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        borderRadius: 0,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id="name"
            size={TextSize.NORMAL}
            title={context.i18n.name}
            value={name}
            onChange={(value) => setName(value)}
            onCancel={() => setName(props.family.name)}
            onSave={() => !saved && onSave()}
            saved={saved}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id="familyNumber"
            size={TextSize.NORMAL}
            title={context.i18n.clientNumber}
            value={familyNumber}
            onChange={(value) => setFamilyNumber(value)}
            onCancel={() => setName(props.family.data.FAMILY_NUMBER)}
            onSave={(value: string) =>
              !saved && onSave({ FAMILY_NUMBER: value })
            }
            saved={saved}
          />
        </Grid>{' '}
        <Grid item xs={12} md={6}>
          <Box m={2}>
            <FormElementHeader
              required={false}
              hideExport={true}
              title={context.i18n.supervisor}
              save={
                supervisorIds.join('') !==
                  props.family.data.SUPERVISOR.join('') &&
                supervisorIds.length !== 0
              }
              onSave={() => onSave({ SUPERVISOR: supervisorIds })}
            />
            <UserSelect
              multiple
              title={context.i18n.supervisor}
              userIds={supervisorIds}
              error={false}
              setId={(ids) => {
                setSupervisorIds(ids);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id="street"
            size={TextSize.NORMAL}
            title={context.i18n.street}
            value={props.family.data.STREET}
            onChange={(value) => setStreet(value)}
            onCancel={() => setStreet(props.family.data.STREET)}
            onSave={(value: string) => !saved && onSave({ STREET: value })}
            saved={saved}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id="city"
            size={TextSize.NORMAL}
            title={context.i18n.city}
            value={props.family.data.CITY}
            onChange={(value) => setCity(value)}
            onCancel={() => setCity(props.family.data.CITY)}
            onSave={(value: string) => !saved && onSave({ CITY: value })}
            saved={saved}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormText
            dataType={FormFieldTextType.TEXT}
            hideExport
            id="zipcode"
            size={TextSize.NORMAL}
            title={context.i18n.zipcode}
            value={props.family.data.ZIPCODE}
            onChange={(value) => setZipcode(value)}
            onCancel={() => setZipcode(props.family.data.ZIPCODE)}
            onSave={(value: string) => !saved && onSave({ ZIPCODE: value })}
            saved={saved}
          />
        </Grid>
        <TransferFamilyDataDialog
          open={openTransferDialog}
          onClose={() => {
            setOpenTransferDialog(false);
            uploadChanges([], false);
          }}
          onTransfer={(transferAll, clientIds) => {
            setOpenTransferDialog(false);
            uploadChanges(clientIds || [], transferAll);
          }}
          familyMembers={props.familyMembers}
        />
      </Grid>
    </Paper>
  );
};

export default FamilyData;
