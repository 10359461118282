import { Box, IconButton, Paper, Tooltip } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../hooks/context';
import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalculator,
  faCodeCompare,
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { EcoMapDataGridElement } from '../../../types';
import { parseDateTimeString } from '../../../utils';
import AddEcoMapDialog from '../../dialogs/add-eco-map-dialog/add-eco-map-dialog';
import EcoMapApi from '../../../api/eco-map/eco-map';
import { useQuery } from 'react-query';
import { EcoMap } from '../../../types/eco-map';
import DeleteEcoMapDialog from '../../dialogs/delete-eco.map/delete-eco-map';

const EcoMapTable: FC<{
  clientId?: string;
}> = (props) => {
  const context = useContext(AppContext);

  const [rows, setRows] = useState<EcoMapDataGridElement[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentEcoMapId, setCurrentEcoMapId] = useState<any>(undefined);

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rowCount, setRowCount] = useState(0);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['ecoMaps', pageSize, pageNum, sortModel],
    queryFn: () => {
      const data: any = {
        pageNum,
        pageSize,
        sortModel,
      };
      return EcoMapApi.listEcoMap(context.authToken, props.clientId, data);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error(error);
      } else if (data && data.data) {
        setRows(
          data.data.ecoMaps.map((ecoMap: EcoMap) => {
            return {
              id: ecoMap.id,
              name: ecoMap.name,
              creationDate: parseDateTimeString(ecoMap.creationDate),
              updateDate: parseDateTimeString(ecoMap.updateDate),
              actions: true,
            };
          }),
        );
        setRowCount(data.data.totalCount);
      }
    }
  }, [data, error, isLoading]);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: context.i18n.name,
      flex: 1,
      hideable: false,
      sortable: false,
    },
    {
      field: 'creationDate',
      headerName: context.i18n.creationDate,
      flex: 1,
      sortable: false,
      hideable: false,
    },
    {
      field: 'updateDate',
      headerName: context.i18n.editDate,
      flex: 1,
      sortable: false,
      hideable: false,
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      width: 200,
      sortable: false,
      renderHeader: () => (
        <Box>
          <Tooltip title={context.i18n.ecoMapAddDialog}>
            <IconButton
              disableRipple
              color="primary"
              onClick={() => {
                setOpenAddDialog(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.ecoMapCompare}>
            <IconButton
              disableRipple
              href={'/eco-map/compare/' + context.clientId}
              aria-label="launch"
              color="primary"
              target="_blank"
              sx={{ height: 39 }}
            >
              <FontAwesomeIcon icon={faCodeCompare} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => (
        <>
          <Tooltip
            title={context.i18n.formatString(
              context.i18n.editFamilyX,
              rows.find((r) => r.id === params.id)?.name,
            )}
          >
            <IconButton
              disableRipple
              href={'/eco-map/details/' + context.clientId + '/' + params.id}
              aria-label="launch"
              color="primary"
              target="_blank"
              id={'edit-button-' + params.id}
              sx={{ height: 39 }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={context.i18n.formatString(
              context.i18n.deleteEcoMap,
              rows.find((r) => r.id === params.id)?.name,
            )}
          >
            <IconButton
              disableRipple
              onClick={() => {
                setCurrentEcoMapId(params.id);
                setOpenDeleteDialog(true);
              }}
              aria-label="launch"
              color="primary"
              id={'delete-button-' + params.id}
              sx={{ height: 39 }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
      }}
    >
      <DataGridPro
        sx={{
          fontSize: 17,
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        columnBufferPx={4}
        loading={isLoading}
        pageSizeOptions={[10, 25, 50, 100]}
        // pageSize={pageSize}
        rowCount={rowCount}
        // sortingMode="server"
        // sortModel={sortModel}
        // onSortModelChange={(model) => setSortModel(model)}
        // sortingOrder={['desc', 'asc']}
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />

      <AddEcoMapDialog
        open={openAddDialog}
        clientId={props.clientId}
        onClose={() => {
          refetch();
          setOpenAddDialog(false);
        }}
      />
      <DeleteEcoMapDialog
        open={openDeleteDialog}
        ecoMapId={currentEcoMapId}
        onClose={() => {
          refetch();
          setOpenDeleteDialog(false);
        }}
      />
    </Paper>
  );
};

export default EcoMapTable;
