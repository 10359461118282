import { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AppContext } from '../../../hooks/context';
import LoginInput from '../../public/login-input/login-input';
import { selectableRoles } from '../../../types/roles';
import { useQueryClient } from 'react-query';
import { AdminApi } from '../../../api';
import { EditUser } from '../../../types/user-dto';

export interface User {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  role: string;
  creationDate: string;
  status: string;
  handle: string;
  academicGradePre: string;
  academicGradeAft: string;
  companyPhoneNumber: string;
}

interface EditUserDialogProps {
  existingUser?: User;
  open: boolean;
  onClose: () => void;
}

const EditUserDialog: FC<EditUserDialogProps> = (
  props: EditUserDialogProps,
) => {
  const { open, onClose, existingUser } = props;
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [handle, setHandle] = useState('');
  const [role, setRole] = useState('USER');
  const [status, setStatus] = useState('');
  const [academicGradePre, setAcademicGradePre] = useState('');
  const [academicGradeAft, setAcademicGradeAft] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const queryClient = useQueryClient();
  const context = useContext(AppContext);

  useEffect(() => {
    if (existingUser) {
      setEmail(existingUser.email);
      setFirstName(existingUser.firstName);
      setLastName(existingUser.lastName);
      setRole(existingUser.role);
      setActive(existingUser.status === 'ACTIVE');
      setStatus(existingUser.status);
      setHandle(existingUser.handle);
      setAcademicGradePre(existingUser.academicGradePre);
      setAcademicGradeAft(existingUser.academicGradeAft);
      setCompanyPhoneNumber(existingUser.companyPhoneNumber);
    }
  }, [existingUser]);

  const closeAndReload = () => {
    resetState();
    queryClient.invalidateQueries('getUsers');
    onClose();
  };

  const resetState = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setRole('USER');
    setActive(true);
    setHandle('');
    setAcademicGradeAft('');
    setAcademicGradePre('');
    setCompanyPhoneNumber('');
  };

  const editUser = async () => {
    if (
      existingUser &&
      (email !== existingUser?.email ||
        firstName !== existingUser?.firstName ||
        lastName !== existingUser?.lastName ||
        role !== existingUser?.role ||
        active !== (existingUser.status === 'ACTIVE') ||
        existingUser?.handle !== handle ||
        existingUser.academicGradeAft !== academicGradeAft ||
        existingUser.academicGradePre !== academicGradePre ||
        existingUser.companyPhoneNumber !== companyPhoneNumber)
    ) {
      setLoading(true);
      const update: EditUser = {
        userId: existingUser.id,
      };
      if (existingUser.firstName !== firstName) {
        update.firstName = firstName;
      }
      if (existingUser.lastName !== lastName) {
        update.lastName = lastName;
      }
      if (existingUser.handle !== handle) {
        update.handle = handle;
      }
      if (existingUser.role !== role) {
        update.role = role;
      }
      if ((existingUser.status === 'ACTIVE') !== active) {
        update.active = active;
      }
      if (existingUser.academicGradeAft !== academicGradeAft) {
        update.academicGradeAft = academicGradeAft;
      }
      if (existingUser.academicGradePre !== academicGradePre) {
        update.academicGradePre = academicGradePre;
      }
      if (existingUser.companyPhoneNumber !== companyPhoneNumber) {
        update.companyPhoneNumber = companyPhoneNumber;
      }
      AdminApi.editUser(context.authToken, update)
        .then((res) => {
          snackbarUtils.success(res.message || context.i18n.success);
          closeAndReload();
        })
        .catch((error) => {
          const errorData = error.response.data;
          errorData.detail?.forEach((message: string) => {
            snackbarUtils.error(message);
          });
        });
    } else {
      snackbarUtils.success(context.i18n.noChanges);
      closeAndReload();
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetState();
        onClose();
      }}
    >
      <DialogTitle>{context.i18n.editUser}</DialogTitle>
      <DialogContent>
        <LoginInput
          onChange={setEmail.bind(this)}
          label={context.i18n.email}
          initialValue={email}
          disabled
        />
        <LoginInput
          label={context.i18n.academicPre}
          onChange={setAcademicGradePre.bind(this)}
          initialValue={academicGradePre}
        />
        <LoginInput
          label={context.i18n.firstName}
          onChange={setFirstName.bind(this)}
          initialValue={firstName}
        />
        <LoginInput
          label={context.i18n.lastName}
          onChange={setLastName.bind(this)}
          initialValue={lastName}
        />
        <LoginInput
          label={context.i18n.academicAft}
          onChange={setAcademicGradeAft.bind(this)}
          initialValue={academicGradeAft}
        />
        <LoginInput
          label={context.i18n.phoneNumberCompany}
          onChange={setCompanyPhoneNumber.bind(this)}
          initialValue={companyPhoneNumber}
        />
        <LoginInput
          label={context.i18n.handle}
          onChange={setHandle.bind(this)}
          initialValue={handle}
        />
        {role === 'SUPERADMIN' ? (
          <LoginInput
            label={context.i18n.role}
            onChange={() => console.log('DISABLED')}
            value={context.i18n.SUPERADMIN}
            disabled
          />
        ) : (
          <FormControl color="secondary" sx={{ mt: 2, width: '22rem' }}>
            <InputLabel sx={{ alignSelf: 'center' }} id="role-slect-label">
              {context.i18n.role}
            </InputLabel>
            <Select
              labelId="role-select-label"
              label={context.i18n.role}
              value={role}
              disabled={context.userId === existingUser?.id}
            >
              {selectableRoles.map((r) => (
                <MenuItem key={r} value={r} onClick={() => setRole(r)}>
                  {context.i18n[r]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box display="flex" justifyContent="center">
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={() => setActive(!active)}
                  disabled={
                    role === 'SUPERADMIN' ||
                    !['ACTIVE', 'DEACTIVATED'].includes(status) ||
                    context.userId === existingUser?.id
                  }
                />
              }
              label={`${context.i18n.userActive}?`}
            />
          </FormControl>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{ mt: 2 }}
        >
          <Button
            color="primary"
            variant="contained"
            id="loginBtn"
            onClick={() => {
              editUser();
            }}
            className="formBtn"
            disabled={email === '' || firstName === '' || lastName === ''}
            sx={{ color: 'white', mr: 'auto', ml: 'auto' }}
          >
            {loading ? (
              <CircularProgress sx={{ color: 'white' }} />
            ) : (
              context.i18n.editUser
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditUserDialog;
