import { ReferencePersonRole, RelationshipType } from '@casecare/types';
import { Paper } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { ClientApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AddFamilyMemberDialog, CreateClientDialog } from '../../dialogs';
import { FamilyMemberTable } from '../../family';

interface ClientFamilyTableProps {
  familyMembers: any[];
  family: any;
  clientId: string | undefined;
}

const ClientFamilyTable: FC<ClientFamilyTableProps> = (props) => {
  const context = useContext(AppContext);

  const [familyMembers, setFamilyMembers] = useState<any[]>([]);
  const [family, setFamily] = useState<any>(undefined);
  const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
  const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
  const [clientId, setClientId] = useState();

  useEffect(() => {
    setFamily(props.family);
  }, [props.family]);

  useEffect(() => {
    setFamilyMembers(props.familyMembers);
  }, [props.familyMembers]);

  const addRelation = async (id: any, type: RelationshipType) => {
    try {
      await ClientApi.updateClientRelation(
        context.authToken,
        context.clientId,
        id,
        type,
      );
      queryClient.invalidateQueries('clientData');
    } catch (e) {
      snackbarUtils.error(context.i18n.error);
    }
  };

  const addRole = async (id: string, role: ReferencePersonRole) => {
    await ClientApi.updateClientRole(context.authToken, id, role);
    queryClient.invalidateQueries('clientData');
  };

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
      }}
    >
      <FamilyMemberTable
        isClientTable
        familyMembers={familyMembers}
        isReferencePersonTable={false}
        onCreateClient={() => {
          setClientId(undefined);
          setCreateClientDialogOpen(true);
        }}
        onAddClient={() => {
          setAddClientDialogOpen(true);
        }}
        onOpenClientDialog={(id) => {
          setClientId(id);
          setCreateClientDialogOpen(true);
        }}
        onAddRelation={addRelation}
        onAddRole={addRole}
        onArchiveClient={() => queryClient.invalidateQueries('clientData')}
      />
      <AddFamilyMemberDialog
        open={addClientDialogOpen}
        onClose={() => setAddClientDialogOpen(false)}
        familyId={family?.id}
        onAddClient={() => {
          setAddClientDialogOpen(false);
          queryClient.invalidateQueries('clientData');
        }}
      />
      <CreateClientDialog
        open={createClientDialogOpen}
        // selectedFamily={family}
        isReferencePerson={false}
        onClose={() => setCreateClientDialogOpen(false)}
        clientId={clientId}
        onFinish={() => queryClient.invalidateQueries('clientData')}
        indexClientId={props.clientId}
        clientFamily={family}
        clientFamilyDialog
        disableFamilyToggle
      />
    </Paper>
  );
};

export default ClientFamilyTable;
