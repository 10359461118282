import { FC, useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { AppContext } from '../hooks/context';
import { getCookie } from '../utils';
import { authCookieName } from '../utils/constants';

const AuthenticatedRoute: FC = () => {
  const context = useContext(AppContext);
  const isAuthenticated = getCookie(authCookieName)
    ? context.isAuthenticated
    : false;

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthenticatedRoute;
