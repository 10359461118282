import { FC, useContext, useEffect, useState } from 'react';
import { BackgroundWave } from '../../../utils/background/background';
import { Box, ClickAwayListener, Toolbar, useMediaQuery } from '@mui/material';
import MenuDrawer from '../menu-drawer/menu-drawer';
import Navbar from '../navbar/navbar';
import { CategoryData } from '../../../types';
import { theme } from '../../../utils';
import { drawerWidthSpaced } from '../../../utils/constants';
import { AppContext } from '../../../hooks/context';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { AdminApi, StorageApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import BreadCrumb from '../breadcrumb/breadcrumb';

interface NavbarWrapperProps {
  title: string;
  selected?: string;
  subTitle?: string;
  admin?: boolean;
  storage?: boolean;
  billing?: boolean;
  adminBilling?: boolean;
  addClient?: boolean;
  search?: boolean;
  addFamily?: boolean;
  showCategories?: boolean;
  customBreadCrumb?: boolean;
  onSelectCategory?: (id: string) => void;
}

const NavbarWrapper: FC<NavbarWrapperProps> = (props) => {
  const context = useContext(AppContext);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ overflowX: 'clip' }}>
      <Navbar {...props} hideMenuDrawer />
      <BreadCrumb customBreadCrumb={props.customBreadCrumb} />
      {props.children}
    </Box>
  );
};

export default NavbarWrapper;
