import { FC, useContext, useEffect } from 'react';
import { ClientTable } from '../../../components/client';
import { MenuWrapper } from '../../../components/core';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle, theme } from '../../../utils';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ContactTable, TokenCard } from '../../../components/admin/billing';

export const AdminBilling: FC = () => {
  const context = useContext(AppContext);

  useEffect(() => {
    setDocumentTitle(context.i18n.billingDetails);
    return () => loadTitle();
  }, []);

  return (
    <MenuWrapper
      admin
      search
      addClient
      title={context.i18n.billingDetails}
      selected="admin-billing"
      adminBilling
    >
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
      >
        <TokenCard />
        <Divider sx={{ m: 2 }} />
      </Box>
    </MenuWrapper>
  );
};
