import {
  faLink,
  faPen,
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AdminApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { loadTitle, setDocumentTitle } from '../../../utils';
import { MenuWrapper } from '../../../components/core';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import DeleteHelpFormOrdererDialog from '../../../components/admin/help-form-orderer/delete-help-form-dialog';
import CreateHelpFormOrdererDialog from '../../../components/admin/help-form-orderer/create-help-form-orderer-dialog';
import EditHelpFormOrdererDialog from '../../../components/admin/help-form-orderer/edit-help-form-orderer-dialog';
import LinkSevDeskOrdererDialog from '../../../components/dialogs/link-sevdesk-contact-dialog/link-sevdesk-contact-dialog';
import LinkSevDeskContactDialog from '../../../components/dialogs/link-sevdesk-contact-dialog/link-sevdesk-contact-dialog';

interface DataGridElement {
  id: string;
  username: string;
  isAdmin: boolean;
}

const HelpFormOrderer = () => {
  const context = useContext(AppContext);
  const [createHelpFormOrdererDialogOpen, setCreateHelpFormOrdererDialogOpen] =
    useState(false);
  const [editHelpFormOrdererDialogOpen, setEditHelpFormOrdererDialogOpen] =
    useState(false);
  const [deleteHelpFormOrdererDialogOpen, setDeleteHelpFormOrdererDialogOpen] =
    useState(false);
  const [rows, setRows] = useState<DataGridElement[]>([]);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [selectedHelpFormOrderer, setSelectedHelpFormOrderer] = useState<any>(
    {},
  );

  const [linkSevdeskOrdererDialogOpen, setLinkSevdeskOrdererDialogOpen] =
    useState(false);

  useEffect(() => {
    setDocumentTitle(context.i18n.orderer);
    return () => loadTitle();
  }, []);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['getHelpFormOrderers'],
    queryFn: () => AdminApi.getHelpFormOrderer(context.authToken),
    enabled: context.authToken !== undefined && context.isAdmin,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (data && data.data && data.data.orderer) {
      setRows(data.data.orderer);
      setTotalRowCount(data.data.orderer.length);
    }
  }, [data, error]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: context.i18n.name,
      editable: false,
      flex: 1,
    },
    {
      field: 'street',
      headerName: context.i18n.street,
      editable: false,
      flex: 1,
    },
    {
      field: 'zipcode',
      headerName: context.i18n.zipcode,
      editable: false,
      flex: 1,
    },
    {
      field: 'city',
      headerName: context.i18n.city,
      editable: false,
      flex: 1,
    },
    {
      field: context.i18n.actions,
      headerName: '',
      flex: 1,
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <Tooltip title={context.i18n.createHelpForm}>
          <IconButton
            disableRipple
            color="primary"
            id="invite-user-button"
            data-id="invite-user-button"
            onClick={() => setCreateHelpFormOrdererDialogOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Box>
          {context.sevDeskActive && (
            <Tooltip title={context.i18n.sevDeskContactLink}>
              <IconButton
                disableRipple
                sx={{ height: 39 }}
                color="primary"
                id="sevdesk-contact-link-button"
                data-id="sevdesk-contact-link-button"
                onClick={() => {
                  setSelectedHelpFormOrderer(params.row);
                  setLinkSevdeskOrdererDialogOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faLink} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={context.i18n.editHelpForm}>
            <IconButton
              disableRipple
              sx={{ height: 39 }}
              color="primary"
              id="edit-help-form-button"
              data-id="edit-help-form-button"
              onClick={() => {
                setSelectedHelpFormOrderer(params.row);
                setEditHelpFormOrdererDialogOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.deleteHelpForm}>
            <IconButton
              disableRipple
              sx={{ height: 39 }}
              color="error"
              id="delete-help-form-button"
              data-id="delete-help-form-button"
              onClick={() => {
                setSelectedHelpFormOrderer(params.row);
                setDeleteHelpFormOrdererDialogOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <MenuWrapper
      title={context.i18n.orderer}
      selected="admin-help-form-orderer"
      addClient
      search
      admin
    >
      <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
        <DataGridPro
          sx={{
            m: 5,
          }}
          columns={columns}
          rows={rows}
          loading={isLoading}
          getRowId={(row) => row.id}
          rowCount={totalRowCount}
          autoHeight
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
        />
      </Box>
      <CreateHelpFormOrdererDialog
        open={createHelpFormOrdererDialogOpen}
        onClose={() => {
          setCreateHelpFormOrdererDialogOpen(false);
          refetch();
        }}
      />
      <EditHelpFormOrdererDialog
        open={editHelpFormOrdererDialogOpen}
        onClose={() => {
          setEditHelpFormOrdererDialogOpen(false);
          setSelectedHelpFormOrderer({});
          refetch();
        }}
        selected={selectedHelpFormOrderer}
      />
      <DeleteHelpFormOrdererDialog
        open={deleteHelpFormOrdererDialogOpen}
        onClose={() => {
          setDeleteHelpFormOrdererDialogOpen(false);
          setSelectedHelpFormOrderer({});
          refetch();
        }}
        helpFormOrdererId={selectedHelpFormOrderer.id}
      />
      <LinkSevDeskContactDialog
        open={linkSevdeskOrdererDialogOpen}
        onClose={() => {
          setLinkSevdeskOrdererDialogOpen(false);
          setSelectedHelpFormOrderer({});
          refetch();
        }}
        orderer={selectedHelpFormOrderer}
      />
    </MenuWrapper>
  );
};

export default HelpFormOrderer;
