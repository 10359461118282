import { faFolder, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../../hooks/context';
import { theme } from '../../../../utils';

interface CreateCategoryDialogProps {
  onCreate: () => void;
  onImport: () => void;
}

const buttonStyle = {
  backgroundColor: theme.palette.primary.main,
  width: 110,
  borderRadius: 4,
};

const CreateCategoryDialog: FC<CreateCategoryDialogProps> = (props) => {
  const context = useContext(AppContext);

  return (
    <Box>
      <DialogTitle>{context.i18n.createCategory}</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <Stack
            direction="column"
            alignItems="center"
            sx={buttonStyle}
            padding={2}
            onClick={props.onImport.bind(this)}
          >
            <IconButton id="import-button" disableRipple>
              <FontAwesomeIcon color="white" icon={faFolder} />
            </IconButton>
            <Typography color="white" textTransform="none">
              {context.i18n.import}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            padding={2}
            sx={buttonStyle}
            onClick={props.onCreate.bind(this)}
          >
            <IconButton id="create-button" disableRipple>
              <FontAwesomeIcon color="white" icon={faPlus} />
            </IconButton>
            <Typography color="white" textTransform="none">
              {context.i18n.newCategory}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Box>
  );
};

export default CreateCategoryDialog;
