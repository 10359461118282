import { CategoryElement } from '../../types';
import { axiosInstance } from '../../utils';

export default class FormApi {
  // to-do change url to form/{clientId}/{categoryId}

  static updateFormField(
    clientId: string | undefined,
    token: string | undefined,
    element: CategoryElement | undefined,
  ) {
    return axiosInstance
      .post(
        'form/' + clientId,
        {
          update: [element],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static updateFormFields(
    clientId: string | undefined,
    token: string | undefined,
    elements: CategoryElement[] | undefined,
  ) {
    return axiosInstance
      .post(
        'form/' + clientId,
        {
          update: elements,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getCategoryData(
    clientId: string | undefined,
    categoryId: string | undefined,
    token: string | undefined,
  ) {
    return axiosInstance
      .get('form/' + clientId + '/' + categoryId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getCategories(
    id: string | undefined,
    token: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('form/' + id, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        return res.data;
      });
  }
}
