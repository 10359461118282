import { faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, IconButton } from '@mui/material';
import { FC } from 'react';
import { CirclePicker, ColorResult } from 'react-color';

interface AvatarColorPickerProps {
  header: string;
  showHeader: boolean;
  onClickPicker: () => void;
  onChangeColor: (color: string) => void;
  color: string;
  colorOptions?: string[];
}

const AvatarColorPicker: FC<AvatarColorPickerProps> = (props) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="flexStart">
      {props.showHeader && <Typography>{props.header}</Typography>}
      <IconButton
        sx={{ alignSelf: 'flex-start' }}
        disableRipple
        onClick={props.onClickPicker}
      >
        <FontAwesomeIcon icon={faPaintBrush} />
      </IconButton>
      <Box width="60px">
        <CirclePicker
          onChange={(col: ColorResult) => props.onChangeColor(col.hex)}
          color={props.color}
          colors={
            props.colorOptions
              ? props.colorOptions
              : ['#0079FF', '#00DFA2', '#F6FA70', '#FF0060']
          }
          styles={{
            default: {
              card: {
                justifyContent: 'center',
              },
            },
          }}
          circleSize={40}
          width="50px"
        />
      </Box>
    </Box>
  );
};

export default AvatarColorPicker;
